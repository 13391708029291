import React, { useEffect, useState } from "react";
import NavMenu from "../Navbar";
import Footer from "../Footer";
import Image from "../../assets/img/brands2/Group 10.png";
import Image1 from "../../assets/img/brands2/paper.jpg";
import Image2 from "../../assets/img/brands2/organic.jpg";
import Image3 from "../../assets/img/brands2/little-bottle.jpg";
import Image4 from "../../assets/img/brands2/pump-spray.jpg";
import Image5 from "../../assets/img/brands2/image 50.png";
import Image6 from "../../assets/img/brands2/image 51.png";
import Image7 from "../../assets/img/brands2/image 52.png";
import Image8 from "../../assets/img/brands2/image 53.png";
import "../Brands2/L&D.css";
import RectImgL from "../../assets/img/brands2/Group 44.png";
import { getBrandsWithId, getProductViaCategory } from "../../api/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ColorRing, TailSpin } from "react-loader-spinner";

export default function LandD() {
  const navigate = useNavigate();
  const [StpData, setStpData] = useState([]);
  const location = useLocation();
  // const { brandid, brandName } = location?.state;
  const {  id } = useParams();
  const [StpCategory, setStpCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [ProductId, setProductId] = useState("");
  const [clicked, setClicked] = useState(false);
  const [pageLoading, setPageLoading] = useState(true)
  const [cartValues, setCartValues] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false)
    }, 500);
    getBrandsById(7);
    getProductbyCategory(ProductId);
    console.log("==productId", ProductId);
    window.scrollTo(0, 0);
    console.log("-->brand armorall", ProductId);
  }, [7, ProductId]);

  const getBrandsById = (id) => {
    const url = getBrandsWithId(id);
    console.log("brandUrl", url);
    axios
      .get(url)
      .then((resp) => {
        console.log("--resp", resp.data);
        setStpData(resp?.data?.brandwise_category_data);
        setProductId(resp.data.brandwise_category_data[0].category_id);
        console.log("- - - category - - - ", resp.data.brandwise_category_data);
      })
      .catch((error) => {
        console.log("--error--", error);
      });
  };

  const getProductbyCategory = (ProductId) => {
    const url = getProductViaCategory(ProductId);

    axios
      .get(url)
      .then((resp) => {
        console.log("--resp in brand product", resp);
        setStpCategory(resp.data.data.product_data);
        // setProductId(resp.data.data.product_data[0].id)
        console.log(
          "--- - - - - setting id",
          resp.data.data.product_data
        );
      })
      .catch((error) => {
        console.log("--error", error);
      });
  };

  const changeProduct = (id) => {
    console.log("...--changeProduct", changeProduct);
    getProductbyCategory(id);
    setCategoryId(id);
  };

  const stpProductWrld = () => {
    console.log("---strproductWorld", id);
    // localStorage.clear();
    localStorage.removeItem('selectedItems');
    window.localStorage.removeItem("CategoryCheckdDataLocal");
    window.localStorage.removeItem('packCheckedDataLocal');
    window.localStorage.removeItem('categoryValue2');
    window.localStorage.removeItem('')
    localStorage.removeItem("selectedItems2");
    localStorage.removeItem("selectedItems3");
    localStorage.removeItem("selectedItems4");
    localStorage.removeItem("brandsCheckdDataLocal");
    localStorage.removeItem("ddvalue");
    localStorage.removeItem("CategoryCheckdDataLocal");
    localStorage.removeItem("bcvalue");
    localStorage.removeItem("packCheckedDataLocal");
    localStorage.removeItem("packsvalue");
    localStorage.removeItem("resbrandsCheckdDataLocal");
    localStorage.removeItem("resddvalue");
    localStorage.removeItem("resCategoryCheckdDataLocal");
    localStorage.removeItem("resbcvalue");
    localStorage.removeItem("respackCheckedDataLocal");
    localStorage.removeItem("respacksvalue");
    navigate("/L&D/Products", {
      state: {
        categoryById: categoryId == "" ? ProductId : categoryId,
        brandID: 7,
      },
    });
  };
  const sendProdId = (id) => {
    console.log(" ------ id", id);
    navigate(`/product/${id}`, { state: { productId: id, clicked: clicked } });
    // localStorage.clear()
  };
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log("Modal is closed, running effect in CartPage...");
  };
  const imageUrls = [
    { img: Image1, heading: "PAPER" },
    { img: Image2, heading: "ORGANIC" },
    { img: Image3, heading: "LITTLE BOTTLE" },
    { img: Image4, heading: "PUMP SPARY" },
  ];

  const imageUrls2 = [
    { img: Image5, heading: "California sun" },
    { img: Image6, heading: "Wild berries" },
    { img: Image7, heading: "Lavender" },
    { img: Image8, heading: "Peach" },
  ];

  return (
    <>
      <NavMenu
        onCloseModal={handleModalClose}
        setCartBoolean={setCartValues}
        cartBoolean={cartValues}
      />
       {
        pageLoading ? (<>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'black'}}>
            <TailSpin  color="#ffd11b" />
          </div>
          </>):(<>
            <div className="LandD">
        <div className="imageColor">
          {/* test */}

          <img src={Image} className="logoImageL" alt="L&DLogo" />
        </div>

        <div className="LfirstImg">
          <h1 className="scienceHrL">A WORLD OF FRAGRANCES AND DESIGNS</h1>
          {/* <div className='scienceDiv'>
          </div> */}
            <p className='LDpText'>The exclusive natural fragrances combined with the original designs</p>
          <span className="redDiv">
            <div className="scienceImg">
              {/* <iframe width="965" height="494" src="https://www.youtube.com/embed/K_R0mQ7Kqsg" className='mainImageL' title="Newer Cars Need Fuel Additives Too!" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
              <iframe
                width="965"
                height="494"
                src="https://www.youtube.com/embed/PjKqkeNC9qI"
                className="mainImageL"
                title="LD Ingles"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </span>
          <div className="greenTransparent"></div>
        </div>
        <div className="ourProducts stprk">
          <h2 className="productsh1 LDCategories">CATEGORIES</h2>
          <div className="scienceDiv">
            {/* <p className='productp'>From fuel additives to oil treatments, functional fluids and more, STP products keep your engines performing at their best.</p> */}
          </div>
          <div className="mainArmorallCar2 ">
            {StpData.map((item, index) => (
              <div
                key={index}
                className="brandingImg4"
                onClick={() => changeProduct(item.category_id)}
              >
                {/* <img src={item.img} className="productImg1L" /> */}
                <img
                  src={item.image_url}
                  className={`categoryImgar2 ${
                    categoryId === item.category_id ||
                    (categoryId === "" && item.category_id === ProductId)
                      ? "product--highlighted2LD"
                      : ""
                  }`}
                  alt={item.name}
                />
                {/* <p className="helloImgA" style={{ textTransform: 'uppercase', }}>{item.heading}</p> */}
                <div className="mainLDText"><p style={{ textTransform: "uppercase" }} className="helloLD">
                  {item.name}
                </p></div>
                
              </div>
            ))}
          </div>
        </div>
        <div className="stpProducts">
          <h2 className="productsh22 newLDpHead">L&D PRODUCTS RANGE</h2>
          {/* <p className='productp'>For over 60 years – and more miles than we can count – STP® has been one of the most trusted names in automotive care. Our products are essential tools in your car maintenance routine.</p> */}
        <div className="greenColor1"></div>
          
          <div className="brandImg2 temp">
            <div className="whiteColorB"></div>
            <div className="MainBrandImg">
              {/* {StpCategory && StpCategory.length > 0 ? (
      StpCategory.map((item, index) => (
        <div key={index} className="brandImg4" onClick={() => sendProdId(item.id)}>
          <img src={item.image_url} className="productImg2" />
          <div className="brandName1 branded1">
            <p className="helloImg1" style={{ textTransform: 'uppercase' }}>{item.title}</p>
          </div>
        </div>
      ))
    ) : (
      <div style={{width:'200%'}}>
        <p className="noProdtFound">No products found.</p>
      </div>
    )} */}
              {/* {StpCategory && StpCategory.length > 0 ? (
                  StpCategory?.map((item, index) => (
                    <div key={index} className='brandImg3' onClick={() => sendProdId(item.id)}>
                      <img src={item.image_url} className="productImgar22" />
                      
                      <div className='brandName1 brandNameArm '>
                        <p className='helloImg2' style={{ textTransform: 'capitalize' }}>{item.title}</p>
                      </div>
                    </div>
                  ))) : (
                  <div style={{ width: '200%' }}>
                    <p className="noProdtFound2">No products found.</p>
                  </div>
                )} */}

              {StpCategory && StpCategory.length > 0 ? (
                StpCategory?.map((item, index) => (
                  <a
                          href={`/product/${item?.id}`}
                          style={{ textDecoration: "none"  }}
                        >
                  <div key={index} className="brandImg4 LDimg4">
                    {/* <img src={item.img} className="productImg2L" /> */}
                    <img src={item.image_url} className="productImgar22LD" alt={item.title} />
                    <div className="brandName1 branded1L">
                      {/* <p className="helloImg1L" style={{ textTransform: 'uppercase' }}>{item.heading}</p> */}
                      <p
                        className="helloImg1L"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.title}
                      </p>
                    </div>
                  </div>
                  </a>
                ))
              ) : (
                <div style={{ width: "200%" }}>
                  <p className="noProdtFound2">No products found.</p>
                </div>
              )}

              {/* {imageUrls2.map((item, index) => (
                <div key={index} className="brandImg4 LDimg4" >
                  <img src={item.img} className="productImg2L" />
                  <div className="brandName1 branded1L">
                    <p className="helloImg1L" style={{ textTransform: 'uppercase' }}>{item.heading}</p>
                  </div>
                </div>

              ))} */}
            </div>
          </div>
          <div className="viewing-more2">
            {/* {StpCategory && StpCategory.length > 0 ? (
    <button onClick={()=>{stpProductWrld(categoryId)}} className="viewBtn">View All</button>
  ) : (
    <button className="viewBtn1" disabled>View All</button>
  )} */}
            <button className="viewBtnL" onClick={() => stpProductWrld()}>
              View All Products
            </button>
          </div>
        </div>
        <div className="discoverL">
         
          {/* <iframe className='rectangleImgL' src="https://www.youtube.com/embed/BpoF5pOgAws" title="Discover The Secret Of Engine Maintenance" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
          <img style={{ width: "100%" }} src={RectImgL} alt="L&DBanner"/>
          {/* <div className="LDline" tyle={{textTransform:'uppercase'}}>DISCLAIMER: ALL PHOTOS, VIDEOS, PRODUCTS, LOGOS BELONG TO © 2023 ENERGIZER AUTO.</div> */}
        </div>

        <Footer />
      </div>

          </>)
}
     
    </>
  );
}
