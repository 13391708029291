import React, { useEffect } from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'
import './loozen.css'
import Image from '../../assets/img/brands2/image 82.png';
import Image1 from '../../assets/img/brands2/image 59.png';
import Image2 from '../../assets/img/brands2/image 57.png';
import Image3 from '../../assets/img/brands2/image 60.png';
import Image6 from '../../assets/img/brands2/image 55.png';
import Image7 from '../../assets/img/brands2/image 56.png';
import Image8 from '../../assets/img/brands2/image 57.png';
import lastImage from '../../assets/img/brands2/Loozen_website-banner2 1.png'
import { ColorRing, TailSpin } from "react-loader-spinner";
import '../Brands2/Blackdecker.css'
import { useState } from 'react';
import { getBrandsWithId, getProductViaCategory, productOfBrand } from '../../api/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import loozenVideo from '../../assets/img/brands2/loozenVideo.mp4'

function Loozen() {
  const navigate = useNavigate()

  const [StpData, setStpData] = useState([]);
  const location = useLocation();
  // const { brandid, brandName } = location?.state;
  const { id } = useParams();
  const [StpCategory, setStpCategory] = useState([])
  const [categoryId, setCategoryId] = useState('')
  const [ProductId, setProductId] = useState('');
  const [clicked, setClicked] = useState(false);
  const [cartValues, setCartValues] = useState(false);
  const [pageLoading, setPageLoading] = useState(true)
  const [product1, setProduct1] = useState()
  const [product2, setProduct2] = useState()

  useEffect(() => {
    setTimeout(() => {
    setPageLoading(false)
  }, 500);
    getBrandsById(34);
    getProductbyCategory(ProductId);
    getBrandProduct(34)
    console.log('==productId',ProductId);
    window.scrollTo(0, 0)
    console.log('-->brand armorall',id);

  }, [34, ProductId]);

  const getBrandsById = (id) => {
    const url = getBrandsWithId(id);
    console.log('brandUrl',url);
    axios
      .get(url)
      .then((resp) => {
        console.log('--resp', resp.data);
        setStpData(resp?.data?.brandwise_category_data)
        setProductId(resp.data.brandwise_category_data[0].category_id);
        console.log('- - - category - - - ', resp.data.brandwise_category_data);

      })
      .catch((error) => {
        console.log('--error--', error);
      });
  }

  const getProductbyCategory = (ProductId) => {
    const url = getProductViaCategory(ProductId)

    axios
      .get(url)
      .then((resp) => {
        console.log('--resp in brand product', resp);
        setStpCategory(resp.data.data.product_data)
        // setProductId(resp.data.data.product_data[0].id)
        console.log('--- - - - - setting id', resp.data.data.product_data[0].id);
      })
      .catch((error) => {
        console.log('--error', error);
      })

  }

  const getBrandProduct = (id)=>{
    const url = productOfBrand(id)
    axios.get(url)
    .then((item)=>{
      console.log(item.data.data_bottom);
      setProduct1(item.data.data_bottom)
      setProduct2(item.data.data_top)
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  const changeProduct = (id) => {
    console.log('...--changeProduct', id);
    getProductbyCategory(id);
    setCategoryId(id)

  }


  const stpProductWrld = () => {
    console.log('---strproductWorld', id);
    // localStorage.clear();
    localStorage.removeItem('selectedItems');
    window.localStorage.removeItem("CategoryCheckdDataLocal");
    window.localStorage.removeItem('packCheckedDataLocal');
    window.localStorage.removeItem('categoryValue2');
    window.localStorage.removeItem('')
    localStorage.removeItem("selectedItems2");
    localStorage.removeItem("selectedItems3");
    localStorage.removeItem("selectedItems4");
    localStorage.removeItem("brandsCheckdDataLocal");
    localStorage.removeItem("ddvalue");
    localStorage.removeItem("CategoryCheckdDataLocal");
    localStorage.removeItem("bcvalue");
    localStorage.removeItem("packCheckedDataLocal");
    localStorage.removeItem("packsvalue");
    localStorage.removeItem("resbrandsCheckdDataLocal");
    localStorage.removeItem("resddvalue");
    localStorage.removeItem("resCategoryCheckdDataLocal");
    localStorage.removeItem("resbcvalue");
    localStorage.removeItem("respackCheckedDataLocal");
    localStorage.removeItem("respacksvalue");
    
    navigate('/LOOZEN/Products', {
      state: {
        categoryById: categoryId == '' ? ProductId : categoryId,
        brandID: 34
      }
    })
  }
  const sendProdId = (id) => {
    console.log(' ------ id', id);
    navigate(`/product/${id}`, { state: { productId: id, clicked: clicked } });
    // localStorage.clear()
  };


  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
    const imageUrls = [
        { "img": Image1, "heading": "VACCUM CLEANER" },
        { "img": Image2, "heading": "TYRE INFILTRATORS" }, 
        { "img": Image3, "heading": "TOOLS" },
        // { "img": Image4, "heading": "VACCUM CLEANER" },
        // {"img":  Image9, "heading":"TYRE INFILTRATORS"}
      ];
    
      const imageUrls2 = [
        // { "img": Image5, "heading": "Petrol Injector Cleaner" },
        { "img": Image6, "heading": "WDC215WA WET AND DRY CAR VACUUM CLEANER" },
        { "img": Image7, "heading": "BEPW2200-IN 150 BAR PRESSURE WASHER" },
        // { "img": Image8, "heading": "AS1400-XJ 12V CORDED TYRE INFLATOR" },
        // { "img": Image10, "heading": "BDC24L COOLER & WARMER" }

      ];

  return (
    <>
    <NavMenu onCloseModal={handleModalClose}
        setCartBoolean={setCartValues}
        cartBoolean={cartValues}/>
          {pageLoading ? (<>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'black'}}>
            <TailSpin  color="#ffd11b" />
          </div>
          </>):(<> <div>
    <div className='firstImgLoozen'>
      <div className='logoDiv'>
      </div>
        <img className='logoImageLoozen' src={Image}/>
      <div  className='firstHeadingContent'>
        <h1  className='firstHeading' style={{textTransform:'uppercase'}}>Car Care: Fast and Fair</h1>
      </div>
      <div className='youtubeContent'>
      {/* <iframe width="965" height="494" className='mainImageLoozen'src="https://www.youtube.com/embed/nPLidvDlOlw?si=KyKEwFHIGOH-JpQW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
      <video src={loozenVideo} className='mainImageLoozen' width="875" height="494" controls="controls" autoplay="true"/>
      </div>
    </div>
    <div className='downContent'>
      <div className='loozenValue'>
        <p className='loozenValue2'>Loozen aims to create a positive impression by smoothening things that trouble you. We provide high-value solutions for various needs. Be it workshops, factories or even homes, Loozen protects, penetrates, displaces moisture, frees sticky mechanisms, cleans, and lubricates.<br/>
Loozen is a multi-purpose household, automotive and industrial product that cleans objects, loosens rust, frees sticky items, lubricates, and protects items from getting rusted.</p>
      </div>
      <div className='loozenProduct'>
        <h2 className='loozenProductRange'>LOOZEN PRODUCT RANGE</h2>
        <div className="brandImg2">
  <div className="yellowColorLooz"></div>
 

  <div className="MainBrandImg">
    {/* {
      imageUrls2.map((item, index) => (
        <div key={index} className="brandImg4" >
          <img src={item.img} className="productImg2Loozen" />
          <div className="brandName1 branded1">
            <p className="helloImg1Loozen" style={{ textTransform: 'uppercase' }}>{item.heading}</p>
          </div>
        </div>
      ))} */}
   {product2 && product2.length > 0 ? (
      product2.map((item, index) => (
        <a
                          href={`/product/${item?.id}`}
                          style={{ textDecoration: "none"  }}
                        >
        <div key={index} className="brandImg4">
          <img src={item.image_url} className="productImg2Loozen" alt={item.title} />
          <div className="brandName1 branded1">
            <p className="helloImg1Loozen" style={{ textTransform: 'uppercase' }}>{item.title}</p>
          </div>
        </div>
        </a>
      ))
    ) : (
      <div style={{width:'200%'}}>
        <p className="noProdtFound">No products found.</p>
      </div>
    )}
      
  </div>
</div>
  <div className="viewing-moreLoozen">
  {/* {StpCategory && StpCategory.length > 0 ? (
    <button onClick={()=>{stpProductWrld(categoryId)}} className="viewBtnB">View All</button>
  ) : (
    <button className="viewBtnB" disabled>View All</button>
  )} */}
   <button className="viewBtnLoozen"  onClick={()=>{stpProductWrld(categoryId)}}>View All Products</button>
</div>
      </div>
      <div>
        <img style={{width:'100%'}} src={lastImage}/>
      </div>
    </div>
    </div>
   <Footer/></>)}
   
    </>
  )
}

export default Loozen