import React from 'react'
import NavMenu from '../Components/Navbar'
import Footer from '../Components/Footer'

function Cancellation() {
  return (
    <>
    <NavMenu/>
    <div>
      Your Payment Got Cancelled
    </div>
    <Footer/>
    </>
  )
}

export default Cancellation