import React, { useState } from "react";
import NavMenu from "../Navbar";
import Footer from "../Footer";
import {GrMail} from 'react-icons/gr'
import {IoIosCall} from 'react-icons/io'
import {MdLocationPin} from 'react-icons/md'

export default function ContactUs() {
  const [cartValues, setCartValues] = useState(false);
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
     <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
     <div className="contactBackground">
     <div  className='mainPrivacy2'>
        
        
          <h1 className="contactush2" style={{color:'white'}}>Contact Us</h1>
          <div className="contactUsDiv">
     <div className="row11"  >
          <div className="row12">
          <a target='_blank' style={{textDecoration:'none', color:'white'}} > <span style={{display:'flex',  gap:'10px'}}><MdLocationPin style={{fontSize:'20px',marginTop:'2px', color:'white', fontWeight:'500'}}/><p style={{marginBottom:'0px', fontFamily:'Rubik', fontWeight:'500', fontSize:'17px'}} >Unit 1, Janata Compound, Senapati Bapat Marg,<br/> Lower Parel West, 400013</p></span></a>
           <a target='_blank' href = "mailto: support@creativecarcare.in" style={{textDecoration:'none', color:'white'}}><span style={{display:'flex',alignItems:'center', gap:'10px', marginTop:'8px', marginBottom:'8px'}}><GrMail style={{fontSize:'18px'}}/>
           <p style={{marginBottom:'0px', fontFamily:'Rubik', fontWeight:'500' , fontSize:'17px'}}>support@creativecarcare.in</p></span></a>
           <span style={{display:'flex',alignItems:'center', gap:'10px'}}><IoIosCall style={{fontSize:'20px', color:'white'}}/><p style={{color:'red',marginBottom:'0px' , fontSize:'17px'}}> <a href='tel: 95459 00009' className="aboutUsNo" style={{textDecoration:'none', color:'red', fontFamily:'Rubik', fontWeight:'500'}}>+91 95459 00009 </a>
           {/* |  <a href='tel: 99302 90909' className="aboutUsNo" style={{textDecoration:'none', color:'red', fontFamily:'Rubik', fontWeight:'500'}}>+91 99301 90909 </a>| <a target='_blank' style={{textDecoration:'none', color:'red', fontFamily:'Rubik', fontWeight:'500'}} className="aboutUsNo" href='tel: 022 2497 9090'>+91 22 2497 9090</a> */}
           </p></span>
          </div>
          <div className="googleMap">
 <iframe className="googleMap1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30179.962722546996!2d72.80682874797535!3d18.99788242455667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cef598110ba7%3A0xea6bb83f5ad3572!2sCreative%20International!5e0!3m2!1sen!2sin!4v1689256895464!5m2!1sen!2sin" width="360" height="280"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>      
          </div>
        </div>
        </div>
          </div>
          </div>

     <Footer/>
    </>
  )
}
