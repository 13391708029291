import React, { useEffect, useRef } from 'react'
import Image from '../../assets/img/userimage.avif'
import { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { editProfielDetails, getAccountDetails, getuserdetail, profilePhoto } from '../../api/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { AiOutlineArrowUp } from 'react-icons/ai'
import {BsPencilSquare} from 'react-icons/bs'
import ImageUploading from 'react-images-uploading';
import userImage from '../../assets/img/Home/user (2).png'

export default function AccountDetails() {
  const storedData = useSelector((state) => state.profile);
  const [editMode, setEditMode] = useState(false);
  const [editMode2, setEditMode2] = useState(false);
  const [originalProfileImage, setOriginalProfileImage] = useState(null);
  const [Name, setName] = useState();
  const [Name1, setName1] = useState();

  const [mobile, setmobile] = useState();
  const [email, setemail] = useState();
  
  const [ProfileData, setProfileData] = useState([])
  const [profileError, setprofileError] = useState([])
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [companyName, setCompanyName] = useState()
  const [gstnNo, setGstnNo] = useState()
  const [compStatus, setCompStatus] = useState()

  useEffect(() => {
    getUserAccount(storedData.userId);
    window.scrollTo(0, 0);
    
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
  };
  const handleEditClick2 = () => {
    setEditMode2(true);
  };
 
  const handleSaveClick = (event) => {
    event.preventDefault(); 
    // const emailRegex = /\S+@\S+\.\S+/;
    // if (mobile.length !== 10) {
    //   alert("Please enter valid mobile number.");
    // } else if (!emailRegex.test(email)) {
    //   alert("Please enter a valid email address.");
    // } else{
    //   setEditMode(false);
    //   updateProdfileDetail(); 
    // }
    setEditMode(false);
    updateProdfileDetail(); 
  };
  const handleSaveClick2 = () => {
    setEditMode2(false);
    // updateProdfileDetail(); 
    updateProfileImage();
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleNameChanged = (e) => {
    setName1(e.target.value);
  };
  const handleNameChange2 = (e) => {
   
    setProfileImage(e.target.files[0]);
   
    
  };


  const inputRef= useRef(null)
  const handlemobileChange = (e) => {
    setmobile(e.target.value);
  };
  const handlemailChange = (e) => {
    setemail(e.target.value);
  };
  const handlCompanyChange = (e) => {
    setCompanyName(e.target.value);
  };
  const handlGstnChange = (e) => {
    setGstnNo(e.target.value);
  };


  let formData = new FormData()

const updateProfileImage = ()=>{

  // inputRef.current.click()
  const url =  profilePhoto() 
//  const body = {
//   user_id:storedData.userId,
//  }
  

  formData.append('user_id',storedData.userId)
formData.append('image', profileImage)

 
  axios.post(url,formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }})
  .then((resp)=>{
    console.log(resp);
    setOriginalProfileImage(profileImage);
  })
  .catch((err)=>{
    console.log(err);
  })}

const updateProfileImage2=()=>{
  inputRef.current.click();
  updateProfileImage()

}

useEffect(() => {
   
    updateProfileImage();
    getUserAccount(storedData.userId)
}, [profileImage]);



  const getUserAccount = (id) => {
    const url = getAccountDetails(id)
    axios(url)
      .then((resp) => {
        console.log(' - -  - resp',resp.data.data);
        setCompanyName(resp.data.data.CompanyName)
        setGstnNo(resp.data.data.gst_no)
        setCompStatus(resp.data.data.is_company)
        setName(resp.data.data.name)
        setName1(resp.data.data.last_name)
        setmobile(resp.data.data.mobile)
        setemail(resp.data.data.email)
        setProfileImage(resp.data.data.image_url)

        console.log('hello',resp.data.data.image_url);
        
      })
      .catch((error) => {
        console.log('  - - - - error', error);
      })
  }

  const updateProdfileDetail = ()=>{
    
    const url = editProfielDetails()
    const body = {
      user_id:storedData.userId,
      name:Name,
      last_name:Name1,
      mobile:mobile,
      email:email,
      company_name:companyName,
      gst_no:gstnNo,
      is_company:compStatus
    }
console.log('--body--',body);
    axios.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      }})
    .then((resp)=>{
      console.log('==profile Image',resp.data);
    })
    .catch((error)=>{
      console.log('hello error',error);
    })
  }


  return (
    <>
      <div className='accountdetails'>
        <h1 className='myAccount'>Account Information</h1>
        <form>
          {!editMode &&
          <div style={{ display: 'flex', marginBottom:'-10px' }} className='displayProfile'>
            {!editMode2 && 
            <div style={{position:'relative'}}>
            <div className='accounImaget'>
              
              <img src={profileImage == null? userImage:profileImage } className='accountImg' alt='profileImage' />
              </div>
             <div onClick={updateProfileImage2} className='updatePencil'>
              <BsPencilSquare className='pencilSquare' />
            
            <input
              // className='nameadd'
              type='file'
              onChange={handleNameChange2}
              // disabled={!editMode2}
              name='file'
              ref={inputRef}
              style={{display:'none'}}
              
            />
            </div>
            </div>
          
}

            <div className='editInfoBtn'>
              {!editMode && (
                <button className='editbtn' onClick={handleEditClick}>
                  Edit
                </button>
              )}
            
            </div>
          </div>
          }
          <div className='AddressInfo'>
            <input
              className='nameadd'
              value={Name}
              onChange={handleNameChange}
              disabled={!editMode}
            />
            <input
              className='nameadd'
              value={Name1}
              onChange={handleNameChanged}
              disabled={!editMode}
            />
            <input
              className='nameadd'
              value={mobile}
              onChange={handlemobileChange}
              disabled={!editMode}
            />
            <input
              className='nameadd'
              value={email}
              onChange={handlemailChange}
              disabled={!editMode}
            />


            {
              compStatus == 1 &&(
                <>
                <input
                className='nameadd'
                value={companyName}
                onChange={handlCompanyChange}
                disabled={!editMode}
              />
            <input
              className='nameadd'
              value={gstnNo}
              onChange={handlGstnChange}
              disabled={!editMode}
            />
                </>
              )
            }
              
            {editMode && (
              <button className='cancelbtn savebtn' onClick={handleSaveClick}>
                Save
              </button>
            )}
            {editMode2 && (
              <button className='cancelbtn savebtn' onClick={handleSaveClick2}>
                Save
              </button>
            )}
          </div>
        </form>
        
      </div>
    </>
  )
}




