import React, { useEffect } from 'react'
import Footer from '../Footer';
import NavMenu from '../Navbar';
import Image from '../../assets/img/brands2/Group 31.png';
import Image1 from '../../assets/img/brands2/image 59.png';
import Image2 from '../../assets/img/brands2/image 57.png';
import Image3 from '../../assets/img/brands2/image 60.png';
import Image6 from '../../assets/img/brands2/image 55.png';
import Image7 from '../../assets/img/brands2/image 56.png';
import Image8 from '../../assets/img/brands2/image 57.png';
import Image10 from '../../assets/img/brands2/image 58.png';
import { ColorRing, TailSpin } from "react-loader-spinner";

import '../Brands2/Blackdecker.css'
import { useState } from 'react';
import { getBrandsWithId, getProductViaCategory, productOfBrand } from '../../api/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
export default function BlackDecker() {
  const navigate = useNavigate()

  const [StpData, setStpData] = useState([]);
  const location = useLocation();
  // const { brandid, brandName } = location?.state;
  const { id } = useParams();
  const [StpCategory, setStpCategory] = useState([])
  const [categoryId, setCategoryId] = useState('')
  const [ProductId, setProductId] = useState('');
  const [clicked, setClicked] = useState(false);
  const [cartValues, setCartValues] = useState(false);
  const [pageLoading, setPageLoading] = useState(true)
  const [product1, setProduct1] = useState()
  const [product2, setProduct2] = useState()

  useEffect(() => {
    setTimeout(() => {
    setPageLoading(false)
  }, 500);
    getBrandsById(19);
    getProductbyCategory(ProductId);
    getBrandProduct(19)
    console.log('==productId',ProductId);
    window.scrollTo(0, 0)
    console.log('-->brand armorall',id);

  }, [19, ProductId]);

  const getBrandsById = (id) => {
    const url = getBrandsWithId(id);
    console.log('brandUrl',url);
    axios
      .get(url)
      .then((resp) => {
        console.log('--resp', resp.data);
        setStpData(resp?.data?.brandwise_category_data)
        setProductId(resp.data.brandwise_category_data[0].category_id);
        console.log('- - - category - - - ', resp.data.brandwise_category_data);

      })
      .catch((error) => {
        console.log('--error--', error);
      });
  }

  const getProductbyCategory = (ProductId) => {
    const url = getProductViaCategory(ProductId)

    axios
      .get(url)
      .then((resp) => {
        console.log('--resp in brand product', resp);
        setStpCategory(resp.data.data.product_data)
        // setProductId(resp.data.data.product_data[0].id)
        console.log('--- - - - - setting id', resp.data.data.product_data[0].id);
      })
      .catch((error) => {
        console.log('--error', error);
      })

  }

  const getBrandProduct = (id)=>{
    const url = productOfBrand(id)
    axios.get(url)
    .then((item)=>{
      console.log(item.data.data_bottom);
      setProduct1(item.data.data_bottom)
      setProduct2(item.data.data_top)
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  const changeProduct = (id) => {
    console.log('...--changeProduct', id);
    getProductbyCategory(id);
    setCategoryId(id)

  }


  const stpProductWrld = () => {
    console.log('---strproductWorld', id);
    // localStorage.clear();
    localStorage.removeItem('selectedItems');
    window.localStorage.removeItem("CategoryCheckdDataLocal");
    window.localStorage.removeItem('packCheckedDataLocal');
    window.localStorage.removeItem('categoryValue2');
    window.localStorage.removeItem('')
    localStorage.removeItem("selectedItems2");
    localStorage.removeItem("selectedItems3");
    localStorage.removeItem("selectedItems4");
    localStorage.removeItem("brandsCheckdDataLocal");
    localStorage.removeItem("ddvalue");
    localStorage.removeItem("CategoryCheckdDataLocal");
    localStorage.removeItem("bcvalue");
    localStorage.removeItem("packCheckedDataLocal");
    localStorage.removeItem("packsvalue");
    localStorage.removeItem("resbrandsCheckdDataLocal");
    localStorage.removeItem("resddvalue");
    localStorage.removeItem("resCategoryCheckdDataLocal");
    localStorage.removeItem("resbcvalue");
    localStorage.removeItem("respackCheckedDataLocal");
    localStorage.removeItem("respacksvalue");
    
    navigate('/BLACK+DECKER/Products', {
      state: {
        categoryById: categoryId == '' ? ProductId : categoryId,
        brandID: 19
      }
    })
  }
  const sendProdId = (id) => {
    console.log(' ------ id', id);
    navigate(`/product/${id}`, { state: { productId: id, clicked: clicked } });
    // localStorage.clear()
  };


  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };

    const imageUrls = [
        { "img": Image1, "heading": "VACCUM CLEANER" },
        { "img": Image2, "heading": "TYRE INFILTRATORS" },
        { "img": Image3, "heading": "TOOLS" },
        // { "img": Image4, "heading": "VACCUM CLEANER" },
        // {"img":  Image9, "heading":"TYRE INFILTRATORS"}
      ];
    
      const imageUrls2 = [
        // { "img": Image5, "heading": "Petrol Injector Cleaner" },
        { "img": Image6, "heading": "WDC215WA WET AND DRY CAR VACUUM CLEANER" },
        { "img": Image7, "heading": "BEPW2200-IN 150 BAR PRESSURE WASHER" },
        { "img": Image8, "heading": "AS1400-XJ 12V CORDED TYRE INFLATOR" },
        { "img": Image10, "heading": "BDC24L COOLER & WARMER" }

      ];
  return (
    <>
    <NavMenu   onCloseModal={handleModalClose}
        setCartBoolean={setCartValues}
        cartBoolean={cartValues} />
        {pageLoading ? (<>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'black'}}>
            <TailSpin  color="#ffd11b" />
          </div>
          </>):(<><div className='MainbrandsB'>
        <div className='imageColor'>
          {/* test */}
          <div className='orangeColor'></div>
       
          <img src={Image} className='logoImageB' alt='orangeLogo'/>
        </div>

        <div className='firstImgB'>
          {/* <h1 className='scienceHrB'>SCIENCE. TECHNOLOGY. PERFORMANCE.</h1> */}
          <h1 className='scienceHrB'>INGENIOUS PRODUCTS MADE  TO MAKE LIFE EASIER.</h1>
          
          <div className='scienceDiv'>
            {/* <p className='scienceP'>Ingenious products made to make life easier.</p> */}
          </div>
          <span className='redDiv'>
            <div className='scienceImg'>
              {/* <iframe width="965" height="494" src="https://www.youtube.com/embed/K_R0mQ7Kqsg" className='mainImageB' title="Newer Cars Need Fuel Additives Too!" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
              <iframe width="965" height="494" src="https://www.youtube.com/embed/gDjYy0IHTaw" className='mainImageB' title="Sunday Funday with BLACK+DECKER™" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </span>
          <div className='blackTransparentB'></div>
        </div>
        {/* <div className='ourProducts stprk'>
          <h1 className='productshSt'>CATEGORIES</h1>
          <div className='scienceDiv'>
          </div>
          <div className='brandStpImg2Black'>
        
            {StpData.map((item, index) => (
              <div key={index} className="brandingImg3" onClick={() => changeProduct(item.category_id)}>
                <img src={item.image_url} className={`productImg1B ${categoryId === item.category_id || (categoryId === '' && item.category_id === ProductId)
                    ? 'product--highlighted2BD'
                    : ''
                  }`} alt={item.name} />
                <p style={{textTransform:'uppercase'}} className="helloImgB">{item.name}</p>
              </div>
            ))}
          </div>
        </div> */}


        <div className='stpProducts'>
          <h2 className='productsh2B'>BLACK+DECKER PRODUCT RANGE</h2>
          {/* <p className='productp'>For over 60 years – and more miles than we can count – STP® has been one of the most trusted names in automotive care. Our products are essential tools in your car maintenance routine.</p> */}
          <div className="brandImg2">
  <div className="yellowColorB"></div>
 

  <div className="MainBrandImg">
    {product1 && product1.length > 0 ? (
      product1.map((item, index) => (
        <a
                          href={`/product/${item?.id}`}
                          style={{ textDecoration: "none"  }}
                        >
        <div key={index} className="brandImg4" >
          <img src={item.image_url} className="productImg2B" alt={item.title}/>
          <div className="brandName1 branded1">
            <p className="helloImg1" style={{ textTransform: 'uppercase' }}>{item.title}</p>
          </div>
        </div>
        </a>
      ))
    ) : (
      <div style={{width:'200%'}}>
        <p className="noProdtFound">No products found.</p>
      </div>
    )}
    {/* {
      imageUrls2.map((item, index) => (
        <div key={index} className="brandImg4" >
          <img src={item.img} className="productImg2B" />
          <div className="brandName1 branded1">
            <p className="helloImg1" style={{ textTransform: 'uppercase' }}>{item.heading}</p>
          </div>
        </div>
      ))} */}
  </div>
</div>
          <div className="brandImg2">
  <div className="yellowColorB"></div>
 

  <div className="MainBrandImg">
    {product2 && product2.length > 0 ? (
      product2.map((item, index) => (
        <a
                          href={`/product/${item?.id}`}
                          style={{ textDecoration: "none"  }}
                        >
        <div key={index} className="brandImg4">
          <img src={item.image_url} className="productImg2B" alt={item.title} />
          <div className="brandName1 branded1">
            <p className="helloImg1" style={{ textTransform: 'uppercase' }}>{item.title}</p>
          </div>
        </div>
        </a>
      ))
    ) : (
      <div style={{width:'200%'}}>
        <p className="noProdtFound">No products found.</p>
      </div>
    )}
    {/* {
      imageUrls2.map((item, index) => (
        <div key={index} className="brandImg4" >
          <img src={item.img} className="productImg2B" />
          <div className="brandName1 branded1">
            <p className="helloImg1" style={{ textTransform: 'uppercase' }}>{item.heading}</p>
          </div>
        </div>
      ))} */}
  </div>
</div>
<div className="viewing-more">
  {StpCategory && StpCategory.length > 0 ? (
    <button onClick={()=>{stpProductWrld(categoryId)}} className="viewBtnB">View All</button>
  ) : (
    <button className="viewBtnB" disabled>View All</button>
  )}
   {/* <button className="viewBtnB">View All Products</button> */}
</div>
         
            
        </div>
        <div className='discoverB'>
          <h2 className='discoverhr'>DISCOVER THE SECRET OF CAR MAINTENANCE</h2>
          
          {/* <iframe className='rectangleImg' src="https://www.youtube.com/embed/BpoF5pOgAws" title="Discover The Secret Of Engine Maintenance" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
          <iframe className='rectangleImg' src="https://www.youtube.com/embed/qGRbNr2J674" title="Stanley Black &amp; Decker Group Corporate Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <div>
            
          </div>
        </div>
        <Footer />
      </div></>)
      }
    </>
  )
}