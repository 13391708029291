import { act } from "react-dom/test-utils";
import { UPDATE_CART_DATA} from "../ActionCreator.js"

export const userDetail = (
    state = {
        loginLoading:false,
        loginErr: null,
        loginSuccess: false,
        accessToken: null,
        userId: null,
        userName: null,
        userNumber : null,
        userEmail : null,
        userLoginSuccess : false,
      },
 action)=> {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                    ...state,
                    loginLoading: false,
                    loginErr: null,
                    loginSuccess: true,
                    accessToken: action.payload.session_id,
                    userId: action.payload.user_id,
                    userName: action.payload.name,
                    userNumber : action.payload.mobile,
                    userEmail : action.payload.email,
                    userLoginSuccess : true
            }
        case 'LOGIN_ERR':
            return {
                    ...state,
                    loginLoading: false,
                    loginErr: action.payload.message,
                    loginSuccess: false,
                    accessToken: null,
                    userId: null,                 
                    userName: null,
                    userNumber : null,
                    userEmail : null,
                    userLoginSuccess: false
            }
        case 'LOG_OUT' :
            return {
                ...state,
                    loginLoading: false,
                    loginErr: null,
                    loginSuccess: false,
                    accessToken: null,
                    userId: null,
                    userName: null,
                    userNumber : null,
                    userEmail : null,
                    userLoginSuccess : false
                }
        default:
            return state;
    }
}

const initialState = {
    cartData: [],
  };

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_CART_DATA:
        return {
          ...state,
          cartData: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default cartReducer;