import React, { useState, useEffect } from "react";
import NavMenu from "../Navbar";
import Footer from "../Footer";
import "../ProductPage/productpage.css";
import img from "../../assets/img/img2/productpageImg.png";
import { useNavigate, Link, useLocation } from "react-router-dom";
// import { getProduct } from "../../api/api";
import axios from "axios";
import {
  deleteWishList,
  getcartId,
  getWhishlist,
  productData,
  productData2,
  productFilter,
} from "../../api/api";

import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { ColorRing, TailSpin } from "react-loader-spinner";
import ReactStars from "react-stars";
import { TbLayoutGrid } from "react-icons/tb";
import { BsListTask } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import BulkOrder from "./BulkOrder";

const BulkPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedData = useSelector((state) => state.profile);
  const [gridThree, setGridThree] = useState(true);
  const [gridFour, setGridFour] = useState(false);
  const [prodData, setProdData] = useState([]);
  const [noprodDataFound, setnoProdDataFound] = useState(false);
  const [isLoding, setisLoding] = useState(false);
  const [brandsfilterData, setbrandsfilterData] = useState([]);
  const [CategoriesfilterData, setCategoriesfilterData] = useState([]);
  const [packssfilterData, setpackssfilterData] = useState([]);
  const [checkedbrandsfilterData, setcheckedbrandsfilterData] = useState([]);
  const [checkedCategoriesfilterData, setcheckedCategoriesfilterData] =
    useState([]);
  const [checkedpackssfilterData, setcheckedpackssfilterData] = useState([]);
  const [filtercom, setFiltercom] = useState(false);
  const [showProduct, setShowProduct] = useState(true);
  const [mobileFilter, setMobileFilter] = useState(true);
  const [btnshow, setBtnShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // Number of items to display per page
  const [isActive, setisActive] = useState(true);
  const [isActive2, setisActive2] = useState(false);
  const [seachValue, setseachValue] = useState("");
  const [numCategoriesToShow, setNumCategoriesToShow] = useState(5);
  const [cartValues, setCartValues] = useState(false);
  const [categorySelected, setCategorySelected] = useState(false);
  const [packSelected, setpackSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchProduct();
    localStorage.removeItem('selectedItems');
    brandValue = []
    fetchProductFilters();
    gridTwoFuc();
    localStorage.removeItem('selectedItems');
    window.localStorage.removeItem("CategoryCheckdDataLocal");
    window.localStorage.removeItem('packCheckedDataLocal');
    window.localStorage.removeItem('categoryValue2');
    window.localStorage.removeItem('')
    localStorage.removeItem("selectedItems2");
    localStorage.removeItem("selectedItems3");
    localStorage.removeItem("selectedItems4");
    localStorage.removeItem("brandsCheckdDataLocal");
    localStorage.removeItem("ddvalue");
    localStorage.removeItem("CategoryCheckdDataLocal");
    localStorage.removeItem("bcvalue");
    localStorage.removeItem("packCheckedDataLocal");
    localStorage.removeItem("packsvalue");
    localStorage.removeItem("resbrandsCheckdDataLocal");
    localStorage.removeItem("resddvalue");
    localStorage.removeItem("resCategoryCheckdDataLocal");
    localStorage.removeItem("resbcvalue");
    localStorage.removeItem("respackCheckedDataLocal");
    localStorage.removeItem("respacksvalue");
   
  }, [
    checkedbrandsfilterData,
    checkedCategoriesfilterData,
    checkedpackssfilterData,
  ]);

  const clearLocalStorage = () => {
    localStorage.removeItem('selectedItems');
    window.localStorage.removeItem("CategoryCheckdDataLocal");
    window.localStorage.removeItem('packCheckedDataLocal');
    window.localStorage.removeItem('categoryValue2');
    window.localStorage.removeItem('')
    localStorage.removeItem("selectedItems2");
    localStorage.removeItem("selectedItems3");
    localStorage.removeItem("selectedItems4");
    localStorage.removeItem("brandsCheckdDataLocal");
    localStorage.removeItem("ddvalue");
    localStorage.removeItem("CategoryCheckdDataLocal");
    localStorage.removeItem("bcvalue");
    localStorage.removeItem("packCheckedDataLocal");
    localStorage.removeItem("packsvalue");
    localStorage.removeItem("resbrandsCheckdDataLocal");
    localStorage.removeItem("resddvalue");
    localStorage.removeItem("resCategoryCheckdDataLocal");
    localStorage.removeItem("resbcvalue");
    localStorage.removeItem("respackCheckedDataLocal");
    localStorage.removeItem("respacksvalue");
    
  };
  useEffect(() => {
    fetchProduct();
  }, [seachValue]);

  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    fetchProduct();
    console.log("Modal is closed, running effect in CartPage...");
  };

  const gridFirstFunc = () => {
    setGridThree(true);
    setGridFour(false);
    setisActive(false);
    setisActive2(true);
    // setGridFive(false)
  };

  const gridTwoFuc = () => {
    setGridFour(true);
    // setGridFive(false)
    setGridThree(false);
    setisActive(true);
    setisActive2(false);
  };

  const fetchProduct = () => {
    window.scrollTo(0, 0);
    var brandArr = JSON.parse(
      window.localStorage.getItem("brandsCheckdDataLocal")
    );
    var categoryArr = JSON.parse(
      window.localStorage.getItem("CategoryCheckdDataLocal")
    );
    var packArr = JSON.parse(
      window.localStorage.getItem("packCheckedDataLocal")
    );

    var resbrandArr = JSON.parse(
      window.localStorage.getItem("resbrandsCheckdDataLocal")
    );
    var rescategoryArr = JSON.parse(
      window.localStorage.getItem("resCategoryCheckdDataLocal")
    );
    var respackArr = JSON.parse(
      window.localStorage.getItem("respackCheckedDataLocal")
    );

    setisLoding(true);
    const url = productData2();
    const userData = {
      user_id: storedData?.userId,
      filter: true,
      brands: filtercom ? resbrandValue : brandValue,
      category: filtercom ? categoryValue2 : categoryArr,
      packs: filtercom ? packValue2 : packArr,
      search: seachValue,
    };
    console.log(userData);
    axios
      .post(url, userData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("product resp --> ", res.data, "lenth ==> ", res.data);
        if (res.data.status) {
          setProdData(res.data.data);
          setTimeout(() => {
            setisLoding(false);
            setnoProdDataFound(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setisLoding(false);
            setnoProdDataFound(true);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        setisLoding(false);
        setnoProdDataFound(true);
      });
  };

  const fetchProductFilters = () => {
    var brandArr = JSON.parse(
      window.localStorage.getItem("brandsCheckdDataLocal")
    );
    var resbrandArr = JSON.parse(
      window.localStorage.getItem("resbrandsCheckdDataLocal")
    );

    const url = productFilter();
    // var body = {
    //   brand_id: filtercom ? resbrandArr : brandArr
    // }

    // console.log("filtert body ==> ", body);

    axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("product category ==.", res.data.data[0].categories);
        setbrandsfilterData(res.data.data[0].brands);
        setCategoriesfilterData(res.data.data[0].categories);
        setpackssfilterData(res.data.data[0].packs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   const handleBrnadChange = (event) => {
  //     setisLoding(true);
  //     var updatedList = [...checkedbrandsfilterData];
  //     if (event.target.checked) {
  //       updatedList = [...checkedbrandsfilterData, event.target.value];
  //     } else {
  //       updatedList.splice(
  //         checkedbrandsfilterData.indexOf(event.target.value),

  //       );
  //       // updatedList.pop()

  //     }
  //     setcheckedbrandsfilterData(updatedList);
  //     const brandChecked = JSON.stringify(updatedList);
  //     // console.log("brand checked data state  ==>", brandChecked);
  //     window.localStorage.setItem("brandsCheckdDataLocal", brandChecked);
  //     var items = document.getElementsByName("inputCheck");
  //     var arr = [];
  //     for (var i = 0; i < items.length; i++) {
  //       if (items[i].type == "checkbox" && items[i].checked == true) {
  //         arr.push(items[i].value);
  //       }
  //     }
  //     window.localStorage.setItem("ddvalue", arr);
  //     fetchProduct();
  //     setCurrentPage(1);
  //   };

  var brandValue = JSON.parse(localStorage.getItem("selectedItems")) || [];

  function handleBrnadChange(event) {
    const clickedItemId = Number(event.target.value);
    let selectedItems = [...categoryValue2]; // Create a separate array
    const index = selectedItems.indexOf(clickedItemId);

    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(clickedItemId);
      }
    } else {
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
    brandValue = selectedItems;
    fetchProduct(selectedItems);
    setCurrentPage(1);
  }

  const handleCategoriesChange = async (event) => {
    const categoryId = event.target.value;
    const updatedList = [...checkedCategoriesfilterData];

    if (event.target.checked) {
      if (!updatedList.includes(categoryId)) {
        updatedList.push(categoryId);
      }
    } else {
      const indexToRemove = updatedList.indexOf(categoryId);
      if (indexToRemove !== -1) {
        updatedList.splice(indexToRemove, 1);
      }
    }
    setcheckedCategoriesfilterData(updatedList);
    const categoriesChecked = JSON.stringify(updatedList);
    window.localStorage.setItem("CategoryCheckdDataLocal", categoriesChecked);
    var items = document.getElementsByName("brandinputCheck");
    var arr = [];
    for (var i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox" && items[i].checked == true) {
        arr.push(items[i].value);
        console.log(arr);
      }
    }
    localStorage.setItem("resddvalue", arr);
    setCategorySelected(true);
    fetchProduct();
    setCurrentPage(1);
  };

  const handlePacksChange = (event) => {
    var updatedList = [...checkedpackssfilterData];
    if (event.target.checked) {
      updatedList = [...checkedpackssfilterData, event.target.value];
    } else {
      updatedList.splice(
        checkedpackssfilterData.indexOf(event.target.value),
        1
      );
    }
    setcheckedpackssfilterData(updatedList);
    const categoriesChecked = JSON.stringify(updatedList);
    window.localStorage.setItem("packCheckedDataLocal", categoriesChecked);

    var items = document.getElementsByName("PacksinputCheck");
    var arr = [];
    for (var i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox" && items[i].checked == true) {
        arr.push(items[i].value);
      }
    }
    // localStorage.setItem("packsvalue", arr);
    localStorage.setItem("resbcvalue", arr);
    setpackSelected(true);

    fetchProduct();
    setCurrentPage(1);
    // console.log(" packs filter datat ==> ", checkedpackssfilterData);
  };

  //   const respHandleBrnadChange = (event) => {
  //     setisLoding(true);
  //     var updatedList = [...checkedbrandsfilterData];
  //     if (event.target.checked) {
  //       updatedList = [...checkedbrandsfilterData, event.target.value];
  //     } else {
  //       updatedList.splice(
  //         checkedbrandsfilterData.indexOf(event.target.value),
  //         1
  //       );
  //     }
  //     setcheckedbrandsfilterData(updatedList);
  //     const brandChecked = JSON.stringify(updatedList);
  //     window.localStorage.setItem("resbrandsCheckdDataLocal", brandChecked);
  //     var items = document.getElementsByName("inputCheck");
  //     var arr = [];
  //     for (var i = 0; i < items.length; i++) {
  //       if (items[i].type == "checkbox" && items[i].checked == true) {
  //         arr.push(items[i].value);
  //       }
  //     }
  //     localStorage.setItem("resddvalue", arr);

  //     fetchProduct();
  //     // console.log(" brands filterd datat ==> ", checkedbrandsfilterData);
  //   };

  var resbrandValue = JSON.parse(localStorage.getItem("selectedItems")) || [];

  function respHandleBrnadChange(event) {
    const clickedItemId = Number(event.target.value);
    let selectedItems = [...categoryValue2]; // Create a separate array
    const index = selectedItems.indexOf(clickedItemId);

    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(clickedItemId);
      }
    } else {
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
    resbrandValue = selectedItems;
    fetchProduct(selectedItems);
    setCurrentPage(1);
  }

  // const respHandleCategoriesChange = async (event) => {
  var categoryValue2 = JSON.parse(localStorage.getItem("selectedItems")) || [];

  function respHandleCategoriesChange(event) {
    const clickedItemId = Number(event.target.value);
    let selectedItems = [...categoryValue2]; // Create a separate array
    const index = selectedItems.indexOf(clickedItemId);

    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(clickedItemId);
      }
    } else {
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
    categoryValue2 = selectedItems;
    fetchProduct(selectedItems);
    setCurrentPage(1);
  }

  var packValue2 = JSON.parse(localStorage.getItem("selectedItems2")) || [];
  function respHandlePacksChange(event) {
    const clickedItemId = Number(event.target.value);
    let selectedItems = [...packValue2];
    const index = selectedItems.indexOf(clickedItemId);

    if (event.target.checked) {
      if (index === -1) {
        selectedItems.push(clickedItemId);
      }
    } else {
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    localStorage.setItem("selectedItems2", JSON.stringify(selectedItems));
    packValue2 = selectedItems;
    fetchProduct(selectedItems);
    setCurrentPage(1);
  }
  // window.onbeforeunload = function () {
  //   localStorage.clear();
  // };
  const sendProdId = (id, pack) => {
    console.log(" Product id =>>>>>>>>SDdddddddd", id);
    // navigate(`/product/${id}`, { state: { productId: id } });
    // window.open(`/product/${id}`, '_blank');
    // window.open(`http://localhost:3000/productDescription?id=${id}` )
  };

  const addWishlist = (id, id2, id3) => {
    if (storedData.userLoginSuccess) {
      const url = getWhishlist();
      const userData = {
        user_id: storedData.userId,
        product_id: id,
        has_login: "yes",
      };

      axios
        .post(url, userData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.data.status) {
            toast.success("Wishlist Added", { icon: "❤️" });
            fetchProduct();
            window.fbq('track','Add to wishlist',{currency:'INR', value:id2, content_name:id3})
          } else {
            toast.error("Something Went Wrong", { icon: "⚠️" });
          }

          // console.log(" the value of list", resp.data);
        })
        .catch((err) => {
          // console.log(err);
          toast.error("Something Went Wrong", { icon: "⚠️" });
        });
    } else {
      // alert("Please Login");
      // navigate("/signin");
      setShowModal(true)

    }
  };

  const removeWishlist = (id) => {
    const url = deleteWishList(id);

    let data = new FormData();
    data.append("_method", "DELETE");
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        if (resp.data.status) {
          toast.success("Wishlist Removed", { icon: "✅" });
          fetchProduct();
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }

        // console.log(" the value of list", resp.data);
      })
      .catch((err) => {
        // console.log(err);
        toast.error("Something Went Wrong", { icon: "⚠️" });
      });
  };

  const addtoCart = (id) => {
    const url = getcartId();
    const postData = {
      user_id: storedData.userId,
      product_id: id,
      has_login: "yes",
    };

    axios
      .post(url, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log("---- cart value == ", resp.data);
        if (resp.data.status) {
          toast.success("Product Added In Cart", { icon: "🛒" });
          fetchProduct();
          setCartValues(true);
          // dispatch()
          // getCartData();

          // window. location. reload()
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }
      })
      .catch((error) => {
        // console.log(" === error ===", error);
      });
  };

  const handleAddToCart = (id, id2, id3) => {
    if (storedData.userLoginSuccess) {
      addtoCart(id);
      window.fbq('track','Add to cart',{currency:'INR',value:id2,content_name:id3})

    } else {
      // alert("Please Login ");
      // navigate("/signin");
      setShowModal(true)

    }
  };

  const resFilterFunc = () => {
    // console.log("fliter com", filtercom);
    setBtnShow(true);
    setFiltercom(!filtercom);
    setShowProduct(false);
    setMobileFilter(false);
    //for mobile
    setnoProdDataFound(true);
  };

  const displayProduct = () => {
    setFiltercom(false);
    setShowProduct(true);
    setBtnShow(false);
    setMobileFilter(true);
    //for mobile
    setnoProdDataFound(false);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  const lastPage = () => {
    const totalPages = Math.ceil(prodData.length / itemsPerPage);
    setCurrentPage(totalPages);
    window.scrollTo(0, 0);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const firstPage = () => {
    setCurrentPage(1);
    window.scrollTo(0, 0);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = prodData.slice(indexOfFirstItem, indexOfLastItem);
  const totalItems = prodData.length;

  const handlePageChange = (selectedPage) => {
    setisLoding(true);
    setTimeout(() => {
      setisLoding(false);
      setCurrentPage(selectedPage.selected + 1);
    }, 1000);
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // const searchFilter_func = (e) => {
  //   const url = serachApi();
  // };



  const clearAllFilters = () => {
    // localStorage.clear();
    localStorage.removeItem('selectedItems');
    window.localStorage.removeItem("CategoryCheckdDataLocal");
    window.localStorage.removeItem('packCheckedDataLocal');
    window.localStorage.removeItem('categoryValue2');
    window.localStorage.removeItem('')
    localStorage.removeItem("selectedItems2");
    localStorage.removeItem("selectedItems3");
    localStorage.removeItem("selectedItems4");
    localStorage.removeItem("brandsCheckdDataLocal");
    localStorage.removeItem("ddvalue");
    localStorage.removeItem("CategoryCheckdDataLocal");
    localStorage.removeItem("bcvalue");
    localStorage.removeItem("packCheckedDataLocal");
    localStorage.removeItem("packsvalue");
    localStorage.removeItem("resbrandsCheckdDataLocal");
    localStorage.removeItem("resddvalue");
    localStorage.removeItem("resCategoryCheckdDataLocal");
    localStorage.removeItem("resbcvalue");
    localStorage.removeItem("respackCheckedDataLocal");
    localStorage.removeItem("respacksvalue");
    setcheckedbrandsfilterData([]);
    setcheckedCategoriesfilterData([]);
    setcheckedpackssfilterData([]);

    // Clear local storage


    // Uncheck all checkboxes in the DOM
    var items = document.getElementsByName("brandinputCheck");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        items[i].checked = false;
      }
    }
    var items = document.getElementsByName("PacksinputCheck");
    for (var i = 0; i < items.length; i++) {
      if (items[i].type === "checkbox") {
        items[i].checked = false;
      }
    }

    // Trigger other actions if needed
    setCategorySelected(false);
    fetchProduct();
  };

  const handleShowAllCategories = () => {
    setNumCategoriesToShow(CategoriesfilterData.length);
  };

  const handleShowLessCategories = () => {
    setNumCategoriesToShow(5);
    window.scrollTo(0, 0);
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
 
  const toLoginPage =()=>{
    navigate('/signin')
  }

  const RespFIlterComponent = () => {
    return (
      <div className="container productCon2" style={{ padding: "20px" }}>
        {/* <div className="row"> */}
          <div className=" col-12 ProductFirstDivv">
            <div>
              {/* <div className="row"> */}
                {/* <div className="col-6"> */}
                  <div className="sortCont">
                    <p className="sortTitle">Filter by Brands</p>
                    {brandsfilterData.slice(0,2).map((item) => {
                      // console.log(" item --> ", item);
                      return (
                        <>
                          <div className="checkCon">
                            <label>
                              <input
                                onChange={respHandleBrnadChange}
                                checked={
                                  resbrandValue == null
                                    ? null
                                    : resbrandValue.includes(item.id)
                                }
                                value={item.id}
                                type="checkbox"
                                id={item.id}
                                name="inputCheck"
                              />
                              {item.brand_name}
                            </label>
                          </div>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                  {/* <div className="sortCont">
                        <p className="sortTitle">filter by Packs</p>
                        {packssfilterData.map((item) => {
                          return (
                            <>
                              <div className="checkCon">
                              <label >
                                <input
                                  onChange={respHandlePacksChange}
                                  checked={
                                    packValue2 == null
                                      ? null
                                      : packValue2.includes(item.pack)
                                  }
                                  value={item.pack}
                                  type="checkbox"
                                  id={item.pack}
                                  name="PacksinputCheck"
                                />
                                Pack of {item.pack}</label>
                              </div>
                              <hr />
                            </>
                          );
                        })}
                      </div> */}
                {/* </div> */}
                {/* <div className="col-6">
                  <div className="sortCont">
                    <p className="sortTitle">FIlter by Categories</p>

                    {CategoriesfilterData.map((item) => {
                      return (
                        <>
                          <div className="checkCon">
                          <label>
                            <input
                              onChange={respHandleCategoriesChange}
                              checked={
                                categoryValue2 == null
                                  ? null
                                  : categoryValue2.includes(item.id)
                              }
                              value={item.id}
                              type="checkbox"
                              id={item.id}
                              name="brandinputCheck"
                            />
                            {item.name}</label>
                          </div>
                          <hr />
                        </>
                      );
                    })}
                     
                  </div>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        {/* </div> */}
      </div>
    );
  };


//   for bulk order modal 

  const [isToastVisible, setToastVisible] = useState(true);
  const [show1, setShow1] = useState(false);
  const handleToastClose = () => {
    setToastVisible(false);
  };

  const handleCloseModalFunc2=()=>{
    setShow1(false)
  }

  const handleOpenModal =()=>{
    setShow1(true)
  }

  return (
    <>
      <NavMenu
        cartBoolean={cartValues}
        setCartBoolean={setCartValues}
        onCloseModal={handleModalClose}
      />

      <Modal show={show1} onHide={handleCloseModalFunc2} animation={false} centered  size="lg" className='modalClass2' >
        <Modal.Body className='modalBulk'>
          <BulkOrder/>
        </Modal.Body>
      </Modal>
      <div>
        <Toaster />
        {filtercom ? <RespFIlterComponent /> : null}
        
        {/* {isToastVisible && (
            <>
            <div style={{width:'100%'}}>
            <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
       
        <div class="toast-body" style={{background:'aliceblue', border:'1px solid aliceblue'}}>
            
          <div className="bulkContent container">
            <p className="bulkText">Contact us for Bulk order</p>
            <button className="bulkContact" onClick={handleOpenModal}>Request Bulk Order </button>
          </div>
        </div>
        </div>
            </div>
            </>
      )} */}
        <div className="container productCon">
            
        {/* {isToastVisible && (
            <>
            <div style={{width:'100%'}}>
            <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
       
        <div class="toast-body" style={{background:'aliceblue', border:'1px solid aliceblue'}}>
            <div className="closeBulk">
            <button onClick={handleToastClose} type="button" className="btn-close " data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          <div className="bulkContent">
            <p className="bulkText">Contact us for Bulk order</p>
            <button className="bulkContact" onClick={handleOpenModal}>Contact us </button>
          </div>
        </div>
        </div>
            </div>
            
            </>
       
      )} */}
        
        
          {btnshow ? (
            <div>
              {" "}
              <button
                onClick={() => clearAllFilters()}
                style={{
                  display: "block",
                  margin: "30px auto",
                  marginTop: "0px",
                  padding: "10px 20px",
                  borderRadius: "14px",
                  zIndex: "10",
                  background: "grey",
                  border: "2px",
                  color: "white",
                  width: "90%",
                }}
              >
                Clear All
              </button>{" "}
              <button
                onClick={() => displayProduct()}
                style={{
                  display: "block",
                  margin: "auto",
                  marginTop: "0px",
                  padding: "10px 20px",
                  borderRadius: "14px",
                  zIndex: "10",
                  background: "#E51515",
                  border: "2px",
                  color: "white",
                  width: "90%",
                }}
              >
                APPLY
              </button>
            </div>
          ) : null}
          <div className="row newRow">
            <div className="col-lg-3 col-md-3 col-3 ProductFirstDiv">
              <div>
                <div className="sortCont">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className="sortTitle">Filter by Brands</p>
                    <div className="checkCon" style={{ marginTop: "5px" }}>
                      <label onClick={() => clearAllFilters()} for="scales">
                        Clear All
                      </label>
                    </div>
                  </div>
                  {brandsfilterData.slice(0,2).map((item) => {
                    return (
                      <>
                        <div className="checkCon">
                          {/* <label className="label" for="scales"> */}
                          <label className="label">
                            <input
                              onChange={handleBrnadChange}
                              checked={
                                brandValue == null
                                  ? null
                                  : brandValue.includes(item.id)
                              }
                              value={item.id}
                              type="checkbox"
                              id={item.id}
                              name="inputCheck"
                            />
                            {item.brand_name}
                          </label>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                </div>

                {/* <div className="sortCont">
                      <p className="sortTitle">FIlter by Categories</p>
                    
                      {CategoriesfilterData.slice(0, numCategoriesToShow).map((item) => {
                        return (
                          <>
                            <div className="checkCon">
                            <label className="label" >
                              <input
                                onChange={handleCategoriesChange}
                                checked={
                                  categoriesArr == null
                                    ? null
                                    : categoriesArr.includes(item.id)
                                }
                                value={item.id}
                                type="checkbox"
                                id={item.id}
                                name="brandinputCheck"
                              />
                              {item.name}</label>
                            </div>
                            <hr />
                          </>
                        );
                      })}
                        {numCategoriesToShow === 5 ? (
          <div className="checkCon" style={{ margin: "5px auto 25px auto", width:"50%", justifyContent:'center',background:'red', borderRadius:'12px', color:'white' }}>
            <label onClick={handleShowAllCategories} htmlFor="scales">
              View More
            </label>
          </div>
        ) : (
          <div className="checkCon" style={{ margin: "5px auto 0px auto", width:"50%", justifyContent:'center',background:'red', borderRadius:'12px', color:'white' }}>
            <label onClick={handleShowLessCategories} htmlFor="scales">
              View Less
            </label>
          </div>
        )}
                    </div>

                <div className="sortCont">
                  <p className="sortTitle">filter by Packs</p>
                  {packssfilterData.map((item) => {
                    return (
                      <>
                        <div className="checkCon">
                        <label >
                          <input
                            onChange={handlePacksChange}
                            checked={
                              packArr == null
                                ? null
                                : packArr.includes(item.pack)
                            }
                            value={item.pack}
                            type="checkbox"
                            id={item.pack}
                            name="PacksinputCheck"
                          />
                          Pack of {item.pack}</label>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                </div> */}
              </div>
              {/* <img
                src={img}
                width="226px"
                height="380px"
                className="sideColumImg"
              /> */}
            </div>
            <div className=" col-lg-9 col-md-12 col-12 ProductSecondDiv">
              <div className="mb-3 minsort">
                <div className="iconGrid">
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      alignItems: "center",
                    }}
                  >
                    <BsListTask
                      onClick={() => {
                        gridFirstFunc();
                      }}
                      style={{ marginRight: "50px" }}
                      fontSize={22}
                      color={isActive2 ? "rgb(189, 39, 50)" : "gray"}
                    />

                    <TbLayoutGrid
                      onClick={() => {
                        gridTwoFuc();
                      }}
                      fontSize={20}
                      className="tfiLayout"
                      color={isActive ? "rgb(189, 39, 50)" : "gray"}
                    />
                  </div>
                  <div style={{ width: "30%" }} class="input-group mySearch">
                    <input
                      type="text"
                      class="form-control mySearch2"
                      placeholder="Search"
                      aria-label="Recipien"
                      aria-describedby="basic-addon2"
                      style={{ textTransform: "uppercase" }}
                      onChange={(e) => setseachValue(e.target.value)}
                    />
                    <span
                      class="input-group-text"
                      id="basic-addon2"
                      onClick={() => {
                        fetchProduct();
                      }}
                    >
                      <BiSearch />
                    </span>
                  </div>
                </div>
                <div
                  className="sortDiv"
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="innerSort"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      marginLeft: "90px",
                    }}
                  ></div>
                </div>
              </div>
              <div className="mobilSort">
                {mobileFilter ? (
                  <div className="row mobilSortRow">
                    <div className="col-8 anotherSpan">
                      <p className="sortIconAndText">
                        <span>
                          <BsListTask
                            onClick={() => {
                              gridFirstFunc();
                            }}
                            color={isActive2 ? "rgb(189, 39, 50)" : "gray"}
                            fontSize={22}
                          />

                          <TbLayoutGrid
                            onClick={() => {
                              gridTwoFuc();
                            }}
                            color={isActive ? "rgb(189, 39, 50)" : "gray"}
                          />
                        </span>
                        <div
                          style={{ width: "60%" }}
                          class="input-group mySearch"
                        >
                          <input
                            type="text"
                            class="form-control mySearch2"
                            placeholder="Search"
                            aria-label="Recipien"
                            aria-describedby="basic-addon2"
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) => setseachValue(e.target.value)}
                          />
                          <span
                            class="input-group-text"
                            id="basic-addon2"
                            onClick={() => {
                              fetchProduct();
                            }}
                          >
                            <BiSearch />
                          </span>
                        </div>
                      </p>
                    </div>
                    <div className="col-4 filterSpan">
                      <span
                        style={{ marginTop: "10px" }}
                        onClick={() => resFilterFunc()}
                      >
                        Filter
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
              {isLoding ? (
                <div
                  style={{
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <TailSpin
                    style={{
                      width: "100%",
                      border: "2px solid black",
                      margin: "auto",
                      display: "block",
                    }}
                    height="70"
                    width="70"
                    color="#ffd11b"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />{" "}
                </div>
              ) : (
                <>
                  <div>
                    {showProduct ? (
                      <div className={` ${
                        gridFour ? "productCradContainer" : "productCradContainer2"
                      }`}>
                        {noprodDataFound ? (
                          <>
                            {" "}
                            <p style={{ fontSize: "30px" }}>
                              No Product Found
                            </p>{" "}
                          </>
                        ) : (
                          currentItems.map((item) => {
                            // console.log("current itemsssss", item.title);
                            return (
                              <div
                                className={`productCard ${
                                  gridFour ? "GridFourCard" : "GridThreeCard"
                                }`}
                              >
                                <a href={`/product/${item.id}`}>
                                  <div
                                    style={{
                                      display: "block",
                                      backgroundColor: "#EEEEEE",
                                    }}
                                  >
                                    <div
                                      onClick={() => {
                                        sendProdId(item.id, item.pack);
                                      }}
                                      className="productPageImage"
                                      style={{
                                        backgroundImage: `white`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundAttachment: "inherit",
                                        mixBlendMode: "multiply",
                                        backgroundSize: "90% 90%",
                                        padding: 10,
                                        backgroundPosition: "center",
                                        backgroundOrigin: "content-box",
                                        backgroundPositionX: "center",
                                      }}
                                    >
                                      <img
                                        src={item.image_url}
                                        alt={item.title}
                                        style={{ width: "100%" }}
                                      />
                                      {item.sp_p ? (
                                        <p className="itemStock">
                                          {item.sp_name}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </a>

                                <p className="productCardReview">
                                  <div className="feratureStar">
                                    <ReactStars
                                      edit={false}
                                      value={item.rating}
                                      count={5}
                                      size={18}
                                    />
                                    <div
                                      style={{
                                        paddingRight: 20,
                                        marginTop: "5%",
                                      }}
                                    >
                                      {item.wishlist_id == null ? (
                                        <i
                                          class="fa-solid fa-heart"
                                          onClick={() => {addWishlist(item.id, item.starting_price, item.title)}}
                                          style={{
                                            color: "lightslategray",
                                            fontSize: "18px",
                                            padding: "1px",
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          class="fa-solid fa-heart"
                                          onClick={() =>
                                            removeWishlist(item.wishlist_id)
                                          }
                                          style={{
                                            color: "red",
                                            fontSize: "18px",
                                            padding: "1px",
                                          }}
                                        ></i>
                                      )}
                                    </div>
                                  </div>
                                </p>

                                <hr />
                                <a
                                  href={`/product/${item.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip
                                        style={{
                                          color: "rgba(255, 255, 255, 0.7)",
                                          color: "#fff",
                                          borderRadius: "4px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item.title}
                                      </Tooltip>
                                    }
                                  >
                                    <p
                                      className="ProductCardText"
                                      onClick={() => {
                                        sendProdId(item.id);
                                      }}
                                    >
                                      {/* {item.title
                                        .split(" ")
                                        .slice(0, 8)
                                        .join(" ")}
                                      {item.title.split(" ").length > 8
                                        ? "..."
                                        : ""} */}
                                        {item.title}
                                    </p>
                                  </OverlayTrigger>
                                </a>
                                {/* <span className="tooltiptext"></span> */}
                                <hr />
                                <a
                                  href={`/product/${item.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <span
                                    className="featureOffer"
                                    onClick={() => sendProdId(item.product_id)}
                                  >
                                    <strong>
                                      {parseInt(
                                        ((item.mrp - item.starting_price) /
                                          item.mrp) *
                                          100
                                      )}
                                      % Off
                                    </strong>
                                  </span>
                                </a>
                                <a
                                  href={`/product/${item.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <p style={{margin:'0px 10px -5px ', color:'black', fontSize:'14px', fontWeight:'500'}}>Our Price</p>
                                  <span
                                    onClick={() => {
                                      sendProdId(item.id);
                                    }}
                                    className="productMrp"
                                  >
                                    <p
                                      className="productCardPrice"
                                      onClick={() => {
                                        sendProdId(item.id);
                                      }}
                                    >
                                      ₹ {item.starting_price}
                                    </p>
                                    <p
                                      className="productCardPrice2"
                                      onClick={() => {
                                        sendProdId(item.id);
                                      }}
                                    >
                                      MRP{" "}
                                      <strike style={{ fontSize: "14px" }}>
                                        ₹{item.mrp}
                                      </strike>
                                    </p>
                                  </span>
                                </a>
                                {item.cart_id == null ? (
                                  <button
                                    onClick={() => {handleAddToCart(item.id, item.starting_price, item.title)}}
                                    className="productCardBtn"
                                  >
                                    <i
                                      class="fa-solid fa-cart-shopping"
                                      style={{ fontSize: "14px" }}
                                    ></i>
                                    Add to cart
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => navigate("/cart")}
                                    className="productCardBtn"
                                  >
                                    View in cart
                                  </button>
                                )}
                              </div>
                            );
                          })
                        )}
                      </div>
                    ) : null}
                  </div>
                </>
              )}

              {!noprodDataFound ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active-page1"}
                    forcePage={currentPage - 1}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg" className='modalClass modalClass2'>
       
       <Modal.Body style={{height:'100px', background:'rgba(241, 241, 241, 1)'}}>
        <p>Please Login!</p>
        <div style={{display:'flex', justifyContent:'end'}}>
        <button onClick={toLoginPage} style={{background:'red', borderRadius:'10px', color:'white', border:'none', padding:'5px 10px'}}>Login</button>
        </div>
       </Modal.Body>
     </Modal>
    </>
  );
};

export default BulkPage;
