import React, { useState,useEffect ,Component }  from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'
import { render } from 'react-dom';
export default function Feedback() {
  const [cartValues, setCartValues] = useState(false);
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
     <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
     <div className='mainPrivacy'>
      <h1 className='creativeCarCare'>Feedback</h1>
      <div className='privacyPolicy'>
        {/* <h3 className='cookies'>Return:</h3><br/> */}
        Thank you for shopping at creativecarcare.in. We are committed to serving our customers with the highest standards of service, with regard to products, process and experience.

Please click on the link provided below to give us your invaluable feedback. We would be delighted to hear from you. As a token of our gratitude and our way of saying “Thank you” for taking time out to share your experience, a coupon code will be generated worth Rs.150/- when you submit the form with your feedback, which you can redeem against any of the product on you next purchase at www.creativecarcare.in after form submission.

We will also share your testimonial in future on our website.

For Feedback (Click here)

Don't forget to follow us on Facebook and Instagram to get real time updates of our deals, discounts or just drop in to say ‘hello!’

<button style={{marginTop:'50px'}} type="button" class="btn btn-dark"><a style={{textDecoration:'none', color:'white'}} href="https://wa.me/9930290909" >Chat with team</a></button>
        {/* In the rare event that you are not fully satisfied with what you ordered, just return it to us within 3 days of delivered date in the original packaging and we will refund you, subject to 15% restocking fees.
        <ol>
          <li>* After 3 days of Delivered date we will not accept any refunds or returns.</li>
          <li>*  All items must be returned in the original package, cannot be damaged or installed, and must be in original condition.</li>
          <li>*  All Liquid / Gel / Aerosol products are not returnable . </li>
          <li>* For all returns, Please whatsapp or sms on +919920290909 or email us at : info@creativecarcare.in - with your order number / invoice number & specific reason for return </li>
        </ol> */}
        

      </div>
    </div>
     <Footer/>
    </>
  )
}






// import  { Component } from 'react';
// import { render } from 'react-dom';
// // window.$ = window.jQuery = require('jquery');
// import  $ from 'jquery';

// import Hello from './Hello';
// import './style.css';

// export class Feedback extends Component {
//   constructor() {
//     super();
//     this.state = {
//       name: 'React',
//     };

//     function handleResponse(res) {
//       console.log(res);
//       //before updating transaction status & showing acknowledgement to end customers, response HASH match on server side is mandatory, for more details please refer integration documentation.
//       if (
//         typeof res != 'undefined' &&
//         typeof res.paymentMethod != 'undefined' &&
//         typeof res.paymentMethod.paymentTransaction != 'undefined' &&
//         typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' &&
//         res.paymentMethod.paymentTransaction.statusCode == '0300'
//       ) {
//         // success block
//       } else if (
//         typeof res != 'undefined' &&
//         typeof res.paymentMethod != 'undefined' &&
//         typeof res.paymentMethod.paymentTransaction != 'undefined' &&
//         typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' &&
//         res.paymentMethod.paymentTransaction.statusCode == '0398'
//       ) {
//         // initiated block
//       } else {
//         // error block
//       }
//     }

//     $(document).on('click', '#btnPay', function () {
//       console.log($('#txtHidden').val());
//       var configJson = {
//         tarCall: false,
//         features: {
//           showPGResponseMsg: true,
//           enableExpressPay: true,
//           enableNewWindowFlow: false, //for hybrid applications please disable this by passing false
//         },
//         consumerData: {
//           deviceId: 'WEBSH2', //possible values 'WEBSH1', 'WEBSH2' and 'WEBMD5'
//           token:
//             '6ee363fc850b38e5c885d2953a1cbddc99982b2a03c8db04c528cab6bf8e999551a7f676e146cdf717b47866e4530dc48995b0861a0c7206c8e2404024ed05e2',
//           returnUrl:
//             'https://www.tekprocess.co.in/MerchantIntegrationClient/MerchantResponsePage.jsp', //merchant response page URL
//           responseHandler: handleResponse,
//           paymentMode: 'all',
//           merchantLogoUrl:
//             'https://www.paynimo.com/CompanyDocs/company-logo-md.png', //provided merchant logo will be displayed
//           merchantId: 'L3348',
//           currency: 'INR',
//           consumerId: 'c964634',
//           consumerMobileNo: '9876543210',
//           consumerEmailId: 'test@test.com',
//           txnId: '1481197581115', //Unique merchant transaction ID
//           items: [
//             {
//               itemId: 'test',
//               amount: '10',
//               comAmt: '0',
//             },
//           ],
//           customStyle: {
//             PRIMARY_COLOR_CODE: '#3977b7', //merchant primary color code
//             SECONDARY_COLOR_CODE: '#FFFFFF', //provide merchant's suitable color code
//             BUTTON_COLOR_CODE_1: '#1969bb', //merchant's button background color code
//             BUTTON_COLOR_CODE_2: '#FFFFFF', //provide merchant's suitable color code for button text
//           },
//         },
//       };

//       $.pnCheckout(configJson);
//       if (configJson.features.enableNewWindowFlow) {
//         pnCheckoutShared.openNewWindow();
//       }
//     });
//   }

//   render() {
//     return (
//       <div>
//         {/* <Hello name={this.state.name} /> */}
//         <button id="btnPay">Pay Now</button>
//       </div>
//     );
//   }
// }

// render(<Feedback />, document.getElementById('root'));
