import React, { useEffect, useState } from 'react';
import NavMenu from '../Navbar';
import Footer from '../Footer';
import AdminDashboard from './admindashboard';
import Wallet from './wallet';
import Orders from './Orders';
import AccountDetails from './AccountDetails';
import AddressDetails from './AddressDetails';
import Logout from './Logout'
import './profile.css';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import { logoutUser } from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../redux/ActionCreator';
import GridImg from '../../assets/img/profile/grid.png'
import WalletImg from '../../assets/img/profile/credit-card.png'
import Package from '../../assets/img/profile/package.png'
import AddressBk from '../../assets/img/profile/map-pin.png'
import Vector from '../../assets/img/profile/Vector (2).png'
import Clipboard from '../../assets/img/profile/clipboard (1).png'
import {RxDashboard} from 'react-icons/rx'
import {FcViewDetails} from 'react-icons/fc'
import {BiWallet} from 'react-icons/bi'
import {FiPackage} from 'react-icons/fi'
import {BiMap} from 'react-icons/bi'
import {TbListDetails} from 'react-icons/tb'
import {AiOutlineHeart} from 'react-icons/ai'
import { ColorRing, TailSpin } from "react-loader-spinner";
import Wishlist2 from './Wishlist2';

export default function Profile() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const storedData = useSelector((state) => state.profile);
  const [cartValues, setCartValues] = useState(false);
  const [isLogoutLoding, setisLogoutLoding] = useState(false)
  const [activeTab, setActiveTab] = useState('dashboard');
  const [pageLoading, setPageLoading] = useState(true)
  useEffect(()=>{
    window.scrollTo(0, 0)
    setTimeout(() => {
      setPageLoading(false)
    }, 500);

  },[])

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <AdminDashboard />;
        case 'wallet':
        return <Wallet/>;
      case 'Orders':
        return <Orders />;
      case 'AccountDetails':
        return <AccountDetails />;
      case 'AddressDetails':
        return <AddressDetails />;
      case 'Wishlist':
        return <Wishlist2 />;
        case 'Logout':
          return <Logout />;
          // case 'Wishlist':
          // return <Logout />;
      default:
        return null;
    }
  };


  const logOutUSerFromReducer = () => {
    dispatch(logOut());
    // <Navigate to="/" push />
    navigate('/')
  
    toast.success('User LoggedOut Successfully')

    // console.log("user Logged out from web");
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
  }
  }

  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };

  const toWishlist = () => {
    navigate('/wishlist');
  };
  return (
    <>
      <NavMenu onCloseModal={handleModalClose}  setCartBoolean={setCartValues} cartBoolean={cartValues}/>
      <Toaster />
      {
        pageLoading ? (<>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'white'}}>
            <TailSpin  color="#ffd11b" />
          </div>
          </>):(<>
            <div className='profileContainer'>
        <div className='overview'>
          <div className='dashboard '>
            <ul className='listofaccount'> 
              <li onClick={() => handleTabClick('dashboard')}   className={`listvalue value2 ${activeTab === 'dashboard' ? 'active' : ''}`}> <RxDashboard style={{marginRight:'5px'}}/>DashBoard</li>
              <hr className='listaccounthr'/>
              <li onClick={() => handleTabClick('wallet')}  className={`listvalue value2 ${activeTab === 'wallet' ? 'active' : ''}`}> <BiWallet  style={{marginRight:'5px'}}/>My wallet</li>
              <hr className='listaccounthr'/>
              <li onClick={() => handleTabClick('Orders')} className={`listvalue  ${activeTab === 'Orders' ? 'active' : ''}`}><FiPackage  style={{marginRight:'5px'}}/>My orders</li>
              <hr className='listaccounthr'/>
              {/* <li onClick={() => handleTabClick('AccountDetails')} className={`listvalue   ${activeTab === 'AccountDetails' ? 'active' : ''}`}> <TbListDetails style={{marginRight:'5px'}}/>My details</li>
              <hr className='listaccounthr'/> */}
             <li onClick = {() => handleTabClick('AddressDetails')} className={`listvalue ${activeTab === 'AddressDetails' ? 'active' : ''}`}><BiMap style={{marginRight:'5px'}}/>My Address</li>
              <hr className='listaccounthr'/>
              <li onClick = {() => handleTabClick('Wishlist')} className={`listvalue   ${activeTab === 'Wishlist' ? 'active' : ''}`}><AiOutlineHeart style={{marginRight:'5px'}}/>My Wishlist</li>
              <hr className='listaccounthr'/>
              <button style={{fontFamily:'Rubik'}} onClick = {() => logOutUSerFromReducer() } className={`logoutBtn ${activeTab === 'Logout' ? 'active' : ''}`}>
               Logout
              </button>
            </ul>
          </div>


          <div className='dashboard2 '>
          
         
          <table>
              <tbody className="mainValues">
                <tr>
                  <td
                    onClick={() => handleTabClick('dashboard')}
                    className={`values2 listvalue ${
                      activeTab === 'dashboard' ? 'active' : ''
                    }`}
                  >
                    <div class="icon"  style={{marginTop: '4px', marginLeft: '6px'}}>
                      <RxDashboard/>
                    </div>
                    <p className="allValueDetails">DashBoard</p>
                  </td>
                  <td
                    onClick={() => handleTabClick('wallet')}
                    className={`values2 listvalue ${
                      activeTab === 'wallet' ? 'active' : ''
                    }`}
                  >
                    <div class="icon"  style={{marginTop: '4px', marginLeft: '6px'}}>
                     <BiWallet/>
                    </div>
                    <p className="allValueDetails">My Wallet</p>
                  </td>
                  <td
                    onClick={() => handleTabClick('Orders')}
                    className={`values2 listvalue ${
                      activeTab === 'Orders' ? 'active' : ''
                    }`}
                  >
                    <div class="icon"  style={{marginTop: '4px', marginLeft: '6px'}}>
                      <FiPackage/>
                    </div>
                    <p className="allValueDetails">My Orders</p>
                  </td>
                </tr>
                <tr>
                  {/* <td
                    onClick={() => handleTabClick('AccountDetails')}
                    className={`values2 listvalue ${
                      activeTab === 'AccountDetails' ? 'active' : ''
                    }`}
                  >
                    <div class="icon"  style={{marginTop: '4px', marginLeft: '6px'}}>
                     <TbListDetails/>
                    </div>
                    <p className="allValueDetails">My Details</p>
                  </td> */}
                  <td
                    onClick={() => handleTabClick('AddressDetails')}
                    className={`values2 listvalue ${
                      activeTab === 'AddressDetails' ? 'active' : ''
                    }`}
                  >
                    <div class="icon" style={{marginTop: '4px', marginLeft: '6px'}}>
                     <BiMap/>
                    </div>
                    <p className="allValueDetails">My Address</p>
                  </td>
                  <td
                   onClick={() => handleTabClick('Wishlist')}
                    className={`values2 listvalue ${
                      activeTab === 'Wishlist' ? 'active' : ''
                    }`}
                  >
                    <div class="icon"  style={{marginTop: '4px', marginLeft: '6px'}}>
                      <AiOutlineHeart/>
                    </div>
                    <p className="allValueDetails">My Wishlist</p>
                  </td>
                  <td>
                  <button style={{fontFamily:'Rubik'}} onClick = {() => logOutUSerFromReducer() } className={`logoutBtn ${activeTab === 'Logout' ? 'active' : ''}`}>
               Logout
              </button>
                  </td>
                 
                </tr>
              </tbody>
            </table>
    </div>
    {/* <div className='logoutOfButton' style={{ justifyContent:'center'}}>
           
    </div> */}
          <div className='contentProfile'>{renderContent()}</div>
        </div>
      </div>
      <Footer />
          </>)
}
    </>
  );
}


