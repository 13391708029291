import React, { useState } from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'
import './policies.css'

export default function Privacy() {
  const [cartValues, setCartValues] = useState(false);
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
    <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
    <div  className='mainPrivacy'>
      <h1 className='creativeCarCare'>Privacy Policy</h1>
      <div className='privacyPolicy'>
      Privacy Policy<br/>
      We do not appreciate it when someone gives out our personal information or details and neither would you. We, at creativecarcare.in, do not indulge in transacting in any way whatsoever that totals to sharing personal information of our esteemed customers to any company, organisation or corporate. We believe in respecting each other’s privacy.
      <h3 className='cookies'>Collection of personal identifiable information:</h3><br/>
      The information you share with us via our website, is utilised by us, in internal trainings, internal reviews, improving the performance of our website regularly, in order to ensure our clients have a seamless and pleasurable experience with us, earning your relationship with us for life.

Creativecarcare.in will not indulge in any trade or monitory transactions that involve trading of information such as your email address to another company, neither will we make our database available to any entity or public as a whole.

If you have selected to subscribe to one of our e-mail newsletters you can unsubscribe at any time.<br/>

<h3 className='cookies2'>Cookies:</h3>
THIS NEEDS TO BE CHECKED WITH SANJIV, NIKHIL AND SHIVANGI FOR ANY TECHNICAL ISSUES WITH THE DISCLAIMER
<br></br><br></br>

Creativecarcare.in like most websites, uses a feature of your browser to set a "cookie" on your computer. We cannot tell your name or any personal information about you based solely on the cookie.

Unless you specifically tell us your name and other identifying information, we will never know who you are. All the cookie tells us is what functions you perform when you are on the site so that we can keep track of your transaction.

A cookie also helps creativecarcare.in, monitor certain traffic patterns on the website, assisting us to improve your experience with us.

Every browser allows you to reject cookies or to choose which cookies you will accept. Rejecting cookies, however, may sometimes interfere with your ability to use our site's features and benefits.<br/>
<h3 className='cookies'>Security Precautions:</h3><br/>
We will cooperate with law enforcement and judicial authorities, and we may provide personally identifiable information to appropriate government authorities upon receipt of a subpoena or court order or to cooperate with a law enforcement investigation.

We will fully cooperate with law enforcement agencies in identifying those who use our services for illegal activities.

We also reserve the right to report to law enforcement agencies activities that we in good faith believe to be illegal.

This privacy policy is subject to change at the sole discretion of creativecarcare.in.

“You”, as our esteemed customer, will be able to read our amendments in a revised policy statement when we modify it from time to time.
    </div>
    
    </div>
    <Footer/>
    </>
  )
}
