import React, { useState } from "react";
import NavMenu from "../Navbar";
import "./Return.css";
import axios from "axios";
// import {orderReturn} from "../api/api";
import {orderReturn} from "../../api/api";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Footer from "../Footer";
// import ReCAPTCHA from 'react-google-recaptcha';

export default function ReturnOrder() {
const [verified, setVerified] = useState(false)
const [name, setName]=useState('')
const[lastname, setLastname]=useState('')
const[email, setEmail]=useState('')
const[mobile,setMobile]=useState('')
const[orderID, setOrderID]=useState('')
const[orderDate, setOrderDate]=useState('')
const[productName, setProductName]=useState('')
const[quantity,setQuantity]=useState('')
const [faulty, setFaulty]=useState('')
const [reasonReturn, setReasonreturn]=useState('')
const[isproduct, setIsproduct]=useState('')
const [bankDetails, setBankDetails] = useState( 'Bank Account:\nIFSC Code:\nAccount No:')
const[file,setFile]=useState(null)
const[video, setVideo]=useState(null)
const navigate = useNavigate();
const [cartValues, setCartValues] = useState(false);

const onChange = ()=>{
  console.log('--changes', );
}
const handleBankDetailsChange = (e) => {
  setBankDetails(e.target.value);
};

 // On file select (from the pop up)
const onFileChange =(event)=> {
 
  // Update the state
  console.log('upload imgggggggg',event.target.files[0])
  setFile(event.target.files[0]);
};

const onVideoChange =(event)=> {
  console.log('upload video',event.target.files[0])
  setVideo(event.target.files[0]);
};

const submitForm = (e)=>{
  e.preventDefault()

  if (name.length <1){
    alert("Please enter a name");
    return;
  }

  if (lastname.length <1){
    alert("Please enter a Last name");
    return;
  }

  const emailRegex = /\S+@\S+\.\S+/;
  if (!emailRegex.test(email)) {
    alert("Please enter a valid email address.");
    return;
  }


if (mobile.length !== 10 || !/^[0-9]+$/.test(mobile)) {
  alert("Please enter a valid 10-digit mobile number.");
  return;
}

  if (orderID.length <1){
    alert("Please enter Order ID");
    return;
  }

  if (orderDate.length <1){
    alert("Please enter Order date");
    return;
  }

  if (productName.length <1){
    alert("Please enter product name");
    return;
  }

  if (quantity.length <1){
    alert("Please enter Quantity");
    return;
  }

  if (faulty.length <1){
    alert("Please enter faulty details");
    return;
  }

 
  // const url='https://admin.creativecarcare.in/api/order_return'
  const url=orderReturn()

  let data = new FormData();
  data.append("first_name", name);
  data.append("last_name", lastname);
  data.append("mobile", mobile);
  data.append("email", email);
  data.append("order_id", orderID);
  data.append("order_date", orderDate);
  data.append("product_name", productName);
  data.append("quantity", quantity);
  data.append("reason_for_return_desc", faulty);
  data.append("bank_details", bankDetails);
  data.append("reason_for_return", reasonReturn);
  data.append("prod_return_condition", isproduct);
  data.append('media',file)
  // data.append('media2',video)

  console.log("bod yy= > ", data);
  axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log('new response', resp.data)
        // console.log(' = = =cartdata = = =', resp.data);
        if (resp.data.status) {
          alert('successfully submited !!')
          // toast.success('successfully submited !!', { icon : '❤️' })
          navigate('/')
        } else {
          // toast.error('Something Went Wrong', { icon : '⚠️' })
          alert('Something Went Wrong')
        }
      })
      .catch((error) => {
       console.log('errrorrrrrrrrrrrrrr',error)
      });

}
const handleModalClose = () => {
  // getCartData()
  // Your code to run when the modal is closed goes here
  console.log('Modal is closed, running effect in CartPage...');
};
  return (
    <>
      <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
      <Toaster />
      <div className="marginOfReturn">
        <div className="container">
          <div className="row">
            <section className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div id="content">
                <h2> Product Return</h2>
                <div className="wrapper clearfix">
                  <p className="overallReturnChanges">
                    Please complete the form below to request an RMA number.
                  </p>{" "}
                </div>
                <div className="wrapper clearfix hasvariant">
                  <form
                    
                    method="post"
                    enctype="multipart/form-data"
                  >
                    <h3 className="page-meta">Order Information</h3>
                    <div className="contentReturn">
                      <div className="row">
                        <div className="col-lg-6  col-md-12  col-sm-12 col-xs-12">
                          <div className="form-group">
                            <label className=" ">
                              {" "}
                              <span className="required">*</span> First Name:{" "}
                            </label>
                            <input
                              type="text"
                              name="firstname"
                              minLength={1}
                              // value=""
                              onChange={(e)=>setName(e.target.value)}
                              className="form-control"
                              required
                            />
                          </div>
                          
                          <div className="form-group">
                            <label className=" ">
                              <span className="required">*</span> E-Mail:
                            </label>
                            <input
                              type="email"
                              name="email"
                              // value=""
                              onChange={(e)=>setEmail(e.target.value)}
                              className="form-control"
                            />
                          </div>
                          <div className="form-group">
                            <label className=" ">
                              <span className="required">*</span> Order ID:
                            </label>
                            <input
                              type="text"
                              name="order_id"
                              minLength={1}
                              // value=""
                              onChange={(e)=>setOrderID(e.target.value)}
                              className="form-control"
                            />
                          </div>
                          
                        </div>
                        <div className="col-lg-6  col-md-12  col-sm-12 col-xs-12">
                          

                          <div className="form-group">
                            <label className=" ">
                              {" "}
                              <span className="required">*</span> Last Name:{" "}
                            </label>
                            <input
                              type="text"
                              name="lastname"
                              minLength={1}
                              // value=""
                              onChange={(e)=>setLastname(e.target.value)}
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              <span className="required">*</span> Mobile:
                            </label>
                            <input
                              type="text"
                              maxLength={10}
                              minLength={10}
                              name="telephone"
                              onChange={(e)=>setMobile(e.target.value)}
                              // value=""
                              className="form-control"
                            />
                          </div>
                          <div className="form-group">
                            <label className=" ">Order Date:</label>
                            <input
                              type="date"
                              name="date_ordered"
                              placeholder="YYYY-MM-DD"
                              
                              minLength={1}
                              // value=""
                              onChange={(e)=>setOrderDate(e.target.value)}
                              className="date form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h2 className="page-meta ">
                      Product Information &amp; Reason for Return
                    </h2>
                    <div id="return-product">
                      <div className="contentReturn">
                        <div className="return-product">
                          <div className="row">
                            <div className="col-lg-6 col-md-6  col-sm-12 col-xs-12">
                              <div className="return-name form-group">
                                <label className=" ">
                                  <span className="required">*</span>{" "}
                                  <b>Product Name:</b>
                                </label>
                                <input
                                  type="text"
                                  name="product"
                                  minLength={1}
                                  // value=""
                                  onChange={(e)=>{setProductName(e.target.value)}}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-4  col-sm-12 col-xs-12">
                              <div className="return-model">
                                <label className=" ">
                                  <span className="required">*</span>{" "}
                                  <b>Product Code:</b>
                                </label>
                                <input
                                  type="text"
                                  name="model"
                                  value=""
                                  className="form-control"
                                />
                              </div>
                            </div> */}
                            <div className="col-lg-4 col-md-4  col-sm-12 col-xs-12">
                              <div className="return-quantity">
                                <label className=" ">
                                  <b>Quantity:</b>
                                </label>
                                <input
                                  type="number"
                                  name="quantity"
                                  min={1}
                                  // value="1"
                                  onChange={(e)=>setQuantity(e.target.value)}
                                  className="form-control"
                                />
                              </div>
                           
                            </div>
                          </div>
                        </div>
                        <div className="return-detail">
                          <div className="row">
                            <div className="col-lg-4 col-md-4  col-sm-12 col-xs-12">
                              <div className="return-reason">
                                <p className=" overallReturnChanges">
                                  <span className="required ">*</span>{" "}
                                  <b>Reason for Return:</b>
                                </p>
                                <table>
                                  {/* <tr>
                                    <td>
                                      <div className="radio">
                                        <label for="return-reason-id1">
                                          <input
                                            type="radio"
                                            name="return_reason_id"
                                            value="1"
                                            id="return-reason-id1"
                                          />
                                          Dead On Arrival{" "}
                                        </label>
                                      </div>
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td>
                                      <div className="radio">
                                        <label for="return-reason-id4">
                                          <input
                                            type="radio"
                                            name="return_reason_id"
                                            value="1"
                                            id="return-reason-id4"
                                            onChange={(e)=>setReasonreturn(e.target.value)}
                                          />
                                          Faulty product{" "}
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="radio">
                                        <label
                                          className=" "
                                          for="return-reason-id3"
                                        >
                                          <input
                                            type="radio"
                                            name="return_reason_id"
                                            value="2"
                                            id="return-reason-id3"
                                            onChange={(e)=>setReasonreturn(e.target.value)}
                                          />
                                          Damaged product{" "}
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="radio">
                                        <label
                                          className=" "
                                          for="return-reason-id3"
                                        >
                                          <input
                                            type="radio"
                                            name="return_reason_id"
                                            value="2"
                                            id="return-reason-id3"
                                            onChange={(e)=>setReasonreturn(e.target.value)}
                                          />
                                          Wrong product{" "}
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>
                                      <div className="radio">
                                        <label
                                          className=" "
                                          for="return-reason-id5"
                                        >
                                          <input
                                            type="radio"
                                            name="return_reason_id"
                                            value="3"
                                            id="return-reason-id5"
                                            onChange={(e)=>setReasonreturn(e.target.value)}
                                          />
                                          Other, please supply details{" "}
                                        </label>
                                      </div>
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td>
                                          <p className="importImage">Import Image</p>
                                      <div className="">
                                        <label
                                          className=" "
                                          for="return-reason-id2"
                                        >
                                            <input
                                            type="file"
                                            // accept="image/*"
                                            onChange={onFileChange}
                                          />
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                          <p className="importImage">Import Video</p>
                                      <div className="">
                                        <label
                                          className=" "
                                          for="return-reason-id2"
                                        >
                                            <input
                                            type="file"
                                            // accept="image/*"
                                            onChange={onVideoChange}
                                          />
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4  col-sm-12 col-xs-12">
                              <div className="return-opened">
                                <p className="overallReturnChanges">
                                  <b>Product is opened:</b>
                                </p>

                                <span style={{ display: "flex" }}>
                                  <span style={{ display: "flex" }}>
                                    <input
                                      type="radio"
                                      name="opened"
                                      value="1"
                                      id="opened"
                                      onChange={(e)=>setIsproduct(e.target.value)}
                                    />
                                    <span style={{marginLeft:'5px'}}>Yes</span>
                                    
                                  </span>
                                  <span
                                    style={{
                                      display: "flex",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name="opened"
                                      value="0"
                                      id="opened"
                                      onChange={(e)=>setIsproduct(e.target.value)}
                                    />
                                    <span style={{marginLeft:'5px'}}>No</span>
                                  </span>
                                </span>
                                {/* 
                                <input type="radio" name="opened" value="0" id="unopened" checked="checked"/>
                                <label className=' ' for="unopened">No</label>  */}

                                <p className="overallReturnChanges">
                                  Brief description for reason of return:
                                </p>
                                <textarea
                                  name="comment"
                                  cols="150"
                                  rows="6"
                                  minLength={1}
                                  onChange={(e)=>setFaulty(e.target.value)}
                                  className="form-control"
                                ></textarea>
                              </div>
                              <br />
                              <b style={{color: 'black'}}>Bank Details</b>
                              <br />
                              <textarea
                                name="bank_detail"
                                cols="40"
                                rows="4"
                                minLength={1}
                                className="form-control"
                                placeholder="Bank Name:"
                                value={bankDetails}
                                onChange={handleBankDetailsChange}
                              >
                                 {/* Bank Account: IFSC Code: Account No: */}
                              </textarea>
                            </div>
                     
                            <div className="col-lg-4 col-md-4  col-sm-12 col-xs-12">
                              <div className="return-captcha" style={{marginTop:'6px'}}>
                                {/* <p className="overallReturnChanges">
                                  <b>Enter the code in the box below:</b>
                                </p>
                                <input
                                  type="text"
                                  name="captcha"
                                  value=""
                                  className="form-control"
                                />
                                <br /> */}
                                {/* <ReCAPTCHA className="recapctha"
                                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                  onChange={onChange}
                                 
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin:'8px 0'
                      }}
                    >
                      <button className="ReturnButton">Back</button>
                      <button onClick={submitForm} className="ReturnButton">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
