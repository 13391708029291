import React, { useEffect, useState } from 'react'
import { deleteTheAddress, getUserAddress, getUserAddressbyId, prefilAddress } from '../../api/api'
import axios from 'axios'
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form'
import { TailSpin } from 'react-loader-spinner'
import { Toaster, toast } from 'react-hot-toast'
import { Navigate, useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


export default function StoredAddress() {
    const storedData = useSelector((state) => state.profile);

    const [address,setaddress]=useState([])
    const [noAddressFound, setnoAddressFound] = useState(false)
    const [addAddressLoding, setaddAddressLoding] = useState(false)
    const [showAddressForm, setshowAddressForm] = useState(false)
    const [showSelectAddressErrorMessaage, setshowSelectAddressErrorMessaage] = useState(false)
    const [status, setStatus] = useState(true)
    const [saurabh, setsaurabh] = useState(true)
    const [firstName, setFirstName] = useState('')

    useEffect(()=>{
        getUserAddress_func(storedData.userId)
        window.scrollTo(0, 0);
        // getCityName()
    },[])


    // const getCityName = ()=>{
    //     const url = 'https://api.postalpincode.in/pincode/'+401107
    //     console.log(url);
    //     axios.get(url)
    //     .then((resp)=>{
    //         console.log(resp.data[0].PostOffice);
            
    //     })
    //     .catch((error)=>{
    //         console.log(error);
    //     })
    // }

    const stateNames = [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal'
      ];
      

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm({
        defaultValues: {
          name: firstName,
          lastName: "",
        },
      })
  
      const onSubmit = (data) => {
        if(data.phoneNumber.length===10){
      setStatus(true); 
        }
        else{
            alert("Please enter a valid phone number.");
            setStatus(false)
        }

        if(data.pincode.length===6){
            setStatus(true); 
              }
              else{
                  alert("Please enter a valid pincode number.");
                  setStatus(false)
              }
        if(status){
        setaddAddressLoding(true)
        const url = getUserAddress();
        var body = {
          user_id: storedData.userId,
          name: data.name,
          mobile: data.phoneNumber,
          city: data.city,
          territory: data.state,
          address: data.addres,
          pincode: data.pincode,
          landmark: data.landmark,
          country: data.country,
          type_of_address: data.addressType
        }
  
        console.log(" url & body ==> ", url, "   ", body);
  
        axios.post(url,body,{
            headers:{
                "Content-Type": "multipart/form-data",    
            }}).then((resp) => {
          console.log("resp in adding address ==> ", resp.data);
          if (resp.data.status) {
            toast.success('Address Added', { icon : '📍' })
            setshowAddressForm(false)
            setTimeout(() => {
              setaddAddressLoding(false)
              getUserAddress_func(storedData.userId)
            }, 1000);
          } else {
            toast.success('!Oops Something Went Wrong', { icon : '⚠️' })
            setTimeout(() => {
              setaddAddressLoding(false)
            }, 1000);
          }
        }).catch((err) => {
          console.log("err in saving address ->< ", err);
          toast.success('!Oops Something Went Wrong', { icon : '⚠️' })
          setTimeout(() => {
            setaddAddressLoding(false)
          }, 1000);
        })
      }
    }

    const getUserAddress_func = (id)=>{

        const url = getUserAddressbyId(id)
        axios.get(url)
        .then((resp)=>{
            console.log("Address info ==> ", url, resp.data.data);
            if (resp.data.data == '') {
                setsaurabh(true)
                setnoAddressFound(true)
                allPrefilAddress()
            }else{
                setaddress(resp.data.data)
                setnoAddressFound(false)
                setsaurabh(false)
                console.log(" -- -  -address",resp.data);
            }
        })
        .catch((error)=>{
            console.log(' - - -error',error);
            setnoAddressFound(true)
        })
    }

    const allPrefilAddress=()=>{
        const url = prefilAddress(storedData.userId)
        axios.get(url)
        .then((resp)=>{
            console.log('==resp==>',resp.data.data.name);
            setFirstName(resp.data.data.name)
        })
        .catch((error)=>{
            console.log('==>error in prefil',error);
        })
    }

    const deleteAddress=(id)=>{
        let data = new FormData();
    data.append("_method", "DELETE");
    const url = deleteTheAddress(id)
    axios.post(url,data)
    .then((resp)=>{
        console.log('==deleted resp',resp);
        toast.success('Deleted Successfully', { icon : '⚠️' })
        setaddress([])
        getUserAddress_func(storedData.userId)
    })
    .catch((error)=>{
        console.log('==deleted error',error);
       
       
    })
    }

    const RenderNewAddressComponent = () =>{
        return (
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div style={{  }}>
                                <div className='payAddInputCon '>
                                    <p className='payDelvyLabel payAddInputCon1'>Name <div style={{ color: 'red' }}>*</div></p>
                                    <input  type='name' {...register("name", { required: true,  })} placeholder='Name' className='PayDeliveryInput PayDeliveryInput1'  />
                                </div>
                                <div className='payAddInputCon '>
                                    <p className='payDelvyLabel payAddInputCon1'>Phone Number<div style={{ color: 'red' }}>*</div></p>
                                    <input type="name" {...register("phoneNumber", { required: true  })}   placeholder='Phone Number' className='PayDeliveryInput PayDeliveryInput1'  />
                                </div>
                                <div className='payAddInputCon'>
                                    <p className='payDelvyLabel payAddInputCon1'>Address<div style={{ color: 'red' }}>*</div></p>
                                    <input type='name' {...register("addres", { required: true,  })}  placeholder='Your address' className='PayDeliveryInput PayDeliveryInput1' />
                                </div>
                                <div className='payAddInputCon'>
                                    <p className='payDelvyLabel payAddInputCon1'>Landmark<div style={{ color: 'red' }}>*</div></p>
                                    <input type='name'   {...register("landmark", { required: true,  })} placeholder='Landmark' className='PayDeliveryInput PayDeliveryInput1' />
                                </div>
                                <div className='payAddInputCon '>
                                    <p className='payDelvyLabel payAddInputCon1'>Pincode<div style={{ color: 'red' }}>*</div></p>
                                    <input type='name' maxLength={6} {...register("pincode", { required: true })}  placeholder='Pincode' className='PayDeliveryInput PayDeliveryInput1' />
                                </div>
                                <div className='payAddInputCon '>
                                    <p className='payDelvyLabel payAddInputCon1'>City<div style={{ color: 'red' }}>*</div></p>
                                    <input type='name'  {...register("city", { required: true,  })} placeholder='City' className='PayDeliveryInput PayDeliveryInput1' />
                                </div>
                                <div className='payAddInputCon '>
                                    <p className='payDelvyLabel payAddInputCon1'>State<div style={{ color: 'red' }}>*</div></p>
                                    <input type='name'  {...register("state", { required: true,  })} placeholder='State' className='PayDeliveryInput PayDeliveryInput1' />
                                </div>
                                {/* <div className='payAddInputCon'>
                                <p className='payDelvyLabel payAddInputCon1'>State<div style={{ color: 'red' }}>*</div></p>
                                  <FormControl className='PayDeliveryInput PayDeliveryInput1'>
                                     <Select
                                       labelId='state-label'
                                       id='state'
                                       {...register('state', { required: true })}
                                       placeholder='State'
                                       defaultValue='' // Set the default value if needed
                                     >
                                       {stateNames.map((states, index) => (
                                         <MenuItem key={index} value={states}>
                                           {states}
                                         </MenuItem>
                                       ))}
                                    </Select>
                                  </FormControl>
                                </div> */}

                                <div className='payAddInputCon '>
                                    <p className='payDelvyLabel payAddInputCon1'>Country<div style={{ color: 'red' }}>*</div></p>
                                    <input type='name'  {...register("country", { required: true,  })} placeholder='Country' className='PayDeliveryInput PayDeliveryInput1' />
                                </div>
                                <div className='payAddInputCon '>
                                    <p className='payDelvyLabel payAddInputCon1'>Address Type<div style={{ color: 'red' }}>*</div></p>
                                    <input type='name'  {...register("addressType", { required: true,  })}  placeholder='e.g "Home, Work, Office..."' className='PayDeliveryInput PayDeliveryInput1' />
                                </div>
                            </div>
  
  
    
                            <button type='submit' style={{ textTransform: 'capitalize' }} className='totalamtbtn' >
                              { addAddressLoding ? <TailSpin
                                    height="30"
                                    width="30"
                                    color="#fff"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{ }}
                                    wrapperClass=""
                                    visible={true}
                                  /> :  'Save Address' }
                            </button>
                    </div>
                </form>
            </div>
        )
      
      }
  
      const RenderSavedAddress = () => {
        // setsaurabh(false)
        return (
          <div style={{marginBottom:'2rem'}}>                
              {address.map((item)=>{
                  return(
                      <div style={{  paddingBottom: 15 }}>
                         
                          <div className='storingAddress'>
                          
                              <span style={{ display: 'flex', alignItems:'start', justifyContent: 'space-between' }}>
                                <div>
                                {
                                    item.is_default == 1 && (<>
                                  <p className='addressHome2'>Default</p>
                                    </>)
                                  }
                                  <p className='addressDetails2'>{item.name}</p>
                                </div>
                                  <p className='addressHome'>{item.type_of_address}</p>
                                  
                                
                              </span>
                              <span style={{display:'flex', justifyContent:'space-between'}}>
                                <span style={{display:'block'}}> 
                              <p className='addressDetails'> <strong>Mob no:</strong>  {item.mobile}</p>
                              <p className='addressDetails'><strong>Address: </strong> {item.address} {item.landmark} {item.city} {item.territory} {item.country} {item.pincode}</p>
                              <p className='addressDetails'><strong>Landmark: </strong>{item.landmark}</p>
                                </span>
                                <span className='addressIcom' style={{display:'flex'}}>
                                  <button style={{backgroundColor:'white', border:'none'}} onClick={()=>deleteAddress(item.id)}><i class="fa-solid fa-trash" style={{fontSize:'20px', color:'gray'}}></i></button>
                                  <button style={{backgroundColor:'white', border:'none', padding:'10px'}} onClick={()=>navigate(`/EditAddress/${item.id}`)}><i class="fa-solid fa-user-pen" style={{fontSize:'20px', color:'gray'}}></i></button>
                                </span>
                              </span>
                              
                          </div>
                          { showSelectAddressErrorMessaage ? <p style={{ color: 'red', fontSize: 15, marginTop: '2px' }} >Please Pick One Address</p> : null }
  
                      </div>
              )})}
          </div>
      )
      }
    return (
    <>
        <div style={{ paddingTop: "30px" }}>
            <div className='saveNewadd'>
            <span style={{display:'flex', alignItems:'center', justifyContent: 'space-between'}} >
            <h3 className='storedHr' >Saved Address</h3>
            { showAddressForm ? <button onClick={() => {setshowAddressForm(false)}} className='addNew'> <i class="fa-regular fa-circle-xmark" style={{fontSize:'26px'}}></i></button> : <button onClick={() => {setshowAddressForm(true);setsaurabh(false)}} className='addNew'>+ NEW ADDRESS</button> }
            </span>
            </div>
            {saurabh &&
            <div className='noTransFound2'>
                No address Found
            </div>
            }
            {
                showAddressForm ? <RenderNewAddressComponent /> : <RenderSavedAddress />
            }
            {/* {
                noAddressFound ? <h3 className='NoAddressSAved'>No Address Saved</h3> : <>
                {address.map((item)=>{
                    return(
                    <>
                         <div className='storedAddress'>
                                            <span className='addressSpan'>
                                            <p className='addressDetails2'>{item.name}</p>
                                            <p className='addressHome'>{item.type_of_address}</p>
                                            </span>
                                            <p className='addressDetails'> <strong>Mob no:</strong>  {item.mobile}</p>
                                            <p className='addressDetails'>{item.address} {item.landmark} {item.city} {item.pincode} {item.territory}</p>
                                        </div>
                    </>
                    )})}
                </>
            } */}
        </div>
    </>
  )
}