import React, { useState, useEffect } from "react";
import "../assets/style.css";
import "../assets/responsive.css";
import Related from "./Related";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  deleteWishList,
  extrabondCategory2,
  getIncrement,
  getReview,
  getWhishlist,
  getcartId,
  getdecrement,
  openPackDetails,
  productCategory,
  productData,
} from "../api/api";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import ReactStars from "react-stars";
import "react-multi-carousel/lib/styles.css";
import { TailSpin } from "react-loader-spinner";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import NavMenu from "./Navbar";
// import ImageZoom from "react-image-zooom";
import thumbnail from "../../src/assets/img/Home/video_thumbnail.jpg";
// import 'react-image-magnifiers/style.css';
// import {BsCircleFill} from 'react-icons/bs'
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import {Helmet} from "react-helmet";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { components } from "react-select";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Modal from 'react-bootstrap/Modal';

const Discription = ({ pId }) => {
  var productId = pId;

  const navigate = useNavigate();

  const storedData = useSelector((state) => state.profile);

  const [active, setActive] = useState([]);
  const [productImg, setproductImg] = useState([]);
  productImg.length = 8;
  // const [img, setimg] = useState(data)
  const [mainImage, setMainImage] = useState("");
  const [mainImage1, setMainImage1] = useState("");
  // const [imgTwo, setImgTwo] = useState(dataTwo)
  const [description, setDescription] = useState(true);
  const [specification, setSpecification] = useState(false);
  const [productdetails, setproductdetails] = useState([]);
  const [productreviews, setproductreviews] = useState([]);
  const [productPacks, setproductPacks] = useState([]);
  const [isProductPacksLoading, setisProductPacksLoading] = useState(false);
  const [isProductPackOpend, setisProductPackOpend] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedImageIndex1, setSelectedImageIndex1] = useState(null);
  const [videoUrl, setvideoUrl] = useState("");
  const [packValue, setPackValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [openResponiveVideo, setopenResponiveVideo] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [cartValues, setCartValues] = useState(false);
  const [videoStatus, setVideoStatus] = useState();
  const [productName, setProductName] = useState(
    "STP Professional Engine Degreaser"
  );
  const [productStatus, setProductStatus] = useState();
  const [xtraStatus, setXtraStatus] = useState(false)
  const location = useLocation();
  const [similar, setSimilar] = useState(false);
  const [productType, setProductType] = useState();
  const [model, setModel] = useState();
  const [brand, setBrand] = useState();
  const [bondParent, setBondParent] = useState([]);
  const [bondChild, setBondChild] = useState([]);
  const [selectedValue, setSelectedValues] = useState('Select Car Brand');
  const [selectedValue2, setSelectedValues2] = useState('');
  const [showModel, setShowModel] = useState(false);
  // console.log("location", location.state.productId);
  const [productAvaialble, setproductAvailable] = useState();
  const parentID = productdetails.parent_id;
  const [parentCatId, setparentCatId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [cartCount, setCartCount] = useState(1)
  useEffect(()=>{
    getProductFunc(productId);
  },[productId])

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('--selected mui==>',selectedValue)
    window.history.scrollRestoration = "manual";
    addUserReview(parentID);
    console.log("--parentId-->", parentID);
    console.log("==>product Id", productId);
    console.log(storedData.userId);
    extraBond()
    extraBond2();
    setTimeout(() => {
      setSimilar(true);
    }, 1000);
    // window.scrollTo(0, 0);
  }, [productId, parentID]);
  
  // useEffect(() => {
  //   // Update the meta description
  //   const metaDescriptionTag = document.querySelector('meta[name="description"]');
  //   if (metaDescriptionTag) {
  //     metaDescriptionTag.setAttribute('content', productName);
  //   }
  // }, [productName]);


  window.addEventListener('popstate', function (event) {
    setSelectedValues2('');
    setSelectedValues('');
    setShowModel(false)
    // if(productdetails.brand_name == "Xtra-Bond"){
    //   navigate('/Shop')
    // }
  });

  const getProductFunc = (id) => {
    setActiveIndex(0);
    setSelectedImageIndex(0);
    setisProductPacksLoading(true);

    const url = productCategory();
    const body = {
      product_id: id,
      user_id: storedData.userId,
    };
    console.log("boduy ==> ", body);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("---pack---", res.data.data[0]);
        setproductdetails(res.data.data[0]);
        setVideoStatus(res.data.data[0].video_status);
        setProductName(productdetails.title);
        console.log("product details", res.data.data[0].status);
        setMainImage(res.data.data[0].image_url);
        setproductImg(res.data.extra_images);
        setproductPacks(res.data.product_packs);
        setvideoUrl(res.data.video[0]);
        setPackValue(res.data.data[0].pack);
        setCategoryId(res.data.data[0].category_id);
        setProductStatus(res.data.data[0].status);
        setProductType(res.data.data[0].product_type);
        setparentCatId(res.data.data[0].cat_parent_id)
        console.log("category--", categoryId);
        localStorage.setItem('productNames', JSON.stringify(productdetails.title));
        console.log("--productType=", productType);
        // console.log(" gkweflwegf  ", productImg);
        // console.log("getProductFunc", productdetails);
        window.fbq('track','Viewed Product',{currency:'INR', value:res.data.data[0].starting_price, content_name:res.data.data[0].title})

        setTimeout(() => {
          setisProductPacksLoading(false);
        }, 1000);
        
        if (res.data.data[0].status) {
          setproductAvailable(res.data.data[0].status);
          console.log("--status of available-->", res.data.data[0].status);
        } else {
          setproductAvailable(res.data.data[0].status);
          console.log("--status of available-->", res.data.data[0].status);
        }
        // setImgTwo(res.data.data[0])
      })
      .catch((err) => {
        console.log(err);
        setisProductPacksLoading(false);
      });
  };

  // useEffect(() => {
  //   console.log(productName);
 
  //   setTimeout(() => {
  //     const storedProductInfo = localStorage.getItem('productNames');
  //   if (location.pathname.startsWith("/product/")) {
  //     const parsedProductInfo = JSON.parse(storedProductInfo);
  //     document.querySelector(
  //       'meta[name="description"]'
  //     ).content = `${parsedProductInfo} `;
  //   } else {
  //     document.querySelector('meta[name="description"]').content =
  //       "Stp is designed to penetrate and dissolve heavy grease and grime to give your engine a professional cleanup.";
  //   }
  //   }, 1000);

  // }, [productName, location.pathname]);

  const addWishlist = (id) => {
    if (storedData.userLoginSuccess) {
      const url = getWhishlist();
      const userData = {
        user_id: storedData.userId,
        product_id: id,
        has_login: "yes",
      };

      console.log(" ===add wish =>  ", url, userData);

      axios
        .post(url, userData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.data.status) {
            toast.success("Wishlist Added", { icon: "❤️" });
            if (isProductPackOpend) {
            } else {
              getProductFunc(productId);
              window.fbq('track','Add to wishlist',{currency:'INR', value:productdetails.starting_price, content_name:productdetails.title})
            }
          } else {
            toast.error("Something Went Wrong", { icon: "⚠️" });
          }

          console.log(" the value of list", resp.data);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something Went Wrong", { icon: "⚠️" });
        });
    } else {
      // alert("Please Login");
      // navigate("/signin");
      setShowModal(true)
    }
  };

  const removeWishlist = (id) => {
    const url = deleteWishList(id);

    let data = new FormData();
    data.append("_method", "DELETE");

    // console.log(" ==> wishlist data => ", url, data);

    axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        if (resp.data.status) {
          toast.success("Wishlist Removed", { icon: "✅" });
          if (isProductPackOpend) {
          } else {
            getProductFunc(productId);
          }
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }

        console.log(" the value of list", resp.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong", { icon: "⚠️" });
      });
  };

  const addtoCart = (id) => {
    const url = getcartId();

    console.log(" -- - - - url - - - ", url);
    const postData = {
      user_id: storedData.userId,
      product_id: id,
      has_login: "yes", 
    };

    axios
      .post(url, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("---- cart value == ", resp.data);
        if (resp.data.status) {
          toast.success("Product Added In Cart", { icon: "🛒" });
          // if (isProductPackOpend) {
          // } else {
            console.log('cart is added product');
            getProductFunc(id);
            setCartValues(true);
          // }
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }
      })
      .catch((error) => {
        console.log(" === error ===", error);
      });
  };

  const addtoCart2 = (id) => {
    const url = getcartId();

    console.log(" -- - - - url - - - ", url);
    const postData = {
      user_id: storedData.userId,
      product_id: id,
      has_login: "yes",
    };

    axios
      .post(url, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("---- cart value == ", resp.data);
        if (resp.data.status) {
          // toast.success("Product Added In Cart", { icon: "🛒" });
          if (isProductPackOpend) {
          } else {
            // getProductFunc(productId);
            setCartValues(true);
            navigate("/cart");
          }
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }
      })
      .catch((error) => {
        console.log(" === error ===", error);
      });
  };

  const handleAddToCart = (id) => {
    if (storedData.userLoginSuccess) {
      addtoCart(id);
      // setCartValues(true);
      window.fbq('track','Add to cart',{currency:'INR', value:productdetails.starting_price, content_name:productdetails.title})
      // if (productStatus == 1) {
      // } else {
      //   toast("Product is unavailable");
      // }
    } else {
      // alert("Please Login");
      // navigate("/signin");
      setShowModal(true)
    }
  };

  const handleAddToCart2 = (id) => {
    if (storedData.userLoginSuccess) {
      if (productdetails.cart_id == null) {
        addtoCart2(id);
        setCartValues(true);
        setTimeout(() => {
          navigate("/cart");
        }, 500);
      } else {
        navigate("/cart");
      }
    } else {
      // alert("Please Login");
      // navigate("/signin");
      setShowModal(true)
    }
  };

  const addUserReview = (id) => {
    const url = getReview();
    console.log(" ---- url ----- ", url);
    const body = {
      product_id: id,
    };

    console.log("===body parent ", body);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("---review", resp.data);
        setproductreviews(resp.data.data);
      })
      .catch((error) => {
        console.log("===error", error);
      });
  };

  const buttondata = [
    { id: 1, pack: "1(₹540)" }, 
    { id: 2, pack: "2(₹1000)" },
    { id: 3, pack: "6(₹3000)" },
    { id: 4, pack: "12(₹4000)" },
  ];

  // useEffect(()=>{
  //   console.log('face');
  //   window.fbq('track','Viewed Product',{Product_name:productdetails.title, currency:'INR', value:productdetails.starting_price})
  // },[productdetails])


  // console.log(mainImage)

  const [num, setNum] = useState(0);
  console.log(num);

  // const update = (e) => {
  //     setMainImage(e)
  // }
  // console.log(img)
  const [activeIndex, setActiveIndex] = useState(false);

  const [activeIndex1, setActiveIndex1] = useState(false);
  const updateActiveIndex = (index, item) => {
    console.log("==>video", String(item).split(".").pop());
    if (String(item).split(".").pop() == "mp4") {
      setActiveIndex(true);
      setvideoUrl(item);
    } else {
      setActiveIndex(false);
    }

    // setActiveIndex(index);
    setMainImage(item);
    setSelectedImageIndex(index);
  };
  const updateActiveIndex1 = (index, item) => {
    console.log("item -> ", item);
    console.log("==>video", String(item).split(".").pop());
    if (String(item).split(".").pop() == "mp4") {
      setActiveIndex1(true);
      setvideoUrl(item);
    } else {
      setActiveIndex1(false);
    }

    setMainImage1(item);
    setSelectedImageIndex1(index);
  };

  
  const [componentSlice, setComponentSlice] = useState(false)

  const Highlight = ReactHtmlParser(productdetails.highlights);  
  const Component = ReactHtmlParser(productdetails.description).slice(0,12);
  const Component2 = ReactHtmlParser(productdetails.description);
  const compText = Component2.map(element => {
    if (typeof element === 'string') {
      return element;
    } else if (element.props && element.props.children) {
      return element.props.children;
    } else {
      return '';
    }
  }).join('');
  const SpecificationComponent = ReactHtmlParser(productdetails.specification);
  var discoutPrice = productdetails.mrp - productdetails.starting_price;
  var dpPrice = discoutPrice / productdetails.mrp;
  var finalPrice = dpPrice * 100;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const allImages = [...productImg, { image_url: mainImage }];

  const showPackProduct = async (item) => {
    const isActive = active.includes(item.pack);
    setActive(
      isActive ? active.filter((current) => current !== item.pack) : [item.pack]
    );

    const url = openPackDetails();
    var body = {
      product_id: productdetails.parent_id, //parent_product_id
      pack: item.pack,
      user_id: storedData.userId,
    };
    console.log("packs body ==> ", body);

    await axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log(" product details", productdetails);
        // setisProductPacksLoading(true)
        setTimeout(async () => {
          await setproductdetails(resp.data.data[0]);
          // console.log(" -- -- -- -- -- ",resp.extra_images.image_urlnpm )
          setMainImage(resp.data.data[0].image_url);
          setproductImg(resp.data.extra_images);
          setproductPacks(resp.data.product_packs);
          // setvideoUrl(resp.data.video[0]);
          // setisProductPackOpend(true);
          setisProductPacksLoading(false);
          setPackValue(resp.data.data[0].pack);
          navigate(`/product/${item.id}`);

          setSelectedImageIndex(0);
          setisProductPacksLoading(true);
        }, 500);
        console.log("resp form packs  ==>", resp.data.data[0]);
      })
      .catch((err) => {
        console.log("err in produts pack ", err);
        // setisProductPacksLoading(false);
        // setisProductPackOpend(false);
      });
  };

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  const openResponsiveVideo = () => {
    console.log("opeening video");
    setopenResponiveVideo(true);
  };

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType },
    } = rest;
    const carouselItems = allImages;
    return (
      <button
        className={active ? "active" : "inactive"}
        onClick={() => onClick()}
      >
        {React.Children.toArray(carouselItems)[index]}
      </button>
    );
  };

  const alreadyInCart = () => {
    toast.success("Already added in cart", { icon: "🛒" });
  };
  const [isVideoPlaying, setVideoPlaying] = useState(true);

  const handleVideoPause = (index) => {
    setVideoPlaying(false);
    // updateActiveIndex1(index + 1);
  };
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setVideoPlaying(false);
      setActiveIndex1(false)
      updateActiveIndex(next);
    },
    
    responsive: [
      {
        breakpoint: 560, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleModalClose = () => {
    console.log("Modal is closed, running effect in CartPage...");
    getProductFunc(productId);
  };

  const [copied, setCopied] = React.useState(false);

  const handleChangeModel = (e) => {
    setModel(e.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedValues(event.target.value);
    setShowModel(true);
  };

  const extraBond = () => {
    const url = extrabondCategory2();
    const body = {
      category_id: categoryId,
    };
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("--extrabond--", resp.data.data.Parent[0].id);
        setBondParent(resp.data.data.Parent);

      })
      .catch((error) => {
        console.log("extrabond error", error);
      });
  };

  useEffect(() => {
    console.log(selectedValue);
    if (selectedValue === "Select Car Brand") {
      setShowModel(false);
    }
    extraBond2();
  }, [selectedValue]);

  const extraBond2 = () => {
    const url = extrabondCategory2();
    const body = {
      category_id: categoryId,
    };
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log(resp.data.data.Childs[parentCatId]);
        setBondChild(resp.data.data.Childs[selectedValue]);
      })
      .catch((error) => {
        console.log("extrabond error", error);
      });
  };

  const handleSelectChange2 = (event) => {
    setSelectedValues2(event.target.value);
  };

  useEffect(() => {
    console.log(selectedValue2);
    extraBond3();
  }, [selectedValue2]);
  

  const extraBond3 = () => {
    const url = extrabondCategory2();
    const body = {
      category_id: selectedValue2,
    };
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log(resp.data.product_id[0].id);
        productId = resp.data.product_id[0].id;
        // setTimeout(() => {
        //   getProductFunc(productId);
        // });
        setXtraStatus(true)
        navigate(`/product/${resp.data.product_id[0].id}`)
      })
      .catch((error) => {
        setXtraStatus(false)
        console.log("extrabond error", error);
      });
  };


  const incrementCart = (cartId, productId) => {
    const url = getIncrement();

    // console.log('- - - url - - -',url);
    const userData = {
      cart_id: cartId,
      product_id: productId,
    };
    console.log('userData ---', url,  userData);

    axios
      .post(url, userData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        getProductFunc(productId);
        console.log('product increment - -  -', resp.data.resultant_quantity);
        setCartCount(resp.data.resultant_quantity)
        setCartValues(true);
      })
      .catch((error) => {
        console.log("- - err", error);
      });
  };

  const decrementCart = (cartId, productId) => {
    const url = getdecrement();

    // console.log('- - - url - - -',url);
    const userData = {
      cart_id: cartId,
      product_id: productId,
    };
    // console.log('userData ---', url,  userData);

    axios
      .post(url, userData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        getProductFunc(productId);
        setCartCount(resp.data.resultant_quantity);
        setCartValues(true);
      })
      .catch((error) => {
        console.log("- - err", error);
      });
  };

  const options = bondParent || [];
  const [isOpen, setIsOpen] = useState(false);

  function CustomDropdown({ options, selectedValue, setSelectedValues }) {
    return (
      <FormControl className="extraBondDropdown1">
          
        <InputLabel id="schoolBoard-label" >{selectedValue}</InputLabel>
      
        <Select
          labelId="schoolBoard-label"
          value={selectedValue}
          onChange={(event) => {
            console.log(selectedValue);
            setSelectedValues(event.target.value);
            setSelectedValues2('')
            if (selectedValue === "Select Car Brand") {
              setShowModel(false);
            }
            setShowModel(true);
          }}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
        >
          <MenuItem value="Select Car Brand">Select Car Brand</MenuItem>
          {
          options.map((item) => {console.log(options);
           return(
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          )})
          }
        </Select>
      </FormControl>
    );
  }

  // const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const storedIsOpen = localStorage.getItem('isOpen');
    if (storedIsOpen !== null) {
      setIsOpen(JSON.parse(storedIsOpen));
    }
  }, []);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
    localStorage.setItem('isOpen', JSON.stringify(isOpen));
  };
 



  function CustomDropdown2({ options2, selectedValue2, setSelectedValues2 }) {
    return (
      <FormControl className="extraBondDropdown1">
           
        <InputLabel id="schoolBoard-label">Select Car Model</InputLabel>
      
      
        <Select
          labelId="schoolBoard-label"
          value={selectedValue2}
          onChange={(event) => {setSelectedValues2(event.target.value);}}
        >
          <MenuItem value="">Select Car Model</MenuItem>
          {options2?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  const options2 = bondChild || [];
  let linkText = 'Shop';

  // Check if the previous page was '/valuePack'
  const isFromValuePack = location.state && location.state.from === '/valuePack';

  if (isFromValuePack) {
    linkText = 'valuePack';
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };
 
  const toLoginPage =()=>{
    navigate('/signin')
  }

  return (
    <>
      <Helmet>
      <title>{productdetails.title}</title>            
      <meta property="og:title" content={productdetails.title}/>
      <meta property="og:description" content={compText}/>
      <meta property="og:url" content={`https://creativecarcare.in/product/${productId}`}/>
      <meta property="og:image" content={mainImage}/>
      <meta property="product:brand" content={productdetails.brand_name}/>
      <meta property="product:availability" content='in stock'/>
      <meta property="product:condition" content="new"></meta>
      <meta property="product:price:amount" content={`${productdetails.starting_price}`}/>
      <meta property="product:price:currency" content="INR"/>
      <meta property="product:retailer_item_id" content={`${productdetails.id}`}/>
      <meta property="product:product_type" content='car products'/>
      <meta property="twitter:title" content={productdetails.title}/>
      <meta property="twitter:description" content={compText}/>
      <meta property="twitter:image" content={mainImage}/>
      <meta property="twitter:image:alt" content={productdetails.title}/>
      <meta property="twitter:card" content="Creative Car Care"/>
      
    </Helmet >
      <div itemscope itemtype ="https://schema.org/Product">
        <NavMenu
          onCloseModal={handleModalClose}
          cartBoolean={cartValues}
          setCartBoolean={setCartValues}
        />
        {productAvaialble !== 0 ? (
          <>
            <Toaster />
            {/* <ScrollToTop smooth /> */}
          
            {isProductPacksLoading ? (
              <div
                style={{
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <TailSpin
                  style={{
                    width: "100%",
                    border: "2px solid black",
                    margin: "auto",
                    display: "block",
                  }}
                  height="70"
                  width="70"
                  color="#ffd11b"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />{" "}
              </div>
            ) : (
              <>
                <div className="Container mainFirstDiv">
                  <div className="breadCrumb">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <a
                            href="/"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            Home
                          </a>
                        </li>
                        <li class="breadcrumb-item">
                          {productType === 3 ? (<> <a
                            href="/valuePacks"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            ValuePacks
                          </a></>):(<> {
                            productType === 6 ? (<>  <a
                              href="/hotdeals"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              Hot deals
                            </a></>):(<>  <a
                              href="/shop"
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              Shop
                            </a></>)
                          }
                        </>)}
                         
                        </li>
                        <li
                          class="breadcrumb-item active"
                          aria-current="page"
                          style={{ color: "black" }} 
                          itemprop="name"
                        >
                          {productdetails.title}
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="row ">
                    <div className="col-lg-6 col-md-6">
                      <div className="container">
                        <div className="row productImg">
                          <div className=" col-lg-4 col-md-4  sideImgs">
                            {productImg.map((item, index) => {
                              var ext = String(item).split(".").pop();
                              return (
                                <img
                                  src={ext == "mp4" ? thumbnail : item}
                                  className={`descrptmImg ${
                                    selectedImageIndex === index
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => updateActiveIndex(index, item)}
                                  alt={productdetails.title}
                                />
                              );
                            })}
                          </div>
                          <div className="col-lg col-md-6 theMainImgDiv">
                            {activeIndex ? (
                              <div
                                style={{
                                  position: "relative",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <ReactPlayer
                                  url={videoUrl}
                                  playIcon={true}
                                  playing={true}
                                  height={"100%"}
                                  controls={true}
                                  width={"100%"}
                                  stopOnUnmount={true}
                                  className="videoPlayer"
                                />
                              </div>
                            ) : (
                              <img
                                style={{ mixBlendMode: "multiply" }}
                                src={mainImage}
                                className="mainProduct"
                                alt={productdetails.title} 
                              />

                              
                            //   <TransformWrapper>
                            //   <TransformComponent>
                            //   <img
                            //     style={{ mixBlendMode: "multiply" }}
                            //     src={mainImage}
                            //     className="mainProduct"
                            //     alt="product"
                            //   />
                            //   </TransformComponent>
                            // </TransformWrapper>


                            // <TransformWrapper
                            //    initialScale={1}
                            //    initialPositionX={200}
                            //    initialPositionY={100}
                            //  >
                            //    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            //      <React.Fragment>
                            //        <div className="mainImageTools">
                            //        <div className="tools toolsBtn">
                            //          <button onClick={() => zoomIn()}>+</button>
                            //          <button onClick={() => zoomOut()}>-</button>
                            //        </div>
                            //        <TransformComponent>
                            //          <img src={mainImage} alt="test" style={{ mixBlendMode: "multiply" }} className="mainProduct"/>
                            //        </TransformComponent>
                                   
                            //        </div>
                                  
                            //    </React.Fragment>
                            //             )}
                            // </TransformWrapper>

                              // <Zoom>
                              //   <img
                              //     alt='product'
                              //     src={mainImage}
                              //     className="mainProduct"
                              //   />
                              // </Zoom>
                            )}
                          </div>
                        </div>

                        <div className="row productImg mobileProd">
                          <div className="col-lg-4 col-md-12 mt-md-5 sideImgs">
                            {/* <Carousel
                          responsive={responsive}
                          swipeable={true}
                          arrows={false}
                          autoPlay={false}
                          draggable={true}
                          showDots={true}
                          // customDot={<CustomDot />}
                          infinite={true}
                          partialVisible={false}
                          dotListClass="custom-dot-list-style"
                          containerClass="con"
                          className="clas"
                        > */}
                            <Slider
                              className="featureCaurnew"
                              {...sliderSettings}
                            >
                              {productImg.map((item, index) => (
                                <div
                                  style={{ marginBottom: "20px" }}
                                  key={index}
                                >
                                  {activeIndex1 ? (
                                    <div onClick={() => handleVideoPause()}>
                                      <ReactPlayer
                                        url={videoUrl}
                                        playIcon={true}
                                        playing={isVideoPlaying}
                                        height="100%"
                                        controls={true}
                                        // p={true}
                                        width="100%"
                                        stopOnUnmount={true}
                                        className="videoPlayer"
                                        onPause={handleVideoPause}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      src={
                                        String(item).split(".").pop() == "mp4"
                                          ? thumbnail
                                          : item
                                      }
                                      className="imageMobileProd"
                                      onClick={() =>
                                        updateActiveIndex1(index, item)
                                      }
                                      alt={productdetails.title}
                                    />
                                  )}
                                </div>
                              ))}
                              {/* </Carousel> */}
                            </Slider>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* { 
                    <div>
                    openResponiveVideo ? <div style={{ backgroundColor: '#000' }}>
                      <ReactPlayer url={videoUrl} playIcon={true} playing={true} height={"50%"} controls={true} width={"100%"} stopOnUnmount={true} className='videoPlayer' />
                    </div> : null
                    </div>
                  } */}
                    <div className="col-lg-6 col-md-6 mt-md-3 productDescription">
                      <span className="carding3">
                        {productdetails.wishlist_id == null ? (
                          <i
                            class="fa-solid fa-heart newHeart"
                            onClick={() => {addWishlist(productdetails.id)}}
                            style={{
                              color: "lightslategray",
                              border: "1px solid lightslategray",
                              borderRadius: 50,
                              padding: 10,
                              fontSize: 25,
                            }}
                          ></i>
                        ) : (
                          <i
                            class="fa-solid fa-heart newHeart2"
                            onClick={() =>
                              removeWishlist(productdetails.wishlist_id)
                            }
                            style={{
                              color: "red",
                              border: "1px solid lightslategray",
                              borderRadius: 50,
                              padding: 10,
                              fontSize: 25,
                            }}
                          ></i>
                        )}
                      </span>
                      {productType != 4 ? (
                        <>
                          <h4 itemprop="Brand" className="brandNameDescription" style={{ fontFamily: "Oswald" }}>
                            Brand: {productdetails.brand_name}{" "}
                          </h4>
                          <p className="star">
                            <ReactStars
                              count={5}
                              value={productdetails.rating}
                              edit={false}
                              size={20}
                            />
                          </p>
                        </>
                      ) : null}

 {
  productdetails.brand_name =='XTRABOND' ? (<><h1
    className="productTitle"
    style={{ overflow: "hidden" }}
  >
    {productdetails.title.substring(0, 23)}
  </h1></>):(<><h1
    className="productTitle"
    style={{ overflow: "hidden" }}
  >
    {productdetails.title}
  </h1></>)
 }
                      {/* <h1
                        className="productTitle"
                        style={{ overflow: "hidden" }}
                      >
                        {productdetails.title}
                      </h1> */}

                      {/* <ul className="listFirst" style={{ listStyle: "inside" }}>
                    <li style={{ fontFamily:'Rubik'}}>Easy to use</li>
                    <li style={{ fontFamily:'Rubik'}}>No buffing required</li>
                    <li style={{ fontFamily:'Rubik'}}>Water beading protection</li>
                  </ul> */}
                      <div className="highlight1">{Highlight}</div>

                      {productType == 1 && productdetails.brand_name !=='BLACK+DECKER' && productdetails.brand_name !=='STANLEY' && (
                        <>
                          <div className="row packDiv">
                            {productPacks.map((item) => {
                              console.log(" itm of packs ==> ,", item);

                              // setImgTwo(res.data.data[0])

                              // const isActive = active.includes(item.pack) ;
                              const isActive =
                                item.pack == packValue ? true : false;
                              return (
                                <button
                                  className="col-5 col-lg-3 col-md-3 packbtn"
                                  key={item}
                                  onClick={() => showPackProduct(item)}
                                  style={{
                                    background: isActive ? "red" : "white",
                                    height: "auto",
                                    border: isActive
                                      ? "2px solid red"
                                      : "2px solid #DCDCDC",
                                    color: isActive ? "#fff" : "#979797",
                                  }}
                                >
                                  Pack of {item.pack} <br />
                                  ₹ {item.starting_price}
                                </button>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {productType == 2 && (
                        <>
                          <div className="extraBondDropdown">
                               
                                <CustomDropdown options={options}
                                  selectedValue={selectedValue}
                                  setSelectedValues={setSelectedValues}/>
                                  {
                                    selectedValue != 'Select Car Brand' && 
                                <CustomDropdown2
                                  options2={options2}
                                  selectedValue2={selectedValue2}
                                  setSelectedValues2={setSelectedValues2}
                                />
                                  }
                          </div>
                        </>
                      )}
                      {
                        productdetails.brand_name =='XTRABOND' && !xtraStatus ? null:
                        (<>
                        <div className="row mrpstockings">
                        <div className="col-sm-4 col-md-6 col-lg-5 mrpBox1">
                          <p style={{margin:'0'}}>MRP</p>
                          <p className="mrpStock2">
                            {" "}
                            <span className="mrpSpan">
                              <strike>₹ {productdetails.mrp}</strike>
                            </span>{" "}
                            <br />{" "}
                            <span style={{}}>
                              SAVE{" "}
                              <span style={{ color: "red" }}>
                                {" "}
                                {Math.round(finalPrice).toFixed(2)}%
                                {/* {parseFloat(finalPrice).toFixed(2)}% */}
                              </span>
                            </span>
                            <p style={{ marginTop: "18px" }}>
                              OUR PRICE{" "}
                              <span
                                style={{
                                  fontWeight: "700",
                                  fontSize: "26px",
                                  // marginLeft: "5px",
                                  display: "block",
                                  marginTop: "-5px",
                                }} 
                                
                              >
                                {" "}
                                <span style={{ fontSize: "20px" }}>
                                  ₹{" "}
                                </span>{" "}
                                {productdetails.starting_price}
                              </span>
                            </p>
                          </p>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-6 mrpBox2">
                          {
                            productdetails.brand_name === "XTRABOND" && (<>
                          <p style={{margin:'0 0 4px 0'}}>Size : <span style={{fontSize:'18px', color:'', fontWeight:600}}>{productdetails.size} </span></p>
                          <p style={{margin:'0 0 4px 0'}}>Length : <span style={{fontSize:'18px', color:'red', fontWeight:600}}>{productdetails.length} INCH</span></p>
                            </>)
                          }
                          <p className="mrpStock2">
                            Stock
                            {productdetails?.stock <= 0 ? (
                              <span style={{ color: "#E73C17" }}>
                                {" "}
                                OUT OF STOCK
                              </span>
                            ) : (
                              <span
                                style={{ color: "#24D600", marginLeft: "4px" }}
                              >
                                Available
                              </span>
                            )}
                          </p>
                          {productType !== 3  && (
                            
                            <>
                              {
                                productdetails.madein == null ? null : <p className="mrpStock2">Made in {productdetails?.madein}</p> 
                              }
                            </>
                          )}
                          {
                            productdetails.cart_id == null ? null:(<>    
                       <p style={{margin:'0'}}>Select Quantity</p>
                             <span className="cartspan2Desc">
                       <button
                         onClick={() =>
                           decrementCart(productdetails.cart_id, productdetails.id)
                         }
                         className="incrbtnDesc"
                         style={{ display: "flex" }}
                       >
                         -
                       </button>
                       <span className="count">{cartCount}</span>
                       <button
                         onClick={() => {
                           incrementCart(productdetails.cart_id, productdetails.id);
                         }}
                         className="decrbtnDesc"
                       >
                         +
                       </button> 
                     </span></>)
                          }
                         
                          {/* </span> */}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        {/* <h1 style={{ fontFamily: "Oswald" }}></h1> */}
                        <h3
                          style={{
                            textDecoration: "line-through",
                            color: "#D9D9D9",
                            marginLeft: "20px",
                            fontFamily: "Oswald",
                          }}
                        ></h3>
                      </div>
                      <div className="d-flex buyBtnDiv">
                        {/* <div className='incremDicri' style={{ border: '2px solid #F0F0F0' }}>
                                      <span style={{ borderRight: '2px solid #F0F0F0', height: '100%' }} onClick={() => setNum(num - 1)}>-</span>{num}
                                      <span style={{ borderLeft: '2px solid #F0F0F0', height: '100%' }} onClick={() => setNum(num + 1)}>+</span></div> */}
                        <div className="byAndadd">
                          <button
                            className="buynow"
                            onClick={() => handleAddToCart2(productdetails.id)}
                          >
                            Buy Now
                          </button>
                          {productdetails.cart_id == null ? (
                            <button
                              onClick={() => {handleAddToCart(productdetails.id);
                                setCartCount(1)
                                }}
                              className="AddCart"
                            >
                              {" "}
                              Add to cart
                            </button>
                          ) : (
                            <button
                              onClick={() => alreadyInCart()}
                              className="AddCart"
                            >
                              Already in cart
                            </button>
                          )}
                          {/* <button onClick={() => } className='AddCart'>Add to Cart</button> */}
                        </div>
                      </div></>)
                      }
                      
                    </div>
                  </div>
                </div>
                <div className="containerr">
                  <div className="reviwe">
                    <span
                      onClick={() => {
                        setDescription(true);
                        setSpecification(false);
                      }}
                      style={{
                        borderBottom: description ? "3px solid black" : "none",
                        fontSize: 18,
                        fontWeight: "600",
                        fontFamily: "Oswald",
                      }}
                    >
                      Description
                    </span>
                    {/* <span
                  onClick={() => {
                    setSpecification(true);
                    setDescription(false);
                  }}
                  style={{ borderBottom: specification ? "3px solid black" : "none", fontSize: 18,fontWeight: "600", fontFamily:'Oswald' }}
                >
                  Specification
                </span> */}
                    <span
                      onClick={() => {
                        setSpecification(false);
                        setDescription(false);
                      }}
                      style={{
                        borderBottom:
                          !description && !specification
                            ? "3px solid black"
                            : "none",
                        fontSize: 18,
                        fontWeight: "600",
                        fontFamily: "Oswald",
                      }}
                    >
                      Reviews
                    </span>
                  </div>
                  {/* <hr style={{margin:'0',marginBottom:'3rem', marginTop:'1rem'}}/> */}
                  {/* comm by sa */}
                  {description ? (
                   <>
                   
                    <div
                      className="description"
                      style={{
                        margin: "35px auto",
                        fontSize: "18",
                        fontFamily: "Rubik",
                        listStyle: "inside",
                      }}
                    >
                      {
                    componentSlice ? (<>{Component2}</>):(<>{Component}</>)
                   }
                   {
                    Component.length > 11 &&  (<>
                    {
                      componentSlice ? (<> <p className="componentSliceBtn"  onClick={()=>setComponentSlice(false)}>View Less</p></>):(<><p className="componentSliceBtn" onClick={()=>setComponentSlice(true)}>View More</p></>)
                    }
                    </>)
                   }
                      
                    </div>
                   </>

                  ) : specification ? (
                    productdetails.specification == null ? (
                      <p style={{ textAlign: "center", marginTop: "40px" }}>
                        No Specification available.
                      </p>
                    ) : (
                      <div className="description" style={{}}>
                        {SpecificationComponent}
                      </div>
                    )
                  ) : (
                    <div className="description2">
                      {productreviews.length > 0 ? (
                        <>
                          {productreviews.map((item) => (
                            <>
                              <div className="reviews">
                                <img
                                  src={item.profile_path}
                                  className="reviewImage"
                                  alt="review"
                                />
                                <div className="reviewContent">
                                  <p style={{ textAlign: "initial" }}>
                                    {" "}
                                    <strong>{item.user_name}</strong>{" "}
                                  </p>
                                  <p className="reviewsDes">
                                    {item.comments && (
                                      <p
                                        className={`reviewsDes ${
                                          showFullText
                                            ? "show-full"
                                            : "truncate"
                                        }`}
                                      >
                                        {item.comments}
                                      </p>
                                    )}
                                    {item.comments &&
                                      item.comments.length > 50 &&
                                      !showFullText && (
                                        <span
                                          onClick={toggleReadMore}
                                          className="readMoreLink"
                                        >
                                          Read More...
                                        </span>
                                      )}
                                    {showFullText && (
                                      <span
                                        onClick={toggleReadMore}
                                        className="readMoreLink"
                                      >
                                        Read Less...
                                      </span>
                                    )}
                                    {/* {showFullText ? item.comments : item.comments.split(' ').slice(0, 50).join(' ')}
                              {item.comments.split(' ').length > 50 && !showFullText && (
                                <span onClick={() => setShowFullText(true)} className="readMoreLink" style={{ color: 'red', marginLeft: '5px' }}>
                                  Read More..
                                </span>
                              )} */}
                                  </p>
                                  <span className="reviewTime">
                                    <p
                                      className="reviewDate2"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {item.created_at}
                                    </p>
                                  </span>
                                </div>
                              </div>
                              <hr
                                style={{ width: "60%", margin: "6px auto" }}
                              />
                            </>
                          ))}
                          <br />
                          <br />
                        </>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            margin: "35px 0px",
                            fontFamily: "Rubik",
                          }}
                        >
                          No reviews available.
                        </p>
                      )}
                    </div>
                  )}
                  {/* comm by sa */}
                </div>
              </>
            )}

            {productdetails.brand_name != 'XTRABOND' && productType != 6 && (<> {similar ? (
              <div style={{ background: "#faf9f6" }}>
                <Related
                  pId={productId}
                  cId={categoryId}
                  setCartBoolean={setCartValues}
                  cartBoolean={cartValues}
                  brandName ={productdetails.brand_name}
                  brandId={productdetails.brand_id}
                />
              </div>
            ) : (
              <>
                <div
                  style={{
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <TailSpin
                    style={{
                      width: "100%",
                      border: "2px solid black",
                      margin: "auto",
                      display: "block",
                    }}
                    height="70"
                    width="70"
                    color="#ffd11b"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />{" "}
                </div>
              </>
            )}</>) }
            
          </>
        ) : (
          <>
            <div className="productNotAvailable">
              <h2>Product Not Available</h2>
            </div>
          </>
        )}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered size="lg" className='modalClass modalClass2'>
       
       <Modal.Body style={{height:'100px', background:'rgba(241, 241, 241, 1)'}}>
        <p>Please Login!</p>
        <div style={{display:'flex', justifyContent:'end'}}>
        <button onClick={toLoginPage} style={{background:'red', borderRadius:'10px', color:'white', border:'none', padding:'5px 10px'}}>Login</button>
        </div>
       </Modal.Body>
     </Modal>
    </>
  );
};

export default Discription;
