import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../../redux/ActionCreator'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../api/api'
import axios from 'axios'
import { Toaster, toast } from 'react-hot-toast'

export default function Logout() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const storedData = useSelector((state) => state.profile);

  const [isLogoutLoding, setisLogoutLoding] = useState(false)


  const logOutUSerFromWeb = (id) => {
    setisLogoutLoding(true)
    const url = logoutUser(id)

    axios.get(url).then((resp) => {
      if (resp.data.status) {
        toast.success('User LoggedOut Successfully')
        logOutUSerFromReducer()
        setisLogoutLoding(false)
      } else {
        toast.success('Something Went Wrong! Try Again in Sometime')
        console.log("status in rep ==> ", resp.data);
        setisLogoutLoding(false)
      }
    }).catch((err) => {
      console.log("err in logot -=> ", err);
      setisLogoutLoding(false)
    })
  }


  const logOutUSerFromReducer = () => {
    dispatch(logOut)
    navigate('/')

    console.log("user Logged out from web");
  }

  return (
   <>
    <Toaster />
    <button onClick={() => logOutUSerFromWeb(storedData.userId)} className='Logout'>
      { isLogoutLoding ? <div class="spinner-border text-warning" role="status">
        <span class="visually-hidden">Loading...</span>
      </div> : <h1 className='myAccount'>Logout</h1> }
    </button>
   </>
  )
}
