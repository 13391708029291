import React from 'react'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const SrcollToTop = () => {
    const { pathname } = useLocation();

    // useEffect(()=>{
    //     document.documentElement.scrollTo({
    //         top: 150,
    //         left: 0,
    //         behavior: "instant", // Optional if you want to skip the scrolling animation
    //       }, [pathname]);
    // })

  return null
}

export default SrcollToTop