import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import NavMenu from '../Components/Navbar';
import Footer from '../Components/Footer';
import { TailSpin } from "react-loader-spinner";
import { Modal, ModalHeader } from 'react-bootstrap';
export default function Verification() {
    const { id } = useParams();

    const [hashCode, sethashCode] = useState('')
    const [paymentSucessfull, setpaymentSucessfull] = useState(false)
    const [jsonObjectState, setjsonObjectState] = useState([])
    const [status,setStatus] = useState()
    const [isLoding, setisLoding] = useState(false);
   
    const navigate = useNavigate()
    const paymentData = id.split("%7C")
    useEffect(()=>{
      console.log('==> ==> verification==> ', paymentData);
      console.log('==> ==> verification 2 ==> ', String(paymentData[0]))
      // console.log('==>order confirm ',txnStatus);
      verificationCall()
    }, []) 

    const verificationCall=()=>{
        const url = 'https://admin.creativecarcare.in/api/token_generator/break_str'
        var body = {
          str : String(paymentData[0]).toString()
        }

        console.log("body  of token ==> ", body);
        axios.post(url, body,{
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((resp) => {
            console.log("esss  ====> ", resp.data);
            sethashCode(resp.data.str[15])
            localStorage.setItem('statusTxn', resp.data.str[0])
            // txn_status|txn_msg|txn_err_msg|clnt_txn_ref|tpsl_bank_cd|tpsl_txn_id|txn_amt|clnt_rqst_meta|tpsl_txn_time|bal_amt|card_id|alias_name
            // |BankTransactionID|mandate_reg_no|token|hash
            var jsonObject = {
              txn_status : resp.data.str[0],
              txn_msg : resp.data.str[1],
              txn_err_msg : resp.data.str[2],
              clnt_txn_ref : resp.data.str[3],
              tpsl_bank_cd : resp.data.str[4],
              tpsl_txn_id : resp.data.str[5],
              txn_amt : resp.data.str[6],
              clnt_rqst_meta : resp.data.str[7],
              tpsl_txn_time : resp.data.str[8],
              bal_amt : resp.data.str[9],
              card_id : resp.data.str[10],
              alias_name : resp.data.str[11],
              BankTransactionID : resp.data.str[12],
              mandate_reg_no : resp.data.str[13],
              token : resp.data.str[14],
              hash : resp.data.str[15],
            }

            setjsonObjectState(jsonObject)

            var jsonObjectwithSalt = {
              txn_status : resp.data.str[0] == null ? '' : resp.data.str[0],
              txn_msg : resp.data.str[1]  == null ? '' : resp.data.str[1],
              txn_err_msg : resp.data.str[2]  == null ? '' : resp.data.str[2],
              clnt_txn_ref : resp.data.str[3]  == null ? '' : resp.data.str[3],
              tpsl_bank_cd : resp.data.str[4]  == null ? '' : resp.data.str[4],
              tpsl_txn_id : resp.data.str[5]  == null ? '' : resp.data.str[5],
              txn_amt : resp.data.str[6]  == null ? '' : resp.data.str[6],
              clnt_rqst_meta : resp.data.str[7]  == null ? '' : resp.data.str[7],
              tpsl_txn_time : resp.data.str[8]  == null ? '' : resp.data.str[8],
              bal_amt : resp.data.str[9]  == null ? '' : resp.data.str[9],
              card_id : resp.data.str[10]  == null ? '' : resp.data.str[10],
              alias_name : resp.data.str[11]  == null ? '' : resp.data.str[11],
              BankTransactionID : resp.data.str[12]  == null ? '' : resp.data.str[12],
              mandate_reg_no : resp.data.str[13]  == null ? '' : resp.data.str[13],
              token : resp.data.str[14]  == null ? '' : resp.data.str[14],
              SALT : '4380966650VNJYXS'  == null ? '' : '4380966650VNJYXS',
              hash : resp.data.str[15]  == null ? '' : resp.data.str[15],

            }

            // console.log("stored json object ==> ", jsonObject);
            // console.log("stored json object with salt ==> ", jsonObjectwithSalt);
            verfyHashTokens(jsonObjectwithSalt)


          }).catch((err) => console.log("errr --> ", err))
    }
   
    // const token_generator=(jsonObj)=>{
    //     const url = 'https://admin.creativecarcare.in/api/token_generator'
    //     var body = jsonObj

    //     console.log("body  of token ==> ", body);
    //     axios.post(url, body,{
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }).then((resp) => {
    //         console.log("esss  ====> ", resp.data.guessed_token);
    //         // if (resp.data.guessed_token == jsonObjectState.hash) {
    //         //   setpaymentSucessfull(true)
    //         //   console.log("sata true");
    //         // }else{
    //         //   setpaymentSucessfull(false)
    //         //   console.log("stat false");
    //         // }



    //         console.log(resp.data.guessed_token, "||||||||", jsonObjectState, "|||||||" , hashCode );

    //       }).catch((err) => console.log("errr --> ", err))
    // }

    const verfyHashTokens = (obj) => {
      const url  = 'https://admin.creativecarcare.in/api/check_hash'
      var body = obj

      console.log("body of verfiy Hadhs => ", body);

      axios.post(url,body,{
              headers: {
                "Content-Type": "multipart/form-data",
              }
            }).then((resp) => {
        console.log("resp form validatioe ==> ", resp.data);
        setStatus(resp.data.response)
        if (resp.data.response && obj.txn_status=='0300') {
          console.log('verification status',resp.data.response);
          navigate("/paymentPage", { 
            state : {
                verificationValue:obj,
                respStatus:resp.data.response
            }
           })
        }
        else{
          // console.log(txnStatus);
          setisLoding(true)
          setTimeout(() => {
            navigate("/Cart")
          }, 2000);
         }
      }).catch((err) => {
        console.log("Er  => ", err);

      })

    }
    
    // console.log(" in hash state ==> ", hashCode);
    // console.log(" in json object state ==> ", jsonObjectState);
    // console.log(" in json object statefor hash ==> ", jsonObjectState.hash);
    // console.log(" in json object state ==> ", jsonObjectStat);
    
  return (
    <>
    <TailSpin
    style={{
      width: "100%",
      border: "2px solid black",
      margin: "auto",
      display: "block",
    }}
    height="70"
    width="70"
    color="#ffd11b"
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
  />
    <Modal show={isLoding}  centered size="lg" className='modalClass modalClass2'>
       
       <Modal.Body style={{height:'100px', background:'rgba(241, 241, 241, 1)'}}>
        <p style={{textAlign:'center'}}>Your order is being Cancelled </p>
        <div style={{display:'flex', justifyContent:'center'}}>
        <TailSpin
          height="30"
          width="30"
          color="red"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        </div>
       </Modal.Body>
     </Modal>
    </>

    // <h5>verification payment status {paymentSucessfull} fdfd {hashCode} server side code </h5>
  )
}



// import axios from 'axios'
// import React, { useEffect } from 'react'
// import { useNavigate, useParams } from 'react-router-dom'


// export default function Verification() {
//     const { id } = useParams();
//  const navigate=useNavigate()
    
//     useEffect(()=>{
//       const urlParts = id.split('|');

//       // Extract the value
//       const desiredValue = urlParts[urlParts.length - 1];
  
//       // Log the extracted value
//       console.log('Extracted Value:', desiredValue);
//         verificationCall()
//         navigate('/paymentPage', {
//           state: {
//            tokenDesired : desiredValue
//           }
//         })
        
//     })
//     const verificationCall=()=>{
//         const url = 'https://admin.creativecarcare.in/api/token_generator/index'

//         axios.get(url, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }).then((resp) => {
//             console.log("esss  ====> ", resp.data);
            
//         }).catch((err) => console.log("errr --> ", err))
//     }
    
//   return (
//     <div>verification</div>
//   )
// }
