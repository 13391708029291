import React, { useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import '../assets/footer.css'
import '../assets/responsive.css';
import { IoIosCall } from 'react-icons/io';
import { FaFacebookF } from "react-icons/fa";
import whatsappIcon from '../assets/img/Home/whatsapp (3).png'

 
const Footer = () => {


    return (
        <>
            <div className='scollingStrip'>
               {/* <p className='scrollingtext'>We cannot be held responsible for any improper, incorrect, or excessive use or application of products listed or purchased from this site.
                </p> */}
            </div>
            <div className='row threegridText' style={{ color: 'white', backgroundColor: '#191919' }}>
                <div className='col-lg-4 col-12 text-center' style={{ borderRight: 'none', border: '2px solid #4e4242' }}>
                    <p className='LastTitle'>Insured Door Delivery</p>
                    {/* <p className='lastText'>Nam liber tempor cum soluta nobis eleifend option congue.</p> */}
                </div>
                <div className='col-lg-4 col-12 text-center' style={{ border: '2px solid #4e4242' }}>
                    <p className='LastTitle'>Manufacturers Exclusive Representative</p>
                    {/* <p className='lastText'>Nam liber tempor cum soluta nobis eleifend option congue.</p> */}
                </div>
                <div className='col-lg-4 col-12 text-center' style={{ borderLeft: 'none', border: '2px solid #4e4242' }}>
                    <p className='LastTitle'>31 Years of Excellence in Car Care</p>
                    {/* <p className='lastText'>Nam liber tempor cum soluta nobis eleifend option congue.</p> */}
                </div>
            </div>
            <Carousel className='boots'>
                <Carousel.Item interval={3000}>
                    <p className='LastTitle'>Insured Door Delivery</p>
                    {/* <p className='lastText'>Nam liber tempor cum soluta nobis eleifend option congue.</p> */}
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <p className='LastTitle'>Manufacturers Exclusive Representative</p>
                    {/* <p className='lastText'>Nam liber tempor cum soluta nobis eleifend option congue.</p> */}
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <p className='LastTitle'>31 years old company pioneered in car care</p>
                    {/* <p className='lastText'>Nam liber tempor cum soluta nobis eleifend option congue.</p> */}
                </Carousel.Item>
            </Carousel>
            <div className='container-fluid ' style={{ backgroundColor: '#191919', color: 'white' }}>
                <div className='row d-flex justify-content-between footerRow' >
                    <div className=' col-lg-3 firstCol'>
                        <h3 className='foooterTitle'>About Us</h3>
                        <p className='FirstColText'>
                            creativecarcare.in is an exclusive E-commerce Platform owned by Creative International. Creative International has achieved the Accreditation of being acknowledged as an Institution, in the field of Import and Distribution of Premium Car Care. Our company has been ISO 9001:2015 certified.... <br></br> <a target='_blank' style={{ color: 'red', textDecoration: 'none' }} href='/AboutUs'>Read more</a></p>
                        
                           <span className='contactFooter'><a target='_blank' href="https://wa.me/9920290909"  style={{textDecoration:'none', color:'white '}}>For Commercial and Bulk enquiries. WhatsApp us on  +91 9920290909</a></span> 
                        
                           <span className='contactFooter2'><a target='_blank' href="https://wa.me/9920290909"  style={{textDecoration:'none', color:'white '}}>For Commercial and Bulk enquiries. WhatsApp us on <br/> +91 9920290909</a></span> 
                        
                        {/* <p className='FooterIcon'><i class="fa-brands fa-facebook-f" style={{padding:'2px'}}></i>
                    <i class="fa-brands fa-whatsapp"></i
                    <i class="fa-brands fa-instagram"></i>
                    <i class="fa-brands fa-youtube"></i>
                    </p> */}
                        {/* <div className='testAndCareer' style={{ display: 'flex' }}>
                            <a target='_blank' href='/Testimonials' style={{ textDecoration: 'none', color: 'white', marginRight: '20px' }}>Testminoials</a>
                            <a target='_blank'  style={{ textDecoration: 'none', color: 'white' }}>Career</a>
                            </div> */}
                    </div>
                    <div className='col-lg-3 footerSecond'>
                        <h3 className='foooterTitle'>Policies</h3>
                        <div className='row secondCol'>
                            <div className='col-6'>
                                <ul className='footerList' style={{ padding: 0 }}>
                                    <li ><a target='_blank' href='/Terms' style={{ textDecoration: 'none', color: 'white' }} className='policyhover'> Terms & Conditions</a></li>
                                    <li><a target='_blank' href='/Privacy' style={{ textDecoration: 'none', color: 'white' }} className='policyhover'>Privacy Policy</a></li>
                                    <li ><a target='_blank' href='/Return' style={{ textDecoration: 'none', color: 'white' }} className='policyhover'> Return & Refund Policy</a></li>
                                    <li ><a target='_blank' href='/cancel' style={{ textDecoration: 'none', color: 'white' }} className='policyhover'> Cancellation Policy</a></li>
                                    <li><a target='_blank' href='/Shipping' style={{ textDecoration: 'none', color: 'white' }} className='policyhover'>Shipping Policy</a></li>
                                    {/* <li><a target='_blank' href='/Privacy' style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy</a></li>
                                    <li><a target='_blank' href='/Testimonials' style={{textDecoration:'none', color:'white'}}>Testminoials</a></li> */}
                                    
                                    

                                </ul>
                            </div>
                            <div className='col-6'>
                                <ul className='footerList' style={{ padding: 0 }}>
                                    {/* <li><a target='_blank' href='/Refund' style={{ textDecoration: 'none', color: 'white' }}>Refund Policy</a></li> */}
                                    
                                    <li><a target='_blank' href='/ContactUs' style={{ textDecoration: 'none', color: 'white' }} className='policyhover'>Contact Us</a></li>
                                    <li><a target='_blank' href='/Feedback' style={{textDecoration:'none', color:'white'}} className='policyhover'>Feedback</a></li>
                                    {/* <li><a target='_blank' href='/Testimonials' style={{textDecoration:'none', color:'white'}} className='policyhover'>Testminoials</a></li> */}
                                    <li><a target='_blank' href='/ReturnOrder' style={{ textDecoration: 'none', color: 'white' }} className='policyhover'>Return Product</a></li>
                                    
                                    

                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3  thridCol'>
                        <h3  className='foooterTitle'>Contact Us</h3>
                        <p style={{marginTop:'18px',minHeight:'auto'}} className='FirstColText2'>If you have any query, please contact us on &nbsp;
                            <a href="mailto: support@creativecarcare.in" style={{ color: '#E51515', textDecoration: 'none' }}>
                                support@creativecarcare.in
                            </a>  </p>
                        <a target='_blank' style={{ textDecoration: 'none', color: 'white', marginTop: '11px',marginBottom:'6px' }} className='locationfooter' href='https://goo.gl/maps/ikDTPLDpHNcorVWG9'>Unit 1, Janata Compound, Senapati Bapat Marg, Lower Parel West, Mumbai, India, 400013</a>
                        <p style={{ marginTop: '10px' }} className='mobileScreen'>
                            {/* <i class="fa-solid fa-mobile-screen"></i>	<a href='tel: 99302 90909' style={{ textDecoration: 'none', color: 'white',fontSize:'16px', marginLeft:'6px' }}>+91 99302 90909</a> <br />
                        	<a href='tel: 99301 90909' style={{ textDecoration: 'none', color: 'white',fontSize:'16px', marginLeft:'22px' }}>+91 99301 90909</a><br /> */}
                        <span style={{ marginTop: '15px' }}><IoIosCall style={{ color: '#ffffff', fontSize: '18px', marginTop:'-2px' }} />	<a style={{ textDecoration: 'none', color: 'white',fontSize:'16px' }} href='tel: 95459 00009'>+91 9545900009</a></span>
                        </p>
                        {/* <p className='FooterIcon2'><i class="fa-brands fa-facebook-f" ></i>
                    <i class="fa-brands fa-whatsapp"></i>
                    <i class="fa-brands fa-instagram"></i>
                    <i class="fa-brands fa-youtube"></i>
                    </p> */}
                        <p className='FooterIcon'>
                            {/* <i class="fa-brands fa-facebook-f" style={{ padding: '2px' }}></i> */}
                        {/* <a style={{color:'white'}} href="https://wa.me/+919930190909" className='footerWhatsapp' ><div style={{}}><img src={whatsappIcon}/></div></a>
                        <a style={{color:'white'}} href="https://wa.me/+919930190909" className='footerWhatsapp2' ><div style={{}}> <img src={whatsappIcon}/></div></a> */}

                       <a style={{color:'white'}} target='_blank' href="https://wa.me/9930290909" className='footerWhatsapp' ><i class="fa-brands fa-whatsapp" style={{marginTop:'6px', fontSize:'20px'}}></i></a>
                        <a style={{color:'white'}} target='_blank' href="https://wa.me/+91 9930290909" className='footerWhatsapp2' ><i class="fa-brands fa-whatsapp" style={{marginTop:'6px'}}></i></a>

                           <a style={{color:'white'}} target='_blank' href='https://www.instagram.com/creativecarcare.in/?igshid=MzRlODBiNWFlZA%3D%3D'> <i class="fa-brands fa-instagram instgaramIcon"></i></a>
                           <a style={{color:'white'}} target='_blank' href='https://www.facebook.com/profile.php?viewas=100000686899395&id=61554003526484'> <FaFacebookF style={{fontSize:'17px'}}/></a>
                           {/* <i class="fa-brands fa-youtube" style={{marginTop:'3px'}}></i> */}
                            {/* <i class="fa-solid fa-location-dot faLocation" ></i> */}
                            </p>
                    </div>
                </div>
                <hr />
                <p className='text-center2'> Copyright © 2023 creativecarcare.in. All rights reserved.</p>
            </div>
        </>
    )
}
// creativecarcare.in Unit 1, Janata Compound, Senapati Bapat Marg, Lower Parel West, Mumbai, India, 400013 
export default Footer