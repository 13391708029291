import React, { useState } from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'
import Testimonial from '../Testimonial'

export default function Testimonials() {
  const [cartValues, setCartValues] = useState(false);
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
        <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
        <div className='mainPrivacy'>
      {/* <h2 className='creativeCarCare'>Testimonials</h2> */}
      <Testimonial/>
      </div>
        <Footer/>
    </>
  )
}
