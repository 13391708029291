import React, { lazy } from "react";
import NavMenu from "../Navbar";
import Footer from "../Footer";
import Related from "../Related";
import dataTwo from "../../Json/dataTwo";
import { Dropdown } from "react-bootstrap";
import "./cart.css";
import dataThree from "../../Json/dataThree";
import { ColorRing, TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  checkUserPoints,
  checkWalletPoint,
  couponValues,
  deleteWishList,
  getcartbyId,
  getCartByItsId,
  getcartId,
  getdecrement,
  getIncrement,
  getSimilarProduct,
  getSummary,
  getWhishlist,
  giftValifityChecker,
  lisOfCoupon,
} from "../../api/api";
import { useEffect, useState } from "react";
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-stars";
import ReactModal from "react-modal";
import Feature from "../Feature";
import { Modal } from "react-bootstrap";
import Coupon from "./Coupon";
import toast, { Toaster } from "react-hot-toast";
import noCartimg from "../../assets/img/confusing-woman-due-to-empty-cart-4558760-3780056.png";
import { AiFillGift } from "react-icons/ai";
import icons from "../../assets/img/Home/Coin.png";
import productReducer from '../../Redux2/reducer/Reducer3.js'

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedData = useSelector((state) => state.profile);

  const [cartData, setCartData] = useState([]);
  const [cartFullData, setCartFullData] = useState([]);
  const [summaryInfo, setsummaryInfo] = useState([]);
  const [summaryPId, setsummaryPId] = useState([]);
  const [count, setCount] = useState(1);
  const [noproduct, setNoProduct] = useState(false);
  const [cartTotalPrice, setcartTotalPrice] = useState(0);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [Product, setProduct] = useState([]);
  const [Points, setPoints] = useState([]);
  const [pointsInput, setpointsInput] = useState();
  const [walletEorr, setwalletEorr] = useState(false);
  const [getCartPId, setgetCartPId] = useState([]);
  const [getCartPQty, setgetCartPQty] = useState([]);
  const [pointCanBeAdded, setpointCanBeAdded] = useState(false);
  const [errPointMessage, seterrPointMessage] = useState("");
  const [SucessPointMessage, setSucessPointMessage] = useState("");
  const [cartCounts, setCartCounts] = useState({});
  const [cartValues, setCartValues] = useState(false);
  const [isLoding, setisLoding] = useState(false);
  const [pointSuccess, setPointSuccess] = useState(false);
  const [checkBtn, setCheckBtn] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [deliveryCharge, setDeliveryCharge] = useState(false)
  const [couponCode, setcouponCode] = useState()
  const [couponstatus, setCouponStatus] = useState(false)
  const [couponAmount, setcouponAmount] = useState()
  const [counponAmountGet, setcounponAmountGet] = useState();
  const [giftVoucher, setgiftVoucher] = useState(false)
  const [giftVoucher2, setgiftVoucher2] = useState(false)
  const [couponList, setcouponList] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [couponApplied, setCouponApplied] = useState(false);
  const [voucherApplied, setvoucherApplied] = useState(false)
const [couponSearch, setcouponSearch] = useState('')
const [couponAvailable, setcouponAvailable] = useState(false)
  useEffect(() => {
    console.log('user values',storedData);
    if (storedData.userLoginSuccess) {
      showCouponList()
      localStorage.setItem('couponAMount', 0)
      localStorage.setItem('couponAMountNumber', 0)
      localStorage.setItem('couponAMount2', 0)
      localStorage.setItem('couponAMount2Name', 0)
      setTimeout(() => {
        setPageLoading(false)
      }, 500);
      getCartData(storedData.userId);
      getPointCheck();

      setTimeout(() => {
        window.history.scrollRestoration = "manual";
      }, 1000);
      // getSummaryValue()
    } else {
      getCartData(null);
    }
    window.scrollTo(0, 0);
    localStorage.setItem("pointstate", 0);
  }, []);

  const handleModalClose = () => {
    getCartData2();
    // Your code to run when the modal is closed goes here
    console.log("Modal is closed, running effect in CartPage...");
  };

  const getCartData = async () => {
    setisLoding(true);
  
    try {
      const url = getcartbyId();
      const body = {
        discount: summaryInfo.DISCOUNT_ON_MRP < pointsInput ? 0 : pointsInput,
        user_id: storedData.userId,
      };

      console.log('body of the cart',body);
  
      const resp = await axios.post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (!resp.data.status) {
        setNoProduct(true);
        // localStorage.clear();
        localStorage.removeItem("toalAMtAfterPoints");
localStorage.removeItem('DISCOUNT_ON_MRP');
localStorage.removeItem('cartNumber')
localStorage.removeItem('productQuantity')
localStorage.removeItem('productQuantity1')
localStorage.removeItem('POINTS_GET');
localStorage.removeItem('TOTAL_ON_MRP');
localStorage.removeItem('GST');
localStorage.removeItem('pointstate');
        setTimeout(() => {
          setisLoding(false);
          localStorage.setItem('cartStatus', false)
        }, 1000);
      } else {
        localStorage.setItem('cartStatus', true)
        console.log(resp.data);
        setNoProduct(false);
        setCartFullData(resp.data);
        setCartData(resp.data.data);
        setcartTotalPrice(resp.data.cart_mrp_price);
        setgetCartPId(resp.data.products);
        setgetCartPQty(resp.data.quantity);
        setsummaryInfo(resp.data.summary);
        seterrPointMessage("");
  
        // Update deliveryCharge state based on the condition
        let newDeliveryCharge =
          resp.data.summary.DISCOUNT_ON_MRP < 500 ? true : false;
  
    
  
        // let totalCharge =
        //   newDeliveryCharge
        //     ? resp.data.summary.TOTAL_AMOUNT_AFTER_POINTS + 150
        //     : resp.data.summary.TOTAL_AMOUNT_AFTER_POINTS;

        var totalCharge = newDeliveryCharge
  ? Math.floor(resp.data.summary.TOTAL_AMOUNT_AFTER_POINTS + 150)
  : Math.floor(resp.data.summary.TOTAL_AMOUNT_AFTER_POINTS);

  // var counponValues = localStorage.getItem('couponAMount') == 0 ? totalCharge:(totalCharge-localStorage.getItem('couponAMount'));
  // counponValues = localStorage.getItem('couponAMount2') == 0 ? totalCharge:(totalCharge-localStorage.getItem('couponAMount2'))
  

  var couponValues;
if (localStorage.getItem('couponAMount') > 0) {
    couponValues = totalCharge - localStorage.getItem('couponAMount');
} else {
    couponValues = localStorage.getItem('couponAMount2') > 0 ? totalCharge - localStorage.getItem('couponAMount2') : totalCharge;
}


        localStorage.setItem("toalAMtAfterPoints", couponValues);
        // localStorage.setItem("toalAMtAfterPoints", totalCharge);
        localStorage.setItem('DISCOUNT_ON_MRP', resp.data.summary.DISCOUNT_ON_MRP);
        localStorage.setItem('cartNumber',resp.data.data)
        var productQuantity = JSON.stringify(resp.data.products)
        localStorage.setItem('productQuantity', productQuantity)
        var productQuantity1 = JSON.stringify(resp.data.quantity)
        localStorage.setItem('productQuantity1', productQuantity1)
        localStorage.setItem('POINTS_GET', resp.data.summary.POINTS_GET);
        localStorage.setItem('TOTAL_ON_MRP', resp.data.summary.TOTAL_MRP);
        localStorage.setItem('GST', parseInt(Math.round(parseFloat(resp.data.summary.GST))));
        // let pointState;

        // // Check if pointState is undefined and set it to 0 if true
        // if (typeof pointState === 'undefined') {
        //   pointState = 0;
        // } else {
        //   // Check if summaryInfo.DISCOUNT_ON_MRP < pointsInput, set pointState to 0, otherwise set it to pointsInput
        //   pointState = summaryInfo.DISCOUNT_ON_MRP < pointsInput ? 0 : pointsInput;
        // }
        
        // // Set pointState in localStorage
        // localStorage.setItem('pointstate', pointState);
  
        setSucessPointMessage("");
        setTimeout(() => {
          setisLoding(false);
        }, 1000);
      }
    } catch (error) {
      console.log(" - - - error - - -", error);
      setNoProduct(true);
      setTimeout(() => {
        setisLoding(false);
      }, 1200);
    }
  };
  

  const getCartData2 = async () => {
    // setisLoding(true)
    const url = getcartbyId();
    var body = {
      discount: pointsInput,
      user_id: storedData.userId,
    };
    // console.log(' = = = url = = =',url);

    await axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log(" = = =cartdata = = =", resp.data);
        // setCartData(resp.data.data);
        if (!resp.data.status) {
          setNoProduct(true);
          // setTimeout(() => {
          setisLoding(false);
          // setnoProdDataFound(false);
          // }, 1000);
        } else {
          setNoProduct(false);
          setCartFullData(resp.data);
          setCartData(resp.data.data);
          setcartTotalPrice(resp.data.cart_mrp_price);
          setgetCartPId(resp.data.products);
          setgetCartPQty(resp.data.quantity);
          setsummaryInfo(resp.data.summary);

          // setTimeout(() => {
          setisLoding(false);
          // setnoProdDataFound(false);
          // }, 100);
          // getSummaryValue()
        }
      })
      .catch((error) => {
        console.log(" - - - error - - -", error);
        setNoProduct(true);
        setTimeout(() => {
          setisLoding(false);
          // setnoProdDataFound(false);
        }, 1200);
      });

    // console.log("in state cart data ==> ", cartFullData);
  };

  const deleteCard = (id) => {
    let data = new FormData();
    data.append("_method", "DELETE");
    const url = getCartByItsId(id);
    console.log("--->url", url);
    axios
      .post(url, data)
      .then((resp) => {
        console.log(" = = = deleted card = = ", resp.data);
        if (storedData.userLoginSuccess) {
          getCartData(storedData.userId);
          setCartValues(true);
          window.location.reload()

        } else {
          getCartData(null);
        }
      })
      .catch((error) => {
        console.log("--error--", error);
      });
  };

  const incrementCart = (cartId, productId) => {
    const url = getIncrement();

    // console.log('- - - url - - -',url);
    const userData = {
      cart_id: cartId,
      product_id: productId,
    };
    // console.log('userData ---', url,  userData);

    axios
      .post(url, userData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log('product increment - -  -', resp.data);
        if (resp.data.status) {
          if (storedData.userLoginSuccess) {
            if (summaryInfo.DISCOUNT_ON_MRP < pointsInput) {
              // setpointsInput(0);
              getCartData(storedData.userId);
              // setPointSuccess(false)
              window.location.reload()

            } else {
              getCartData(storedData.userId);
              window.location.reload()

              // setPointSuccess(false)
            }
          } else {
            getCartData(null);
          }
        } else {
          console.log("someyhing went wrong");
        }
      })
      .catch((error) => {
        console.log("- - err", error);
      });
  };

  const decrementCart = (cartId, productId) => {
    const url = getdecrement();

    // console.log('- - - url - - -',url);
    const userData = {
      cart_id: cartId,
      product_id: productId,
    };
    // console.log('userData ---', url,  userData);

    axios
      .post(url, userData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log('product increment - -  -', resp.data);
        if (resp.data.status) {
          if (storedData.userLoginSuccess) {
            if (summaryInfo.DISCOUNT_ON_MRP < pointsInput) {
              // setpointsInput(0)
              getCartData(storedData.userId);
              setCartValues(true);
              window.location.reload()
              // setPointSuccess(false)
            } else {
              getCartData(storedData.userId);
              setCartValues(true);
              window.location.reload()
              // setPointSuccess(false)
            }
          } else {
            getCartData(null);
          }
        } else {
          console.log("someyhing went wrong");
        }
      })
      .catch((error) => {
        console.log("- - err", error);
      });
  };

  const getPointCheck = () => {
    const url = checkWalletPoint();
    var body = {
      user_id: storedData.userId,
      points: 0,
    };

    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("resp in wllet =>>> ", resp.data);
        if (resp.data.status) {
          setPoints(resp.data);
          // localStorage.setItem("pointstate", 0);
        } else {
          setwalletEorr(true);
          setPoints(0);
          localStorage.setItem("pointstate", 0);
        }
      })
      .catch((err) => {
        setwalletEorr(true);
      });
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const formHandler = () => {
    handleShowModal();
  };

  const getSimilarValue = () => {
    const url = getSimilarProduct();

    const body = {
      product_id: "1",
      user_id: storedData.userId,
    };
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log('-----realteddd',resp.data);
        setProduct(resp.data.data);
      })
      .catch((error) => {
        // console.log('--related error',error);
      });
  };
  
  const removePoints = () => {
    window.location.reload()
  //   const url = getcartbyId();
  //   var body = {
  //     discount: 0,
  //     user_id: storedData.userId,
  //   };
  //   // console.log(' = = = url = = =',url);
  //   console.log("getcartbody", body);
  //   axios
  //     .post(url, body, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then((resp) => {
  //       console.log(" = = =cartdata = = =", resp.data);
  //       // setCartData(resp.data.data);
  //       if (!resp.data.status) {
  //         setNoProduct(true);
  //         setTimeout(() => {
  //           setisLoding(false);
  //           // setnoProdDataFound(false);
  //         }, 1000);
  //       } else {
  //         setDeliveryCharge(false)
  //         setNoProduct(false);
  //         setCartFullData(resp.data);
  //         setCartData(resp.data.data);
  //         setcartTotalPrice(resp.data.cart_mrp_price);
  //         setgetCartPId(resp.data.products);
  //         setgetCartPQty(resp.data.quantity);
  //         setsummaryInfo(resp.data.summary);
  //         seterrPointMessage("");
  //         setPointSuccess(false);
  //         setCheckBtn(false);
  //         setSucessPointMessage("");
  //         let newDeliveryCharge =
  //         resp.data.summary.DISCOUNT_ON_MRP < 500 ? true : false;
  //       // let totalCharge =
  //       //   newDeliveryCharge
  //       //     ? resp.data.summary.TOTAL_AMOUNT_AFTER_POINTS + 150
  //       //     : resp.data.summary.TOTAL_AMOUNT_AFTER_POINTS;
  
  //       let totalCharge = newDeliveryCharge
  // ? Math.floor(resp.data.summary.TOTAL_AMOUNT_AFTER_POINTS + 150)
  // : Math.floor(resp.data.summary.TOTAL_AMOUNT_AFTER_POINTS);


  // let counponValues = localStorage.getItem('couponAMount') == 0 ? totalCharge:(totalCharge-localStorage.getItem('couponAMount'))
  // counponValues = localStorage.getItem('couponAMount2') == 0 ? totalCharge:(totalCharge-localStorage.getItem('couponAMount2'))


  //       console.log('dcfwec',resp.data.summary.TOTAL_MRP);
  //       localStorage.setItem("toalAMtAfterPoints", counponValues);
  //       localStorage.setItem('DISCOUNT_ON_MRP', resp.data.summary.DISCOUNT_ON_MRP);
  //       localStorage.setItem('TOTAL_ON_MRP', resp.data.summary.TOTAL_MRP);
  //       var productQuantity = JSON.stringify(resp.data.products)
  //       localStorage.setItem('productQuantity', productQuantity)
  //       var productQuantity1 = JSON.stringify(resp.data.quantity)
  //       localStorage.setItem('productQuantity1', productQuantity1)
  //       localStorage.setItem('POINTS_GET', resp.data.summary.POINTS_GET);
  //       localStorage.setItem('GST', parseInt(Math.round(parseFloat(resp.data.summary.GST))));
  //         localStorage.setItem("pointstate", 0);
  //         setTimeout(() => {
  //           setpointsInput(0)
  //         }, 500);
  //         // getCartData()
        
  //         setTimeout(() => {
  //           setisLoding(false);
            
  //           // setnoProdDataFound(false);
  //         }, 1000);
  //         // getSummaryValue()
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(" - - - error - - -", error);
  //       setNoProduct(true);
  //       setTimeout(() => {
  //         setisLoding(false);
  //         // setnoProdDataFound(false);
  //       }, 1200);
  //     });
  };

  const addtoCart = (id) => {
    const url = getcartId();

    // console.log( ' -- - - - url - - - ',url);
    const postData = {
      user_id: storedData.userId,
      product_id: id,
      has_login: "yes",
    };

    axios
      .post(url, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log('---- cart value == ', resp.data);
        if (resp.data.status) {
          toast.success("Product Added In Cart", { icon: "🛒" });
          getSimilarValue(storedData.userId);
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }
      })
      .catch((error) => {
        // console.log(' === error ===',error);
      });
  };

  const handleAddToCart = (id) => {
    addtoCart(id);
  };

  const checkUserPoint_Func = () => {
    if (summaryInfo.DISCOUNT_ON_MRP < pointsInput) {
      setpointsInput(0);
      setpointCanBeAdded(false);
      return;
    }

    const url = checkUserPoints();
    var body = {
      user_id: storedData.userId,
      points: pointsInput,
    };
    console.log("point input", pointsInput);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("resp from points ==> ", resp.data);
        if (resp.data.status) {
          if((localStorage.getItem('toalAMtAfterPoints')-99) <= pointsInput){
            alert('you  cannot apply code')
          }
          else{ 
            setpointCanBeAdded(true);
            setDeliveryCharge(true)
            setSucessPointMessage(resp.data.message);
            getCartData(storedData.userId);
            toast.success("points added successfully", { icon: "📍" });
            setPointSuccess(true);
            setIsCheckboxChecked(false);
            setCheckBtn(true);
            localStorage.setItem("pointstate", pointsInput);
          }
          //  setpointsInput(0)
         
        } else {
          setpointCanBeAdded(false);
          seterrPointMessage(resp.data.message);
          setpointsInput(0);
          localStorage.setItem("pointstate", 0);
        }
      })
      .catch((err) => {
        console.log("err in points system => ", err);
        setpointCanBeAdded(false);
      });
  };

  var couponAmounts;
  const checkUserCode = ()=>{
    const url = giftValifityChecker()
    const body = {
        mobile : storedData.userNumber,
        code :couponCode
    }
    console.log(body);
    axios.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp)=>{
      console.log(resp.data.amount);
      setCouponStatus(resp.data.status);
      setcounponAmountGet(resp.data.amount);
      if(resp.data.status == false){
        // alert('Enter Valid Code')
        toast.error('Enter Valid Code', { icon: "⚠️" });
      }
    })
    .catch((error)=>{
      console.log(error);
      setCouponStatus(false)
    })
  }
  const checkUserAmount = ()=>{
    const url = giftValifityChecker()
    const body = {
        mobile : storedData.userNumber,
        code :couponCode,
        amount:couponAmount
    }
    console.log(body);
    axios.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp)=>{
      console.log(resp.data.status);
      if(resp.data.status){
        if((localStorage.getItem('DISCOUNT_ON_MRP')-localStorage.getItem('pointstate')-99) <= couponAmount ){
          // alert('you  cannot apply code')
          toast.error('Minimum Rs 100 is requied', { icon: "⚠️" });
        }
        else{
          
          localStorage.setItem('couponAMount', couponAmount);
          localStorage.setItem('couponAMountNumber', couponCode);
          getCartData()
          setvoucherApplied(true) ;
          setgiftVoucher(false)
        }
      }else{
        toast.error("Not sufficient amount in coupon", { icon: "⚠️" });
      }
      
     
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  const showCouponList = () =>{
    const url = lisOfCoupon()
    const body={
      search : couponSearch
  }
  console.log(body);
    axios.post(url,body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp)=>{
      console.log(resp.data);
      setcouponList(resp.data.data)
      if(resp.data.status){
        setcouponAvailable(true)
      }else{
        setcouponAvailable(false)
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  useEffect(()=>{
    showCouponList()
  },[couponSearch])

  const useCouponValue = () =>{
    const url = couponValues()
    const body = {
      code:selectedCoupon,
      cart_amt:localStorage.getItem('toalAMtAfterPoints')
    }
    console.log(body);
    axios.post(url, body,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    } )
    .then((resp)=>{
      console.log(resp.data);
      if(resp.data.status){
        if((localStorage.getItem('DISCOUNT_ON_MRP')-localStorage.getItem('pointstate')-99) <= resp.data.value ){
          toast.error('Minimum Rs 100 is requied', { icon: "⚠️" });
        }else{

          setCouponApplied(true)
          // setcouponPrice(resp.data.value)
          
          localStorage.setItem('couponAMount2',resp.data.value )
          localStorage.setItem('couponAMount2Name',selectedCoupon )
          setgiftVoucher2(false)
          getCartData(storedData.userId);
        }
      }
      else{
        toast.error('Sorry, your cart total is below the coupon limit.', { icon: "⚠️" });
      }
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  const handleCouponCode = (code) => {
    setSelectedCoupon(code);
    console.log(code);
  };
  
  const helloWorld = () => {
    console.log("---hello world---");
  };

  

  return (
    <div>
      <div className="container-fliud">
        <NavMenu
          onCloseModal={handleModalClose}
          cartBoolean={cartValues}
          setCartBoolean={setCartValues}
          setcartpoint={pointsInput}
        />
        <Toaster />
        {
        pageLoading ? (<>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'white'}}>
            <TailSpin  color="#ffd11b" />
          </div>
          </>):(<>
            <div>
           {isLoding ? (
          <div
            style={{
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <TailSpin
              style={{
                width: "100%",
                border: "2px solid black",
                margin: "auto",
                display: "block",
              }}
              height="70"
              width="70"
              color="#ffd11b"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />{" "}
          </div>
        ) : (
          <div className="container cardContainer">
            <div className="row cartRow">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <p className="CartTitle mt-5">Shopping Cart</p>
                {noproduct ? (
                  <>
                    <div style={{ margin: "50px 0px" }}>
                      <p>No products Added in Cart</p>
                      <img src={noCartimg} className="noCartImage" alt="EmptyCart"></img>
                    </div>
                  </>
                ) : (
                  cartData.map((item) => (
                    <React.Fragment key={item.cart_id}>
                      <hr />
                      <div style={{ display: "flex" }}>
                        <div
                          className=""
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span className="cartimage2">
                            <img
                              src={item.image_url}
                              className="cartimage"
                              alt={item.title}
                              loading="lazy"
                            />
                          </span>
                        </div>

                        <div className=" cartContext">
                          <p className="CartbrandTitle">
                            {" "}
                            Brand : {item.brand_name}
                          </p>
                          {/* <p className='mrpStock'>Stock Status:
                                    {
                                        item?.stock <= 10 && item?.stock > 0 ?
                                            <span style={{ color: "#E73C17", marginLeft: '5px', fontFamily:'Rubik' }}>
                                                Only {item?.stock} in Stock
                                            </span> : item?.stock <=0 ? <span style={{ color: "#E73C17", fontFamily:'Rubik', marginLeft: '5px' }}>
                                                Out of Stock
                                            </span> : <span style={{ color: "#24D600", fontFamily:'Rubik', marginLeft: '5px' }}>
                                                IMMEDIATE DISPATCH  
                                                
                                            </span>
                                    }
                                </p> */}
                       <p className="CartProTitle" onClick={helloWorld}>
                        {item.title.split(' ').map((word, index, array) => (
                          <React.Fragment key={index}>
                            {word}
                            {index < array.length - 1 && (index + 1) % 12 === 0 && <br />}
                            {' '}
                          </React.Fragment>
                        ))}
                      </p>
                          <p className="stock"></p>

                          <div className="prizes">
                            <p className="cartPrize " onClick={helloWorld}>
                              ₹ {item.starting_price}
                            </p>
                            <p className="cartstartPrize"> 
                              MRP <strike>₹{item.mrp}</strike>
                            </p>
                          </div>
                          <span className="cartspan2">
                            {/* <span className="cartspan2"> */}
                            <button
                              onClick={() =>
                                decrementCart(item.cart_id, item.product_id)
                              }
                              className="incrbtn"
                              style={{ display: "flex" }}
                            >
                              -
                            </button>
                            <span className="count">{item.quantity}</span>
                            <button
                              onClick={() => {
                                incrementCart(item.cart_id, item.product_id);
                              }}
                              className="decrbtn"
                            >
                              +
                            </button>
                          </span>
                        </div>
                        <div className="cartFatimes" style={{cursor:'pointer'}}>
                          <FaTimes
                            className="fatimes2"
                            onClick={() => deleteCard(item.cart_id)}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                )}
                {/* <hr  /> */}
              </div>

              {noproduct ? null : (
                <div className="col-lg-5 couponContainer col-md-12 col-sm-12">
                  {/* <div className="couponSearch">
                        <p style={{ fontFamily: 'Oswald', fontWeight: 500 }}>Best Coupon For you</p>
                        <p className="allCoupon" onClick={() => formHandler()}>All coupon  </p>
                      </div>
                      <div style={{display:'flex'}} >
                        <input className="couponInput" placeholder="Typing coupon code"/>
                        <button className="couponbtn">Apply</button>
                      </div> */}

                  <div className="cartColumn">
                  {!Points.user_points ? (
                          <p style={{  fontSize:'22px', marginBottom:'6px' }}>
                          <img
                          src={icons}
                          alt="icons"
                          style={{ width: "8%", marginRight: "6px" }}
                        />   <strong> Balance : 0 points</strong>
                          </p>
                        ) : (
                          <p style={{ fontSize:'22px', marginBottom:'6px' }}>
                            
                            <img
                          src={icons}
                          alt="icons"
                          style={{ width: "8%", marginRight: "6px" }}
                        /> <strong>  Balance : {Points.user_points} points</strong>
                          </p>
                        )}
                  {!pointSuccess ? null: (
                      <>
                        <p
                          style={{
                            color: "green",
                            margin: "0",
                            textAlign: "center",
                          }}
                        >
                          (You have attached <strong>{pointsInput}</strong>{" "}
                          points Successfully.)
                        </p>
                        <p
                          style={{
                            color: "red",
                            textDecoration: "underline",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          onClick={removePoints}
                        >
                          Remove
                        </p>
                      </>
                    )}
                    <div className="pointAndCheck">
                    {!checkBtn && (
                      <div className="checkorder">
                        
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={isCheckboxChecked}
                          onChange={handleCheckboxChange}
                          id="checkbox1"
                        />
                        <span class="checkmark"></span>
                        <p className="pointorder">Pay Using Points</p>
                      </div>
                    )}
                    <div className="entercoupon">
                      {isCheckboxChecked && (
                        <>
                          <input
                            onChange={(e) => setpointsInput(e.target.value)}
                            className="couponValue"
                            placeholder="Enter Points"
                          />
                          {pointsInput >= 1 &&
                            (localStorage.getItem('DISCOUNT_ON_MRP')-localStorage.getItem('couponAMount') - localStorage.getItem('couponAMount2') -99)  > pointsInput &&  (
                              <button
                                onClick={() => checkUserPoint_Func()}
                                className="checkcouponbtn"
                              >
                                CHECK
                              </button>
                            )}
                        </>
                      )}
                    </div>
                      </div>
                   

                    {pointCanBeAdded ? (
                      <p style={{ color: "green", textAlign: "center" }}>
                        {SucessPointMessage}
                      </p>
                    ) : (
                      <p style={{ color: "red", textAlign: "center" }}>
                        {errPointMessage}{" "}
                        {summaryInfo.DISCOUNT_ON_MRP < pointsInput &&
                          "You cannot add more points than the cart price"}
                      </p>
                    )}

                   
                    {
                      deliveryCharge ? null:(<>    <p style={{color:'red', marginBottom:'5px', fontSize:'15px', padding:'0px 10px'}}>You can use maximum {localStorage.getItem('DISCOUNT_ON_MRP') -localStorage.getItem('couponAMount') - localStorage.getItem('couponAMount2') - 100} points on this order.</p></>)
                    }
                
                  </div>

{
  !voucherApplied && !couponApplied && (<>
   <div className="earnPoint" style={{padding:'5px 15px', display:'flex', justifyContent:'space-between', border:'none'}}>
                    <p className={giftVoucher ? "voucherCouponSection" : "voucherCouponSection2"} onClick={()=>{setgiftVoucher(!giftVoucher);setgiftVoucher2(false);setSelectedCoupon(null)}} style={{margin:'5px 0', cursor:'pointer'}} >Gift Voucher</p>
                    <hr/>
                    <p className={giftVoucher2 ? "voucherCouponSection" : "voucherCouponSection2"}  onClick={()=>{setgiftVoucher2(!giftVoucher2);setgiftVoucher(false);setCouponStatus(false)} } style={{margin:'5px 0',cursor:'pointer'}}>Copon Code</p>
                    </div>
  </>)
}
                 

                    {
                      giftVoucher && (
                        <>
                          <div className="earnPoint">
                   <div>
                <p style={{marginBottom:'5px', fontWeight:'700'}}>Gift Voucher</p>
                <p style={{marginBottom:'5px'}}>Enter coupon code: </p>
                <div className="giftVoucherCode" style={{ display:'flex', justifyContent:'space-between'}}>
                <input onChange={(e)=>setcouponCode(e.target.value)} value={couponCode} placeholder="coupon code" style={{padding:'3px', border:'1px solid lightgrey', borderRadius:'2px'}}/>
                <button style={{background:'#e51515', borderRadius:'3px', color:'white', border:'none', padding:'0 10px'}} onClick={()=>checkUserCode()}>check</button>
                </div>
                {/* <p style={{margin:'0', fontSize:'10px', color:'#e51515'}}>Enter valid code</p> */}
                {
                  couponstatus ? (<>
                  <div style={{margin:'20px 0 0'}}>
                  {/* <p style={{marginBottom:'5px'}}>Enter Amount: </p> */}
                <div className="giftVoucherCode" style={{ display:'flex', justifyContent:'space-between'}}>
                <input onChange={(e)=>setcouponAmount(e.target.value)} value={couponAmount} placeholder="Enter Amount" style={{padding:'3px', border:'1px solid lightgrey', borderRadius:'2px'}}/>
                <button
                                onClick={() => checkUserAmount()}
                                style={{background:'#e51515', borderRadius:'3px', color:'white', border:'none'}}
                              >
                                Submit
                              </button>
                </div>
                <p style={{margin:'0', fontSize:'12px', color:'#e51515'}}>You can use maximum ₹{localStorage.getItem('DISCOUNT_ON_MRP') -localStorage.getItem('pointstate') - 100} </p>
                  </div>
                  </>):null
                }
                 </div>
               </div>
                        </>
                      )
                    }
                     {
                  voucherApplied && (<>
                <div className="earnPoint">
                   <p style={{margin:0, color:'green', textAlign:'center', fontFamily:'Rubik', fontWeight:'500', fontSize:'15px'}}>Voucher Applied Successfully</p>
               </div>
                  </>)
                }

                    {
                      giftVoucher2 && (
                        <>
                        
                          <div className="earnPoint">
                          {
                          couponAvailable ? (<><div>
                            <span style={{display:'flex', justifyContent:'space-between'}}>
                        <p style={{marginBottom:'5px', fontWeight:'700'}}>Coupons</p>
                        <p style={{marginBottom:'0px', fontWeight:'400', color:'#E51515', cursor:'pointer'}}>Show all</p>
                            </span>
                        <div style={{padding:'10px 0'}}>
                          <input type="text" placeholder="code" style={{border:'1px solid lightgrey',borderRight:'none',width:'75%', borderRadius:'5px 0 0 5px', padding:'3px 5px'}} value={couponSearch} onChange={(e)=>setcouponSearch(e.target.value)}/>
                          <button style={{border:'1px solid lightgrey', background:'white', color:'#E51515', borderRadius:'0 5px 5px 0',borderLeft:'none',  padding:'3px 8px'}} onClick={()=> showCouponList()}> Check</button> 
                        </div>
                        
                         {
                          couponList.map((item, index)=>{
                            return(
                              <>
                              <div  className="CoupondivSub">
                                <span style={{display:'flex'}}>
                             <input key={index}  onClick={()=>{
                               handleCouponCode(item.code);
                               setSelectedCoupon(selectedCoupon === item.code ? null : item.code);
                              }
                            } checked={selectedCoupon === item.code} type="checkbox" style={{marginRight:'10px'}}/>   <p className="couponCodeName" style={{margin:'0',color: selectedCoupon === item.code ? '#E51515' : 'inherit' }}>{item.code}</p>
                            </span>
                            {
                              item.type=='AMT'?(<><p style={{margin:'3px 0 0 0', fontWeight:600}}>Save Rs.{item.code_amount}</p></>):(<><p style={{margin:'3px 0 0 0', fontWeight:600}}>Save upto {item.code_amount}%</p></>)
                            }
                                <p style={{margin:'0', fontSize:'15px'}}>  {
                              item.type=='AMT'?(<>Save Rs.{item.code_amount}</>):(<> get upto {item.code_amount}% discount</>)
                            } on total price</p>
                                {/* <p style={{margin:'0'}}>{item.msg}</p> */}
                                <p style={{margin:'0',fontSize:'15px'}}>
                                Expires on: {new Date(item.expire_at).getDate()} {new Date(item.expire_at).toLocaleString('default', { month: 'long' })} {new Date(item.expire_at).getFullYear()}
                                  </p>
                              </div>
                              <hr/>
                              </>
                            )
                          })
                         }
                         {
                          selectedCoupon && (
                            <>
                            {/* <p style={{marginBottom:'5px', fontWeight:'500'}}>Selected Coupon</p>
                            <div  style={{ 
            // background: 'rgba(244, 244, 244, 1)',
            margin: '8px 0',
            // padding: '5px 0px',
            fontSize: '14px',
            fontWeight: '700',
            textTransform: 'uppercase',
            boxShadow: '0 0 1px rgba(244, 244, 244, 1)',
            cursor:'pointer',
            borderRadius: '10px'}} >
                                <p style={{margin:'0'}}>{selectedCoupon}</p>
                              </div> */}
                              
                              <div>
                                <button onClick={useCouponValue} style={{background:'#E51515', border:'none', borderRadius:'5px', padding:'2px 10px', color:'white', margin:'10px 0'}}>Apply code</button>
                              </div>
                            </>
                          )
                         }
                        {/* <p style={{margin:'0', fontSize:'10px', color:'#e51515'}}>Enter valid code</p> */}
                        {
                          couponstatus ? (<>
                          <div style={{margin:'20px 0 0'}}>
                          <p style={{marginBottom:'5px'}}>Enter Amount: </p>
                        <div style={{width:'75%', display:'flex', justifyContent:'space-between'}}>
                        <input onChange={(e)=>setcouponAmount(e.target.value)} value={couponAmount} placeholder="Enter Amount" style={{padding:'3px', border:'1px solid lightgrey', borderRadius:'2px'}}/>
                        <button
                                        onClick={() => checkUserAmount()}
                                        style={{background:'#e51515', borderRadius:'3px', color:'white', border:'none'}}
                                      >
                                        Submit
                                      </button>
                        </div>
                        <p style={{margin:'0', fontSize:'12px', color:'#e51515'}}>You can use maximum ₹{localStorage.getItem('DISCOUNT_ON_MRP') - 100} </p>
                          </div>
                          </>):null
                        }
                         </div></>):(<><p style={{textAlign:'center', color:'red', margin:'0'}}>No coupon available</p></>)
                        }
                   
               </div>
                        </>
                      )
                    }
                {
                  couponApplied && (<>
                <div className="earnPoint">
                   <p style={{margin:0, color:'green', textAlign:'center', fontFamily:'Rubik', fontWeight:'500', fontSize:'15px'}}>Coupon Applied Successfully</p>
               </div>
                  </>)
                }
               
                 
                  <div className="couponcalc">
                    <h3 className="priceSummary">
                      Price Summary
                      <span
                        style={{
                          fontSize: "12px",
                          textTransform: "capitalize",
                        }}
                      >
                        : ( {cartData.length} items)
                      </span>
                    </h3>
                    <div className="row">
                      <div className="col-6  priceDistribution">
                        <ul style={{margin:'0px'}}>
                          <li
                          className="allCartInfo"
                          
                          >
                            Total MRP
                          </li>
                          <li
                          className="allCartInfo"
                           
                          >
                            Selling Price
                          </li>
                          {/* <li  style={{marginBottom:'8px', marginLeft:'25px', fontFamily:'Rubik'}}>Coupon Discount</li> */}
                          <li
                          className="allCartInfo"
                           
                          >
                            Point discount
                          </li>
                          <li
                          className="allCartInfo"
                           
                          >
                            Gift Voucher
                          </li>
                          <li
                          className="allCartInfo"
                           
                          >
                            Coupon
                          </li>
                          {/* <li  style={{marginBottom:'8px', marginLeft:'25px', fontFamily:'Rubik'}}>GST.</li> */}
                          <li
                          className="allCartInfo"
                           
                          >
                            Delivery Charges
                          </li>
                          {/* <div className="hrLine2" ></div> */}
                          {/* <hr style={{}} /> */}
                          
                        </ul>
                      </div>
                      <div className="col-6 numericalPrize">
                        <ul style={{margin:'0px'}}>
                          <li
                          className="allCartInfo2"
                           
                          >
                            ₹ {summaryInfo.TOTAL_MRP}
                          </li>
                          <li
                          className="allCartInfo2"
                       
                          >
                            ₹ {summaryInfo.DISCOUNT_ON_MRP}
                          </li>
                          {/* <li  style={{marginBottom:'8px', marginLeft:'65px'}}>₹{summaryInfo.COUPON_DISCOUNT}</li> */}
                          <li
                          className="allCartInfo2"
                       
                          >
                            ₹ {summaryInfo.POINTS_DISCOUNT}
                          </li>
                          <li
                          className="allCartInfo2"
                       
                          >
                            ₹ {localStorage.getItem('couponAMount')}
                          </li>
                          <li
                          className="allCartInfo2"
                       
                          >
                            ₹ {localStorage.getItem('couponAMount2')}
                          </li>
                          {/* <li  style={{marginBottom:'8px', marginLeft:'65px'}}>₹{parseFloat(summaryInfo.GST).toFixed(2)}</li> */}
                          <li
                          className="allCartInfo2"
                           
                          >
                            ₹ {summaryInfo.DISCOUNT_ON_MRP < 500 ? 150 : 0.00}
                          </li>
                          {/* <hr style={{}} /> */}
                        
                        </ul>
                      </div>
                      {/* {
                       summaryInfo.TOTAL_AMOUNT_AFTER_POINTS < 500 ? (<><p className="discountcut">For free shipping add product of value INR {500 - summaryInfo.TOTAL_AMOUNT_AFTER_POINTS }</p></>):null
                      } */}
                      {/* {
                       summaryInfo.DISCOUNT_ON_MRP < 500 ? (<><p className="discountcut">Add items worth <strong>₹ {500 - summaryInfo.DISCOUNT_ON_MRP }</strong> to get <strong>Free Delivery</strong></p></>):null
                      } */}
                      {
                       summaryInfo.DISCOUNT_ON_MRP < 500 ? (<><p className="discountcut">Order above <strong>500</strong> will be eligible for free delivery</p></>):null
                      }
                      <hr/>
                      <div className="col-6  ">
                        <ul>
                          
                          {/* <hr style={{}} /> */}
                          <li
                          className="allCartInfoMain"
                            
                          >
                            TOTAL AMOUNT : 
                          </li>
                        </ul>
                      </div>
                      <div className="col-6 ">
                        <ul>
                         
                          
                          {/* <hr style={{}} /> */}
                          <li className="allCartInfoMain2">
                            ₹ {localStorage.getItem('toalAMtAfterPoints')} 
                          </li>
                        </ul>
                      </div>
                      {/* <hr style={{ width: "85%", marginLeft: "45px" }} />
                      <div className="totalPrizes">
                        <h3 className="totalAmount">TOTAL AMOUNT : </h3>
                        <h3 className="totalAmount2">
                          {" "}
                          INR{" "}
                          {summaryInfo.TOTAL_AMOUNT_AFTER_POINTS < 0
                            ? 0
                            : summaryInfo.TOTAL_AMOUNT_AFTER_POINTS}
                        </h3> */}
                      {/* </div> */}
                     
                      <button
                        onClick={() =>
                          navigate("/paymentPage", {
                            state: { cuponDiscountInput: pointsInput },
                          })
                        }
                        className="totalamtbtnTobuy"
                      >
                        Proceed to buy
                      </button>
                    </div>
                   
                    
                  </div>
                  <div className="earnPoint">
                   
                        <div>
                          
                      <p
                        className="earnPointText"
                        style={{ fontWeight: "500" }}
                      >
                        {/* <AiFillGift className="giftIcons" /> */}
                        
                        Hurray! You will earn {" "}
                        <span style={{ color: "green", fontWeight: "600" }}>
                          {" "}
                          {summaryInfo.POINTS_GET}
                        </span>{" "}
                        <span style={{ color: "green", fontWeight: "600" }}>
                          points
                        </span>{" "}
                        on this order
                      </p>
                      <p className="forEveryPoint">
                      Get 5 points for every Rs.100 spent.{" "}
                       
                      </p>
                      </div>
                    </div>
                </div>
              )}
            </div>
          </div>
        )}

        <Modal
          show={showModal}
          onHide={handleCloseModal}
          className="modalCoupon"
        >
          <Modal.Header closeButton style={{ backgroundColor: "#F1F1F1" }}>
            <Modal.Title>All Coupon Code</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* <Loginotp mobileNo={mobileNo} onVerify={handleVerifyOtp} /> */}
            <Coupon />
          </Modal.Body>
        </Modal>

        <p style={{color:'red', margin:'7px auto', textAlign:'center', fontSize:'15px'}}>We cannot be held responsible for any improper, incorrect, or excessive use or application of products listed or purchased from this site.</p>
        <Footer />
      </div>
          </>)
}


    </div>
       
    </div>
  );
};

export default Cart;

