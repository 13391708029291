import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "../Components/Home";
import Cart from "../Components/Cart/Cart";
import ProductPage from "../Components/ProductPage/ProductPage";
import Brands from "../Components/Brands2/Stp";
import BrandPage from "../Components/BrandPage";
import ProductDescription from "../Components/ProductDescription";
import SrcollToTop from "../Components/SrcollToTop";
import Singin from "../Components/LoginResg/Singin";
import NewAcount from "../Components/LoginResg/NewAcount";
import PaymentPage from "../Components/PaymentPage";
import Loginotp from "../Components/LoginResg/Loginotp";
import Wishlist from "../Components/Wishlist";
import Profile from "../Components/Profile/Profile";
import Armorall from "../Components/Brands2/Armorall";
import AllStpProducts from "../Components/Brands2/AllStpProducts";
import LandD from "../Components/Brands2/LandD";
import BlackDecker from "../Components/Brands2/BlackDecker";
import Aboutus from "../Components/Aboutus/Aboutus";
import Privacy from "../Components/Policies/Privacy";
import Refund from "../Components/Policies/Refund";
import Return from "../Components/Policies/Return";
import Shipping from "../Components/Policies/Shipping";
import Terms from "../Components/Policies/Terms";
import ContactUs from "../Components/Policies/ContactUs";
import Testimonials from "../Components/Policies/Testimonials";
import Myoders from "../Components/Profile/Myoders";
import Confirmation from "../Components/Confirmation/Confirmation";
import ReturnOrder from "../Components/Return/ReturnOrder";
import Feedback from "../Components/Policies/Feedback";
import Cancel from "../Components/Policies/Cancel";
import Verification from "../Pages/Verification";
import Cancellation from "../Pages/Cancellation";
import EditAddress from "../Components/editAddress/EditAddress";
import BulkPage from "../Components/BulkOrder/BulkPage";
import ExtraBond from "../Components/Brands2/ExtraBond";
import XtraBondProducts from "../Components/Brands2/XtraBondProducts";
import Stanley from "../Components/Brands2/Stanley";
import TopSeller from "../Components/TopSeller/TopSeller";
import BdstProducts from "../Components/Brands2/BdstProducts";
import Clearance from "../Components/ClearenceCell/Clearance";
import Loozen from "../Components/Brands2/Loozen";
import Dewalt from "../Components/Brands2/Dewalt";
import GiftCoupon from "../Components/GiftCoupon/GiftCoupon";
import Verification2 from "../Pages/Verification2";
import DealOfMonth from "../Components/Dealofmonth/DealOfMonth";


function PageRoute() {
  const storedData = useSelector((state) => state.profile);
  const isLoggedIn = storedData.userLoginSuccess;
  
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     alert("You are logged in.");
  //   } else {
  //     alert("You are not logged in.");
  //   }
  // }, [isLoggedIn]);

  return (
    <>
      <BrowserRouter>
        <SrcollToTop />
        <Routes>
          <Route path="/" ta element={<Home />} />
          {isLoggedIn ? (
            <>
              <Route path="/cart" element={<Cart />} />
              
            </>
          ) : (
            <Route path="*" element={<Navigate to="/signin" />} />
          )}
          <Route path="/brandPage" element={<BrandPage />} />
          <Route path="/product/:id" element={<ProductDescription />} />
          <Route path="/Shop" element={<ProductPage />} />
          <Route path="/signin" element={<Singin />} />
          <Route path="/Loginotp" element={<Loginotp />} />
          <Route path="/createAcount" element={<NewAcount />} />
          <Route path="/paymentPage" element={<PaymentPage />} />
          <Route path="/wishList" element={<Wishlist />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/STP" element={<Brands />} />
          <Route path="/Armorall" element={<Armorall />} />
          <Route path="/STP/Products" element={<AllStpProducts />} />
          <Route path="/ARMORALL/Products" element={<AllStpProducts />} />
          <Route path="/L&D/Products" element={<AllStpProducts />} />
          <Route path="/L&D" element={<LandD/>}/>
          <Route path="/XTRABOND" element={<ExtraBond/>}/>
          <Route path="/BLACK+DECKER" element={<BlackDecker/>}/>
          <Route path="/LOOZEN" element={<Loozen/>}/>
          <Route path="/Stanley" element={<Stanley/>}/>
          <Route path="/AboutUs" element={<Aboutus/>}/>
          <Route path='/Privacy' element={<Privacy/>}/>
          <Route path='/Refund' element={<Refund/>}/>
          <Route path='/Shipping' element={<Shipping/>}/>
          <Route path="/Return" element={<Return/>}/>
          <Route path="/Terms" element={<Terms/>} />
          <Route path="/ContactUs" element={<ContactUs/>}/>      
          <Route path="/Testimonials" element={<Testimonials/>}/>
          <Route path="/Feedback" element={<Feedback />}/>       
          <Route path="/Myorder" element={<Myoders/>}/>          
          <Route path='/Confirmation/:id' element={<Confirmation/>}/>
          <Route path="/ReturnOrder" element={<ReturnOrder/>}/>  
          <Route path="/cancel" element={<Cancel />}/>    
          <Route path="/verification/:id" element={<Verification />}/>    
          <Route path="/verification2/:id" element={<Verification2 />}/>    
          <Route path="/Cancellation" element={<Cancellation/>}/>    
          <Route path="/EditAddress/:id" element={<EditAddress/>}/>
          <Route path="/valuePacks" element={<BulkPage/>}/>
          <Route path="/XTRABOND/Products" element={<XtraBondProducts/>}/>
          <Route path="/BLACK+DECKER/Products" element={<BdstProducts/>}/>
          <Route path="/DEWALT/Products" element={<BdstProducts/>}/>
          <Route path="/LOOZEN/Products" element={<BdstProducts/>}/>
          <Route path="/STANLEY/Products" element={<BdstProducts/>}/>
          <Route path='/topsellers' element={<TopSeller/>}/>
          <Route path='/clearance' element={<Clearance/>}/>
          <Route path="/DEWALT" element={<Dewalt/>}/>
          <Route path="/Giftcoupon" element={<GiftCoupon/>}/>
          <Route path="/hotdeals" element={<DealOfMonth/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PageRoute;


