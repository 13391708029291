import React, { useState } from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'

export default function Shipping() {
  const [cartValues, setCartValues] = useState(false);
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
    <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
    <div className='mainPrivacy'>
      <h1 className='creativeCarCare'> Shipping Policy</h1>
      <div className='privacyPolicy'>
      {/* Shipping Policy<br/> */}
{/* All the orders are shipped within 24-48 hours. Currently, we are shipping thru Bluedart, Gati, FedEx, IndiaPost & many more.<br/>
Please note that all orders are typically shipped within 1 - 2 working days and take additional 4-7 working days of shipment time. For some destinations of NORTHEAST, the<br/> shipment time may be longer(up to 15 days).<br/>
We will notify you when your order is shipped. <br/>
Orders once processed and shipped can be tracked using the consignment/tracking number. A mail is sent to the customer after the order is shipped with this tracking number.<br/>
In order to track the order, go to the website of the service provider mentioned in your mail.<br/>
On the "Track Status" Panel enter the tracking number as mentioned in your shipment mail to view your order status or call us on +91-9920290909 or email us<br/> at: info@creativecarcare.in<br/> */}
All the orders are shipped within 24-48 hours. Currently, we are shipping through Amazon Shipping, Delhivery, DTDC, Ecomm Express, Kerry Indev Services, & many more. Please allow the couriers 5-7 working days. For certain pincodes in the Northeast etc, the courier will require 7-14 working days due to certain state policies. There may be a slight lag in delivery time, during festivals, bank holidays. 
We will notify you when your order is shipped. In the unusual event that any item you have ordered is physically not available, or not in a condition to be shipped to you, we shall inform you accordingly, and cancel the item(s) from your order. In such cases, your credit card will not be charged.
<br></br>
<br></br>
<h3 className='cookies'>How can I track my order?</h3><br/>
<span style={{width:'100%'}}>Orders once processed and shipped can be tracked using the consignment/tracking number, that will be sent to the customer via WhatsApp and email.

In order to track the order, go to the website of the service provider mentioned in your mail or you can check your order status by logging in to our website and clicking on Track your Order.

When you search on the website of the courier service, On the "Track Status" Panel enter the tracking number as mentioned in your shipment mail, to view your order status or call us at +91-9930290909 between 10:00-18:30 or email us at: customercare@creativecarcare.in</span><br/>
{/* <h3 className='cookies'>Cash on Delivery (CoD):</h3><br/>
<span style={{width:'100%'}}>Cash on Delivery CoD will be available on orders below Rs 2000 .</span><br/>
<h3 className='cookies'>NOTE:</h3><br/>
All orders are placed at creativecarecare.in. are dispatched via reputed couriers, if you find your order tampered or damaged please do not accept and immediately call us to resolve this issue at +919920290909 or email us<br/> at info@creativecarcare.in.<br/> 
All liquid products are shipped by Surface due to the restriction imposed by Bureau Civil Aviation Security (BCAS) and/or the Regulatory Requirements of the State on<br/>  the courier service providers.<br/>  */}
<h3 className='cookies'>Partial Orders:</h3><br/>
<span style={{width:'100%'}}>If an item goes on partial order (i.e. if you order 2 products and we only have 1 in stock), we will intimate you, to explain the situation before shipping the product.

We may ship part of your order that is in stock after getting the customer’s approval via email or whatsapp. When we have replenished our stock and your pending order becomes available, we will ship you the rest of your order.

You will not be charged any additional shipping and handling charges for the second shipment. We also provide you the option either to cancel the order of the item which is not in stock OR you can buy another product in place of your previously ordered item (which is not in stock).

In case the buyer chooses to cancel the item which is not in stock then we will refund you back the relevant amountaccount within 7 business days.</span><br/>

<h3 className='cookies'>Shipping charges:</h3><br/>
<span style={{width:'100%'}}>Free shipping is available on orders above Rs.1000.00 (One thousand rupees) only.

Cash on Delivery (COD):

We, at creativecarcare.in, do not support the “Cash On Deliver” facility.</span><br/>
<h3 className='cookies'>NOTE:</h3><br/>
All orders placed at creativecarcare.in, are dispatched through reputed courier/ logistic services. If you find your order tampered or damaged or leaked, please do not accept your order. For immediate resolution of the issue, please call us on our customer care helpline: +91 9930290909 between email us at: customercare@creativecarcare.in

All liquid & aerosol products are shipped by surface transport only, due to the restriction imposed by the Bureau of Civil Aviation Security (BCAS) and/or the State's Regulatory Requirements on the courier service providers carrying liquid or hazmat material.<br/> 

      </div>
    </div>
    <Footer/>
    </>
  )
}
