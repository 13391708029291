import React, { useEffect, useState } from 'react'
import NavMenu from './Navbar'
import Discription from './Discription'
import Footer from './Footer'
import { useLocation, useParams } from 'react-router-dom';



function ProductDescription() {
  const { id,pack } = useParams();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      window.history.scrollRestoration = "manual";
    }, 1000);
  }, []);

  // const location = useLocation();

  // const product_id = location.state.productId
  return (
    <div>
        
        <Discription pId={id }  />
        <Footer/>
    </div>
  )
}

export default ProductDescription