import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import './login.css'
import { getUserOtp,getuserdetail,getresendotp } from '../../api/api';
import axios from 'axios';
import Singin from './Singin';
import { useNavigate } from 'react-router-dom';
import { storeData, userDetail,  } from '../../redux/ActionCreator';
import { useSelector, useDispatch } from 'react-redux';

export default function LoginPage({mobileNo}) {

   // console.log(mobileNo);

   const [otp, setOtp] = useState('');
   const [status, setStatus]=useState(false);
   const [block, setBlock] = useState()
   const navigate = useNavigate();
   

   const dispatch = useDispatch();
   const formHandler =()=>{

     if (!otp) {
       alert('Please enter OTP');
       return;
     }

     const userData = {
       otp: otp,
       mobile: mobileNo
     };
     
     console.log(userData);
     
     const url = getUserOtp();
     axios
       .post(url, userData, {
         headers: {
           "Content-Type": "multipart/form-data"
         }
       })
       .then((response) => {
         console.log('Response in resg:', response.data.data.status);
         setStatus(true);
         if (response.data.status) {
           if(response.data.data.status==1){
             getUserProfile(response.data.data.id);
           }else{
            alert ('Your account has been blocked')
           }
         } else {
           alert('Invalid OTP'); // Display alert for invalid OTP
         }
       })
       .catch((error) => {
         console.error('Error in register:', error);
         setStatus(false);
         console.log("error is coming");
         console.log(otp);
         alert('Invalid OTP'); // Display alert for invalid OTP
       });
     }


  const getUserProfile = (id) => {
   const url = getuserdetail(id);
     axios.get(url).then((resp) => {
       localStorage.clear()
       // console.log('Resp ydyrdkd :', resp.data);
       dispatch(userDetail(resp.data.data));
       // localStorage.setItem('userDetail', resp.data.data);
       // setTimeout(() => {   
       //   localStorage.setItem("UserId", resp.data.data.user_id);
       //   localStorage.setItem("name", resp.data.data.name);
       //   localStorage.setItem("mobile", resp.data.data.mobile);
       //   localStorage.setItem("email",resp.data.data.email);
       //   localStorage.setItem("created_at", resp.data.data.created_at);
       //   localStorage.setItem("session_id",resp.data.data.session_id);
       // }, 2000 );


       console.log(' -- -- -- ---',localStorage.getItem("UserId"));
      //  navigate('/'); 
      window.history.back();
 
   })
   .catch((error) => {
     console.log('Error:', error);
   });
 };

 const resendOtp =()=>{
   const url = getresendotp()
   console.log(url);
   const userData = {
     mobile: mobileNo
   }
   console.log(userData);
   axios .post(url, userData,{
     headers: {
         "Content-Type": "multipart/form-data",
       },
 })
   .then((resp)=>{
     console.log(' - - - resp  == ',resp.data);
   })
   .catch((error)=>{
     console.log(' - - error',error);
   })
 }

 const containerStyle = {
   display: 'flex',
   justifyContent: 'center',
 };

   return (
       <>
       <div style={{width:'100%', textAlign:'center',}}>
       <div className="otp-container" >

         <h3 className='otpVerification'>OTP Verification</h3>
         <div style={containerStyle}>
       <OtpInput
       value={otp}
       onChange={setOtp}
       numInputs={6}
       // renderSeparator={<span>-</span>}
       renderInput={(props) => <input {...props} style={{ width: '35px' }}/>}
       inputStyle="otp-input"
     />  
     </div>
      <div className='otpbtn'>
     <button className="verification-button" onClick={formHandler} >
       
         Verify
       </button>
       <button className="verification-button2" onClick={resendOtp} >
       
       Resend OTP
     </button>
       </div>
       </div>
       </div>
     </>
   );

   }