import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import NavMenu from "../Navbar";
import Image from "../../assets/img/brands2/Group 12 (5).png";
import Image2 from "../../assets/img/brands2/Group 13 (4).png";
import Image3 from "../../assets/img/brands2/Group 14 (4).png";
import Image4 from "../../assets/img/brands2/Group 27 (3).png";
import Image5 from "../../assets/img/brands2/Group 40.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../Brands2/L&D.css";
import "../Brands2/extrabond.css";
import lastBanner from '../../assets/img/brands2/banner2 11.jpg'
import { ColorRing, TailSpin } from "react-loader-spinner";
import lastBanner2 from '../../assets/img/brands2/banner2---responsive (2).jpg'


function ExtraBond() {
  const navigate = useNavigate();
  const [StpData, setStpData] = useState([]);
  const location = useLocation();
  // const { brandid, brandName } = location?.state;
  const { id } = useParams();
  const [StpCategory, setStpCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [ProductId, setProductId] = useState("");
  const [clicked, setClicked] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [cartValues, setCartValues] = useState(false);
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log("Modal is closed, running effect in CartPage...");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      
      setPageLoading(false);
    }, 500);
  }, []);

  
  const stpProductWrld = () => {
    console.log("---strproductWorld", id);
    // localStorage.clear();
    localStorage.removeItem('selectedItems');
    window.localStorage.removeItem("CategoryCheckdDataLocal");
    window.localStorage.removeItem('packCheckedDataLocal');
    window.localStorage.removeItem('categoryValue2');
    window.localStorage.removeItem('')
    localStorage.removeItem("selectedItems2");
    localStorage.removeItem("selectedItems3");
    localStorage.removeItem("selectedItems4");
    localStorage.removeItem("brandsCheckdDataLocal");
    localStorage.removeItem("ddvalue");
    localStorage.removeItem("CategoryCheckdDataLocal");
    localStorage.removeItem("bcvalue");
    localStorage.removeItem("packCheckedDataLocal");
    localStorage.removeItem("packsvalue");
    localStorage.removeItem("resbrandsCheckdDataLocal");
    localStorage.removeItem("resddvalue");
    localStorage.removeItem("resCategoryCheckdDataLocal");
    localStorage.removeItem("resbcvalue");
    localStorage.removeItem("respackCheckedDataLocal");
    localStorage.removeItem("respacksvalue");
    navigate("/XTRABOND/Products", {
      state: {
        brandID: 30,
      },
    });
  };


  // const stpProductWrld = () => {
  //   console.log("---strproductWorld", id);
  //   localStorage.clear();
  //   navigate("/Shop");
  // };

   
  return (
    <>
      <NavMenu
        onCloseModal={handleModalClose}
        setCartBoolean={setCartValues}
        cartBoolean={cartValues}
      />
      {pageLoading ? (
        <>
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "black",
            }}
          >
            <TailSpin  color="#ffd11b" />
          </div>
        </>
      ) : (
        <>
          <div className="rectangleImage11">
          </div>
          <div className="extraCotent">
             <div className="extraCotentText">
              <p>
                Xtrabond (Blue) is the only 100% waterproof body
                covers for cars in the world. They are designed in the
                USA, the fabric for the cover is manufactured in Taiwan and the
                cover is made in China under very high standards of quality.
              </p>
              <p>
                Its ultrasonically welded seams prevent against water leaks and
                exclusively patented vents ensure air circulation under the car
                cover allowing the cover to breathe. It comes with side pockets
                for the mirrors.
              </p>
              <p>
                The covers have under belly belt, elastic on the front and rear
                 therefore allowing the cover to stay secure.
              </p>
             </div>
             <div className="extraImageMain">
              <div className="extraImageSubMain">
                <img className="extraImageMain2" src={Image} alt="extrabondImage"/>
                <img className="extraImageMain2" src={Image2} alt="extrabondImage"/>
                <img className="extraImageMain2" src={Image3} alt="extrabondImage"/>
                <img className="extraImageMain2" src={Image4} alt="extrabondImage"/>
              </div>
              
             </div>
             <div className="blueColor2"></div>
             <div className="lastImage">
                <img style={{width:'100%', position:'relative'}} src={Image5} alt="extrabond"/>
             </div>
             <div className="lastImageText1">
                <p className="lastImageText">XtraBond Car Body Cover</p>
             </div>
             <div className="shopNow">
                <button onClick={stpProductWrld} className="shopNowBtn">Shop now</button>
             </div>
             <div className="lastBannerExtra">
              <img className="lastBannerExtraImg" src={lastBanner} alt="extrabondBanner"/>
              <img  className="lastBannerExtraImg2" src={lastBanner2} alt="extrabondBanner"/>
             </div>
          </div>
          <Footer />
        </>
      )}
   
    </>
  );
}

export default ExtraBond;
