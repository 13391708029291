import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import data from "../Json/Homebg";
import { useNavigate } from "react-router-dom";
import "../assets/css/brands.css";
import { getBrands, getSpecialCategoryApi } from "../api/api";
import axios from "axios";
import LoadingSpinner from "./loader/LoadingSpinner";

const Brands = (props) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [brandsData, setbrandsData] = useState([]);
  const [brandLoding, setbrandLoding] = useState(false);
  const [isBrandEmpty, setisBrandEmpty] = useState(false);

  useEffect(() => {
    getBradnsData_fun();
  }, []);

  const getBradnsData_fun = async () => {
    setbrandLoding(true);
    const url = getBrands();
    // console.log("brands url --> ", url);
    axios
      .get(url)
      .then((resp) => {
        console.log("resp in brands --> ", resp.data.data);
        if (resp.data.data == []) {
          setisBrandEmpty(true);
          setbrandLoding(false);

          // setbrandLoding(resp.data.data[0].hot_products)
        } else {
          setbrandsData(resp.data.data);
          setbrandLoding(false);
          setisBrandEmpty(false);
        }
      })
      .catch((err) => {
        console.log("err in brands --> ", err);
        setbrandLoding(false);
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const navigate = useNavigate();

  const sendId = (id, brandName) => {
    console.log("---id, name", brandName);
    switch (brandName) {
      case "STP":
        return navigate(`/Stp`, {
          state: {
            brandid: id,
            brandName: brandName,
          },
        });
      case "ARMORALL":
        return navigate("/Armorall", {
          state: {
            brandid: id,
            brandName: brandName,
          },
        });
      case "L&D":
        return navigate("/L&D", {
          state: {
            brandid: id,
            brandName: brandName,
          },
        });
      case "BLACK+DECKER":
        return navigate("/BLACK+DECKER", {
          state: {
            brandid: id,
            brandName: brandName,
          },
        });
      case "XTRABOND":
        return navigate("/XTRABOND", {
          state: {
            brandid: id,
            brandName: brandName,
          },
        });
      case "STANLEY":
        return navigate("/STANLEY", {
          state: {
            brandid: id,
            brandName: brandName,
          },
        });
      case "DEWALT":
        return navigate("/DEWALT", {
          state: {
            brandid: id,
            brandName: brandName,
          },
        });
      case "LOOZEN":
        return navigate("/LOOZEN", {
          state: {
            brandid: id,
            brandName: brandName,
          },
        });
      default:
        return navigate("/default", {
          state: {
            brandid: id,
            brandName: brandName,
          },
        });
    }
  };
  return (
    <div style={{}} className="container-fluid">
      <div className="row ">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          arrows={false}
          showDots={false}
          className="mt-0 p-0 mainBrandPoster "
        >
          {brandLoding ? (
            <LoadingSpinner />
          ) : isBrandEmpty ? (
            <h2>No brands found</h2>
          ) : (
            brandsData.map((item, index) => {

              const isHovered = index === hoveredIndex;
              if(hoveredIndex==null){

                var grayscale = 0 ;
              }else{

                var grayscale = isHovered ? 0 : 1;
              }
              return (
                <div
                  key={index}
                  className="brandBanners"
                  style={{
                    width: "100%",
                    backgroundImage: `url(${item.img})`,
                    backgroundSize: "100% 100%",
                    overflow: "hidden",
                    opacity: "0.9",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
                >
                  <img
                    onClick={() => {
                      sendId(item.id, item.brand_name);
                    }}
                    src={item.image_url}
                    alt="brandpage"
                    style={{ display: "block", margin: "auto", width: "100%",filter: `grayscale(${grayscale})`, cursor:'pointer' }}
                  />
                </div>
              );
            })
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default Brands;
