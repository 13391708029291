import React, { useState } from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'
import ImagesConfirm from '../../assets/img/Home/verify.png'
import { AiFillGift } from "react-icons/ai";
export default function Cancel() {
  const [cartValues, setCartValues] = useState(false);
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
    <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
    <div className='mainPrivacy'>
      <h1 className='creativeCarCare'>Cancellation Policy</h1>
      <div className='privacyPolicy'>
        
You may cancel your order during any SALE within 6 hours of placing the order or prior to shipment of the order. However, if you cancel your order after 6 hrs of placing the order or after dispatch, a 15% restocking fee or 2% payment gateway charges may apply in special cases. These charges will be subtracted from the credit amount issued to your credit card. for more details please call on +91-9930290909 between 10 am – 7 pm, or you could email us at: customercare@creativecarcare.in.

Please note: We have no control over 2% payment gateway charges as these are directly charged by the payment gateway service providers.


      </div>
    </div>
      {/* <div className='orderStatusDetails'>
        <div className='displayStatusFlex'>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
         
          <img className='imageConfirm' src={ImagesConfirm} />
        </div>
        <h2 style={{ textAlign: 'center' }} className='myOrdersh2'> Order Confirmed</h2>
        </div>

        <div className='row conRow'>
          <div className='col-lg-6 col-12 addressContent'>


            <div className="addressConfirmation">
              {" "}
              <h3
                style={{
                  marginTop: "15px",
                  fontFamily: "Oswald",
                  marginBottom: "10px",
                  fontSize: "25px",
                }}
              >
              </h3>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: 10,
                  width: "100%",
                  padding: 20,
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="addressDetails2"></p>
                  <p className="addressHome">
                  </p>
                </span>

                <p className="addressDetails">
               
                </p>
                <p className="addressDetails">
                  {" "}
                  <strong>Mob no:</strong> 
                </p>
              </div>
            </div>

            
            
             
                  <div className='orderDetails'>
                    <div className='orderDetails2'>
                      <div style={{ display: 'block', width: '70%' }}>
                        <p className='orderStatus'><strong>pending </strong> </p>
                        <p className='orderID'>ORDER ID: </p>
                        <p className='orderID2'><strong></strong></p>
                        <p className='orderID3'><strong>INR</strong></p>
                        <p className='quantity1'> quantity: </p>
                      </div>

                      <div className='orderImg'>
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
              
          </div>

          <div className='col-lg-6 col-12 oderOfSumm2'>
            <div className="earnPoint2">
              <p className="earnPointText22">
                <AiFillGift className="giftIcons" />
                You have won{" "}
                <span style={{ color: "red", fontWeight: "600" }}>
                  {" "}
                 
                </span>{" "}
                points on this order
              </p>
            </div>
            <div className='paymentInfos'>
              <h2 className='priceSummary'>Payment Information</h2>
              <p className='payTmDetails'>payment Id : </p>
              <p className='payTmDetails'> payment amount : INR</p>
              <p className='payTmDetails'>Payment method : </p>
            </div>
            <div className="couponcalc3">
              <h3 className="priceSummary">
                Price Summary
              </h3>
              <div className="row">
                <div className="col-6  priceDistribution2">
                  <ul>
                    <li
                    className="allCartInfoConfirm"
                    >
                      Total MRP
                    </li>
                    
                    <li
                     className="allCartInfoConfirm"
                    >
                      Point discount
                    </li>
                    <li
                     className="allCartInfoConfirm"
                    >
                      Delivery Charges
                    </li>
                    <li
                    className="allCartInfoMainConfirm"
                    >
                      TOTAL AMOUNT :
                    </li>
                  </ul>
                </div>
                <div className="col-6 numericalPrize2">
                  <ul>
                    <li  className="allCartInfo2">
                      INR 0
                    </li>
                    
                        <li  className="allCartInfo2">
                        INR 0
                      </li>
                     
                 
                    <li  className="allCartInfo2">
                      INR 0.00
                    </li>
                    <li  className="allCartInfoMain2">
                    INR 0
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
          <div className='invoiceButton2'>
            <button className='buttonInvoice2Confirm'>Continue Shopping</button>
            <button className='buttonInvoice3'>View Order</button>

          </div>

        </div>
      </div> */}

    <Footer/>
    </>
  )
}
