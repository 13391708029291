import React, { useEffect, useState } from "react";
import {
  getCartByItsId,
  getIncrement,
  getcartbyId,
  getdecrement,
  getdeleteId,
} from "../api/api";
import axios from "axios";
import "../assets/style.css";
import { FaTimes } from "react-icons/fa";
import Images from "../assets/img/brandpage/Feat1.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactStars from "react-stars";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import NavMenu from "./Navbar";

export default function Cartmodal({ cartsBoolean, setCartBoolean }) {
  const navigate = useNavigate();
  const storedData = useSelector((state) => state.profile);
  const userData = storedData.userId;
  const [cartData, setCartData] = useState([]);
  const [count, setCount] = useState(0);
  const [cartTotalPrice, setcartTotalPrice] = useState(0);
  const [noproduct, setNoProduct] = useState(false);
  const [increaseQProductId, setincreaseQProductId] = useState("");
  const [pointsInput, setpointsInput] = useState();
  const [cartValues, setCartValues] = useState(false);


  useEffect(() => {
    if (storedData.userLoginSuccess) {
      getCartData(storedData.userId);
    } else {
      getCartData(null);
    }
  }, []);

  const getCartData = () => {
    const url = getcartbyId();
    // console.log('= = = url = = =', url);
    var body = {
      discount: pointsInput,
      user_id: storedData.userId,
    };

    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log(' = = =cartdata = = =', resp.data);
        if (!resp.data.status) {
          setNoProduct(true);
           setcartTotalPrice(0);
        } else {
          setNoProduct(false);
          setCartData(resp.data.data);
          setcartTotalPrice(resp.data.cart_price);
        }
      })
      .catch((error) => {
        console.log(" - - - error - - -", error);
        setNoProduct(true);
        setcartTotalPrice(0);
        // setcartTotalPrice(0);
      });
  };

  const deleteCard = (id) => {
    let data = new FormData();
    data.append("_method", "DELETE");
    const url = getCartByItsId(id);
    // console.log("--->url", url);
    axios
      .post(url, data)
      .then((resp) => {
        // console.log(" = = = deleted card = = ", resp.data);
        if (storedData.userLoginSuccess) {
          getCartData(storedData.userId);
         
        } else {
          getCartData(null);
        }
        
      })
      .catch((error) => {
        console.log("--error--", error);
      });
  };

  const incrementCart = (cartId, productId) => {
    const url = getIncrement();

    // console.log('- - - url - - -',url);
    const userData = {
      cart_id: cartId,
      product_id: productId,
    };
    // console.log("userData ---", url, userData);

    axios
      .post(url, userData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log("product increment - -  -", resp.data);
        if (resp.data.status) {
          if (storedData.userLoginSuccess) {
            getCartData(storedData.userId);
          } else {
            getCartData(null);
          }
        } else {
          console.log("someyhing went wrong");
        }
      })
      .catch((error) => {
        console.log("- - err", error);
      });
  };

  const decrementCart = (cartId, productId) => {
    const url = getdecrement();

    // console.log('- - - url - - -',url);
    const userData = {
      cart_id: cartId,
      product_id: productId,
    };
    // console.log("userData ---", url, userData);

    axios
      .post(url, userData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log("product increment - -  -", resp.data);
        if (resp.data.status) {
          if (storedData.userLoginSuccess) {
            getCartData(storedData.userId);
          } else {
            getCartData(null);
          }
        } else {
          console.log("someyhing went wrong");
        }
      })
      .catch((error) => {
        console.log("- - err", error);
      });
  };

  const cartsWorld=()=>{
    setCartValues(true)
  }

  return (
    <>
    <div style={{display:'none'}}> 
    <NavMenu setCartBoolean={setCartValues} cartBoolean={cartValues}/>
    </div>
      <div>
          <div className='modalPrice' style={{"display":"flex","alignItems":"center","justifyContent":"space-between","gap":"150px","marginBottom":'10px'}}>
              <div>
                <span style={{"fontFamily":"'Oswald'","fontStyle":"normal","fontWeight":"500","fontSize":"18px","lineHeight":"20px","color":"#000"}}>In Cart {cartData.length} items</span>
              </div>
              <div style={{ alignItems: "center", display:"flex" }}>
                <i class="fa-solid fa-bag-shopping" style={{ fontSize: '18px', color: '#17191d',marginRight: '5px' }}></i>
                <span style={{"fontFamily":"'Oswald'","fontStyle":"normal","fontWeight":"500","fontSize":"18px","color":"#E51515"}}>₹ {cartTotalPrice}</span>
              </div>
          </div>
          
        <hr />
      </div>
      {noproduct ? (
        <p className="NouserFound">No product added in cart</p>
      ) : (
        cartData.map((item) => {
          const titleWords = item.title.split(" ").slice(0, 7).join(" ");
          const truncatedTitle =
            item.title.split(" ").length > 7 ? `${titleWords}...` : titleWords;

          return (
         

            <div className="cartScroll" key={item.cart_id}>
              <div style={{ display: "flex", flex: 1 }}>
                <img
                  src={item.image_url}
                  style={{ flex: 0.4, objectFit: "contain", height: "118px" }}
                  alt={truncatedTitle}
                />
                <div style={{ flex: 1.3, paddingLeft: "10px", width: "100%" }}>
                  <strong>{truncatedTitle}</strong>
                  <div>
                    <span>
                      <ReactStars
                        className="fa-regular"
                        edit={false}
                        value={item.rating}
                        count={5}
                        size={22}
                      />
                    </span>

                    <br />
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>₹ {item.starting_price}</span>
                    <span style={{ textDecoration: 'line-through', marginLeft: '10px', fontSize: '12px' }}>
                      MRP ₹{item.mrp}
                    </span>
                  </div>
                  <div>
                    <span>
                      <button
                        onClick={() =>
                          decrementCart(item.cart_id, item.product_id)
                        }
                        className="cartvalue"
                      >
                        -
                      </button>
                      <span className="cartcount">{item.quantity}</span>
                      <button
                        onClick={() =>
                          incrementCart(item.cart_id, item.product_id)
                        }
                        className="cartvalue"
                      >
                        +
                      </button>
                    </span>
                  </div>
                  <span className="spanningcart"></span>
                </div>
                <div style={{ flex: 0.1, paddingLeft: "10px" }}>
                  <FaTimes
                  style={{cursor:'pointer'}}
                    onClick={() => {
                      deleteCard(item.cart_id);
                      cartsWorld()
                      
                      // console.log("--cartID", item.cart_id);
                      
                    }}
                  />
                </div>
              </div>
              <hr />
            </div>
          );
        })
      )}
    </>
  );
}
