// import React from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'
import React, { useEffect, useState } from 'react'

// import { getMyOrder, getTheOrder, getUserAddressbyId } from '../../api/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Confirmation/Confirmation.css'
import { downloadInvoice, getCartByItsId, getMyOrder, getcartbyId, saveTheReview } from '../../api/api';
import { AiFillGift } from "react-icons/ai";
import toast, { Toaster } from 'react-hot-toast';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


export default function Myoders() {
  const location = useLocation()
  const navigate = useNavigate();
    const storedData = useSelector((state) => state.profile);
    const { orderData } = location?.state;
    const [address, setaddress]=useState('')
    const [order, setOrder] = useState([]);
    const [name, setname] = useState('')
    const [transaction, settransaction] = useState('')
    const [details, setDetails] = useState([]);
    const [rewardPoint, setrewardPoint]= useState(0)
    const [review, setreview] = useState()
  const [reviews, setreviews] = useState('')
  const [trackingStatus, setTrackingStatus] = useState(false)
  const [review2, setreview2] = useState(false)
  const [giftprice, setGiftPrice] = useState(0)
  const [couponDisc, setCouponDisc] = useState(0)
  const [deliveryStatus, setDelhiveryStatus] = useState()
    
  useEffect(() => {
    if (storedData.userLoginSuccess) {
      // getUserAddress_func(storedData.userId);
      getOrder(orderData)
      console.log('--orderid',orderData);
    } 
    window.scrollTo(0, 0);
  }, [orderData]);



      const getOrder =(id)=>{
        const url = getMyOrder(id)
      console.log(url);
      
        axios.get(url)
        .then((resp)=>{
          console.log('..--..--..', resp.data.data);
          setDelhiveryStatus(resp.data.data.delivery_status);
          const orderDetails = resp.data.data.details;
          setDetails(orderDetails); // Set the order details in the state
          // localStorage.setItem('orderDetails', JSON.stringify(orderDetails));
          console.log('sate info ==> ', details );
          setaddress(resp.data.data)
          setname(resp.data.data.user)
          settransaction(resp.data.data.transaction)
          setrewardPoint( resp.data.data.reward_points)
          setGiftPrice(resp.data.data.gift_code_price)
          setCouponDisc(resp.data.data.coupon_discount)
        })
        .catch((error)=>{
          console.log('errerrerrerr', error);
        })
      
      }

      const justdownloadInvoice = (orderData)=>{
        const url = downloadInvoice(orderData)
//  console.log(url);
window.location.href = url
      }

      
      const postTheReview = ()=>{

        const url = saveTheReview();
        const body={
          user_id:storedData.userId,
          has_login:'yes',
          product_id:review,
          comments:reviews
    
        }
console.log('--body review',body, url);
        axios.post(url, body,{
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
.then((resp)=>{
  console.log('hello',resp.data);
  // alert(resp.data.message)
  if (resp.data.status) {
    toast.success('Review  submitted', { icon : '❤️' })
    getOrder(orderData)
  } else {
    toast.error(resp.data.message, { icon : '⚠️' })
  }
  
})
.catch((err)=>{
  console.log(err);
})
      }


      const saveTheReviews=()=>{
        if(reviews!==''){
          postTheReview()
        }else{
          alert('Please write the review')
        }
        setreview(-1)
        setreview2(false)
      }

      // const reviewsSave=(e)=>{
      //   setreviews(e.target.value)
      // }

      const steps = ['Order Confirmed', 'In Process', 'Order Shipped', 'Delivered'];

      const HorizontalLinearAlternativeLabelStepper = () => {
        console.log(deliveryStatus);
      
        const activeStep = steps.indexOf(deliveryStatus);
      
        return (
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  {/* <p>{address?.delivery_date}</p> */}
                </Step>
              ))}
            </Stepper>
          </Box>
        );
      };
      

  return (
    <>
<NavMenu/>
<Toaster />
  <div className='orderStatusDetails'>
  <h2 style={{textAlign:'center'}} className='myOrdersh2'> Order Details</h2>
     <div className='row'>
      <div className='col-lg-6 col-12 addressContent'>
      
      
      <div className="addressConfirmation">
                {" "}
                <h3
                  style={{
                    marginTop: "15px",
                    fontFamily: "Oswald",
                    marginBottom: "10px",
                    fontSize: "25px",
                  }}
                >
                  {/* <strong>Delivery Address</strong>{" "} */}
                </h3>
                <div
                  // onClick={() => handleAddress(item.id)}
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 10,
                    width: "100%",
                    padding: 20,
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="addressDetails2">{name.name}</p>
                    <p className="addressHome">
                      {address.type_of_address}
                    </p>
                  </span>
                  
                  <p className="addressDetails">
                    {address.address} {address.landmark} {address.city}, {address?.territory}, {address?.country}{" "}
                    {address.pincode}
                  </p>
                  <p className="addressDetails">
                    {" "}
                    <strong>Mob no:</strong> {name.mobile}
                  </p>
                  <p className="addressDetails">
                    {" "}
                    <strong>Landmark:</strong> {address.landmark}
                  </p>
                </div>
                </div>
                
                {details.map((item,Index)=>{
                  console.log(item);

                  const handleReview=(id)=>{
                    console.log(id);
                    setreview(id)
                    setreview2(true)
                  }
                  return(
                    <>          
                    <div className='orderDetails'>
  <div className='orderDetails2' key={item.product_id}>
    
  <div style={{display:'block', width:'70%'}}>
  <p className='orderStatus'><strong>pending </strong> </p>
  {/* <p className='order-status'>Item will be delivered on: 20th Mar 2023</p> */}
  <p className='orderID'>ORDER ID: #{item.order_id} </p>
  {/* <p className='orderIDs'>Transaction ID:{transaction.MID} </p> */}
  <p className='orderID2'><strong>{item.title}</strong></p>
  <span style={{display:'flex'}}>
  <p className='orderID3'><strong>₹ {item.starting_price}</strong></p>
  <p className='orderMrp'><strong>MRP <strike>₹{item.mrp}</strike></strong></p>
  </span>
  <p className='quantity1'>Quantity :{item.quantity}</p>
  </div>

<div className='orderImg'>
  <img className='image-order2' src={item.image_url} alt={item.title}/>

  <div style={{display:'flex', justifyContent:'center'}}>

{!review2 && 
<button className='buttonReview' onClick={()=>handleReview(item.parent_id)}>Add Review</button>
                }
  </div>
  
</div>

</div>
<div>
{review === item.parent_id && 
 <div style={{display:'flex', flexDirection:'column'}}>
 <textarea id="reviewText" placeholder='Enter the review' name="reviewText" rows="4" cols="50" onChange={(e)=>{setreviews(e.target.value)}} style={{width:'80%', margin:' 11px auto'}} required></textarea>
 <button onClick={saveTheReviews} style={{width:'20%', margin:'10px auto', color:'white', backgroundColor:'red', borderRadius:'5px' ,border:'none', padding:'3px 8px'}}>submit</button>
{/*<button onClick={saveTheReviews} style={{width:'20%', margin:'10px auto', color:'white', backgroundColor:'red', borderRadius:'5px' ,border:'none', padding:'3px 8px'}}>submit</button>*/}
</div>}
</div>
{/* <div style={{display:'flex', justifyContent:"space-around"}}>
  <button className='trackBtn2'>TRACK</button>
  <button className='cancelRBtn2'>CANCEL</button>
  </div> */}
  </div>
</>
                  )
})  }
               {/* </div> */}
         </div>

    <div className='col-lg-6 col-12 oderOfSumm2'>
    <div className="earnPoint2">
                  <p className="earnPointText22">
                    <AiFillGift className="giftIcons" />
                    You have won{" "}
                    <span style={{ color: "red", fontWeight: "600" }}>
                      {" "}
                      {rewardPoint}
                    </span>{" "}
                    points on this order
                  </p>
                </div>
        {
        transaction ? (<>
      <div className='paymentInfos'>
        <h2 className='priceSummary'>Payment Information</h2>
        <p className='payTmDetails'>payment Id : #{transaction?.TXNID}</p>
        <p className='payTmDetails'> payment amount : ₹ {transaction?.TXNAMOUNT}</p>
        {/* <p className='payTmDetails'>Payment method : {transaction.PAYMENTMODE}</p> */}
        <p className='payTmDetails'>Payment method : {address?.payment_mode}</p>

      </div>
          </>):(<><div className='paymentInfos'>
        <h2 className='priceSummary'>Payment Information</h2>
        {/* <p className='payTmDetails'>Payment method : {transaction.PAYMENTMODE}</p> */}
        <p className='payTmDetails'>Payment method : {address?.payment_mode}</p>

      </div></>)
        }
      {/* <span style={{marginTop:'5px'}}>You have won {rewardPoint} points</span> */}
    <div className="couponcalc3">
          <h3 className="priceSummary">
            Price Summary
            {/* <span
              style={{ fontSize: "12px", textTransform: "capitalize" }}
            >
              : ( {cartData.length} items)
            </span> */}
          </h3>
          <div className="row">
            <div className="col-6  priceDistribution2">
              <ul>
                <li
                  className="allCartInfo"
                >
                  Total MRP
                </li>
                {/* <li
                  style={{
                    marginBottom: "8px",
                    marginLeft: "25px",
                    fontFamily: "Rubik",
                  }}
                >
                  Discount on MRP
                </li> */}
                {/* <li  style={{marginBottom:'8px', marginLeft:'25px', fontFamily:'Rubik'}}>Coupon Discount</li> */}
                <li
               className="allCartInfo"
                >
                  Point discount
                </li>
                <li
               className="allCartInfo"
                >
                  Gift voucher
                </li>
                <li
               className="allCartInfo"
                >
                  Coupon
                </li>
                {/* <li  style={{marginBottom:'8px', marginLeft:'25px', fontFamily:'Rubik'}}>GST.</li> */}
                <li
                 className="allCartInfo"
                >
                  Delivery Charges
                </li>
                <div className="hrLine2" ></div>
                <li
                 className="allCartInfoMain"
                >
                  TOTAL AMOUNT :
                </li>
              </ul>
            </div>
            <div className="col-6 numericalPrize2">
              <ul>
                <li className="allCartInfo2">
                  ₹ {address.price}
                </li>
                {/* <li style={{ marginBottom: "8px", marginLeft: "65px" }}>
                  ₹ 100  
                </li> */}
                {/* <li  style={{marginBottom:'8px', marginLeft:'65px'}}>₹{summaryInfo.COUPON_DISCOUNT}</li> */}
                {
                      !address.discount_points ?(
                        <li className="allCartInfo2">
                      ₹ 0
                      </li>
                      ):(
                      <li className="allCartInfo2">
                        ₹ {address.discount_points}
                      </li>
                      )
                    
                    }
                {/* <li  style={{marginBottom:'8px', marginLeft:'65px'}}>₹{parseFloat(summaryInfo.GST).toFixed(2)}</li> */}
                {
                  giftprice == null ? (<> <li className="allCartInfo2">
                  ₹ 0
                </li></>) : (<> <li className="allCartInfo2">
                  ₹ {giftprice }
                </li></>)
                }
               
                {
                  couponDisc == null ? (<> <li  className="allCartInfo2">
                  ₹ 0
                </li></>):(<> <li  className="allCartInfo2">
                      ₹ {couponDisc }
                    </li></>)
                }
               
                <li className="allCartInfo2">
                  ₹ {address?.shipping_charge}
                </li>
                <li className="allCartInfoMain2">
                  ₹ {address.total}
                </li>
              </ul>
            </div>
            {/* <hr style={{ width: "85%", marginLeft: "45px" }} /> */}
            {/* <div className="totalPrizes">
              <h3 className="totalAmount">TOTAL AMOUNT : </h3>
              <h3 className="totalAmount2Of">
                {" "}
                INR {address.total}
              </h3>
            </div> */}
           
          </div>
        </div>
   </div>
<div className='invoiceButton2'>
      <button onClick={()=>justdownloadInvoice(orderData)} style={{color:'black', cursor:'pointer'}} className='buttonInvoice2'>Get Invoice</button>
     {
      address.Track_id == 'NULL' ? null: (
        <>
     <a  className='buttonInvoice2' style={{textAlign:'center', textDecoration:'none', color:'black', cursor:'pointer'}}  onClick={()=>setTrackingStatus(!trackingStatus)}>
      Track Order
      </a> 
        </>
      )
     }
     </div>
     {
      trackingStatus && (<>
        <div className='trackingTheOrder'>
        
     <HorizontalLinearAlternativeLabelStepper/>
     {
      address.Tracking_link == null || address.Tracking_link == '' ? null : (<>
      <div style={{marginTop:'25px'}}>
      {/* <p>{address?.Tracking_link}</p> */}
      <p style={{color:'red', textDecoration:'underline', textAlign:'center'}}><a href={address?.Tracking_link}>Tracking Link</a></p>
     </div>
      </>)
     }

     
     </div>
      </>)
     }
   
    
</div>
</div>

<Footer/>
</>
  )
}