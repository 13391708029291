import img1 from '../assets/img/brandpage/instaimg1.png'
import img2 from '../assets/img/brandpage/instaimg2.png'
import img3 from '../assets/img/brandpage/instaimg3.png'
import img4 from '../assets/img/brandpage/instaimg4.png'


const data = [
    {
        img: img1
    },
    {
        img: img2
    },
    {
        img: img3
    },
    {
        img: img4
    }
]

export default data