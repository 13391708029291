import React, { useEffect, useState,useRef } from 'react';
import { Navigate, useNavigate, useHistory } from 'react-router-dom';
import { getuserdetail } from '../../api/api';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../api/api';
import { logOut } from '../../redux/ActionCreator';
// import Terms from '../../assets/img/profile/file-text.png'
// import Help from '../../assets/img/profile/help-circle.png'
import {BiHelpCircle} from 'react-icons/bi'
import {GrDocumentText} from 'react-icons/gr'
import userImage from '../../assets/img/Home/user (2).png'
import { editProfielDetails, getAccountDetails, profilePhoto } from '../../api/api';
import {BsPencilSquare} from 'react-icons/bs'


export default function Admindashboard() {

  // const storedData = useSelector((state) => state.profile);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [company,setCompany] = useState(false)
  const [companyStatus, setCompanyStatus] = useState()
  const [isLogoutLoding, setisLogoutLoding] = useState(false)
  const storedData = useSelector((state) => state.profile);
  const [editMode, setEditMode] = useState(false);
  const [editMode2, setEditMode2] = useState(false);
  const [originalProfileImage, setOriginalProfileImage] = useState(null);
  const [Name, setName] = useState();
  const [Name1, setName1] = useState();

  const [mobile, setmobile] = useState();
  const [email, setemail] = useState();
  
  const [ProfileData, setProfileData] = useState([])
  const [profileError, setprofileError] = useState([])
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [companyName, setCompanyName] = useState()
  const [gstnNo, setGstnNo] = useState()
  const [compStatus, setCompStatus] = useState('')
    
  useEffect(() => {
    getUserDetail__fun(storedData.userId)
    getUserAccount(storedData.userId);
    window.scrollTo(0, 0);
  }, [])

  const getUserDetail__fun = (id) => {
    const url = getuserdetail(id)

    axios.get(url).then((resp) => {
      if (resp.data.status) {
        setProfileData(resp.data.data)
      } else {
        setprofileError(true);
      }
    }).catch((Err) => {
      console.log("er  -> ", Err);
      setprofileError(true)
    })
  }
  

  const logOutUSerFromWeb = (id) => {
    setisLogoutLoding(true)
    const url = logoutUser(id)

    axios.get(url).then((resp) => {
      if (resp.data.status) {
        toast.success('User LoggedOut Successfully')
        logOutUSerFromReducer()
        setisLogoutLoding(false)
      } else {
        toast.success('Something Went Wrong! Try Again in Sometime')
        // console.log("status in rep ==> ", resp.data);
        setisLogoutLoding(false)
      }
    }).catch((err) => {
      console.log("err in logot -=> ", err);
      setisLogoutLoding(false)
    })
  }


  const logOutUSerFromReducer = () => {
    dispatch(logOut());
    // <Navigate to="/" push />
    navigate('/')
  
    toast.success('User LoggedOut Successfully')

    // console.log("user Logged out from web");
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
  }
  }





  const handleEditClick = () => {
    setEditMode(true);
  };
  const handleEditClick2 = () => {
    setEditMode2(true);
  };
 
  const handleSaveClick = (event) => {
    event.preventDefault(); 
    setEditMode(false);
    updateProdfileDetail(); 
  };
  const handleSaveClick2 = () => {
    setEditMode2(false);
    updateProfileImage();
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleNameChanged = (e) => {
    setName1(e.target.value);
  };
 
  const handleNameChange2 = (e) => {
   
    setProfileImage(e.target.files[0]);
  };


  const inputRef= useRef(null)
  const handlemobileChange = (e) => {
    setmobile(e.target.value);
  };
  const handlemailChange = (e) => {
    setemail(e.target.value);
  };
  const handlCompanyChange = (e) => {
    setCompanyName(e.target.value);
  };
  const handlGstnChange = (e) => {
    setGstnNo(e.target.value);
  };


  let formData = new FormData()

const updateProfileImage = ()=>{

  // inputRef.current.click()
  const url =  profilePhoto() 
//  const body = {
//   user_id:storedData.userId,
//  }
  

  formData.append('user_id',storedData.userId)
formData.append('image', profileImage)

 
  axios.post(url,formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }})
  .then((resp)=>{
    console.log(resp);
    setOriginalProfileImage(profileImage);
  })
  .catch((err)=>{
    console.log(err);
  })}

const updateProfileImage2=()=>{
  inputRef.current.click();
  updateProfileImage()

}

useEffect(() => {
   
    updateProfileImage();
    getUserAccount(storedData.userId)
}, [profileImage]);



  const getUserAccount = (id) => {
    const url = getAccountDetails(id)
    axios(url)
      .then((resp) => {
        console.log(' - -  - resp',resp.data.data);
        setCompanyName(resp.data.data.CompanyName)
        setGstnNo(resp.data.data.gst_no)
        setCompStatus(resp.data.data.is_company)
        setName(resp.data.data.name)
        setName1(resp.data.data.last_name)
        setmobile(resp.data.data.mobile)
        setemail(resp.data.data.email)
        setProfileImage(resp.data.data.image_url)

        console.log('hello',resp.data.data.image_url);
        
      })
      .catch((error) => {
        console.log('  - - - - error', error);
      })
  }

  const updateProdfileDetail = ()=>{
     
    const url = editProfielDetails()
    const body = {
      user_id:storedData.userId,
      name:Name,
      last_name:Name1,
      mobile:mobile,
      email:email,
      company_name:companyName,
      gst_no:gstnNo,
      is_company:companyStatus !== undefined ? companyStatus : compStatus
      // is_company:0


    }
console.log('--body--',body);
    axios.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      }})
    .then((resp)=>{
      console.log('==profile Image',resp.data);
      window.location.reload();
    })
    .catch((error)=>{
      console.log('hello error',error);
    })
  }

  const handleCompanyChange=()=>{
   if(company){
    setCompanyStatus(0)
   }else{
    setCompanyStatus(1)
   }
    setCompany(!company)
  }


  return (
    <>
      {/* <div className='account'>
        <h1 className='myAccount'>My Account</h1>
        <h4 className='hello'>Hello, {ProfileData.name}</h4>
        <p className='dashPara'>
          Welcome to your account dashboard. Use the navigation on the left-hand side to manage your account.
        </p>
        <div className='boxmain'>
        <button style={{fontFamily:'Rubik'}} onClick = {() => logOutUSerFromReducer() } className={`logoutBtn ${activeTab === 'Logout' ? 'active' : ''}`}>
               Logout
              </button>
      
        </div>
      </div> */}

<div className='accountdetails'>
        <h1 className='myAccount'>Account Information</h1>
        <form>
          {!editMode &&
          <div style={{ display: 'flex', marginBottom:'-10px' }} className='displayProfile'>
            {!editMode2 && 
            <div style={{position:'relative'}}>
            <div className='accounImaget'>
              
              <img src={profileImage == null? userImage:profileImage } className='accountImg' alt='profileImage' />
              </div>
             <div onClick={updateProfileImage2} className='updatePencil'>
              <BsPencilSquare className='pencilSquare' />
            
            <input
              // className='nameadd'
              type='file'
              onChange={handleNameChange2}
              // disabled={!editMode2}
              name='file'
              ref={inputRef}
              style={{display:'none'}}
              
            />
            </div>
            </div>
          
}

            <div className='editInfoBtn'>
              {!editMode && (
                <button className='editbtn' onClick={handleEditClick}>
                  Edit
                </button>
              )}
            
            </div>
          </div>
          }
          <div className='AddressInfo'>
            <input
              className='nameadd'
              value={Name}
              onChange={handleNameChange}
              disabled={!editMode}
            />
            <input
              className='nameadd'
              value={Name1}
              onChange={handleNameChanged}
              disabled={!editMode}
            />
            <input
              className='nameadd'
              value={mobile}
              onChange={handlemobileChange}
              disabled={!editMode}
            />
            <input
              className='nameadd'
              value={email}
              onChange={handlemailChange}
              disabled={!editMode}
            />
            {
              compStatus == 1 &&(
                <>
                <input
                className='nameadd'
                value={companyName}
                onChange={handlCompanyChange}
                disabled={!editMode}
              />
            <input
              className='nameadd'
              value={gstnNo}
              onChange={handlGstnChange}
              disabled={!editMode}
            />
                </>
              )
            }

            {
              editMode && compStatus == 0 && (
                <>
                <div style={{display:'flex',}}>
              <p style={{marginBottom:'0px'}}>Are you a company?</p>
              <input
              type="checkbox"
              style={{marginLeft:'10px' ,marginTop:'0px', width:'20px'}}
              // style={{marginLeft:'10px' ,marginTop:'-8px', width:'20px'}}
              onChange={handleCompanyChange}
              checked={company}
              />
            </div>
            {
              company && (
              <>
              <div className="  createInputDiv">
              <input
              className='nameadd'
              value={companyName}
              onChange={handlCompanyChange}
              disabled={!editMode}
            />
            </div>

            <div className="  createInputDiv">
            <input
              className='nameadd'
              value={gstnNo}
              onChange={handlGstnChange}
              disabled={!editMode}
            />
            </div>
              </>
              )
            }
            </>
              )
            }
              
            {editMode && (
              <button className='cancelbtn savebtn' onClick={handleSaveClick}>
                Save
              </button>
            )}
            {editMode2 && (
              <button className='cancelbtn savebtn' onClick={handleSaveClick2}>
                Save
              </button>
            )}
          </div>
        </form>
        
      </div>
    </>
  );
}