import { useEffect, useState } from 'react';
import { Button, Container, Form, Nav, Navbar, DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCircle } from 'react-icons/fa';
import '../assets/responsive.css'
import { NavLink, useLocation, useNavigate, } from "react-router-dom";
import logo2 from '../assets/img/Home/31years.png'
import logo from '../assets/img/Home/creativeLogo2.png'
import { Link } from "react-router-dom";
import { FaHammer } from 'react-icons/fa';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Cartmodal from './Cartmodal';
import { getBrands, getcartId, getcartbyId, serachApi } from '../api/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import Image from '../assets/img/Home/Vector (1).png'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { IoIosCall, IoLogoWhatsapp, } from 'react-icons/io'
import { AiOutlineCaretDown } from 'react-icons/ai'
import ReactWhatsapp from 'react-whatsapp';
import '../assets/style.css';
import '../assets/responsive.css';
import BulkOrder from './BulkOrder/BulkOrder';
import whatsappIcon from '../assets/img/Home/whtasapp.png'
import profileImg from '../assets/img/Home/profile.png'
import cartIcon from '../assets/img/Home/cart.png'
import callIcon from '../assets/img/Home/Call.png'
import hotdeal from '../assets/img/Home/hot-deal.png'

function NavMenu({ cartBoolean, setCartBoolean, setcartpoint, onCloseModal}) {

  const navigate = useNavigate();
  const storedData = useSelector((state) => state.profile);


  const handleClose = (data) => {
    // const brandid = data?.id;
    // navigate('/' + data?.brand_name, {
    //   state: {
    //     brandid: data?.id,
    //     brandName: data?.brand_name
    //   }
    // })
    navigate(`/${data?.brand_name}`, { state: {
      brandid:data?.id,
      brandName: data.brand_name
    } });
    console.log('brand data',data);
  };


  const handleShow = () => setShow(true);
  const modalShow = () => {
    setModal(true)
  }

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const currentRoute = useLocation().pathname.toLowerCase();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [cartData, setCartData] = useState([]);
  const [cartTotalPrice, setcartTotalPrice] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [noproduct, setNoProduct] = useState(false);
  const [brandName, setBrandName] = useState([])
  const [modal, setModal] = useState(false)
  const [color, setColor] = useState('transparent')
  const [cartValue, setcartValue] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  

  const location = useLocation()

  const paymentCart = location.pathname === '/paymentPage'

  useEffect(() => {
    console.log('==payment cart',paymentCart);
      const totalQuantity = cartData.reduce((accum, item) => accum + item.quantity, 0);
    setcartValue(totalQuantity)
  }, [cartData]);


  useEffect(()=>{
    localStorage.removeItem('selectedItems');
    localStorage.removeItem("selectedItems2");
    localStorage.removeItem("selectedItems3");
    localStorage.removeItem("selectedItems4");
  },[])
  
 useEffect(()=>{
  console.log('---',cartBoolean);
    if(cartBoolean){
      getCartData()
      setCartBoolean(false)
      
    }
 },[cartBoolean])


  
  useEffect(() => {
    if (storedData.userLoginSuccess) {
      getCartData(storedData.userId);
    } else {
      getCartData(null)
    }
    getThebrands()
    console.log('--hello',cartValue);
  }, [cartValue]);

  

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleClosing2();
  };
  const handleClosing = () => {
    setAnchorEl(null);
  };
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
    handleClosing();
  };
  const handleClosing2 = () => {
    setAnchorEl2(null);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleShowModal2 = () => {
    setShow1(true)
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModal2 = () => {
    setShowModal1(false);
  };

  const handleSearch = () => {
    // Perform search logic using the searchQuery state value

  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const cartpage = () => {
      navigate('/Cart')
  }

  const shopPage = () => {
    navigate('/shop')
}


  const handleCloseModalFunc = () => {
    setShow(false)
    setCartBoolean(true)
    onCloseModal();
  }

  const handleCloseModalFunc2=()=>{
    setShow1(false)
  }
   const getCartData = () => {
    const url = getcartbyId();
    var body = {
      discount: setcartpoint,
      user_id: storedData.userId,
    }
    console.log(body);
    axios.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((resp) => {
      console.log('cart values', resp);
      if (resp.data.data == '') {
        setNoProduct(true);
        setcartValue(0);
        localStorage.setItem('cartStatus', false)
      } else {
        localStorage.setItem('cartStatus', true)
        setNoProduct(false);
        setCartData(resp.data.data);
        console.log('get cart data');
        // setcartValue(cartData.reduce((accum, item) => accum + item.quantity, 0))
        setcartTotalPrice(resp.data.cart_price)

      }
    })
      .catch((error) => {
        setNoProduct(true);
setcartValue(0)
    console.log('e',error);
      });
  };

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  
  const getThebrands = () => {
    const url = getBrands()

    axios.get(url)
      .then((resp) => {
        setBrandName(resp.data.data)
      })
      .catch((error) => {
        console.log('--error--');
      })
  }
  
  const whatsappCall = () => {
    return (
      <div>
        <ReactWhatsapp number="+91 9930290909" message="Hello World!!!" />
      </div>
    )
  }
  
  const homeFunc = () => {
    navigate('/')
    setColor('red')
  }

  const goTobulk = ()=> {
navigate('/valuePacks')
  }


  const handleClosing3 = ()=>{
    handleClosing();
    handleClosing2();
  }

  // console.log(currentRoute);                                        
  // console.log("test shop", currentRoute.includes("shop"));          
  // console.log("test", currentRoute.includes(""));                   

  // document.getElementById("rkp-1").className = "navbarHeader-active"; 
  // document.getElementById("rkp-2").className = "navbarHeader-active"; 
  return (

    <>
      <Modal show={show} onHide={handleCloseModalFunc} animation={false} >

        <Modal.Header closeButton className='modalMobileHeader'>
          {/* <Modal.Title style={{"fontFamily":"'Rubik'","fontStyle":"normal","fontWeight":"400","fontSize":"16px","lineHeight":"23px","textAlign":"center","color":"#6F6F6F"}}></Modal.Title> */}
        </Modal.Header>

        <Modal.Body className='modalbody' >
          <Cartmodal  />
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: 'center' }}>
          {/* {cartValue > 0 &&   */}
          <button onClick={shopPage} className='modalFooter' > Continue Shopping</button>
          <button onClick={cartpage} className='modalFooter2' > Go to cart</button>

          {/* } */}
        </Modal.Footer>
      </Modal>

      {/* <Modal show={show1} onHide={handleCloseModalFunc2} animation={false} centered  size="lg" className='modalClass2' >
        <Modal.Body className='modalBulk'>
          <BulkOrder/>
        </Modal.Body>
      </Modal> */}

      <div  className="Navdiv" onMouseLeave={handleClosing3} style={{ "backgroundColor": "rgb(47, 51, 58)", "position": "fixed", "top": "0", "zIndex": "1001", "width": "100%", }}>
        <div className="container navSearch cont">

        </div>
        <Navbar style={{ backgroundColor: 'black', paddingBottom: "px", }} expand="lg">
          <Container fluid className="navContainer">
            <div className="col-2 col-md-3 firstBtn"  >

            
              {/* <i class="fa-solid fa-user" style={{ fontSize: '20px', color: 'white' }} onClick={() => navigate('/signin')}></i> */}
              {storedData.userLoginSuccess ? (
                <i style={{ color: 'white',padding:'11px',marginLeft:'-8px' }} className='userFontIcon'>
                  
                  <FontAwesomeIcon icon={faUserCircle} onClick={() => navigate('/profile')} style={{ fontSize: '19px', marginTop: '8px', marginLeft: '0px', }} />
                  {/* <img src={profileImg} alt='profile' onClick={() => navigate('/profile')} style={{ width: '21px', marginTop: '8px', marginLeft: '0px', cursor: 'pointer' }}/> */}
                  
                </i>
              ) : (
                <i className="userFontIcon" onClick={() => navigate('/signin')} style={{ color: 'white',padding:'11px',marginLeft:'-8px' }}>
                   {/* <img src={profileImg} alt='login' style={{ width: '21px', marginTop: '8px', marginLeft: '0px', cursor: 'pointer' }}/> */}
                   <FontAwesomeIcon icon={faUserCircle} onClick={() => navigate('/profile')} style={{ fontSize: '19px', marginTop: '8px', marginLeft: '0px', }} />
                </i>
              )} 
              {!paymentCart ? (<><div className='bagDiv' style={{  position: 'relative',width:'100%'}}>
              <div onClick={handleShow} style={{ fontSize: '18px', color: 'white', marginTop: '3px', width:'21px' }}>
                {/* <img style={{width:'100%'}} alt='cartIcon' src={cartIcon}/> */}
                <i class="fa-solid fa-bag-shopping" style={{ fontSize: '18px', color: 'white', marginTop: '10px' }}></i>
                </div>
              <div style={{ "color": "black", "backgroundColor": "#ffd11b", fontWeight: '600', width:'17px',textAlign:'center', "borderRadius": "26px", "position": "absolute","fontSize":'13px', "top": "5px", "left": "20px", cursor: 'pointer' }}>{cartValue}</div>
              </div></>):(<> <div className='bagDiv' style={{  position: 'relative',width:'100%'}}>
              <i class="fa-solid fa-bag-shopping" style={{ fontSize: '18px', color: 'white', marginTop: '10px' }}></i>
              {/* <div style={{ fontSize: '18px', color: 'white', marginTop: '8px', width:'21px' }}><img style={{width:'100%'}} alt='cartIcon' src={cartIcon}/></div> */}
              <div style={{ "color": "black", "backgroundColor": "#ffd11b", fontWeight: '600', width:'17px',textAlign:'center', "borderRadius": "26px", "position": "absolute","fontSize":'13px', "top": "5px", "left": "20px", cursor: 'pointer' }}>{cartValue}</div>
              </div></>) }

              
             
               
              {/* <i><FaCircle values='5' style={{fontSize:'20px', color:'white'}} onClick={() => navigate('/profile')}/></i> */}
            </div>
            {/* <img  src={Image}alt="My Image" className='CollapsedImg' onClick={() => setIsCollapsed(!isCollapsed)}/> */}
            <img src={logo} className='mainlogo' alt='logo' onClick={() => navigate('/')} />
            {/* <img src={logo2} style={{width:'4%',gap:'20px'}} className='mainlogooo' onClick={() => navigate('/')}  /> */}
            {/* <Navbar.Toggle aria-controls="navbarScroll" className="custom-toggle-button  " /> */}
            {/* {Image} */}
            <img src={Image} alt="My Image" className='CollapsedImg' onClick={() => setIsCollapsed(!isCollapsed)} />

            <Navbar.Collapse id="navbarScroll" className={`${isCollapsed ? 'show' : ''}`}>
              <Nav
                className=" my-2 my-lg-0 mainNav"
                navbarScroll
              >


                <div className='nav1' onClick={homeFunc} onMouseEnter={handleClosing3}>Home</div>
                <a className='nav2' href="/Shop" onMouseEnter={handleClosing3}>Shop</a>
                <div class="dropdown dropdown11">
                  <a style={{backgroundColor:'black',border:'0px',fontFamily: 'Oswald'}} class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Brands
                  </a>
                  <ul style={{width:'100%', background:'black'}} class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  {brandName.map((item) => {
                  return (
                    <li className='brandNavbarName' onClick={() => handleClose(item)}>{item.brand_name}  <span style={{ fontFamily: 'Rubik', textTransform: 'capitalize', fontWeight: '300', fontSize: '14px', marginLeft: '12px' }}> {`  ${item.heading}`}</span></li>
                  )
                })}
                 
                  </ul>
                </div>
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  onMouseEnter={handleClick}
                  // onMouseLeave={handleClosing}
                  style={{ background: 'black', border: 'none', padding: '25px', marginLeft: '-79px', borderRadius: '0px' }}
                  className='brandsOptn'
                >
                  Brands
                </Button>
                <Menu 
                  className='main-container'
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onMouseLeave={handleClosing}
                  onClose={handleClosing}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <span  onMouseLeave={handleClosing}>
                  {brandName.map((item) => {
                  return (
                  
                    <MenuItem className='' style={{ background: 'black', color: 'white', marginTop: '-8px', marginBottom: '-8px', padding: '15px 15px 15px 30px', fontFamily: 'Oswald', textTransform: "uppercase" }} onClick={() => handleClose(item)}>{item.brand_name}  <span style={{ fontFamily: 'Rubik', textTransform: 'capitalize', fontWeight: '300', fontSize: '14px', marginLeft: '12px',marginTop:'5px' }}> {`  ${item.heading}`}</span></MenuItem>
                  )
                })}
                      </span>
                      
                </Menu>
                
              {/* <span className='nav2' onClick={goTobulk} >Value Packs</span> */}
              <div class="dropdown dropdown11">
                  <a style={{backgroundColor:'black',border:'0px',fontFamily: 'Oswald'}} class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Deals
                  </a>
                  <ul style={{width:'100%', background:'black'}} class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                 
                    <li className='brandNavbarName' onClick={()=>navigate('/hotdeals')}> Hot Deals </li>
                    <li className='brandNavbarName' onClick={goTobulk}> Value Packs </li>
                    <li className='brandNavbarName' onClick={()=>navigate('/topsellers')}> New Arrivals </li>
                    <li className='brandNavbarName' onClick={()=>navigate('/clearance')}> Clearance Sale </li>
                 
                 
                  </ul>
                </div>
              <Button
                  id="basic-button"
                  aria-controls={open2 ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? 'true' : undefined}
                  onClick={handleClick2}
                  onMouseEnter={handleClick2}
                  // onMouseLeave={handleClosing2}
                  style={{ background: 'black',  border: 'none', padding: '25px', marginLeft: '-79px', borderRadius: '0px' }}
                  className='brandsOptn2'
                >
                  Deals
                </Button>
                <Menu
                  className='main-container'
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={open2}
                  onMouseLeave={handleClosing2}
                  onClose={handleClosing2}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <span onMouseLeave={handleClosing2}>
                  <MenuItem className='' style={{ background: 'black', color: 'white', marginTop: '-8px', marginBottom: '-8px', padding: '15px 15px 15px 30px', fontFamily: 'Oswald', textTransform: "uppercase" }} onClick={()=>navigate('/hotdeals')}> Hot Deals </MenuItem>
                  <MenuItem className='' style={{ background: 'black', color: 'white', marginTop: '-8px', marginBottom: '-8px', padding: '15px 15px 15px 30px', fontFamily: 'Oswald', textTransform: "uppercase" }} onClick={goTobulk}> Value Packs </MenuItem>
                    <MenuItem className='' style={{ background: 'black', color: 'white', marginTop: '-8px', marginBottom: '-8px', padding: '15px 15px 15px 30px', fontFamily: 'Oswald', textTransform: "uppercase" }} onClick={()=>navigate('/topsellers')}>New Arrivals </MenuItem>
                    <MenuItem className='' style={{ background: 'black', color: 'white', marginTop: '-8px', marginBottom: '-8px', padding: '15px 15px 15px 30px', fontFamily: 'Oswald', textTransform: "uppercase" }} onClick={()=>navigate('/clearance')}>Clearance Sale </MenuItem>
                    
                  </span>
                    
                 
                </Menu>

{/* <div class="dropdownNavbar">
  <button class="dropbtnNavbar">Dropdown</button>
  <div class="dropdown-contentNavbar">
    <a href="#">Link 1</a>
    <a href="#">Link 2</a>
    <a href="#">Link 3</a>
  </div>
</div> */}
                <a className='nav2' href='/giftcoupon'  onMouseEnter={handleClosing3}>Gift Voucher</a> 
                <a className='nav2' href='/AboutUs'  onMouseEnter={handleClosing3}>About us</a> 

                <div className='displayIcon'  >
                  <div style={{width:'24px', marginRight:'6px'}}>
                  <a target='_blank' href="tel:9545900009">
                    <IoIosCall size={25} style={{ color: 'white', }} />
                    {/* <img alt='callIcon' style={{width:'100%'}} src={callIcon}/> */}
                    
                    </a>
                  </div>
                 
                  {/* <img style={{width:'23px'}} src={whatsapp}></img> */}
                  <a target='_blank' href="https://wa.me/+919930190909" >
                    <IoLogoWhatsapp size={25} style={{ color: 'white', }} />
                    {/* <img alt='whatsappIcon' style={{width:'24px'}} src={whatsappIcon}/> */}

                  </a>
                </div>
              </Nav>
            </Navbar.Collapse>


            <div className="col-3 d-flex align-items-center iconHolder" style={{ height: '56px', color: 'white', justifyContent: 'center', gap: '29px', marginTop:'5px' }}>
              {/* <button className="searchIcon" onClick={handleShowModal} style={{backgroundColor:'black'}}>
                <i className="fa-solid fa-magnifying-glass usergs" ></i>
                  </button> */}

              <Modal show={showModal} onHide={handleCloseModal} animation={false} dialogClassName="">
                <Modal.Header closeButton>
                  <Modal.Title>Search</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-content">
                  <input
                    type="text"
                    placeholder="Search here"
                    value={searchQuery}
                    onChange={handleInputChange}
                    className="searchInput"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <button onClick={handleSearch} className='modalsearch'>Search</button>
                </Modal.Footer>
              </Modal>



              {storedData.userLoginSuccess ? (
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}} onMouseEnter={handleClosing3}>
                  <FontAwesomeIcon icon={faUserCircle} onClick={() => navigate('/profile')} style={{ fontSize: '21px', marginTop: '4px', marginLeft: '0px' }} />
                  {/* <img src={profileImg} alt='profileIcon' onClick={() => navigate('/profile')} style={{ width: '28px', marginTop: '0px', marginLeft: '0px' , cursor: 'pointer'}}/> */}
                  <span style={{fontSize:'12px', color:'white', marginTop:'6px'}}>Profile</span>
                </div>
              ) : (
                // <i onClick={() => navigate('/signin')} >
                //   <img src={profileImg}  style={{ width: '28px', marginTop: '0px', marginLeft: '0px' }}/>
                // </i>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}} onMouseEnter={handleClosing3}>
                <FontAwesomeIcon icon={faUserCircle} onClick={() => navigate('/signin')} style={{ fontSize: '21px', marginTop: '4px', marginLeft: '0px' }} />
                {/* <img src={profileImg} alt='signinIcon' onClick={() => navigate('/signin')} style={{ width: '28px', marginTop: '0px', marginLeft: '0px', cursor: 'pointer' }}/> */}
                <span style={{fontSize:'12px', color:'white', marginTop:'6px'}}>Login</span>
              </div>
              )}
               <div className='bagDiv' onMouseEnter={handleClosing3} style={{ display: 'flex', position: 'relative', width: '15%', height: '100%', alignItems: 'center', }}>
               {!paymentCart ? (<>
                <div className="usersh" onClick={handleShow} style={{display:'flex', flexDirection:'column', alignItems:'center'}} >
                  {/* <img style={{width:'28px'}} src={cartIcon} alt='cartIcon'/> */}
                  <i class="fa-solid fa-bag-shopping  usersh"  ></i>
                <p style={{margin:'0', fontSize:'12px', marginTop:'6px'}}>Cart</p>
                </div>
                <div style={{ "color": "black", "backgroundColor": "#ffd11b", fontWeight: '600',padding:'0px 7px', "borderRadius": "26px", "position": "absolute", "top": "0px", "left": "25px", cursor: 'pointer' }}>{cartValue}</div>
               </>  ) : (<> 
               {/* <i class="fa-solid fa-bag-shopping  usersh"  ></i> */}
               {/* <i className="usersh" ><img style={{width:'28px'}} src={cartIcon}/></i> */}
               <div className="usersh" onClick={handleShow} style={{display:'flex', flexDirection:'column', alignItems:'center'}} >
                {/* <img style={{width:'28px'}} src={cartIcon} alt='cartIcon'/> */}
                <i class="fa-solid fa-bag-shopping  usersh"  ></i>
                <p style={{margin:'0', fontSize:'12px', marginTop:'6px'}}>Cart</p>
                </div>
                <div style={{ "color": "black", "backgroundColor": "#ffd11b", fontWeight: '600',padding:'0px 7px', "borderRadius": "26px", "position": "absolute", "top": "0px", "left": "20px", cursor: 'pointer' }}>{cartValue}</div>
              </>)
              }
              </div>
              
              <div style={{ border: '2px solid red', height: '40px', width: '1px', marginLeft: '-30px' }}></div>
              <div onMouseEnter={handleClosing3} style={{}}>
              <a target='_blank' href="tel:9545900009" style={{textDecoration:'none', color:'white', textAlign:'center'}}>
                <IoIosCall size={25} style={{ color: 'white', }} />
                {/* <img  style={{width:'28px'}} alt='callIcon' src={callIcon}/> */}
                <p style={{margin:'0', fontSize:'12px', marginTop:'6px'}}>Call</p>
                </a>
              </div>
              
              {/* <img style={{width:'23px'}} src={whatsapp}></img> */}
              <a target='_blank' href="https://wa.me/+919930190909" style={{textDecoration:'none', color:'white', textAlign:'center'}} onMouseEnter={handleClosing3}>
                <IoLogoWhatsapp size={25} style={{ color: 'white', }}  />
                {/* <img style={{width:'28px'}} alt='whatsappIcon' src={whatsappIcon}/> */}
                <p style={{margin:'0', fontSize:'12px', marginTop:'6px'}}>WhatsApp</p>
              </a>


              {/* <ReactWhatsapp number="1-212-736-5000" message="Hello World!!!" /> */}

            </div>


          </Container>
        </Navbar>
      </div>
      {/* <div  className='nav1'  onClick={homeFunc} >Home</div>
        <div  className='nav1' onClick={() => navigate('/')} >Home</div>
        <a className='nav2' href="/Shop">Shop</a>
       

        <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{background:'black', border:'none', padding:'0px',}}
        style={{background:'black', border:'none', padding:'25px',marginLeft:'-79px',borderRadius:'0px'}}
        className='brandsOptn'
      >
        Brands
      </Button> */}
    </>

  );
}
export default NavMenu;