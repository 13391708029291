import React, { useEffect, useState } from 'react'
import img from '../assets/img/img2/cart1.png'
import data from '../Json/FeatureData'
import '../assets/style.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import '../assets/responsive.css';
import { deleteWishList, getcartbyId, productData } from "../api/api";
import { getWhishlist, getSpecialCategoryApi,getcartId } from '../api/api';
import axios from 'axios';
// import '../assets/responsive.css';
import '../assets/css/home.css'
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import ReactStars from 'react-stars';
import { auto } from '@popperjs/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal, ModalHeader } from 'react-bootstrap';
// import Carousel from 'react-responsive-carousel';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ReactGA from 'react-ga';
const Feature = ({cartBoolean, setCartBoolean, setReload, Reload}) => {


  
  const navigate = useNavigate();
  const storedData = useSelector((state) => state.profile);
  const [section_OnefullInfo,setsection_OnefullInfo]=useState([])
  const [section_OneProductInfo,setsection_OneProductInfo]=useState([])
  const [dicount,setDiscount] = useState([])
  const [productStatus, setProductStatus] = useState()
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (storedData.userLoginSuccess) {
      getSpecailCategory_func(storedData.userId);
    }else{
      getSpecailCategory_func(null);
    }
    // ReactGA.initialize('G-P4V3bbbbbbXFEZFR');
    window.scrollTo(0, 0);
   
  }, [])

useEffect(()=>{
  getSpecailCategory_func(storedData.userId)
  setReload(false)
},[Reload])

  const getSpecailCategory_func=(id)=>{
    const url= getSpecialCategoryApi(id)

    // console.log(" url from specl cat  -- > ", url);
     axios.get(url)
     .then((resp)=>{
      
        console.log("--brandData",resp.data.data[1].new_arrival.data)
        // console.log("data---->>>>",resp.data.data[0].hot_products.data[0].starting_price)
        setsection_OnefullInfo(resp.data.data[1].new_arrival)
        setsection_OneProductInfo(resp.data.data[1].new_arrival.data)
        // console.log("length of bproduct dataa  ===> ", section_OneProductInfo.length);
        setDiscount(resp.data.data[1].new_arrival.data[0])
        // section_OneProductInfo(resp.data.data[0].hot_products.product_id)

     }).catch((err)=>{
        console.log(err)
     })
}   


const addWishlist=(id, id2, id3)=>{
  if (storedData.userLoginSuccess) {
    const url = getWhishlist()
    const userData = {
       user_id: storedData.userId,
       product_id: id,
       has_login: 'yes', 
    }
    console.log('==body==',userData);
  
    axios.post(url, userData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((resp)=>{
      // console.log(' = = = resp = = ',resp.data);
      if (resp.data.status) {
        toast.success('Wishlist Added', { icon : '❤️' })
        getSpecailCategory_func(storedData.userId)
        window.fbq('track','Add to wishlist',{currency:'INR', value:id2, content_name:id3})
        // ReactGA.event({
        //   category: 'Ecommerce',
        //   action: 'add_to_cart',
        //   items: [
        //     {
        //       item_id: id,
        //       item_name: id3,
        //       price: id2,
        //     }
        //   ]
        // });
      } else {
        toast.error('Something Went Wrong', { icon : '⚠️' })
      }
      
      // console.log(' the value of list',resp.data);
    }).catch((err)=>{
      console.log(err);
      toast.error('Something Went Wrong', { icon : '⚠️' })

    })
  } else {
    // alert('Please Login')
    setShowModal(true)
    // navigate('/signin' )
  }
}

const removeWishlist=(id)=>{
  if (storedData.userLoginSuccess) {
    const url = deleteWishList(id)

    let data = new FormData();
    data.append("_method",'DELETE');

    // console.log(" ==> wishlist data => ", url, data);
    
    axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((resp)=>{
      // console.log(resp.data);
      if (resp.data.status) {
        toast.success('Wishlist Removed', { icon : '✅' })
        getSpecailCategory_func(storedData.userId)
      } else {
        toast.error('Something Went Wrong', { icon : '⚠️' })
        
      }
      
      // console.log(' the value of list',resp.data);
    }).catch((err)=>{
      console.log(err);
      toast.error('Something Went Wrong', { icon : '⚠️' })

    })
  } else {
    alert('Please Login')
    navigate('/signin' )
  }
}

const sendProdId = (id) => {
  // console.log(' ------ id',id);
      // navigate(`/product/${id}`,{state:{productId:id,clicked: clicked}});
     
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    dots: true
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

  const addtoCart = (id) => {
    const url = getcartId();
  
    // console.log( ' -- - - - url - - - ',url);
    const postData = {
      user_id: storedData.userId,
      product_id: id,
      has_login: 'yes',
    };
  
    axios
      .post(url, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log('---- cart value == ', resp.data);
        if (resp.data.status) {
          toast.success('Product Added In Cart', { icon : '🛒' })
          if (storedData.userLoginSuccess) {
            getSpecailCategory_func(storedData.userId)
            setCartBoolean(true)
            // window.fbq('track','Add to cart',{currency:'INR',value:section_OneProductInfo[0].starting_price})
          }else{
            getSpecailCategory_func(null)
          }
      } else {
          toast.error('Something Went Wrong', { icon : '⚠️' })
      }
      })
      .catch((error) => {
        // console.log(' === error ===',error);
      });
  };

  const handleAddToCart = (id,id2,id3,id4) => {
    console.log('id of the cart',id,id4);
    if (storedData.userLoginSuccess) {
      // addtoCart(id);
      if(id2 == 1){
        addtoCart(id);
        window.fbq('track','Add to cart',{currency:'INR', value:id3, content_name:id4})
      //   window.gtag('event', 'manual_event_PURCHASE', {'send_to': 'G-P4V3XFEZFR',
      //   'value': id3,
      //   'currency': 'INR'
      // });
        // window.fbq('track','AddToCart',{currency:'INR', value:id3, content_name:id4})

       

        // setCartValues(true);
      }else{
        toast('Product is unavailable')
      }
     
    }else{
      // alert('Please Login')
      // navigate('/signin' )
      setShowModal(true)

    }
  };



  var discoutPrice = dicount?.starting_price - dicount?.mrp
  var dpPrice = discoutPrice / dicount?.starting_price
  var finalPrice = dpPrice*100

  const sliderSettings = {
    dots: true,
    autoplay:true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, 
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 560, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 840, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  if (section_OneProductInfo.length === 2) {
    sliderSettings.slidesToShow = 2;
  } else if(section_OneProductInfo.length === 3){
    sliderSettings.slidesToShow = 3
  }
  else {
    sliderSettings.slidesToShow = 4; // Set the default to show 4 products
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };
 
  const toLoginPage =()=>{
    navigate('/signin')
  }

  return (
    <>
      <div className='container featureContainer'>
      <Toaster />

            <h1 className='FeatureHeading'><i class="fa-solid fa-star" style={{ color: 'red' }}></i> &nbsp;
             {section_OnefullInfo.name} &nbsp;<i class="fa-solid fa-star" style={{ color: 'red' }}></i></h1>

             <div className="slider-wrapper">

       

      </div>
        
        {/* <p className='featureHeadingText' style={{}}>{section_OnefullInfo.message}</p> */}
        {/* <div className='row featureRow'> */}


        <Carousel
          // className='featureCaurnew'
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={10000}
          arrows={true} 
          showDots={false}
          emulateTouch={true}
          renderButtonGroupOutside={true}
          renderDotsOutside={false} 
          // centerMode={centerMode}
          
          rewindWithAnimation={true}
          
        >
           {/* <Slider className='featureCaurnew' {...sliderSettings}> */}
          {section_OneProductInfo?.map((item,index) => {
            return (
              <>
              
              <div 
                // onClick={() => sendProdId(item.product_id)}

                
             className='featureCard' key={item.id}>
                <div  style={{ display: 'block', backgroundColor:'#EEEEEE' }} >
                    {/* <img src={item.img} onClick={() => sendProdId(item.product_id)} style={{width: '200px',height: 'auto', backgroundColor: 'green', padding: 60}} />  */}
                    <a  href={`/product/${item.product_id}`} style={{textDecoration:'none'}}>
                   <div className='featureOfImg'  onClick={() => sendProdId(item.product_id)} style={{ backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundSize: "95% 95%",  padding: 10, backgroundPosition: 'center', mixBlendMode: 'multiply' ,backgroundOrigin: 'content-box', backgroundPositionX: 'center' }}>
                    <img src={ item.image_url} style={{width:'100%'}} alt={item.product_name}/>
                      {/* <span className={`carding ${item.wishlist_id == null ? '' : 'clicked'}`}   onClick={() => addWishlist(item.product_id)}>
                      <i class="fa-solid fa-heart"  ></i>
                      </span> */}
                       {/* {item.stock > 0 && item.stock <= 10 && (
                          <p className='itemStock'> Only {item.stock}</p>
                        )}

                        {item.stock > 10 && (
                          // <p className='itemStock'>null</p>
                          null
                        )}

                        {item.stock <= 0 && (
                          <p className='itemStock'>Out of Stock</p>
                        )} */}

                        {/* <p className='offBanner'>{parseInt(finalPrice)}% Off</p> */}

                    </div>
                    </a>
                    </div>
                
                <div className='feratureStar'>
                  <ReactStars edit={false} value={item.rating} count={5} size={18}/>
                  <div style={{ paddingRight: 20, marginTop: '5%'}}>
                        {
                          item.wishlist_id == null ? <i class='fa-solid fa-heart' onClick={() => {addWishlist(item.product_id, item?.starting_price, item.product_name)}} style={{ color: "lightslategray", fontSize: '18px',   }}></i> : <i class='fa-solid fa-heart' onClick={() => removeWishlist(item.wishlist_id)} style={{color: "red", fontSize: '18px',  }}></i>
                        }   
                      </div>
                </div>
                <a  href={`/product/${item.product_id}`} style={{textDecoration:'none'}}>
                <hr className='featureHr'   onClick={() => sendProdId(item.product_id)}/>
                <OverlayTrigger
                                 placement="bottom"  
                                 overlay={
                                   <Tooltip style={{ backgroundColor: '#333', color: '#fff', borderRadius: '4px', fontSize: '12px' }}>
                                     {item.product_name}
                                   </Tooltip>}
                               >
              <p className='featureText' onClick={() => sendProdId(item.product_id)}>
                {item.product_name}
                {/* {item.product_name.split(' ').slice(0, 7).join(' ')}
                {item.product_name.split(' ').length > 7 ? '...' : ''} */}
              </p>
              </OverlayTrigger>
                <hr className='featureHr'  onClick={() => sendProdId(item.product_id)}/>
                <div className='featureOffering'>
                  <span className='featureOffer'  onClick={() => sendProdId(item.product_id)}><strong>{parseInt(((item.mrp-item?.starting_price)/item.mrp)*100)}% Off</strong></span></div>
                  <p className='ourPrice'>Our Price</p>
                <div className='featurePrizeContainer'  onClick={() => sendProdId(item.product_id)}>

                  <span className='featurePrize fearut'  onClick={() => sendProdId(item.product_id)}>₹ {item?.starting_price}</span>
                  <span className='featutreFinalpries'  onClick={() => sendProdId(item.product_id)}>MRP <strike style={{fontSize:'15px'}}>₹{item.mrp }</strike></span></div>
                  </a>
                  {
                    item.cart_id == null ?
                      <button onClick={() => {handleAddToCart(item.product_id,item?.status,item?.starting_price,item.product_name);
                         console.log('1212312') 
                        }} className='Featurebtn'><i class="fa-solid fa-cart-shopping" style={{ fontSize: '14px' }} ></i>Add to cart</button> : <button onClick={() => navigate('/cart')} className='Featurebtn'>View in cart</button>
                  }
                </div>
              </>
            )
          })
          }
        </Carousel>
        {/* </Slider> */}
        
        <div style={{display:'flex', justifyContent:'center', marginTop:'12px'}}>
          <button onClick={()=>navigate('/topsellers')} style={{ color:'black', justifyContent:'center', fontWeight:'500', background:'#FFD11B', border:'none', borderRadius:'12px', padding:'8px 25px'}}>View All</button>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg" className='modalClass modalClass2'>
       
        <Modal.Body style={{height:'100px', background:'rgba(241, 241, 241, 1)'}}>
         <p>Please Login!</p>
         <div style={{display:'flex', justifyContent:'end'}}>
         <button onClick={toLoginPage} style={{background:'red', borderRadius:'10px', color:'white', border:'none', padding:'5px 10px'}}>Login</button>
         </div>
        </Modal.Body>
      </Modal>
    </>
  )
}


export default Feature





















  
// const settings = {
//   dots: true,
//   arrows: true,
//   infinite: true,
//   speed: 500,
//   responsive: [
//     {
//       breakpoint: 700,
//       settings: {
//         arrows: true,
//         slidesToShow: 3
//       }
//     },
//     {
//       breakpoint: 500,
//       settings: {
//         arrows: true,
//         slidesToShow: 2
//       }
//     },
//     {
//       breakpoint: 400,
//       settings: {
//         arrows: true,
//         slidesToShow: 1
//       }
//     }
//   ]
// };
// const slidesData = [
//   {
//     id: 1,
//     title: 'repellendus id ullam',
//     label: 'Dolorem officiis temporibus.'
//   }, {
//     id: 2,
//     title: 'excepturi consequatur est',
//     label: 'Officia non provident dolor esse et neque.'
//   }, {
//     id: 3,
//     title: 'eius doloribus blanditiis',
//     label: 'Ut recusandae vel vitae molestiae id soluta.'
//   }, {
//     id: 4,
//     title: 'nihil voluptates delectus',
//     label: 'Qui vel consequatur recusandae illo repellendus.'
//   }, {
//     id: 5,
//     title: 'nemo dolorem necessitatibus',
//     label: 'Placeat odit velit itaque voluptatem.'
//   }, {
//     id: 6,
//     title: 'dolorem quibusdam quasi',
//     label: 'Adipisci officiis repudiandae.'
//   },
// ];