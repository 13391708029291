import React, { useState } from "react";
import "./bulk.css";
import backImage from "../../assets/img/Home/BULK-ORDER-BG.jpg";
import { bulkAllDetails } from "../../api/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ColorRing, TailSpin } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
function BulkOrder() {
  const [Name, setName] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [city, setcity] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [ description, setDescription] = useState("")

  // const closeTheSubmit = ()=>{

  // }
  const bulkDEtails = () => {
    if (
      Name.trim() === "" ||
      email.trim() === "" ||
      mobileNo.trim() === "" ||
      city.trim() === ""
      // address.trim() === ""
      // pincode.trim() === "" ||
      // city.trim() === ""
    ) {
      alert("Please enter all the details.");
      return;
    }
    setLoading(true)
    const url = bulkAllDetails();
    const body = {
      name: Name,
      email: email,
      mobile: mobileNo,
      city: city,
    };
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log(resp);
        setLoading(false)
        toast('Thankyou, Submitted Successfully')
        setTimeout(() => {
          
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
    <Toaster/>
      <div className="bulkOrder">
        <a href="/">
          <div className="bulkX">X</div>
        </a>
        {
          loading ? (<>
           <div
          className="loadingBulk"
        >
          {" "}
          <TailSpin
            style={{
              width: "100%",
              border: "2px solid black",
              margin: "auto",
              display: "block",
            }}
            height="70"
            width="70"
            color="#008fef"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />{" "}
        </div>
          </>):(<>
            <div className="bulkDesc">
          <p className="bulkBuys">Bulk Buys, Bigger Savings</p>
          <p className="bulkBuys2">
            Are you a business owner, reseller, or just love to stock up on your
            favorite products?
            <br />
            We've got great news for you! Introducing our Bulk Ordering option,
            designed to help you save big on every purchase.
          </p>
          <div className="bulkInput">
            <input
              // ref={nameRef}
              type="name"
              className="bulkMain"
              placeholder="Full Name"
              // value={Name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="bulkInput">
            <input
              // ref={nameRef}
              type="name"
              className="bulkMain"
              placeholder="Contact/Whatsapp Number"
              // value={Name}
              onChange={(e) => setmobileNo(e.target.value)}
            />
          </div>
          <div className="bulkInput">
            <input
              // ref={nameRef}
              type="name"
              className="bulkMain"
              placeholder="Email"
              // value={Name}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="bulkInput">
            <input
              // ref={nameRef}
              type="name"
              className="bulkMain"
              placeholder="City"
              // value={Name}
              onChange={(e) => setcity(e.target.value)}
            />
          </div>
          <div className="bulkInput">
            <textarea
              // ref={nameRef}
              type="name"
              className="bulkMain"
              placeholder="Description"
              // value={Name}
              onChange={(e) => setcity(e.target.value)}
            />
          </div>
          <div className="bulkInput2">
            <button onClick={bulkDEtails} className="bulkMain2">
              {" "}
              SUBMIT
            </button>
          </div>
        </div>
          </>)
        }
      
      </div>
    </>
  );
}

export default BulkOrder;
