import { legacy_createStore, combineReducers } from 'redux';
import {userDetail} from './reducer/Reducer2'
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { configureStore } from "@reduxjs/toolkit";
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'



const rootPersistConfig = {
  key: 'root',
  storage,
}

const userPersistConfig = {
  key: 'user',
  storage: storageSession,
}

const rootReducer = combineReducers({
  profile: persistReducer(userPersistConfig, userDetail),
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store)