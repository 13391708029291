import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
// import NavMenu from "./Navbar";
// import Footer from "./Footer";
// import "../assets/style.css";
import { deleteWishList, fertchWhishlist, getcartId } from "../../api/api";
import axios from "axios";
import { useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
import ReactStars from "react-stars";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default function Wishlist2() {
  const [data, setData] = useState([]);
  const [noproduct, setNoProduct] = useState(false);
  const [wishlistLoading, setwishlistLoading] = useState(false);
  const [cartValues, setCartValues] = useState(false);
  const navigate = useNavigate();
  const storedData = useSelector((state) => state.profile);

  useEffect(() => {
    getwishlistId(storedData.userId);
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, []);

  const getwishlistId = async (id) => {
    setwishlistLoading(true);
    const url = fertchWhishlist(id);

    axios
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log("url in wishlish", url);
        if (resp.data.data == "") {
          setNoProduct(true);
          setwishlistLoading(false);
        } else {
          setNoProduct(false);
          setData(resp.data.data);
          window.history.scrollRestoration = "manual";
          window.scrollTo(0, 0);
          setTimeout(() => {
            setwishlistLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        setNoProduct(true);
      });
  };

  const deleteCard = (id) => {
    let data = new FormData();
    data.append("_method", "DELETE");
    const url = deleteWishList(id);
    axios
      .post(url, data)
      .then((resp) => {
        // console.log(" = = = deleted card = = ", resp.data);
        if (resp.data.status) {
          toast.success("Wishlist Removed", { icon: "✅" });
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }
        getwishlistId(storedData.userId);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong", { icon: "⚠️" });
      });
  };

  const addtoCart = (id) => {
    const url = getcartId();

    // console.log(" -- - - - url - - - ", url);
    const postData = {
      user_id: storedData.userId,
      product_id: id,
      has_login: "yes",
    };

    axios
      .post(url, postData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        // console.log("---- cart value == ", resp.data);
        if (resp.data.status) {
          toast.success("Product Added In Cart", { icon: "🛒" });
          getwishlistId(storedData.userId);
          setCartValues(true)
        } else {
          toast.error("Something Went Wrong", { icon: "⚠️" });
        }
      })
      .catch((error) => {
        console.log(" === error ===", error);
      });
  };

  const handleAddToCart = (id, id2, id3) => {
    addtoCart(id);
    window.fbq('track','Add to cart',{currency:'INR',value:id2,content_name:id3})

  };

  const toDescription = (id) => {
    // console.log(" ------ id", id);
    navigate("/product", { state: { productId: id } });
  };
  const handleModalClose = () => {
    getwishlistId(storedData.userId);
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  // console.log(" state --> ", noproduct);
  return (
    <>
      {/* <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/> */}
      <Toaster />
      <div className="mainWishlist">
      <h2 className="wishlist-heading">My Wishlist</h2>
      <div className="productCradContainerW crad2">
        {noproduct ? (
          <h3 className="noWishlist">No Wishlist Added</h3>
        ) : wishlistLoading ? (
          <div className="centered-container">
            {" "}
            <ColorRing
              visible={true}
              height="60"
              width="60"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />{" "}
          </div>
        ) : (
          data.map((item) => {
            return (
              <>
               
                  <div className="productCard GridFourCard wish">
                  <a target="_blank" href={`/product/${item.product_id}`} style={{textDecoration:'none'}}>
                    <img src={item.image_url} className="productCardImg" alt={item.title}/>
                    </a>
                    <p className="productCardReview">
                      <ReactStars
                        edit={false}
                        value={item.rating}
                        count={5}
                        size={22}
                      />
                    </p>
                    <hr className="GridThreeCardhr" />
                    <a target="_blank" href={`/product/${item.product_id}`} style={{textDecoration:'none'}}>
                    <OverlayTrigger
                                 placement="bottom"  
                                 overlay={
                                   <Tooltip style={{ backgroundColor: '#333', color: '#fff', borderRadius: '4px', fontSize: '12px' }}>
                                     {item.title}
                                   </Tooltip>}
                               >
                    <p
                      className="ProductCardText"
                      // onClick={() =>
                      //   navigate(`/product/${item.product_id}`, {
                      //     state: { productId: item.product_id },
                      //   })
                      // }
                    >
                      {item.title}
                      {/* {item.title.split(" ").slice(0, 7).join(" ")}
                      {item.title.split(" ").length > 7 ? "..." : ""} */}
                    </p>
                    </OverlayTrigger>
                    </a>
                    <hr
                      // onClick={() =>
                      //   navigate(`/product/${item.product_id}`, {
                      //     state: { productId: item.product_id },
                      //   })
                      // }
                    />
                    <a target="_blank" href={`/product/${item.product_id}`} style={{textDecoration:'none'}}>
                    <span
                      // style={{ marginLeft: "20px" }}
                      className="featureOffer"
                    >
                      <strong>
                        {parseInt(
                          ((item.mrp - item.starting_price) /
                            item.mrp) *
                            100
                        )}
                        % Off
                      </strong>
                    </span>
                    <p className="ourPrice">Our Price</p>
                    <span
                      className="productMrp"
                      // onClick={() =>
                      //   navigate(`/product/${item.product_id}`, {
                      //     state: { productId: item.product_id },
                      //   })
                      // }
                    >
                      <p className="productCardPrice">
                        ₹ {item.starting_price}
                      </p>
                      <p className="productCardPrice2">MRP <strike>₹{item.mrp}</strike></p>
                    </span>
                    </a>
                    {item.cart_id == null ? (
                      <button
                        onClick={() => {handleAddToCart(item.product_id, item.starting_price, item.title)}}
                        className="productCardBtn"
                      >
                        <i
                          class="fa-solid fa-cart-shopping"
                          style={{ fontSize: "14px" }}
                        ></i>
                        Add to cart
                      </button>
                    ) : (
                      <button
                        onClick={() => navigate("/cart")}
                        className="productCardBtn"
                      >
                        View in cart
                      </button>
                    )}
                    <button
                      className=" wishbtn"
                      style={{color:'black'}}
                      onClick={() => {
                        deleteCard(item.wishlist_id);
                      }}
                    >
                      Remove
                    </button>
                    <span className="wishspan"> </span>
                  </div>
                
              </>
            );
          })
        )}
      </div>
      </div>
    

      {/* <Footer /> */}
    </>
  );
}
