import React, { useEffect, useState } from 'react';
import NavMenu from "../Navbar";
import './brands2.css';
import Image from '../../assets/img/brands2/2 89675380 (1).png';
import Image1 from '../../assets/img/brands2/image 31.png';
import Image2 from '../../assets/img/brands2/image 32.png';
import Image3 from '../../assets/img/brands2/image 33.png';
import Image4 from '../../assets/img/brands2/image 34.png';
import Image5 from '../../assets/img/brands2/01 (1).jpg';
import Image6 from '../../assets/img/brands2/02 (1).jpg';
import Image7 from '../../assets/img/brands2/03 (1).jpg';
import Image8 from '../../assets/img/brands2/04.jpg';
import Footer from "../Footer";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "react-multi-carousel/lib/styles.css";
import { getBrandsWithId, getProductViaCategory } from '../../api/api';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { ColorRing, TailSpin } from "react-loader-spinner";

export default function Stp(brandid,brandName) {
  const imageUrls = [
    { "img": Image1, "heading": "FUEL ADDICTIVES" },
    { "img": Image2, "heading": "OIL & ENGINE ADDITIVES" },
    { "img": Image3, "heading": "FUNCTIONAL FLUIDS" },
    { "img": Image4, "heading": "FUEL AND OTHERS" },
  ];

  const imageUrls2 = [
    { "img": Image5, "heading": "Petrol Injector Cleaner" },
    { "img": Image6, "heading": "Diesel Injector Cleaner" },
    { "img": Image7, "heading": "Oil Treatment  Engines" },
    { "img": Image8, "heading": "Oil Treatment Engines" },
  ];

  const [StpData, setStpData] = useState([]);
  const [StpCategory, setStpCategory] = useState([]);
  const [ProductId, setProductId] = useState('');
  const [clicked, setClicked] = useState(false);
  const [categoryId, setCategoryId] = useState('')
  const [cartValues, setCartValues] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [pageLoading, setPageLoading] = useState(true)
  // const { brandid, brandName } = location?.state;
  // const { brandid, brandName } = location?.state;
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false)
    }, 500);
    getBrandsById(1);
    getProductbyCategory(ProductId);
    window.scrollTo(0, 0);
  }, [1, ProductId]);

  const getBrandsById = (id) => {
    const url = getBrandsWithId(id);
    axios
      .get(url)
      .then((resp) => {
        // console.log('--resp', resp);
        setStpData(resp?.data?.brandwise_category_data);
        setProductId(resp.data.brandwise_category_data[0].category_id);
        // setCategoryId(resp.data.brandwise_category_data[0].category_id)
        // console.log('- - - category - - - ', resp.data.brandwise_category_data[0].category_id);
      })
      .catch((error) => {
        console.log('--error--', error);
      });
  }

  const getProductbyCategory = (id) => {
    const url = getProductViaCategory(id);

    axios
      .get(url)
      .then((resp) => {
        // console.log('--resp in brand product', resp);
        setStpCategory(resp.data.data.product_data);
        
        // console.log('--- - - - - setting id', resp.data.data.product_data[0].id);
      })
      .catch((error) => {
        console.log('--error', error);
      })
  }

  const changeProduct = (id) => {
    getProductbyCategory(id);
    setCategoryId(id)
    
  }

const stpProductWrld =()=>{
  // console.log('---strproductWorld', brandid);
  // localStorage.clear();
  localStorage.removeItem('selectedItems');
  window.localStorage.removeItem("CategoryCheckdDataLocal");
  window.localStorage.removeItem('packCheckedDataLocal');
  window.localStorage.removeItem('categoryValue2');
  window.localStorage.removeItem('')
  localStorage.removeItem("selectedItems2");
  localStorage.removeItem("selectedItems3");
  localStorage.removeItem("selectedItems4");
  localStorage.removeItem("brandsCheckdDataLocal");
  localStorage.removeItem("ddvalue");
  localStorage.removeItem("CategoryCheckdDataLocal");
  localStorage.removeItem("bcvalue");
  localStorage.removeItem("packCheckedDataLocal");
  localStorage.removeItem("packsvalue");
  localStorage.removeItem("resbrandsCheckdDataLocal");
  localStorage.removeItem("resddvalue");
  localStorage.removeItem("resCategoryCheckdDataLocal");
  localStorage.removeItem("resbcvalue");
  localStorage.removeItem("respackCheckedDataLocal");
  localStorage.removeItem("respacksvalue");
  navigate('/STP/Products',{
    state:{
      categoryById: categoryId == '' ? ProductId : categoryId,
      brandID: 1
    }
  })
}

  const sendProdId = (id) => {
    console.log(' ------ id',id);
        navigate(`/product/${id}`,{state:{productId:id,clicked: clicked}});
      //  localStorage.clear()
  };

  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
      <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
      {
         pageLoading ? (<>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'black'}}>
            <TailSpin  color="#ffd11b" />
          </div>
          </>):(
            <>
            <div className='Mainbrands'>
        <div className='imageColor'>
          {/* test */}
          <div className='redColor'></div>
          <div className='whiteColor'></div>
          <div className='whiteColor'></div>
          <div className='blueColor'></div>
          <img src={Image} className='logoImage' alt='stpLogo' />
        </div>

        <div className='firstImg'>
          <h1 className='scienceHr'>SCIENCE. TECHNOLOGY. PERFORMANCE.</h1>
          <div className='scienceDiv'>
            <p className='scienceP'>STP<sup>®</sup> products use advanced chemical technologies to keep your vehicle running stronger for longer. Learn which STP products your vehicle needs to perform at its peak.</p>
          </div>
          <span className='redDiv'>
            <div className='scienceImg'>
              <iframe width="965" height="394" src="https://www.youtube.com/embed/K_R0mQ7Kqsg" className='mainImage' title="Newer Cars Need Fuel Additives Too!" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </span>
          <div className='blueTransparent'></div>
        </div>
        <div className='ourProducts stprk'>
          <h2 className='productsh1'>CATEGORIES</h2>
          <div className='scienceDiv'>
            <p className='productp'>From fuel additives to oil treatments, functional fluids and more, STP products keep your engines performing at their best.</p>
          </div>
          <div className='brandStpImg2'>
            {StpData.map((item) => (
              <div className='stpMainAllDiv' key={item.id} onClick={() => changeProduct(item.category_id)} style={{ flex: 1 }}>
                <img alt={item.name} src={item.image_url} className={`productImg1 ${categoryId === item.category_id|| (categoryId === '' && item.category_id === ProductId)? 'product--highlighted' : ''}`} />
                <p className="helloImg" style={{ textTransform:'uppercase' }}>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='stpProducts'>
          <h2 className='productsh22'>STP PRODUCT RANGE</h2>
          <p className='productp'>For over 60 years – and more miles than we can count – STP® has been one of the most trusted names in automotive care. Our products are essential tools in your car maintenance routine.</p>
          <div className="brandImg2">
  <div className="redColor1"></div>
  <div className="whiteColor1"></div>
  <div className="whiteColor1"></div>
  <div className="blueColor1"></div>

  <div className="MainBrandImgg">
    {StpCategory && StpCategory.length > 0 ? (
      StpCategory.map((item, index) => (
        <a
                          href={`/product/${item?.id}`}
                          style={{ textDecoration: "none"  }}
                        >
        <div key={index} className="brandImg4Stp" onClick={() => sendProdId(item.id)}>
          <img src={item.image_url} className="productImg2" alt={item.title}/>
          <div className="brandName1 branded1">
            <p className="helloImg1" style={{ textTransform: 'capitalize' }}>{item.title}</p>
          </div>
        </div>
        </a>
      ))
    ) : (
      <div style={{width:'200%'}}>
        <p className="noProdtFound">No products found.</p>
      </div>
    )}

  </div>
</div>
<div className="viewing-more">
  {StpCategory && StpCategory.length > 0 ? (
    <button onClick={()=>{stpProductWrld()}} className="viewBtn">View All Products</button>
  ) : (
    <button className="viewBtn1" disabled>View All Products</button>
  )}
</div>
         
            
        </div>
        <div className='discover'>
          <h2 className='discoverhr'>DISCOVER THE SECRET OF ENGINE MAINTENANCE</h2>
          <iframe className='rectangleImg' src="https://www.youtube.com/embed/BpoF5pOgAws" title="Discover The Secret Of Engine Maintenance" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          <div className="redDivv" tyle={{textTransform:'uppercase'}}>
            {/* <div id='disc'> */}
          DISCLAIMER: ALL PHOTOS, VIDEOS, PRODUCTS, LOGOS BELONG TO © 2023 ENERGIZER AUTO.</div>
          {/* </div> */}
        </div>
        <Footer />
      </div>
            </>
          )
      }
      
    </>
  );
}

