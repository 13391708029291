import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import './login.css'
import { getUserOtp,getuserdetail,getresendotp, tempLogin, loginApi, getresendotp1 } from '../../api/api';
import axios from 'axios';
import Singin from './Singin';
import { useNavigate } from 'react-router-dom';
import { storeData, userDetail,  } from '../../redux/ActionCreator';
import { useSelector, useDispatch } from 'react-redux';


export default function Loginotp({mobileNo, email, mobileNo2, Name, lastName, gstin, companyName, company}) {
  useEffect(() => {
  console.log(mobileNo2);
  }, []) 
  
  // console.log(mobileNo);

    const [otp, setOtp] = useState('');
    const [status, setStatus]=useState(false);
    const navigate = useNavigate();
    

    const dispatch = useDispatch();
    const formHandler =()=>{

      if (!otp) {
        alert('Please enter OTP');
        return;
      }

      const userData = {
        otp: otp,
        mobile: mobileNo
      };
      
      console.log(userData);
      
      const url = tempLogin();
      axios
        .post(url, userData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          console.log('Response in resg:', response.data.data);
          setStatus(true);
          if (response.data.status) {
            // getUserProfile(response.data.data.id);
            storeAllData()
          } else {
            alert('Invalid OTP'); // Display alert for invalid OTP
          }
        })
        .catch((error) => {
          console.error('Error in register:', error);
          setStatus(false);
          console.log("error is coming");
          console.log(otp);
          alert('Invalid OTP'); // Display alert for invalid OTP
        });
      }


      const storeAllData=()=>{
        const userData = {
          name: Name,
          last_name:lastName,
          gst_no:gstin,
          company_name:companyName,
          is_company:company,
          mobile: mobileNo,
          mobile2:mobileNo2,
          email: email,
          address: 'static',
          pincode: '000000',
          city: 'Mumbai',
          // password:password
        };
    
        const url = loginApi()
    
      console.log(" body  --> ", url , "    ", userData);
        axios
          .post(url, userData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log('Response in resg:', response.data);
            setStatus(true); 
            if (response.data.status) {
              console.log(response.data.status);
              getUserProfile(response.data.id)
            }
            else{
              console.log('false',response);
              // alert(response.data.message)
            }
          })
          .catch((error) => {
            console.error('Error in register:', error);
            setStatus(false);
    
          })
          .catch((error) => {
            console.error('Error in register:', error);
            setStatus(false); 
          });
      };
    



   const getUserProfile = (id) => {
    const url = getuserdetail(id);
      axios.get(url).then((resp) => {
        localStorage.clear()
        // console.log('Resp ydyrdkd :', resp.data);
        dispatch(userDetail(resp.data.data));
        // localStorage.setItem('userDetail', resp.data.data);
        // setTimeout(() => {   
        //   localStorage.setItem("UserId", resp.data.data.user_id);
        //   localStorage.setItem("name", resp.data.data.name);
        //   localStorage.setItem("mobile", resp.data.data.mobile);
        //   localStorage.setItem("email",resp.data.data.email);
        //   localStorage.setItem("created_at", resp.data.data.created_at);
        //   localStorage.setItem("session_id",resp.data.data.session_id);
        // }, 2000 );


        console.log(' -- -- -- ---',localStorage.getItem("UserId"));
        // window.history.back();
        navigate('/')
    })
    .catch((error) => {
      console.log('Error:', error);
    });
  };

  const resendOtp =()=>{
    const url = getresendotp1()
    console.log(url);
    const userData = {
      mobile: mobileNo
    }
    console.log(userData);
    axios .post(url, userData,{
      headers: {
          "Content-Type": "multipart/form-data",
        },
  })
    .then((resp)=>{
      console.log(' - - - resp  == ',resp.data);
    })
    .catch((error)=>{
      console.log(' - - error',error);
    })
  }



  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
  };

    return (
        <>
        <div style={{width:'100%', textAlign:'center',}}>
        <div className="otp-container" >

          <h3 className='otpVerification'>OTP Verification</h3>
          <div style={containerStyle}>
        <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        // renderSeparator={<span>-</span>}
        renderInput={(props) => <input {...props} style={{ width: '35px' }}/>}
        inputStyle="otp-input"
      />  
      </div>
       <div className='otpbtn'>
      <button className="verification-button" onClick={formHandler} >
        
          Verify
        </button>
        <button className="verification-button2" onClick={resendOtp} >
        
        Resend OTP
      </button>
        </div>
        </div>
        </div>
      </>
    );
  }