import React, { useEffect, useState } from "react";
import "../Aboutus/aboutUs.css";
import NavMenu from "../Navbar";
import Image1 from "../../assets/img/aboutUs/safety.png";
import Image2 from "../../assets/img/aboutUs/excellence.png";
import Image3 from "../../assets/img/aboutUs/partnership.png";
import Image4 from "../../assets/img/aboutUs/integrity.png";
import aboutImg from "../../assets/img/aboutUs/31years 1.png"
import ReactCardFlip from 'react-card-flip';
import Footer from '../Footer'
import certificate from '../../assets/img/aboutUs/Certificate-registration 1.png'
import certificate1 from '../../assets/img/aboutUs/Recognition-Certificate(1) 1.png'
import {HiOutlineMapPin} from 'react-icons/hi'
import {GrMail} from 'react-icons/gr'
import {IoIosCall} from 'react-icons/io'
import {MdLocationPin} from 'react-icons/md'
import { Navigate, useNavigate } from "react-router-dom";

export default function Aboutus() {
  
const [isFlipped, setIsFlipped] = useState(false);
const [isFlipped1, setIsFlipped1] = useState(false);
const [isFlipped2, setIsFlipped2] = useState(false);
const [isFlipped3, setIsFlipped3] = useState(false);
const [cartValues, setCartValues] = useState(false);
const navigate = useNavigate()

useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component is mounted
  window.history.scrollRestoration = 'manual'
}, []);

    const handleMouseEnter = () => {
    setIsFlipped(true);
  };
  const handleMouseLeave = () => {
    setIsFlipped(false);
  };

const leaveThebox = () =>{
  handleMouseLeave()
  handleMouseLeave1()
  handleMouseLeave2()
  handleMouseLeave3()
}

  const handleMouseEnter1 = () => {
    setIsFlipped1(true);
  };
  const handleMouseLeave1 = () => {
    setIsFlipped1(false);
  };



  const handleMouseEnter2 = () => {
    setIsFlipped2(true);
  };
  const handleMouseLeave2 = () => {
    setIsFlipped2(false);
  };


  const handleMouseEnter3 = () => {
    setIsFlipped3(true);
  };
  const handleMouseLeave3 = () => {
    setIsFlipped3(false);
  };

  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
      <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
      <div className="Aboutus">
        <div className="aboutDesc">
          {/* <img src={aboutImg} className="thirtyFirstYEar" /> */}
          <h1 className="aboutTitle">About us</h1>
          <p className="aboutDescText">
          <span onClick={()=>navigate('/')} style={{color:'red', textDecoration:'none', cursor:'pointer'}}>creativecarcare.in</span> is an exclusive E-commerce Platform owned by Creative International.<br/><br/> Welcome to Creative International, a distinguished institution specializing in the Import and Distribution of Premium Car Care products. We take pride in our accreditation and ISO 9001:2015 certification, symbolizing our commitment to excellence. As we celebrate 31 Years of Excellence, our team at Creative International stands as a benchmark in the industry, setting standards that are not just followed but revered.<br/><br/>Our corporate objective is clear: to engage in both online and offline trade, providing the Indian consumer with access to the finest brands. We prioritize partnerships with principles and brands that share our commitment to environmental sustainability. Our focus extends beyond business transactions – we aim for successful and mutually beneficial relationships with both patrons and suppliers. At Creative International, we are dedicated to shaping a future where premium car care meets conscientious business practices.
          </p>
        </div>
        {/* <hr style={{color:'rgba(229, 21, 21, 1)', height:'3px'}}/> */}
        <div
          style={{
            width: "100%",
            height: "2px",
            background: "rgba(229, 21, 21, 1)",
          }}
        ></div>
        <div className="aboutInfo">
          <h3 className="aboutInfoTitle">Our Guiding Principles</h3>

          <div className="imageAboutOf">



          <ReactCardFlip isFlipped={isFlipped}
            flipDirection="horizontal"
              >
            <div className="aboutInfo1Img"  onMouseEnter={handleMouseEnter}  onMouseLeave={handleMouseLeave}
        >
              <img src={Image1} className="imageStyle" alt="safetyIcon"/>
              <p className="teamwork">SAFETY</p>
            
              </div>
              
              <div className="aboutInfoContext" style={{border:'black'}} onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave} onClick={()=>leaveThebox()}>
                  <p style={{color:'red', fontWeight:'bold'}} className="teamWork2">SAFETY</p>
             <p className="aboutPara">We prioritize the utmost standards of health and safety within our workplace, ensuring a secure and protected environment for all.</p>
                </div>
              </ReactCardFlip>
            


              <ReactCardFlip isFlipped={isFlipped1}
            flipDirection="horizontal"
              >
            <div className="aboutInfo1Img" onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}
        >
              <img src={Image2} className="imageStyle" alt="excellenceIcon" />
              <p className="teamwork">EXCELLENCE</p>
            </div>

            <div className="aboutInfoContext2" style={{border:'black'}} onMouseEnter={handleMouseEnter1}
        onMouseLeave={handleMouseLeave1} onClick={()=>leaveThebox()}>
           <p style={{color:'red', fontWeight:'bold'}} className="teamWork2">EXCELLENCE</p>
             <p className="aboutParaEx">We are driven by a commitment to excellence, striving to be industry leaders. Our dedication places customers as our top priority, and we aim to foster enduring value for them in the long term.</p>
                </div>
              </ReactCardFlip>




              <ReactCardFlip isFlipped={isFlipped2}
            flipDirection="horizontal"
              >
            <div className="aboutInfo1Img" onMouseEnter={handleMouseEnter2}  onMouseLeave={handleMouseLeave2}
        >
              <img src={Image3} alt="teamwork" className="imageStyle" />
              <p className="teamwork">TEAMWORK</p>
              </div>
           <div className="aboutInfoContext3" style={{border:'black'}} onMouseEnter={handleMouseEnter2}
        onMouseLeave={handleMouseLeave2} onClick={()=>leaveThebox()}>
           <p style={{color:'red', fontWeight:'bold'}} className="teamWork2">TEAMWORK</p>
             <p className="aboutPara2">We engage in collaborative partnerships with both our suppliers and customers, seeking to maximize the collective impact of individual contributions.</p>
                </div>
              </ReactCardFlip>


              <ReactCardFlip isFlipped={isFlipped3}
            flipDirection="horizontal"
              >
            <div className="aboutInfo1Img" onMouseEnter={handleMouseEnter3}  onMouseLeave={handleMouseLeave3}
       >
              <img src={Image4} className="imageStyle" alt="integrity" />
              <p className="teamwork">INTEGRITY</p>
            </div>
            <div className="aboutInfoContext1" style={{border:'black'}} onMouseEnter={handleMouseEnter3}
        onMouseLeave={handleMouseLeave3} onClick={()=>leaveThebox()}>
          <p style={{color:'red', fontWeight:'bold'}} className="teamWork2">INTEGRITY</p>
             <p className="aboutPara"> We uphold the highest ethical standards in every facet of our actions and conduct.</p>
                </div>
              </ReactCardFlip>


          </div>
          

        </div>
      </div> 
      <div className="certificate">
        <div style={{display:'block'}}>
        <p className="certificateText">Certificates</p>
        <div className="certificate1">
          <img src={certificate} className="certificateImg" alt="certificate"/>
          <img src={certificate1} className="certificateImg2" alt="certificate"/>
        </div>
        </div>
      </div>
      <div
          style={{
            width: "100%",
            height: "2px",
            background: "rgba(229, 21, 21, 1)",
          }}
        ></div>
      <div className="aboutMap">
        <p className="certificateText" style={{marginTop:'10px'}}>Contact us</p>
        <div className="row11"  >
          <div className="row12">
          <a target='_blank' style={{textDecoration:'none', color:'black'}} > <span style={{display:'flex',  gap:'10px'}}><MdLocationPin style={{fontSize:'20px',marginTop:'2px', color:'black', fontWeight:'500'}}/><p style={{marginBottom:'0px', fontFamily:'Rubik', fontWeight:'500', fontSize:'17px'}} >Unit 1, Janata Compound, Senapati Bapat Marg,<br/> Lower Parel West, 400013</p></span></a>
           <a target='_blank' href = "mailto: support@creativecarcare.in" style={{textDecoration:'none', color:'black'}}><span style={{display:'flex',alignItems:'center', gap:'10px', marginTop:'8px', marginBottom:'8px'}}><GrMail style={{fontSize:'18px'}}/><p style={{marginBottom:'0px', fontFamily:'Rubik', fontWeight:'500' , fontSize:'17px'}}>support@creativecarcare.in</p></span></a>
           <span style={{display:'flex',alignItems:'center', gap:'10px'}}><IoIosCall style={{fontSize:'20px'}}/><p style={{color:'red',marginBottom:'0px' , fontSize:'14px'}}> <a href='tel: 99302 90909' className="aboutUsNo" style={{textDecoration:'none', color:'red', fontFamily:'Rubik', fontWeight:'500'}}>+91 99302 90909 </a>| <a target='_blank' className="aboutUsNo" style={{textDecoration:'none', color:'red', fontFamily:'Rubik', fontWeight:'500'}} href='tel: 99301 90909'>+91 99301 90909</a>| <a target='_blank' className="aboutUsNo" style={{textDecoration:'none', color:'red', fontFamily:'Rubik', fontWeight:'500'}} href='tel: 022 2497 9090'>+91 22 2497 9090</a></p></span>
          </div>
          <div className="googleMap">
 <iframe className="googleMap1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30179.962722546996!2d72.80682874797535!3d18.99788242455667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cef598110ba7%3A0xea6bb83f5ad3572!2sCreative%20International!5e0!3m2!1sen!2sin!4v1689256895464!5m2!1sen!2sin" width="360" height="280"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>      
          </div>
        </div>
         
            </div>
       
      <Footer/>
    </>
  );
}
