// const url =  'http://192.168.0.105:8000/api/'
const url =  'https://admin.creativecarcare.in/api/'
export default url


export const getBrands=()=>{
    return url + 'getbrands'
}

export const getBrandsWithId=(id)=>{
    return url + 'getbrands/'+ id
}

export const categoryData=()=>{
    return url + 'getcategorywiseproduct/'
}
// export const productData=()=>{
//     return url + 'product2'
// }
export const productData=()=>{
    return url + 'product3'
}

export const productData2=()=>{
    return url + 'bulk-product'
}

// export const productCategory=()=>{
//     return url + 'show_product'
// }

export  const productCategory=()=>{
    return url +'product-description'
}

export const loginApi=()=>{
    return url + 'store3'
}

export const tempData=()=>{
    return url + 'store-temp-user'
}

export const tempLogin = ()=>{
    return url + 'login-temp'
}
// export const loginApi=()=>{
//     return url + 'register'
// }
export const getSpecialCategoryApi=(id)=>{
    return url + 'getspecialcategory/'+ id
}
export const getSpecialCategoryApi2=(id)=>{
    return url + 'getspecialcategory-all/'+ id
}

export const getUserLogin = ()=>{
    return url + 'getotp2'
}

export const getUserOtp =()=>{
    return url + 'login'
}

export const getWhishlist=()=>{
    return url + 'getwishlist'
}

export const fertchWhishlist=(id)=>{
    return url + 'getwishlist/'+id
}

export const getuserdetail=(id)=>{
    return url + 'userdetail/'+id
}

export const deleteWishList = (id)=>{
    return url + 'getwishlist/' + id
}

export const getcartId =()=>{
    return url + 'getcart'
}

export const getcartbyId =()=>{
    return url + 'cart_with_summary'
}

export const getCartByItsId =(id)=>{
    return url + 'getcart/' + id
}

export const getIncrement = ()=>{
    return url + 'getcart/increment'
}

export const getdecrement = ()=>{
    return url + 'getcart/decrement'
}

export const getresendotp = ()=>{
    return url + 'resend_otp'
}

export const getresendotp1 = ()=>{
    return url + 'resend-temp-otp'
}


export const getAccountDetails = (id)=>{
    return url + 'userdetail/' +id
}

export const getUserAddress =()=>{
    return url + 'getaddress'
}

export const getUserAddressbyId =(id)=>{
    return url + 'getaddress/' + id
}

export const getReview = ()=>{
    return url + 'get_reviews'
}

export const getRewardById=(id)=>{
    return url + 'reward/'+id
}

export const getSimilarProduct = ()=>{
    return url + 'similarproduct'
}
export const getSimilarProduct2 = ()=>{
    return url + 'extrabond_similar'
}
export const getSimilarProductdewalt = ()=>{
    return url + 'showloozenanddewalt'
}

export const getCouponValue=()=>{
    return url + 'get_coupon'
}

export const getSummary =()=>{
    return url + 'summary'
}

export const checkWalletPoint =()=>{
    return url + 'check_wallet'
}

export const logoutUser =(id)=>{
    return url + 'logout/'+id
}

export const openPackDetails =() => {
    return url + 'show_product_pack'
}

export const getProductViaCategory = (id)=>{
    return url + 'get_product_via_category_id/'+id
}

export const checkUserPoints = ()=>{
    return url + 'check_wallet'
}

export const serachApi = ()=>{
    return url + 'search'
}

export const getTheOrder =(id)=>{
    return url + 'myorder/' + id
}

export const getBrandFilterProduct = () =>{
    return url + 'brand_filter6'
}
// export const getBrandFilterProduct = () =>{
//     return url + 'brand_filter5'
// }


export const getMyOrder = (id)=>{
    return url + 'getOrderDetailedInformation/' + id
}

export const productFilter = ()=>{
    return url + 'filter'
}

export const getAllProductCategory = (id) =>{
    return url + 'get_product_via_category_id_all/'+id
}

export const getBrandFilter = () =>{
    return url + 'brand_filter2'
}

export const getBrandFilterData = () =>{
    return url + 'get_brand_Filter_data'
}

export const getTestimonial = ()=>{
    return url + 'Testimonial'
}

export const getStoredOrder = () =>{
    return url + 'getorder'
}
export const getStoredOrder2 = () =>{
    // return url + 'getorder2'
    return url + 'getorder3'
}

export const orderReturn =()=>{
    return url + 'order_return'
}

export const profilePhoto = ()=>{
    return url  + 'profile_photo'
}

export const downloadInvoice =(id)=>{
  return url + 'download-invoice/'+id  
//  return `${url}download/${id}`
}


// export const getBrandsAllData=()=>{
//     return url + 'get_brand_Filter_data'
// }
export const getBrandsAllData=()=>{
    return url + 'get_brand_Filter_data2'
}

export const editProfielDetails = ()=>{
    return url + 'edit_profile'
}

export const userValidation=()=>{
    return url + 'user_validation'
}

export const saveTheReview = ()=>{
    return url + 'save_review'
}

export const getTheSlider = ()=>{
    return url + 'slider'
}

export const getTheSlider2 = ()=>{
    return url + 'live_banner'
}

export const getTheDelete = (id)=>{
    return url + 'delete_cart/' + id
}

export const forgetPassowrd = ()=>{
    return url + 'forget_password_validation'
}

export const changePassword = ()=>{
    return url + 'forget_password'
}

export const generateTheToken = ()=>{
    return url + 'token_generator'
}

export const prefilAddress = (id)=>{
    return url + 'get_prefilled_address/'+id
}

export const deleteTheAddress=(id)=>{
    return url + 'getaddress/'+id
}

export const toUpdateAddress=(id)=>{
    return url + 'show-address/'+id
}

export const updateSuccessfully=()=>{
    return url + 'update-address'
}

export const bulkAllDetails=()=>{
    return url + 'bulk-order'
}

export const extrabondCategory=()=>{
    return url + 'extra-bond-categories2'
}
export const extrabondCategory2=()=>{
    return url + 'extra-bond-categories'
}

export const productOfBrand=(id)=>{
    return url + 'brand_products/'+id
}

export const whatsappMsg= (id)=>{
    return url + 'alert_gift_user/' + id
}

export const generateNumber = ()=>{
    return url + 'generate_code_number'
}
export const giftCodeGenerater =()=>{
    return url +'generate_gift_code'
}

export const giftValifityChecker = ()=>{
    return url + 'check_validity'
}

export const lisOfCoupon = () =>{
    return url + 'coupon/list_coupon'
}

export const couponValues = () =>{
    return url + 'coupon/coupon_value'
}

export const couponUsed =()=>{
    return url + 'coupon/coupon_used'
}

export const voucherUsed=()=>{
    return url + 'use_code'
}

export const hotDeals = ()=>{
    return url + 'hot_deals-product'
}