import React, { useState } from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'

export default function Return() {
  const [cartValues, setCartValues] = useState(false);
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
    <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
    <div className='mainPrivacy'>
      <h1 className='creativeCarCare'> Return / Refund Policy</h1>
      <div className='privacyPolicy'>
        <h3 className='cookies'>Return:</h3><br/>
        Creativecarcare.in, does accept returns. We expect our customer’s in all fairness to read about the product carefully, know what you are purchasing from us and enjoy the results of the same when you do receive it.
        {/* In the rare event that you are not fully satisfied with what you ordered, just return it to us within 3 days of delivered date in the original packaging and we will refund you, subject to 15% restocking fees.
        <ol>
          <li>* After 3 days of Delivered date we will not accept any refunds or returns.</li>
          <li>*  All items must be returned in the original package, cannot be damaged or installed, and must be in original condition.</li>
          <li>*  All Liquid / Gel / Aerosol products are not returnable . </li>
          <li>* For all returns, Please whatsapp or sms on +919920290909 or email us at : info@creativecarcare.in - with your order number / invoice number & specific reason for return </li>
        </ol> */}
        <h3 className='cookies2'>Replacement:</h3>
        If you have received an item in a 'Damaged', 'Defective' or ‘Leaked’ condition, or it is 'Not as Described', you may request a replacement within 24 hours of delivery at no extra cost, or in case we can’t replace the product we will give you a full refund.

When packaging your items for shipping, include a copy of the original invoice in the package.

We strongly recommend packing any items with fragile components with sufficient padding. Note that we are not responsible for products being returned to us that are damaged mid-shipment.

For all returns, please call +91-9930290909 between 10 am – 7 pm or email at: customercare@creativecarcare.in. Provide a specific reason for your replacement.
 <h3 className='cookies2'>Refund Policy:</h3>
 In the event of an error on our part, such as sending a damaged product or the wrong item, we will arrange a reverse pickup for your convenience. Please ensure that the product is repackaged in its original condition as it was received.

Upon approval of the return and after discussing the matter with the customer, the refund will be initiated. This refund will be processed according to the bank details provided by you in the return form. Please allow 5-7 days for the refund to be credited to your account after the return is approved and the necessary discussions have taken place.

Your satisfaction is our priority, and we strive to ensure a seamless return experience for you. If you have any further questions or concerns, please don't hesitate to reach out to our customer service team.
<h3 className='cookies2'>Pricing Policy:</h3>
The Price displayed for products on our website represents the full retail price listed on the product itself as shared by the Importer / Distributor.

While we attempt to ensure that this information is accurate, there may be cases where the price of the product delivered is different from the price given on the website due to various reasons including time lag in updating prices post changes in price, the different prices being used by manufacturers in different regions

In this case, we will only charge you the price you actually place the order.

<h3 className='cookies2'>Contact Us:</h3>
If you wish to contact us with any further questions, comments, or concerns, please call +91 9930290909 between 10 am – 7 pm or you could email us at customercare@creativecarcare.in.

      </div>
    </div>
    <Footer/>
    </>
  )
}
