import React, { useEffect, useState } from "react";
import { FaWallet } from "react-icons/fa";
import { getRewardById } from "../../api/api";
import axios from "axios";
import { useSelector } from "react-redux";
import { AiOutlineArrowUp } from 'react-icons/ai'

export default function Wallet() {
  const storedData = useSelector((state) => state.profile);

  const [walletInfo, setwalletInfo] = useState([]);
  const [AllwalletInfo, setAllwalletInfo] = useState([]);
  const [walletINfoError, setwalletINfoError] = useState(false);

  useEffect(() => {
    getReward(storedData.userId);
    window.scrollTo(0, 0);
  }, []);

  function refreshPage(){
    window.scrollTo(0, 0);
}

  const getReward = (id) => {
    const url = getRewardById(id);
    axios
      .get(url)
      .then((resp) => {
        if (resp.data.data == "") {
          setwalletINfoError(true);
        } else {
          setwalletInfo(resp.data.data);
          setAllwalletInfo(resp.data);
          setwalletINfoError(false);
          console.log(" = = = =reward", walletInfo);
        }
      })
      .catch((error) => {
        console.log(" = = =error", error);
        setwalletINfoError(false);
      });
  };

  return (
    <>
      <div className="wallet">
        <h1 className="myAccount">My Wallet</h1>
        <h4 className="hello">Hello, {storedData.userName}</h4>
        <div></div>
        {walletINfoError ? (
          <h4 className="noTransFound" style={{ fontFamily: "Rubik" }}>
            No Transaction for Now
          </h4>
        ) : (
          <>
            <div className="walletDetails">
              <p className="yourWallet2">Your Wallet</p>
              <h3 className="walletbalance">
                <FaWallet className="fawallet" />{" "}
                <span className="balancespan">
                  ₹ {AllwalletInfo.Total_points}
                </span>
              </h3>
            </div>
            <p className="headlinetn">Wallet transaction</p>
            {walletInfo.map((item) => {
              const isCredit = item.txn_type === "credit";
              const sign = isCredit ? "+" : "-";

              return (
                <>
                  <div className="transaction">
                    <p className="rewardtype">{item.txn_type}</p>
                    <div className="transactiondetails">
                      <div className="transcontent">
                        <p className="yourWallet">{item.msg}</p>
                        <p className="yourDate">{item.created_at}</p>
                      </div>
                      <h3
                        className={`transbalance ${
                          isCredit ? "credit" : "debit"
                        }`}
                      >
                        {sign} INR {item.reward_point}
                      </h3>
                    </div>
                    <hr className="transhr" />
                  </div>
                </>
              );
            })}
          </>
        )}
        <div onClick={refreshPage} className='topBtn'>
          < AiOutlineArrowUp size={25} style={{ color: 'white', }} />
        </div>
      </div>
    </>
  );
}
