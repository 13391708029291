
export const userDetail = (data) => {
  return {
    type: 'LOGIN_SUCCESS',
    payload: data,
  };
};

export const logOut = () => (dispatch) => dispatch(logOutuser());

export const logOutuser = () => ({
  type: 'LOG_OUT',
});


export const UPDATE_CART_DATA = 'UPDATE_CART_DATA';
export const updateCartData = (cartData) => {
  return {
    type: 'UPDATE_CART_DATA',
    payload: cartData,
  };
};