import React, { useState } from "react";
import { getCouponValue } from "../../api/api";
import { useEffect } from "react";
import axios from "axios";
import moment from "moment";

export default function Coupon() {
  const [coupon, setCoupon] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  useEffect(() => {
    getCoupon();
  }, []);

  const getCoupon = () => {
    const url = getCouponValue();

    axios
      .get(url)
      .then((resp) => {
        console.log("---value_type", resp.data.data[0].value_type);
        setCoupon(resp.data.data);
      })
      .catch((error) => {
        console.log("--error---resp", error);
      });
  };

  const handleCheckboxChange = (item) => {
    setSelectedCoupon(item);
  };

  return (
    <>
      <div className="CouponInput">
        <input className="couponIpt" placeholder="Type Coupon Code" />
        <button className="applyCpn">Apply</button>
      </div>
      {coupon.map((item) => {
        return (
          <div className="CouponDetails" key={item.coupon_code}>
            <span style={{ display: "flex" }}>
              <input
                type="checkbox"
                className={`couponCheckbtn ${
                  selectedCoupon === item ? "checked" : ""
                }`}
                checked={selectedCoupon === item}
                onChange={() => handleCheckboxChange(item)}
              />
              <h3 className="couponHeading"> {item.coupon_code}</h3>
            </span>
            {item.value_type === "₹" ? (
              <p className="cuponspecs">
                <span className="cuponspecs2">Save INR {item.value}</span>
              </p>
            ) : (
              <p className="cuponspecs">
                <span className="cuponspecs2">Save {item.value}%</span>
              </p>
            )}
            <p className="cuponspecs">{item.coupon_description}</p>
            <p className="cuponspecs">
              Expires on: {moment(item.end_date).format("MMM Do YY")}
            </p>
          </div>
        );
      })}
      <div className="couponFooter">
        <span style={{ display: "flex" }}>
          <p className="totalCoupon">
            You Saved{" "}
            <span className="couponAmount">
              {selectedCoupon
                ? selectedCoupon.value_type === "₹"
                  ? `Rs. ${selectedCoupon.value}`
                  : `${selectedCoupon.value}%`
                : "0"}
            </span>
          </p>
          <button className="couponApply"> Apply</button>
        </span>
      </div>
    </>
  );
}
