import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import axios from "axios";
import NavMenu from "./Navbar";
import Footer from "./Footer";
import Feature from "./Feature";
import Brands from "./Brands";
// import Carousel from "react-bootstrap/Carousel";
import Testimonial from "./Testimonial";
import LoadingSpinner from "./loader/LoadingSpinner";
import readmorepic from "../assets/img/Home/a.readmore.png";
import {
  getBrands,
  getSpecialCategoryApi,
  getTestimonial,
  getTheSlider,
  getTheSlider2,
  getcartbyId,
} from "../api/api";
                                                  
import { userDetail, logOut } from "../redux/ActionCreator";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { ColorRing, TailSpin } from "react-loader-spinner";
const Home = () => {
const storedData = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [brandName, setBrandName] = useState([])
  const [sectionTwoFull, setSectionTwoFull] = useState({});
  const [sectionTwoProductInfo, setSectionTwoProductInfo] = useState([]);
  const [isSectionTwoLoading, setIsSectionTwoLoading] = useState(false);
  const [cartValues, setCartValues] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [reloadFeature, setREloadFeature] = useState(false)

  const [slider, setSlider]= useState([])
  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false)
    }, 500);
    getCategoryOnlyFunc(storedData);
    // window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0)
    console.log('hello',storedData.userId);
    console.log(storedData);
    getSlider()
  }, []);

  
  const getCategoryOnlyFunc = (id) => {
    setIsSectionTwoLoading(true);
    const url = getSpecialCategoryApi(id);

    axios
      .get(url)
      .then((resp) => {
        const clearanceSaleData = resp.data.data[2].clearance_sale.data;
        if (clearanceSaleData.length === 0) {
          setSectionTwoFull(resp.data.data[2].clearance_sale);
          setIsSectionTwoLoading(false);
        } else {
          setSectionTwoFull(resp.data.data[2].clearance_sale);
          setSectionTwoProductInfo(clearanceSaleData);
          setIsSectionTwoLoading(false);
          console.log('home feature');
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSectionTwoLoading(false);
      });
  };

  const sliderSettings = {
    dots: true,
    autoplay:true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, 
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 560, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 840, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1100, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1300, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  if (sectionTwoProductInfo.length === 2) {
    sliderSettings.slidesToShow = 2;
  } else if(sectionTwoProductInfo.length === 3){
    sliderSettings.slidesToShow = 3
  }
  else {
    sliderSettings.slidesToShow = 4; // Set the default to show 4 products
  }
  const logoutUser = () => {
    dispatch(logOut());
    // console.log("user logout");
  };

  const toShop = () => {
    navigate('/shop');
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3001 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1025 },
      items: 1,
      arrows: true
    },
    tablet: {
      breakpoint: { max: 1024, min: 465 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const responsive2 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      dots: true
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

const hello = ()=>{
  for (let i = 0;i<=10;i++){
    console.log(i);
  }
}
 
// const getSlider = ()=>{
//   const url = getTheSlider()
//   axios.get(url)
//   .then((resp)=>{
//     console.log('hello', resp.data.data);
//   setSlider(resp.data.data)
//   })
//   .catch((error)=>{
//     console.log('error the slider', error);
//   })
// }


const getSlider = ()=>{
  const url = getTheSlider2()
  axios.get(url)
  .then((resp)=>{
    console.log('hello', resp.data.data);
  setSlider(resp.data.data.Home)
  })
  .catch((error)=>{
    console.log('error the slider', error);
  })
}

const getThebrands = () => {
  const url = getBrands()

  axios.get(url)
    .then((resp) => {
      setBrandName(resp.data.data)
    })
    .catch((error) => {
      console.log('--error--');
    })
}

const handleModalClose = () => {
  setREloadFeature(true)
  // getCartData()
  // Your code to run when the modal is closed goes here
  console.log('Modal is closed, running effect in CartPage...');
};
  return (
    <>
      <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues}  cartBoolean={cartValues} />
      <ToastContainer />
      {
        pageLoading ? (<>
          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'black'}}>
            <TailSpin  color="#ffd11b" />
          </div>
          </>)
          :(<>
           <div>
      <Carousel
          className="carouselSlider"
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          arrows={true} 
          // showDots={true}
          emulateTouch={true}
          renderButtonGroupOutside={true}
          renderDotsOutside={false} 
          // centerMode={centerMode}
          rewindWithAnimation={true}
        > 
        {slider.map((item)=>{
          const toBrandPage=()=>{
            navigate(item.url)
          }
          return(
            <>
              <a href={item.url} target='_blank' className='sliderResp'>
                <div className="sliderCon container-fluid"   style={{backgroundImage:`url(${item.image_url})`}}>
        <div className="container HomeSliderCon">
          <div className="row newRow">
            <div className="content content2">
              {/* <p className="Homeslidertitle">experience the power within</p> */}
              <div className="viewCol">
                <img
                  // onClick={toShop}
                  style={{ width: "60%" }}
                  src={readmorepic}
                  alt="Read More"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </a>
      <a href={item.url} target='_blank' className='sliderResp2'>
       <div className="sliderCon container-fluid"   style={{backgroundImage:`url(${item.image_url2})`}}>
        <div className="container HomeSliderCon">
          <div className="row newRow">
            <div className="content content2">
              <div className="viewCol">
                <img
                  // onClick={toShop}
                  style={{ width: "60%" }}
                  src={readmorepic}
                  alt='BrandBanner'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </a>
            </>
          )
        })}
    
      </Carousel>



      <div className="container-fluid"></div>
      <Brands />
      <div className="decreaseSpace">
        <Feature Reload={reloadFeature} setReload={setREloadFeature} setCartBoolean={setCartValues} cartBoolean={cartValues} />
      </div>
      <div className="HomeFeature">
        <div className="container featureContainer2">
          <p className="HomeFeatureTitle">
            <i
              className="fa-solid fa-star"
              style={{ color: "red", marginRight: "10px" }}
            ></i>
            &nbsp;
            {sectionTwoFull.name} &nbsp;{" "}
            <i className="fa-solid fa-star" style={{ color: "red" }}></i>
          </p>
          {/* <div className="row featureCardContainer">
            {isSectionTwoLoading ? (
              <LoadingSpinner />
            ) : sectionTwoProductInfo.length === 0 ? (
              <p style={{ textAlign: "center", fontSize: "17px", color: "#fff" }}>
                {sectionTwoFull.message}
              </p>
            ) : (
              sectionTwoProductInfo.map((item, index) => (
                <div
                  key={index}
                  onClick={() =>
                    navigate(`/product/${item.product_id}`, {
                      state: { productId: item.product_id },
                    })
                  }
                  className="col-lg-3 col-md-5 col-8 featureCardHome"
                >
                  <div className="FeatImgDiv">
                    <img src={item.image_url} className="imagehere" alt="Product" />
                  </div>
                  <div className="fullHomeFeat">
                    <p className="homeFeatureStar">
                      <ReactStars edit={false} value={item.rating} count={5} size={22} />
                    </p>
                    <p className="HomeFeatCardTitle">
                        
                        {item.product_name.split(' ').slice(0, 7).join(' ')}
                        {item.product_name.split(' ').length > 7 ? '...' : ''}
                        </p>
                    <span className="featureOffer featureOffer2">
                      <strong>
                        {parseInt(((item.mrp - item.starting_price) / item.mrp) * 100)}% Off
                      </strong>
                    </span>
                    <span className="productMrp">
                      <p className="HomeFeatPrice">INR {item.starting_price}</p>
                      <p className="HomeFeatPrice2">
                        INR <strike style={{ fontSize: "14px" }}>{item.mrp}</strike>
                      </p>
                    </span>
                  </div>
                </div>
              ))
            )}
          </div> */}

<Carousel
          // className='featureCaurnew'
          responsive={responsive2}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={10000}
          arrows={true} 
          showDots={false}
          emulateTouch={true}
          renderButtonGroupOutside={true}
          renderDotsOutside={false} 
          // centerMode={centerMode}
          
          rewindWithAnimation={true}
          
        >

       {/* <Slider className='featureCaurnew' {...sliderSettings}> */}
          {sectionTwoProductInfo.map((item,index) => {
            return (
              <>
              {/* <a  href={`/product/${item.product_id}`} style={{textDecoration:'none'}}> */}
              <div 
                
                // onClick={() =>
                //   navigate(`/product/${item.product_id}`, {
                //     state: { productId: item.product_id },
                //   })
                // }
                
             className='featureCard' key={item.id}>
                <div  style={{ display: 'block', backgroundColor:'#EEEEEE' }} >
                <a  href={`/product/${item.product_id}`} style={{textDecoration:'none'}}>
                   <div className='featureOfImg'   style={{ backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundSize: "95% 95%",  padding: 10, backgroundPosition: 'center', mixBlendMode: 'multiply' ,backgroundOrigin: 'content-box', backgroundPositionX: 'center' }}>
                    <img src={ item.image_url} style={{width:'100%'}} alt={item.product_name}/>
                     

                    </div>
                                   
                 </a>
                </div>
               
                <div className='feratureStar'>
                  <ReactStars edit={false} value={item.rating} count={5} size={18}/>
                  <div style={{ paddingRight: 20, marginTop: '5%'}}>
                       
                      </div>
                </div>
                 
                <hr className='featureHr'   />
                <a  href={`/product/${item.product_id}`} style={{textDecoration:'none'}}>
                <OverlayTrigger
                                 placement="bottom"  
                                 overlay={
                                   <Tooltip style={{ backgroundColor: '#333', color: '#fff', borderRadius: '4px', fontSize: '12px' }}>
                                     {item.product_name}
                                   </Tooltip>}
                               >
              <p className='featureText2' >
                
                {item.product_name.split(' ').slice(0, 7).join(' ')}
                {item.product_name.split(' ').length > 7 ? '...' : ''}
              </p>
              </OverlayTrigger>
                <hr className='featureHr'  />
                  </a>
                  <a  href={`/product/${item.product_id}`} style={{textDecoration:'none'}}>
                <div className='featureOffering'>
                  <span className='featureOffer'  ><strong>{parseInt(((item.mrp-item.starting_price)/item.mrp)*100)}% Off</strong></span>
                  </div>
                  </a>
                <p className="ourPrice2">Our Price</p>
                <a  href={`/product/${item.product_id}`} style={{textDecoration:'none'}}>
                <div className='featurePrizeContainer'>
                  <span className='HomeFeatPrice'  >₹ {item.starting_price}</span>
                  <span className='HomeFeatPrice2'  >MRP <strike style={{fontSize:'15px'}}>₹{item.mrp }</strike></span>
                  </div>
                </a>
                </div>
                
              </>
            )
          })
          }
       
        {/* </Slider> */}
        </Carousel>

        <div style={{display:'flex', justifyContent:'center', marginTop:'12px'}}>
                  <button onClick={()=>navigate('/clearance')} style={{ color:'black', justifyContent:'center', fontWeight:'500', background:'#FFD11B', border:'none', borderRadius:'12px', padding:'8px 25px'}}>View All</button>
                </div>
        </div>


      </div>
      <Testimonial />
      <Footer />
      </div>
          </>)
      }
     
    </>
  );
};

export default Home;

