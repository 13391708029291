import React, { useEffect, useRef, useState } from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'
import './giftcoupon.css'
import { useSelector } from 'react-redux'
import { generateTheToken, giftCodeGenerater, whatsappMsg } from '../../api/api'
import axios from 'axios'
import $ from "jquery";
import giftCard from '../../assets/img/giftcoupon/Asset 2 1.png'
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from 'react-router-dom'

function GiftCoupon() {


  const location = useLocation();
  const [senderName, setsenderName] = useState();
const [receiverName, setreceiverName] = useState('');
const [senderNumber, setsenderNumber] = useState();
const [message, setmessage] = useState()
const [receiverNumber, setREceiverNumber] = useState();
const [amount, setamount] = useState();
// const [tokenValue, setTokenValue] = useState("");
var tokenValue;
const pnCheckoutRef = useRef(null);
// const { verificationValue, respStatus } = location.state;
if (location.state !== null) {
  var { verificationValue, respStatus } = location.state;
  // Now you can safely use verificationValue and respStatus here
} else {
  // Handle the case where location.state is null
  console.error("location.state is null");
}

const storedData = useSelector((state) => state.profile);

useEffect(() => {
  window.scrollTo(0, 0);
  pnCheckoutRef.current = window.pnCheckout;
  jQuerycode();
}, []);

useEffect(() => {
  if (respStatus) {
    generateGiftCode();
    console.log("==>verification value", verificationValue);
    console.log(respStatus);
  } else {
    console.log("==tokenDesired==", verificationValue);
    console.log("==tokenvalue==", verificationValue);
    console.log(respStatus);
  }
}, []);

const min = 1000000000; // Minimum 10-digit number
const max = 9999999999; // Maximum 10-digit number
var transactionId = Math.floor(Math.random() * (max - min + 1)) + min;
const transionString = transactionId.toString();

const showAllValue = ()=>{
  console.log(receiverName);
  console.log(senderName);
  console.log(receiverNumber);
  console.log(message);
  console.log(senderNumber);
  console.log(amount);
  localStorage.setItem('receiver name', receiverName);
localStorage.setItem('receiver number', receiverNumber);
localStorage.setItem('amount', amount);
localStorage.setItem('sender name', senderName);
localStorage.setItem('sender number', senderNumber);
localStorage.setItem('mmessage', message)

if(receiverName && receiverNumber.length == 10  && amount > 0 && senderName && senderNumber.length == 10  && message){
  if (storedData.userLoginSuccess) {
    generateToken();
    // generateGiftCode();
  } else {
      alert('please login')
  }
}else{
  alert('Please Fill all details properly')
}

}


const generateToken = () => {
    const url = generateTheToken();
    const body = {
      value1: "L954243",
      value2: '1708068696283',
      value3: amount,
      value4: "",
      value5: storedData.userId,
      value6: storedData.userNumber,
      value7: storedData.userEmail,
      value8: "",
      value9: "",
      value10: "",
      value11: "",
      value12: "",
      value13: "",
      value14: "",
      value15: "",
      value16: "",
      value17: "4380966650VNJYXS",
    };
    console.log("token body", body);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("==>", resp.data);
        // setTokenValue(resp.data.guessed_token);
        tokenValue = resp.data.guessed_token;
        setTimeout(() => {
          handlePayClick();
        }, 2000);
      })
      .catch((error) => {
        console.log("==>error", error);
      });
  };

  function handleResponse(res) {
    console.log("---", res);
    if (
      typeof res !== "undefined" &&
      typeof res.paymentMethod !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction.statusCode !== "undefined" &&
      res.paymentMethod.paymentTransaction.statusCode === "0300"
    ) {
      // Payment was successful, you can proceed with further actions here
      // For example, you can trigger the returnUrl here
    } else if (
      typeof res !== "undefined" &&
      typeof res.paymentMethod !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction.statusCode !== "undefined" &&
      res.paymentMethod.paymentTransaction.statusCode === "0398"
    ) {
      // Payment was initiated
    } else {
      // Payment encountered an error
    }
  }

function handlePayClick() {
    console.log("hello");
    const configJson = {
      tarCall: false,
      features: {
        showPGResponseMsg: true,
        enableExpressPay: true,
        enableNewWindowFlow: true,
      },

    "consumerData": {
        "deviceId": "WEBSH2",    //possible values "WEBSH1" or "WEBSH2"
        "token": tokenValue,
        "returnUrl": "https://admin.creativecarcare.in/api/token_generator/index2 ",    //merchant response page URL
        "responseHandler": handleResponse,
        "paymentMode": "all",
        "merchantLogoUrl": "https://www.paynimo.com/CompanyDocs/company-logo-vertical.png",  //provided merchant logo will be displayed
        "merchantId": "L954243",
        "currency": "INR",
        "consumerId": storedData.userId,
        "consumerMobileNo": storedData.userNumber,
        "consumerEmailId": storedData.userEmail,
        "txnId": "1708068696283",   //Unique merchant transaction ID
        "items": [{
            "itemId": "first",
            "amount": amount,
            "comAmt": "0"
        }],
        "customStyle": {
            "PRIMARY_COLOR_CODE": "#45beaa",   //merchant primary color code
            "SECONDARY_COLOR_CODE": "#FFFFFF",   //provide merchant's suitable color code
            "BUTTON_COLOR_CODE_1": "#2d8c8c",   //merchant's button background color code
            "BUTTON_COLOR_CODE_2": "#FFFFFF"   //provide merchant's suitable color code for button text
        }
    }
    }; // <-- Add a comma here
    console.log("payent value", configJson);

    // console.log(" dsdssd => ", $);
    // $(document).ready(function() {
    // Initialize the plugin or call pnCheckout here
    window.$.pnCheckout(configJson);
    if (configJson.features.enableNewWindowFlow) {
      window.$.pnCheckoutShared.openNewWindow();
    }
   
  }
  const jQuerycode = () => {
    $(".button").click(function () {
      $("p").css("color", "red");
    });
  };


  const generateGiftCode = ()=>{
    const url = giftCodeGenerater()
    const body={
      sender_mobile : localStorage.getItem('sender number'),
      reciever_mobile : localStorage.getItem('receiver number'),
      amount : localStorage.getItem('amount'),
      message : localStorage.getItem('mmessage'),
      sender_name : localStorage.getItem('sender name'),
      reciever_name : localStorage.getItem('receiver name'),
      response_id : verificationValue.tpsl_txn_id,
      response_amount :localStorage.getItem('amount'),
      payment_mode : "Worlidne",
      response_currency : "₹",
      response_payment_id: "MOJO3704A05A79942508",
      response_billing_instrument:
        "International Regular Credit Card (Visa/Mastercard)",
      response_instrument_type: "CARD",
      response_status: "Credit",
      response_created_at: verificationValue.tpsl_txn_time,
      RESPCODE: verificationValue.txn_status,
      RESPMSG: verificationValue.txn_msg,
      BANKTXNID: verificationValue.BankTransactionID,
      CHECKSUMHASH: verificationValue.hash,
      merchant_id: "T954243",
      GATEWAYNAME: "worldline",
      BANKNAME: verificationValue.alias_name,
      worldline_response: verificationValue,
      }

      // const body={
      //   sender_mobile : localStorage.getItem('sender number'),
      //   reciever_mobile : localStorage.getItem('receiver number'),
      //   amount : localStorage.getItem('amount'),
      //   message : localStorage.getItem('mmessage'),
      //   sender_name : localStorage.getItem('sender name'),
      //   reciever_name : localStorage.getItem('receiver name'),
      //   response_id : '',
      //   response_amount :localStorage.getItem('amount'),
      //   payment_mode : "Worlidne",
      //   response_currency : "₹",
      //   response_payment_id: "MOJO3704A05A79942508",
      //   response_billing_instrument:
      //     "International Regular Credit Card (Visa/Mastercard)",
      //   response_instrument_type: "CARD",
      //   response_status: "Credit",
      //   response_created_at: '',
      //   RESPCODE: '',
      //   RESPMSG: '',
      //   BANKTXNID: '',
      //   CHECKSUMHASH: '',
      //   merchant_id: "T954243",
      //   GATEWAYNAME: "worldline",
      //   BANKNAME: '',
      //   worldline_response: '',
      //   }
  
    
      console.log(body);
    axios.post(url, body,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp)=>{
      console.log(resp.data.status);
      if(resp.data.status){
        toast.success(resp.data.message, { icon: "❤️" });
        // window.location.href = `https://admin.creativecarcare.in/api/alert_gift_user/${resp.data.id}`;
        localStorage.removeItem('receiver name');
        localStorage.removeItem('receiver number');
        localStorage.removeItem('amount');
        localStorage.removeItem('sender name');
        localStorage.removeItem('sender number');
        localStorage.removeItem('mmessage');
        whatsapp(resp.data.id)
      }else{
        toast.success("Something went wrong", { icon: "⚠️" });
      }
    })
    .catch((error)=>{
      console.log(error);
    })
    }

    const whatsapp = (id)=>{
      const url = whatsappMsg(id)
      axios.get(url)
      .then((resp)=>{
        console.log(resp);
      })
      .catch((error)=>{
        console.log(error);
      })
    }


  return (
   <>
    <Toaster />
    <NavMenu/>
    <div className='giftCoupon'>
        {/* <button onClick={handlePayClick}>Submit here</button> */}
      <div className='container'>
      <div className='row'>
        
        <div className='col-lg-7 giftCouponDetailsMain'>
        <div className='giftCouponDetails'>
        <h3 style={{fontFamily: 'Oswald'}}>Who is this for?</h3>
        <div>
          <input placeholder='To' className='inputTo' value={receiverName} onChange={(e)=>{setreceiverName(e.target.value)}}  />
          <input placeholder='Recipient’s phone number' className='inputTo' value={receiverNumber} onChange={(e)=>{setREceiverNumber(e.target.value)}}/>
          <textarea placeholder='Type your message' className='tetMessageGift' value={message} onChange={(e)=>{setmessage(e.target.value)}}/>
          <p className='maxCharacter'>Max 500 charcters</p>
        </div>
        <h3>How much would you like to gift?</h3>
        <div>
        <input placeholder='₹ Enter ammount (Max 10000)' className='inputTo' value={amount} onChange={(e)=>{setamount(e.target.value)}}/>
        <div className='giftPriceBtn'>
          <button onClick={()=>setamount(250)}>₹250</button>
          <button onClick={()=>setamount(500)}>₹500</button>
          <button onClick={()=>setamount(1000)}>₹1000</button>
          <button onClick={()=>setamount(2000)}>₹2000</button>
        </div>
        <input placeholder='From' className='inputTo' value={senderName} onChange={(e)=>{setsenderName(e.target.value)}}/>
        <input placeholder='Your phone number' className='inputTo' value={senderNumber} onChange={(e)=>{setsenderNumber(e.target.value)}}/>
        <p>We will be sending you the confirmation over here</p>
        </div>
        {/* <button className='giftcouponSubmit' onClick={()=>generateGiftCode()}>Proceed To Pay</button> */}
        <button className='giftcouponSubmit' onClick={()=>showAllValue()}>Proceed To Pay</button>
        </div>
        </div>
        

        <div className='col-lg-5 giftCouponCardsMain'> 
        <div className='giftCouponCards'>
        <div className='previewSection'>
          <h3>PREVIEW</h3>
        </div>
        <div>
          <img  style={{width:'100%'}} src={giftCard}/>
        </div>
        <div className='ReceiverDetails'>
          <p className='ReceiverDetailsP'>Hi Receiver’s Name,</p>
          <p className='ReceiverDetailsP2'>You have received a gift card from Sender’s Name</p>
          <p style={{fontFamily:'Rubik'}}>Celebrating you today and every day!</p>
        </div>
        <div className='giftPinCard'>
          <h4 style={{color:'red', fontSize:'25px'}}>₹ {amount}</h4>
          <p>Gift Card Id:  **** **** **** ****</p>
          <p>Pin: ******  </p>
        </div>
        </div>
        
        </div>
        </div>        
      </div>
    </div>
    <Footer/>
   </>
  )
}

export default GiftCoupon