import React, { useEffect, useState } from 'react';
import NavMenu from '../Navbar';
import Footer from '../Footer';
import '../../assets/style.css';
import { useNavigate } from 'react-router-dom';
import '../../assets/responsive.css';
import { changePassword, forgetPassowrd, getUserLogin, getUserOtp, getuserdetail } from '../../api/api';
import axios from 'axios';
import Loginotp from './Loginotp';
import { Modal } from 'react-bootstrap';
import { storeData, userDetail  } from '../../redux/ActionCreator.js';
import { useSelector, useDispatch } from 'react-redux';
import LoginPage from './LoginPage';

// const Singin = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0); // Scrolls to the top when the component is mounted
//     window.history.scrollRestoration = 'manual'
//     passwordEmpty()
//   }, []);

//   const [mobileNo, setmobileNo] = useState('');
//   const [status, setStatus] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [password,setPassword] = useState('')
//   const [password2,setPassword2] = useState('')
//   const [username, setUsername] = useState('')
//   const [email, setEmail] = useState("");
//   const [forget, setForget] = useState(false)
  
//   const dispatch = useDispatch();

//   const navigate = useNavigate();

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleShowModal = () => {
//     setShowModal(true);
//   };

//   const forgetPass = () => {
//     const url = forgetPassowrd()
//     const body = { 
//       email:email,
//       mobile:mobileNo,
//     }
//     axios.post(url, body, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     })
//     .then((resp)=>{
//       if(resp.data.status){
//         setShowModal(true);
//       }
//       else{
//         alert('enter valid data')
//       }
//     })
//     .catch((err)=>{
//       console.log('errorrr');
//     })
    
//   };

//   const checkPassword=()=>{
//     if(password==password2){
//       changePassword2()
//     }
//     else{
//       alert('password does notmatch')
//     }
//   }

//   const changePassword2=()=>{
//     const url = changePassword()
//     const body = {
//       email:email,
//       mobile:mobileNo,
//       password:password
//     }

//     axios.post(url, body, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     })
//     .then((resp)=>{
//       if(resp.data.status){
//         // navigateToDashboard()
//         handleCloseModal()
//         changeContent2()
//         passwordEmpty()
//       }
//     })
//     .catch((error)=>{
//       console.log('error in change passowrd');
//     })
//   }



//   const navigateToDashboard = () => {
//     window.location.href = '/'; // Replace '/dashboard' with the actual route of your destination page
//   }

//   const formHandler = () => {
//     const userData = {
//       password:password,
//       email:email,
//     };

//     // if (mobileNo.length !== 10 || !/^[0-9]+$/.test(mobileNo)) {
//     //   alert('Please enter a valid 10-digit mobile number.');
//     //   setStatus(false);
//     //   return;
//     // }

//     const url = getUserLogin();

//     axios
//       .post(url, userData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       })
//       .then((response) => {
//         console.log('Response in resg:', response.data);
//         setStatus(true);
//         if (response.data.status) {
//           // handleShowModal();
//           console.log(response.data.data);
//           // dispatch(userDetail(response.data.data));
//           // console.log(userDetail);
//           // navigateToDashboard()
//           getUserProfile(response.data.data.id);

//         }
//         else{
//           alert('Invalid Email and password')
//         }
//       })
//       .catch((error) => {
//         console.error('Error in register:', error);
//         setStatus(false);
//       });
//   };

//   const getUserProfile = (id) => {
//     const url = getuserdetail(id);
//       axios.get(url).then((resp) => {
//         localStorage.clear()
//         // console.log('Resp ydyrdkd :', resp.data);
//         dispatch(userDetail(resp.data.data));
//         // localStorage.setItem('userDetail', resp.data.data);
//         // setTimeout(() => {   
//         //   localStorage.setItem("UserId", resp.data.data.user_id);
//         //   localStorage.setItem("name", resp.data.data.name);
//         //   localStorage.setItem("mobile", resp.data.data.mobile);
//         //   localStorage.setItem("email",resp.data.data.email);
//         //   localStorage.setItem("created_at", resp.data.data.created_at);
//         //   localStorage.setItem("session_id",resp.data.data.session_id);
//         // }, 2000 );


//         console.log(' -- -- -- ---',localStorage.getItem("UserId"));
//         navigate('/'); 
  
//     })
//     .catch((error) => {
//       console.log('Error:', error);
//     });
//   };



//   const handleVerifyOtp = (otp) => {
//     const userData = {
//       otp: otp,
//       mobile: mobileNo,
//     };

//     const url = getUserOtp();

//     axios
//       .post(url, userData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       })
//       .then((response) => {
//         console.log('Response in resg:', response.data);
//         setStatus(true);
//         handleCloseModal();
//       })
//       .catch((error) => {
//         console.error('Error in register:', error);
//         setStatus(false);
//       });
//   };

//   const changeContent = ()=>{
// setForget(true)
//   }

//   const changeContent2 = ()=>{
//     setForget(false)
//       }
// const passwordEmpty=()=>{
// setPassword('')
// setPassword2('')
// }
//   return (
//     <>
//       <NavMenu />
//       <div className="container singInCon">
//         <div className="row singInrow">
//           <div className="col-lg-5 col-12">
//             <p className="singIn">Sign In</p>
//             <p className="singinText">
//               Welcome back, please sign into your Creative
//                Car care account below.
//             </p>
//             {/* <div className="d-flex singInDiv">
//               <input
//                 type="text"
//                 className="singInInput"
//                 pattern="[7-9]{1}[0-9]{9}"
//                 placeholder="Contact Number"
//                 value={mobileNo}
//                 maxLength={10}
//                 onChange={(e) => setmobileNo(e.target.value)}
//               />
//               <span className="errStar">*</span>
//             </div> */}
//         { !forget && (
//           <>
//             <div className="d-flex singInDiv">
//               <input
//                 type="text"
//                 className="singInInput"
//                 // pattern="[7-9]{1}[0-9]{9}"
//                 placeholder="email"
//                 value={email}
//                 // maxLength={10}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               <span className="errStar">*</span>
//             </div>

//             <div className="d-flex singInDiv">
//               <input
//                 type="password"
//                 className="singInInput"
//                 // pattern="[7-9]{1}[0-9]{9}"
//                 placeholder="password"
//                 value={password}
//                 // maxLength={10}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//               <span className="errStar">*</span>
//             </div>
//             </>
//             ) 
//           }
            
//             { forget && (
//           <>
//             <div className="d-flex singInDiv">
//               <input
//                 type="text"
//                 className="singInInput"
//                 // pattern="[7-9]{1}[0-9]{9}"
//                 placeholder="Email"
//                 value={email}
//                 // maxLength={10}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               <span className="errStar">*</span>
//             </div>

//             <div className="d-flex singInDiv">
//               <input
//                 type="text"
//                 className="singInInput"
//                 // pattern="[7-9]{1}[0-9]{9}"
//                 placeholder="mobile"
//                 value={mobileNo}
//                 maxLength={10}
//                 onChange={(e) => setmobileNo(e.target.value)}
//               />
//               <span className="errStar">*</span>
//             </div>
//             </>
//             ) 
//           }

//              {
//               !forget && 
//             <p onClick={changeContent} style={{color:'Black', fontFamily:'rubik'}} className='forgetPassword'> Forget Password ?</p>
//              }
//              {
//               forget &&
//             <p onClick={changeContent2} style={{color:'black', fontFamily:'rubik'}} className='forgetPassword'> Back</p>

//              }
//             {!forget &&
//             <input
//             value="Sign In Securely"
//             type="button"
//             className="singSecure"
//             onClick={() => formHandler()}
//           />}
//           {forget &&
//             <input
//             value="Submit"
//             type="button"
//             className="singSecure"
//             onClick={() => forgetPass()}
//           />}

//           </div>
//           <div className="middleSingDiv"></div>
//           <div className="col-lg-5 col-12">
//             <p className="NewCust">New Customer?</p>
//             <p className="newCustText">
//               Registering for an account is quick and easy. Benefits of signing
//               up for an account include:
//               <ul style={{ listStyle: 'inside' }}>
//                 <li>Buy more save more  </li>
//                 <li>Loyalty points</li>
//                 <li>Freebies</li>
//                 <li> Insured door delivery</li>
//               </ul>
//             </p>
//             <input
//               value="Create Account"
//               type="button"
//               className="singSecure"
//               onClick={() => {
//                 navigate('/createAcount');
//               }}
//             />
//           </div>
//         </div>
//       </div>
//       <Footer />

//       <Modal show={showModal} onHide={handleCloseModal} centered  size="lg" className='modalClass'>
//         <Modal.Header closeButton style={{background:'rgba(241, 241, 241, 1)'}}>
         
//         </Modal.Header>
//         <Modal.Body style={{height:'300px', background:'rgba(241, 241, 241, 1)'}}>
//           <p style={{marginTop:'10px',marginBottom:'0px'}}>New password</p>
//         <div className="d-flex singInDiv">
//               <input
//                 type="password"
//                 className="singInInput"
//                 // pattern="[7-9]{1}[0-9]{9}"
//                 placeholder="password"
//                 value={password}
//                 // maxLength={10}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//               <span className="errStar">*</span>
//             </div>
//             <p style={{marginTop:'10px',marginBottom:'0px'}}>Confirm password</p>
//             <div className="d-flex singInDiv">
//               <input
//                 type="password"
//                 className="singInInput"
//                 // pattern="[7-9]{1}[0-9]{9}"
//                 placeholder="password"
//                 value={password2}
//                 // maxLength={10}
//                 onChange={(e) => setPassword2(e.target.value)}
//               />
//               <span className="errStar">*</span>
//             </div>
//             <input
//               value="Reset"
//               type="button"
//               className="singSecure"
//               onClick={() => checkPassword()}
//             />
//         </Modal.Body>
//       </Modal>

//       {/* <Modal show={showModal} onHide={handleCloseModal} centered  size="lg" className='modalClass'>
//         <Modal.Header closeButton style={{background:'rgba(241, 241, 241, 1)'}}>
         
//         </Modal.Header>
//         <Modal.Body style={{height:'450px', background:'rgba(241, 241, 241, 1)'}}>
//           <Loginotp mobileNo={mobileNo} onVerify={handleVerifyOtp} />
//         </Modal.Body>
//       </Modal> */}
//     </>
//   );
// };




const Singin = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component is mounted
    window.history.scrollRestoration = 'manual'
  
  }, []);

  const [mobileNo, setmobileNo] = useState('');
  const [status, setStatus] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [password,setPassword] = useState('')
  const [password2,setPassword2] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState("");
  const [forget, setForget] = useState(false)
  
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };





  const navigateToDashboard = () => {
    window.location.href = '/'; // Replace '/dashboard' with the actual route of your destination page
  }

  const formHandler = () => {
    const userData = {
      mobile:mobileNo
    };

    if (mobileNo.length !== 10 || !/^[0-9]+$/.test(mobileNo)) {
      alert('Please enter a valid 10-digit mobile number.');
      setStatus(false);
      return;
    }

    const url = getUserLogin();

    axios
      .post(url, userData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Response in resg:', response.data);
        setStatus(true);
        if (response.data.status) {
          handleShowModal();
          console.log(response.data.data);
          // dispatch(userDetail(response.data.data));
          // console.log(userDetail);
          // navigateToDashboard()
          

        }
        else{
          alert('Invalid Mobile Number')
        }
      })
      .catch((error) => {
        console.error('Error in register:', error);
        setStatus(false);
      });
  };





  const handleVerifyOtp = (otp) => {
    const userData = {
      otp: otp,
      mobile: mobileNo,
    };

    const url = getUserOtp();

    axios
      .post(url, userData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Response in resg:', response.data);
        setStatus(true);
        handleCloseModal();
      })
      .catch((error) => {
        console.error('Error in register:', error);
        setStatus(false);
      });
  };

 
  return (
    <>
      <NavMenu />
      <div className="container singInCon">
        <div className="row singInrow">
          <div className="col-lg-5 col-12">
            <p className="singIn">Sign In</p>
            <p className="singinText">
              Welcome back, please sign into your Creative
               Car Care account below.
            </p>
            <div className="d-flex singInDiv">
              <input
                type="text"
                className="singInInput"
                pattern="[7-9]{1}[0-9]{9}"
                placeholder="Contact Number"
                value={mobileNo}
                maxLength={10}
                onChange={(e) => setmobileNo(e.target.value)}
              />
            
            </div>
     
            
          

            
          
            <input
            value="Sign In Securely"
            type="button"
            className="singSecure"
            onClick={() => formHandler()}
          />
        
          </div>
          <div className="middleSingDiv"></div>
          <div className="col-lg-5 col-12">
            <p className="NewCust">New Customer?</p>
            <p className="newCustText">
              Registering for an account is quick and easy. Benefits of signing
              up for an account include:
              <ul style={{ listStyle: 'inside' }}>
                <li>Buy more save more  </li>
                <li>Loyalty points</li>
                <li>Freebies</li>
                <li> Insured door delivery</li>
              </ul>
            </p>
            <input
              value="Create Account"
              type="button"
              className="singSecure"
              onClick={() => {
                navigate('/createAcount');
              }}
            />
          </div>
        </div>
      </div>
      <Footer />

    

      <Modal show={showModal} onHide={handleCloseModal} centered  size="lg" className='modalClass'>
        <Modal.Header closeButton style={{background:'rgba(241, 241, 241, 1)'}}>
         
        </Modal.Header>
        <Modal.Body style={{height:'450px', background:'rgba(241, 241, 241, 1)'}}>
          <LoginPage mobileNo={mobileNo} onVerify={handleVerifyOtp} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Singin;