import React, { useEffect, useState } from 'react'
import { getTheOrder } from '../../api/api';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

import { AiOutlineArrowUp } from 'react-icons/ai'
import moment from "moment";

export default function Orders() {
  const storedData = useSelector((state) => state.profile);
  const [order, setOrder] = useState([]);
  const [orderData, setorderData] = useState()

  function refreshPage() {
    window.scrollTo(0, 0);
  }

  const navigate = useNavigate()
  useEffect(() => {
    getOrder(storedData.userId)

  }, [])

  const getOrder = (id) => {
    const url = getTheOrder(id)


    axios.get(url)
      .then((resp) => {
        console.log('..--..--..', resp.data.data);
        setOrder(resp.data.data)
        // console.log('sate info ==> ',resp.data.data[0].TXNID);
        setorderData(resp.data.data[0].payment_details.order_id)


      })
      .catch((error) => {
        console.log('errerrerrerr', error);
      })
  }

  // const goToOrderDetails = () => {
  //   // navigate('/Myorder')
  // }

 

  const goToOrderDetails = (id)=>{
    console.log('--orderEdId',id);
    navigate('/Myorder',{
      state: {
        orderData:id
       }
    })
  }
  return (
    <>
      <div className='order'>
        <h1 className='myAccount'>My Orders</h1>
        {
          order?.length > 0 ?
            order?.map((item) => {
            console.log('==order Item',item);
              return (
                <>
                  <div className='orderDetails22'>
                    <div  className='orderDetails2'>
                      <div style={{ display: 'block', width: '75%' }} className='fullDetails'>
                        <p  className='orderStatus'><strong>{item?.payment_details.status} </strong> </p>
                        <p className='order-status'>order date: { moment(item?.payment_details.order_date).format("DD-MM-YYYY")}</p>
                        <p className='orderID'>Order ID: #{item?.payment_details?.order_id} </p>
                        {
                          item.order_details[0]?.TXNID && 
                        <p className='orderIDs'>TXNID: #{item.order_details[0]?.TXNID} </p>
                        }
                        <p className='orderID2'><strong>{item.order_details[0].title}</strong></p>
                        <span style={{display:'flex'}}>
                        <p className='orderID3'><strong>₹ {item.order_details[0].starting_price}</strong></p>
                        <p className='orderMrp'><strong>MRP <strike>₹{item.order_details[0].mrp}</strike></strong></p>

                        </span>
                        <p className='quantity1'>Quantity: {item.order_details[0]?.quantity}</p>
                        
                      </div>

                      <div className='orderImg'>
                      {item.order_details.length>1 &&(
                          <span className='orderLength2'>+{item.order_details.length-1}</span>
                        )}
                        <img className='image-order' src={item.order_details[0]?.image_url} alt='orderImage'/>
                        {item.order_details.length>1 &&(
                          <span className='orderLength'>+{item?.order_details.length-1}</span>
                        )}
                      </div>
                      <div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      {/* <button className='cancelRBtn'>Track</button> */}
                      <button style={{color:'black'}} onClick={()=>goToOrderDetails(item?.payment_details.order_id)} className='trackBtn' >Order details</button>
                    </div>
                  </div>
                </>
              )
            }) : (
              <div className='noOrderFound' >
                <p style={{ fontFamily: 'Rubik' }}> Currently their are no orders </p>
              </div>
            )

        }

        {/* <button style={{display:'block',margin:'20px auto'}} onClick={refreshPage} className="ReturnButton">to top</button> */}
        <div onClick={refreshPage} className='topBtn'>
          < AiOutlineArrowUp size={25} style={{ color: 'white', }} />
        </div>

      </div>
    </>
  )
}


