import React, { useState } from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'

export default function Refund() {
  const [cartValues, setCartValues] = useState(false);
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
    <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
    <div className='mainPrivacy'>
      <h1 className='creativeCarCare'>RETURN & SHIPPING POLICY FOR INDIVIDUAL PRODUCT</h1>
      <div className='privacyPolicy'>
      For all returns, replacement, cancellation & shipping related inquires Please WhatsApp or SMS on +919930290909 or Email us at : info@creativecarcare.in - with your order number / invoice number.
        <h3 className='cookies'>RETURNS POLICY</h3><br/>
        In the rare event that you are not fully satisfied with what you ordered, just return it to us within 3 days of delivered date in the original packaging and we will refund you, subject to 15% restocking fees.
        <ol>
          <li>* After 3 days of Delivered date we will not accept any refunds or returns.</li>
          <li>*  All items must be returned in the original package, cannot be damaged or installed, and must be in original condition.</li>
          <li>*  All Liquid / Gel / Aerosol products are not returnable . </li>
          <li>* For all returns, Please whatsapp or sms on +919930290909 or email us at : info@creativecarcare.in - with your order number / invoice number & specific reason for return </li>
        </ol>
        <h3 className='cookies2'>SHIPPING POLICY</h3>
        All the orders are shipped within 24-48 hours. Currently, we are shipping thru Bluedart, Gati, FedEx, IndiaPost & many more.<br/>
Please note that all orders are typically shipped within 1 - 2 working days and take additional 4-7 working days of shipment time. For some destinations of NORTHEAST, the shipment time may be longer(up to 15 days).<br/>
We will notify you when your order is shipped. <br/>
Orders once processed and shipped can be tracked using the consignment/tracking number. A mail is sent to the customer after the order is shipped with this tracking number.<br/>
In order to track the order, go to the website of the service provider mentioned in your mail.<br/>
On the "Track Status" Panel enter the tracking number as mentioned in your shipment mail to view your order status or call us on +91-9930290909 or email us at: info@creativecarcare.in<br/>

<h3 className='cookies'>Free Shipping:</h3><br/>
<span style={{width:'100%'}}>We do not charge for shipping . All product pricing include free shipping .</span> <br/>
<h3 className='cookies'> Cash on Delivery (CoD):</h3><br/>
<span style={{width:'100%'}}>Cash on Delivery CoD will be available on orders below Rs 2000 . </span><br/>
      </div>
    </div>
    <Footer/>
    </>
  )
}
