
import React, { useEffect, useState } from 'react';
import { getUserAddress } from '../../api/api';
import axios from 'axios';
import StoredAddress from './StoredAddress';
import { AiOutlineArrowUp } from 'react-icons/ai'


export default function AddressDetails() {

  useEffect(()=>{
  })

  const [isSaved, setIsSaved] = useState(false);
  function refreshPage() {
    window.scrollTo(0, 0);
  }

  const userAddress = () => {
    const url = getUserAddress();
    window.scrollTo(0, 0);
    const userData ={
    
    }
    // console.log(' - -- - -here is the value',userData);
    axios.post(url,userData) 
      .then((resp) => {
        setIsSaved(true);
      })
      .catch((error) => {
        console.log('error:', error);
      });
  };


  const saveAddress = () => {
    userAddress();
    <StoredAddress />
  }

  return (
    <>
      <div className='addressDetials'>
        <h1 className='myAccount'>Address Details</h1>
        
        <StoredAddress />
      
        <div onClick={refreshPage} className='topBtn'>
          < AiOutlineArrowUp size={25} style={{ color: 'white', }} />
        </div>
         </div>
     
    </>
  );
}