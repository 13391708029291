import React, { useEffect, useState } from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'
import { getCartByItsId, getMyOrder, getTheDelete, getTheOrder, getUserAddressbyId, getcartbyId } from '../../api/api';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './Confirmation.css'
import { AiFillGift } from "react-icons/ai";
import ImagesConfirm from '../../assets/img/Home/verify.png'

export default function Confirmation() {
  const location = useLocation()
  const navigate = useNavigate();
  const storedData = useSelector((state) => state.profile);
  const { orderData } = location?.state;
  const [address, setaddress] = useState('')
  const [order, setOrder] = useState([]);
  const [name, setname] = useState('')
  const [transaction, settransaction] = useState('')
  const [rewardPoint, setrewardPoint] = useState(0)
  const [orderId,setOrderId]=useState()
  const [cartValues, setCartValues] = useState('')
  const [giftprice, setGiftPrice] = useState(0)
  const [couponDisc, setCouponDisc] = useState(0)
  const [details, setDetails] = useState(
   [] // Load details from localStorage
  );
  
  const [pointsInput, setpointsInput] = useState(0);
  const {id} = useParams()
  useEffect(() => {
    // console.log('==orderdata = data ==',orderData);
    localStorage.setItem('cartStatus', true)
    if (storedData.userLoginSuccess) {
      // getUserAddress_func(storedData.userId);
      getOrder( id)
      deleteTheCart(storedData.userId)
    }



  }, [id]);


const deleteTheCart = (id)=>{
const url = getTheDelete(id)

axios.get(url)
.then((resp)=>{
  console.log('hello',resp);
})
.catch((error)=>{
  console.log('error',error);
})
}

  const getCartData = async () => {
    
    const url = getcartbyId();
    var body = {
      discount: pointsInput,
      user_id: storedData.userId,
    };
    // console.log(' = = = url = = =',url);

    await axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log(" = = =cartdata = = =", resp.data);
        // setCartData(resp.data.data);
       
      })
      .catch((error) => {
        console.log(" - - - error - - -", error);
        
      });

    // console.log("in state cart data ==> ", cartFullData);
  };

  const deleteCard = (id) => {
    let data = new FormData();
    data.append("_method", "DELETE");
    const url = getCartByItsId(id);
    console.log("--->url", url);
    axios
      .post(url, data)
      .then((resp) => {
        console.log(" = = = deleted card = = ", resp.data);
        if (storedData.userLoginSuccess) {
          getCartData(storedData.userId);
         
        } else {
          getCartData(null);
        }
      })
      .catch((error) => {
        console.log("--error--", error);
      });
  };


  const getOrder = (id) => {
    const url = getMyOrder(id)
    console.log(url);

    axios.get(url)
      .then((resp) => {
        console.log('..--..--..',resp.data.data.id);
        setOrderId(resp.data.data.id);
        const orderDetails = resp.data.data.details;
        setDetails(orderDetails); // Set the order details in the state
        // localStorage.setItem('orderDetails', JSON.stringify(orderDetails));
        console.log('sate info ==> ', details);
        
        setaddress(resp.data.data)
        setname(resp.data.data.user)
        settransaction(resp.data.data.transaction)
        setrewardPoint(resp.data.data.reward_points)
        setGiftPrice(resp.data.data.gift_code_price)
        setCouponDisc(resp.data.data.coupon_discount)
      })
      .catch((error) => {
        console.log('errerrerrerr', error);
      })

  }

  const goToOrderDetails = (id)=>{
    console.log('--orderEdId',id);
    navigate('/Myorder',{
      state: {
        orderData:id
       }
    })
  }
  const handleModalClose = () => {
    // getCartData2()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };


  const generateRandomNumber = () => {
    const min = 1000000000; // Minimum 10-digit number
    const max = 9999999999; // Maximum 10-digit number
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  
  // Call the function to get a random number
  const random10DigitNumber = generateRandomNumber();
  
  return (
    <>
      <NavMenu onCloseModal={handleModalClose} cartBoolean={cartValues} setCartBoolean={setCartValues}/>
      <div className='orderStatusDetails'>
        
        <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
         
          <img className='imageConfirm' src={ImagesConfirm} alt='confirmationIcon' />
        </div>
        <h2 style={{ textAlign: 'center' }} className='myOrdersh2'> Order Confirmed</h2>
        <div className='row conRow'>
          <div className='col-lg-6 col-12 addressContent'>


            <div className="addressConfirmation">
              {" "}
              <h3
                style={{
                  marginTop: "15px",
                  fontFamily: "Oswald",
                  marginBottom: "10px",
                  fontSize: "25px",
                }}
              >
                {/* <strong>Delivery Address</strong>{" "} */}
              </h3>
              <div
                // onClick={() => handleAddress(item.id)}
                style={{
                  border: "1px solid lightgray",
                  borderRadius: 10,
                  width: "100%",
                  padding: 20,
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p className="addressDetails2">{name?.name}</p>
                  <p className="addressHome">
                    {address?.type_of_address}
                  </p>
                </span>

                <p className="addressDetails">
                  {address?.address} {address?.landmark} {address?.city}, {address?.territory}, {address?.country} {" "}
                  {address?.pincode}
                </p>
                <p className="addressDetails">
                  {" "}
                  <strong>Mob no:</strong> {name?.mobile}
                </p>
                <p className="addressDetails">
                  {" "}
                  <strong>Landmark: </strong> {address?.landmark}
                </p>
              </div>
            </div>

            {details.map((item, Index) => {
            
              return (
                <>
                  <div className='orderDetails'>
                    <div className='orderDetails2' key={item?.order_id}>
                      <div style={{ display: 'block', width: '70%' }}>
                        <p className='orderStatus'><strong>pending </strong> </p>
                        {/* <p className='order-status'>Item will be delivered on: 20th Mar 2023</p> */}
                        <p className='orderID'>ORDER ID:{item?.order_id} </p>
                        <p className='orderID2'><strong>{item?.title}</strong></p>
                        <p className='orderID3'><strong>₹ {item?.starting_price}</strong></p>
                        <p className='quantity1'> quantity: {item?.quantity}</p>
                      </div>

                      <div className='orderImg'>
                        <img className='image-order2' src={item?.image_url} alt='orderImage' />
                      </div>
                      <div>
                      </div>
                    </div>

                  </div>
                </>
              )
            })}
          </div>

          <div className='col-lg-6 col-12 oderOfSumm2'>
            <div className="earnPoint2">
              <p className="earnPointText22">
                <AiFillGift className="giftIcons" />
                You have won{" "}
                <span style={{ color: "red", fontWeight: "600" }}>
                  {" "}
                  {rewardPoint}
                </span>{" "}
                points on this order
              </p>
            </div>
            <div className='paymentInfos'>
              <h2 className='priceSummary'>Payment Information</h2>
              { transaction?.TXNID && 
              <p className='payTmDetails'>payment Id : {transaction?.TXNID}</p>
              }
              <p className='payTmDetails'> payment amount : INR {transaction?.TXNAMOUNT}</p>
              <p className='payTmDetails'>Payment method : {address?.payment_mode}</p>
            </div>
            {/* <span style={{marginTop:'5px'}}>You have won {rewardPoint} points</span> */}
            <div className="couponcalc3">
              <h3 className="priceSummary">
                Price Summary
              </h3>
              <div className="row">
                <div className="col-6  priceDistribution2">
                  <ul>
                    <li
                    className="allCartInfoConfirm"
                    >
                      Total MRP
                    </li>
                    {/* <li
                      style={{
                        marginBottom: "8px",
                        marginLeft: "25px",
                        fontFamily: "Rubik",
                      }}
                    >
                      Discount on INR
                    </li> */}
                    
                    <li
                     className="allCartInfoConfirm"
                    >
                      Point discount
                    </li>
                    <li
                     className="allCartInfoConfirm"
                    >
                      Gift voucher
                    </li>
                    <li
                     className="allCartInfoConfirm"
                    >
                      Coupon
                    </li>
                    {/* <li  style={{marginBottom:'8px', marginLeft:'25px', fontFamily:'Rubik'}}>GST.</li> */}
                    <li
                     className="allCartInfoConfirm"
                    >
                      Delivery Charges
                    </li>
                    <div className="hrLineCr" ></div>
                    <li
                    className="allCartInfoMainConfirm"
                    >
                      TOTAL AMOUNT :
                    </li>
                  </ul>
                </div>
                <div className="col-6 numericalPrize2">
                  <ul>
                    <li  className="allCartInfo2">
                      ₹ {address?.price }
                    </li>
                    {
                      !address.discount_points ?(
                        <li  className="allCartInfo2">
                        ₹ {address?.discount_points}
                      </li>
                      ):(
                      <li  className="allCartInfo2">
                        ₹ {address?.discount_points}
                      </li>
                      )
                    }
                    {
                      giftprice == null ? (<><li  className="allCartInfo2">
                      ₹ 0
                    </li></>):(<>
                        <li  className="allCartInfo2">
                      ₹ {giftprice }
                    </li>
                        </>)
                    }
                     {
                      couponDisc == null ? (<> <li  className="allCartInfo2">
                      ₹ 0
                    </li></>):(<> <li  className="allCartInfo2">
                      ₹ {couponDisc }
                    </li></>)
                     }
                   
                  
                    {/* <li style={{ marginBottom: "8px", marginLeft: "65px" }}>
                          ₹ 100  
                        </li> */}
                    {/* <li  style={{marginBottom:'8px', marginLeft:'65px'}}>₹{summaryInfo.COUPON_DISCOUNT}</li> */}
                    {/* <li style={{ marginBottom: "8px", marginLeft: "65px" }}>
                      ₹{address.discount_points}
                    </li> */}
                    {/* <li  style={{marginBottom:'8px', marginLeft:'65px'}}>₹{parseFloat(summaryInfo.GST).toFixed(2)}</li> */}
                    <li  className="allCartInfo2">
                      ₹ {address?.shipping_charge}
                    </li>
                    <li  className="allCartInfoMain2">
                    ₹ {address?.total}
                    </li>
                  </ul>
                </div>
                {/* <hr style={{ width: "85%", marginLeft: "45px" }} />
                <div className="totalPrizes">
                  <h3 className="totalAmount">TOTAL AMOUNT : </h3>
                  <h3 className="totalAmount2">
                    {" "}
                    INR {address?.total}
                  </h3>
                </div> */}

              </div>
            </div>
          </div>
          <div className='invoiceButton2'>
            <button onClick={() => { navigate('/') }} className='buttonInvoice2Confirm'>Continue Shopping</button>
            <button onClick={() => { goToOrderDetails(orderId) }} className='buttonInvoice3'>View Order</button>

          </div>

        </div>
      </div>

      <Footer />
    </>
  )
}
