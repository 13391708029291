import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { getTestimonial } from '../api/api'
import axios from 'axios'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Carousel from 'react-bootstrap/Carousel';
import img from '../assets/img/Home/user (2).png'
import '../assets/css/home.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';



export default function Testimonial() {
//   let history = createBrowserHistory();
//   const location = useLocation();

const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      dots: true
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const [testimonial, setTestimonial] = useState([])
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 30000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <button className="prev-arrow" style={{color:'white', background:'white'}}>Previous</button>,
    nextArrow: <button className="next-arrow">Next</button>,
  };

  useEffect(() => {

    getTheTestimonial()

  }, [])

  const getTheTestimonial = () => {
    const url = getTestimonial()

    axios.get(url)
      .then((resp) => {
        // console.log('---testimonial', resp.data);
        setTimeout(() => {

          setTestimonial(resp.data.data)
        }, 1000);
      })
      .catch((error) => {
        console.log("===test error");
      })
  }



  return (
    <>
      <div className='testimonial'>
        <p  className='HomeFeatureTitleT'><i class="fa-solid fa-star" style={{ color: 'red',marginRight:'10px' }}></i>&nbsp;
          Testimonial &nbsp; <i class="fa-solid fa-star" style={{ color: 'red' }}></i></p>

        {/* <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">

            {testimonial.map((item) => {
              return (
                <>
                  <div class="carousel-item active">
                    <div style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                      <p style={{ fontSize: '20px' }} className='testimonialtext'>{item.description}</p>
                    </div>
                    <p style={{ textAlign: 'center', color: 'white', fontSize: '20px' }}>- {item.name}.</p>
                  </div>

                </>

              )
            })}


          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div> */}

        {/* carousel 2 */}
        <div>
          {/* <Slider className='sliderTest'  {...settings}>
            {testimonial.map((item) => {
              // console.log('testimonial', item)
              return (
                <>
              
                  <div style={{ overflow: 'hidden' }} class="carousel-item active">
                    <div className='testimonialImgDiv'>
                      {item.image_url && 
                      <img className='testimonialImg' src={item.image_url}></img>
                      }
                      
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                      <p  className='testimonialtext'>{item.description} </p>
                    </div>
                    <p className='testimonialName' style={{ textAlign: 'center', color: 'white', fontSize: '20px',marginBottom:'20px' }}>- {item.name}.</p>
                  </div>
                </>

              )
            })}
          </Slider> */}

          <Carousel
          className="carouselSlider2"
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={30000}
          arrows={true} 
          showDots={true}
          emulateTouch={true}
          renderButtonGroupOutside={true}
          renderDotsOutside={false} 
          // centerMode={centerMode}
          rewindWithAnimation={true}
        > 
     {testimonial.map((item) => {
              // console.log('testimonial', item)
              return (
                <>
              
                  <div style={{ overflow: 'hidden' }} class="carousel-item active">
                    <div className='testimonialImgDiv'>
                      {item.image_url && 
                      <img className='testimonialImg' src={item.image_url} alt={item.name}></img>
                      }
                      
                    </div>
                    <div style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                      <p  className='testimonialtext' style={{textAlign:'justify'}}>{item.description} </p>
                    </div>
                    <p className='testimonialName' style={{ textAlign: 'center', color: 'white', fontSize: '20px',marginBottom:'20px' }}>- {item.name}</p>
                  </div>
                </>

              )
            })}
    
      </Carousel>

       
        </div>
      </div>

    </>
  )
}
