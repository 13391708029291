import React, { useEffect, useState } from 'react'
import NavMenu from '../Navbar'
import Footer from '../Footer'
import axios from 'axios'
import { deleteWishList, getSpecialCategoryApi2, getWhishlist, getcartId, hotDeals } from '../../api/api'
import { useSelector } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import '../TopSeller/topSeller.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import ReactStars from 'react-stars'
import { ColorRing, TailSpin } from "react-loader-spinner";
import ReactPaginate from 'react-paginate';
import { Modal, ModalHeader } from 'react-bootstrap';

export default function DealOfMonth() {

    const [dicount,setDiscount] = useState([])
    const [section_OneProductInfo,setsection_OneProductInfo]=useState([])
    const [pageLoading, setPageLoading] = useState(true)
    const [sectionTwoFull, setSectionTwoFull] = useState({});
    const [sectionTwoProductInfo, setSectionTwoProductInfo] = useState([]);
    const [isSectionTwoLoading, setIsSectionTwoLoading] = useState(false);
    const [cartValues, setCartValues] = useState(false);
    const [itemsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setisLoding] = useState(false)
    const [showModal, setShowModal] = useState(false);


    const navigate = useNavigate();

    const storedData = useSelector((state) => state.profile);
    useEffect(() => {
      setTimeout(() => {
        setPageLoading(false)
      }, 500);
        if (storedData.userLoginSuccess) {
            getCategoryOnlyFunc(storedData.userId);
        }else{
            getCategoryOnlyFunc(null);
        }
        
        window.scrollTo(0, 0);
       
      }, [])


      const getCategoryOnlyFunc = (id) => {
        setIsSectionTwoLoading(true);
        const url = hotDeals(id);
        const body ={
          user_id:id,
          filter:true,
          brands:[],
          category:'',
          packs:'',
          amount:'',
          search:''
        }
    console.log(url);
        axios
          .post(url, body, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            console.log(resp.data)
            const clearanceSaleData = resp.data.data;
            if (clearanceSaleData.length === 0) {
              setSectionTwoFull(resp.data.data[0].hot_deals);
              setIsSectionTwoLoading(false);
            } else {
              setSectionTwoFull(resp.data.data[0].hot_deals);
              setSectionTwoProductInfo(clearanceSaleData);
              setIsSectionTwoLoading(false);
              console.log('home feature');
            }
          })
          .catch((err) => {
            console.log(err);
            setIsSectionTwoLoading(false);
          });
      };
    
      const indexOfLastItem = currentPage * itemsPerPage;                    
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;               
      const currentItems = sectionTwoProductInfo.slice(indexOfFirstItem, indexOfLastItem);
      const totalItems = sectionTwoProductInfo.length     
  
      const handlePageChange = (selectedPage) => {
        setisLoding(true)
        setTimeout(() => {
          setisLoding(false)
          setCurrentPage(selectedPage.selected + 1);
        }, 1000);
        window.history.scrollRestoration = "manual";
        window.scrollTo(0, 0);
      };
    
      const totalPages = Math.ceil(totalItems / itemsPerPage);

    const addWishlist=(id, id2, id3)=>{
        if (storedData.userLoginSuccess) {
          const url = getWhishlist()
          const userData = {
             user_id: storedData.userId,
             product_id: id,
             has_login: 'yes', 
          }
          console.log('==body==',userData);
        
          axios.post(url, userData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((resp)=>{
            // console.log(' = = = resp = = ',resp.data);
            if (resp.data.status) {
              toast.success('Wishlist Added', { icon : '❤️' })
              getCategoryOnlyFunc(storedData.userId)
              window.fbq('track','Add to wishlist',{currency:'INR', value:id2, content_name:id3})
            } else {
              toast.error('Something Went Wrong', { icon : '⚠️' })
            }
            
            // console.log(' the value of list',resp.data);
          }).catch((err)=>{
            console.log(err);
            toast.error('Something Went Wrong', { icon : '⚠️' })
      
          })
        } else {
          // alert('Please Login')
          // navigate('/signin' )
          setShowModal(true)

        }
      }
      
      const removeWishlist=(id)=>{
        if (storedData.userLoginSuccess) {
          const url = deleteWishList(id)
      
          let data = new FormData();
          data.append("_method",'DELETE');
      
          // console.log(" ==> wishlist data => ", url, data);
          
          axios.post(url, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((resp)=>{
            // console.log(resp.data);
            if (resp.data.status) {
              toast.success('Wishlist Removed', { icon : '✅' })
              getCategoryOnlyFunc(storedData.userId)
            } else {
              toast.error('Something Went Wrong', { icon : '⚠️' })
            }
            
            // console.log(' the value of list',resp.data);
          }).catch((err)=>{
            console.log(err);
            toast.error('Something Went Wrong', { icon : '⚠️' })
      
          })
        } else {
          // alert('Please Login')
          // navigate('/signin' )
          setShowModal(true)

        }
      }

      const addtoCart = (id) => {
        const url = getcartId();
      
        // console.log( ' -- - - - url - - - ',url);
        const postData = {
          user_id: storedData.userId,
          product_id: id,
          has_login: 'yes',
        };
      
        axios
          .post(url, postData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            // console.log('---- cart value == ', resp.data);
            if (resp.data.status) {
              toast.success('Product Added In Cart', { icon : '🛒' })
              if (storedData.userLoginSuccess) {
                getCategoryOnlyFunc(storedData.userId)
                setCartValues(true)
              }else{
                getCategoryOnlyFunc(null)
              }
          } else {
              toast.error('Something Went Wrong', { icon : '⚠️' })
          }
          })
          .catch((error) => {
            // console.log(' === error ===',error);
          });
      };
    
      const handleAddToCart = (id,id2, id3, id4) => {
        console.log('id of the cart',id,id2);
        if (storedData.userLoginSuccess) {
          // addtoCart(id);
          addtoCart(id);
          window.fbq('track','Add to cart',{currency:'INR',value:id3,content_name:id4})
          if(id2 == 1){
            // setCartValues(true);
          }else{
            toast('Product is unavailable')
          }
         
        }else{
          // alert('Please Login')
          // navigate('/signin' )
          setShowModal(true)

        }
      };
    
      const sendProdId = (id) => {
        // console.log(' ------ id',id);
            // navigate(`/product/${id}`,{state:{productId:id,clicked: clicked}});
           
      };
    
      var discoutPrice = dicount.starting_price - dicount.mrp
      var dpPrice = discoutPrice / dicount.starting_price
      var finalPrice = dpPrice*100
    
      const handleModalClose = () => {
        getCategoryOnlyFunc(storedData.userId)
        // getCartData()
        // Your code to run when the modal is closed goes here
        console.log('Modal is closed, running effect in CartPage...');
      };

      const handleCloseModal = () => {
        setShowModal(false);
      };
     
      const toLoginPage =()=>{
        navigate('/signin')
      }
    return (
        <>
        <div>
        <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues}  cartBoolean={cartValues}/>
        {
             pageLoading ? (<>
             <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'black'}}>
               <TailSpin  color="#ffd11b" />
             </div>
             </>)
             :(<> <div className='topSellerMain'>
             <p className='FeatureHeading'><i class="fa-solid fa-star" style={{ color: 'red' }}></i> &nbsp;
                       Hot Deals &nbsp;<i class="fa-solid fa-star" style={{ color: 'red' }}></i></p>
                       
                       {
                         loading ? (<>
                          <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
               <TailSpin  color="#ffd11b" />
             </div>
                         </>):(<>
                          {
                            sectionTwoProductInfo == '' ? (<><p style={{fontSize:'20px', fontWeight:'600', textAlign:'center'}}>No Deals currently</p></>):(<>
                           <div className='topSellerProduct col-12'>
                       {currentItems.map((item,index) => {
                      return (
                        <>
                        
                        <div 
                          // onClick={() => sendProdId(item.product_id)}
                       className='featureCardTs' key={item.id}>
                          <div  style={{ display: 'block', backgroundColor:'#EEEEEE' }} >
                              {/* <img src={item.img} onClick={() => sendProdId(item.product_id)} style={{width: '200px',height: 'auto', backgroundColor: 'green', padding: 60}} />  */}
                              <a  href={`/product/${item.id}`} style={{textDecoration:'none'}}>
                             <div className='featureOfImg'  onClick={() => sendProdId(item.product_id)} style={{ backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundSize: "95% 95%",  padding: 10, backgroundPosition: 'center', mixBlendMode: 'multiply' ,backgroundOrigin: 'content-box', backgroundPositionX: 'center' }}>
                              <img src={ item.image_url} style={{width:'100%'}} alt={item.product_name}/>
                                {/* <span className={`carding ${item.wishlist_id == null ? '' : 'clicked'}`}   onClick={() => addWishlist(item.product_id)}>
                                <i class="fa-solid fa-heart"  ></i>
                                </span> */}
                                 {/* {item.stock > 0 && item.stock <= 10 && (
                                    <p className='itemStock'> Only {item.stock}</p>
                                  )}
          
                                  {item.stock > 10 && (
                                    // <p className='itemStock'>null</p>
                                    null
                                  )}
          
                                  {item.stock <= 0 && (
                                    <p className='itemStock'>Out of Stock</p>
                                  )} */}
          
                                  {/* <p className='offBanner'>{parseInt(finalPrice)}% Off</p> */}
          
                              </div>
                              </a>
                              </div>
                          
                          <div className='feratureStar'>
                            <ReactStars edit={false} value={item.rating} count={5} size={18}/>
                            <div style={{ paddingRight: 20, marginTop: '5%'}}>
                                  {
                                    item.wishlist_id == null ? <i class='fa-solid fa-heart' onClick={() => {addWishlist(item.id, item.starting_price, item.product_name)}} style={{ color: "lightslategray", fontSize: '18px',   }}></i> : <i class='fa-solid fa-heart' onClick={() => removeWishlist(item.wishlist_id)} style={{color: "red", fontSize: '18px',  }}></i>
                                  }   
                                </div>
                          </div>
                          <a  href={`/product/${item.id}`} style={{textDecoration:'none'}}>
                          <hr className='featureHr'   onClick={() => sendProdId(item.product_id)}/>
                          <OverlayTrigger
                                           placement="bottom"  
                                           overlay={
                                             <Tooltip style={{ backgroundColor: '#333', color: '#fff', borderRadius: '4px', fontSize: '12px' }}>
                                               {item.title}
                                             </Tooltip>}
                                         >
                        <p className='featureText' onClick={() => sendProdId(item.product_id)}>
                          {item.title}
                          {/* {item.product_name.split(' ').slice(0, 7).join(' ')}
                          {item.product_name.split(' ').length > 7 ? '...' : ''} */}
                        </p>
                        </OverlayTrigger>
                          <hr className='featureHr'  onClick={() => sendProdId(item.product_id)}/>
                          <div className='featureOffering'>
                            <span className='featureOffer'  onClick={() => sendProdId(item.product_id)}><strong>{parseInt(((item.mrp-item.starting_price)/item.mrp)*100)}% Off</strong></span></div>
                            <p className='ourPrice'>Our Price</p>
                          <div className='featurePrizeContainer'  onClick={() => sendProdId(item.product_id)}>
          
                            <span className='featurePrize fearut'  onClick={() => sendProdId(item.product_id)}>₹ {item.starting_price}</span>
                            <span className='featutreFinalpries'  onClick={() => sendProdId(item.product_id)}>MRP <strike style={{fontSize:'15px'}}>₹{item.mrp }</strike></span></div>
                            </a>
                            {
                              item.cart_id == null ?
                                <button onClick={() => {handleAddToCart(item.id,item.status, item.starting_price, item.product_name)}} className='Featurebtn'><i class="fa-solid fa-cart-shopping" style={{ fontSize: '14px' }} ></i>Add to cart</button> : <button onClick={() => navigate('/cart')} className='Featurebtn'>View in cart</button>
                            }
                          </div>
                        </>
                      )
                    })
                    }
                       </div>
                            </>)
                           }
                          
                         </>)
                       }
                       {/* {totalPages.length > 20 ? 
                       (<> */}
                       <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
                 <ReactPaginate
                 previousLabel={'<'}
                 nextLabel={'>'}
                 breakLabel={'...'}
                 breakClassName={'break-me'}
                 pageCount={totalPages}
                 marginPagesDisplayed={2}
                 pageRangeDisplayed={5}
                 onPageChange={handlePageChange}
                 containerClassName={'pagination'}
                 subContainerClassName={'pages pagination'}
                 activeClassName={'active-page1'}
               />
               </div>
               {/* </>)
               :null } */}
                       
             <Footer/>
             </div></>)}
       
        </div>
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg" className='modalClass modalClass2'>
            
            <Modal.Body style={{height:'100px', background:'rgba(241, 241, 241, 1)'}}>
             <p>Please Login!</p>
             <div style={{display:'flex', justifyContent:'end'}}>
             <button onClick={toLoginPage} style={{background:'red', borderRadius:'10px', color:'white', border:'none', padding:'5px 10px'}}>Login</button>
             </div>
            </Modal.Body>
          </Modal>
        </>
       )
}
