import img1 from '../assets/img/brandpage/a1.png'
import img2 from '../assets/img/brandpage/a2.png'
import img3 from '../assets/img/brandpage/a3.png'


const data = [
    { 
        img: img1,
        id : 0
    },
    {
        img: img2,
        id : 1
    },
    {
        img: img3,
        id : 2
    }
]
 
export default data