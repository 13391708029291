import { Navigate, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import NavMenu from "../Navbar";
import './brands2.css';
import Image from '../../assets/img/brands2/image 37.png';
import Image1 from '../../assets/img/brands2/image 40.png';
import Image2 from '../../assets/img/brands2/image 41.png';
import Image5 from '../../assets/img/brands2/Group 25.png';
import Image6 from '../../assets/img/brands2/image 43.png';
import Image7 from '../../assets/img/brands2/image 44.png';
import Footer from "../Footer";
import { getBrandsWithId, getProductViaCategory } from '../../api/api';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ColorRing, TailSpin } from "react-loader-spinner";

export default function Armorall() {
  const imageUrls = [
    { "img": Image1, "heading": "Exterior Products" },
    { "img": Image2, "heading": "Interior Products" },
  ];

  const imageUrls2 = [
    { "img": Image5, "heading": "SHIELD™ +CERAMIC CAR WASH", "img_heading": "Exterior" },
    { "img": Image6, "heading": "SHIELD™ PROTECTANT", "img_heading": "Interior" },
    { "img": Image7, "heading": "ORANGE CLEANING WIPES", "img_heading": "Interior" },
  ];

  const navigate = useNavigate()

  const [StpData, setStpData] = useState([]);
  const location = useLocation();
  // const { brandid, brandName } = location?.state;
  // const { id } = useParams();
  const [StpCategory, setStpCategory] = useState([])
  const [categoryId, setCategoryId] = useState('')
  const [ProductId, setProductId] = useState('');
  const [clicked, setClicked] = useState(false);
  const [cartValues, setCartValues] = useState(false);
  const [pageLoading, setPageLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
    setPageLoading(false)
  }, 500);
    getBrandsById(2);
    getProductbyCategory(ProductId);
    console.log('==productId',ProductId);
    window.scrollTo(0, 0)
    console.log('-->brand armorall',);

    
 

  }, [2, ProductId]);

  const getBrandsById = (id) => {
    const url = getBrandsWithId(id);
    console.log('brandUrl',url);
    axios
      .get(url)
      .then((resp) => {
        console.log('--resp', resp.data);
        setStpData(resp?.data?.brandwise_category_data)
        setProductId(resp.data.brandwise_category_data[0].category_id);
        console.log('- - - category - - - ', resp.data.brandwise_category_data);

      })
      .catch((error) => {
        console.log('--error--', error);
      });
  }

  const getProductbyCategory = (ProductId) => {
    const url = getProductViaCategory(ProductId)

    axios
      .get(url)
      .then((resp) => {
        console.log('--resp in brand product', resp);
        setStpCategory(resp.data.data.product_data)
        // setProductId(resp.data.data.product_data[0].id)
        console.log('--- - - - - setting id', resp.data.data.product_data[0].id);
      })
      .catch((error) => {
        console.log('--error', error);
      })

  }

  const changeProduct = (id) => {
    console.log('...--changeProduct', id);
    getProductbyCategory(id);
    setCategoryId(id)

  }


  const stpProductWrld = () => {
    console.log('---strproductWorld', );
    localStorage.removeItem('selectedItems');
    window.localStorage.removeItem("CategoryCheckdDataLocal");
    window.localStorage.removeItem('packCheckedDataLocal');
    window.localStorage.removeItem('categoryValue2');
    window.localStorage.removeItem('')
    localStorage.removeItem("selectedItems2");
    localStorage.removeItem("selectedItems3");
    localStorage.removeItem("selectedItems4");
    localStorage.removeItem("brandsCheckdDataLocal");
    localStorage.removeItem("ddvalue");
    localStorage.removeItem("CategoryCheckdDataLocal");
    localStorage.removeItem("bcvalue");
    localStorage.removeItem("packCheckedDataLocal");
    localStorage.removeItem("packsvalue");
    localStorage.removeItem("resbrandsCheckdDataLocal");
    localStorage.removeItem("resddvalue");
    localStorage.removeItem("resCategoryCheckdDataLocal");
    localStorage.removeItem("resbcvalue");
    localStorage.removeItem("respackCheckedDataLocal");
    localStorage.removeItem("respacksvalue");
    navigate('/ARMORALL/Products', {
      state: {
        categoryById: categoryId == '' ? ProductId : categoryId,
        brandID: 2
      }
    })
  }
  const sendProdId = (id) => {
    console.log(' ------ id', id);
    navigate(`/product/${id}`, { state: { productId: id, clicked: clicked } });
    // localStorage.clear()
  };
  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };
  return (
    <>
      <NavMenu onCloseModal={handleModalClose} setCartBoolean={setCartValues} cartBoolean={cartValues}/>
      {
        pageLoading ? (<>
        <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', background:'black'}}>
          <TailSpin  color="#ffd11b" />
        </div>
        </>)
        :
        (<>
        <div className='Mainbrands2'>
        <div className='imageColor'>
          {/* test */}
          <div className='yellowColor'></div>
          <img src={Image} className='logoImage2' alt="yellow band" />
        </div>

        <div className='secondImg'>
          <h1 className='scienceHr'>EVERYDAY PROOF YOUR CAR™</h1>
          <div className='scienceDiv'>
            <p className='scienceP2'>Since 1962, Armor All<sup>®</sup> has kept vehicles clean and protected. Achieve premium results every time with our well-known products, from protectants to car washes, wheel cleaners and everything in between. Discover Armor All products today to easily protect and clean your car.</p>
          </div>
          <span className='redDiv'>
            <div className='scienceImg'>
              {/* <img src={MainImage} className='mainImage'/> */}
              {/* <video src="https://youtu.be/K_R0mQ7Kqsg" className='mainImage' controls>
                  Your browser does not support the video tag.
                </video> */}
              <iframe width="965" height="494" className='mainImagee' src="https://www.youtube.com/embed/oCfFTob9khg" title="Oracle Red Bull Racing: Armor All Clean Car Challenge" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              {/* <iframe width="765" height="394"  className='mainImage' src="https://www.youtube.com/embed/0BFGNZTInCc" title="Oracle Red Bull Racing- Armor All" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
            </div>
          </span>
          <div className='blackTransparent'></div>
        </div>
        <div className='ourProducts2 stprk'>

          <h2 className='productsh2'>CATEGORIES</h2>
          <div className='scienceDiv'>
            {/* <h3 className='lookinghr '>KNOW WHAT YOU ARE LOOKING FOR</h3> */}
            </div>


          <div className="mainArmorallCar">
            {/* <Carousel
          className="armorallCar"
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          autoPlaySpeed={10000}
          arrows={false}
          
          // itemClass="carousel-item-padding-40-px"
        > */}
            {StpData.map((item, index) => (
              <div key={index} className="brandingImg3" onClick={() => changeProduct(item.category_id)}>
                <img src={item.image_url}  className={`productImgar2 ${categoryId === item.category_id || (categoryId === '' && item.category_id === ProductId)
                    ? 'product--highlighted2'
                    : ''
                  }`} alt={item.name} />
                {/* <div className="brandName1"></div> */}
                <p style={{textTransform:'uppercase'}} className="hello1">{item.name}</p>
              </div>
            ))}
            {/* </Carousel> */}
          </div>






          {/* {imageUrls.map((item, index) => (
                <div key={index} className='brandImg3 '>
                  <img src={item.img} className="productImgar2" />
                  <div className='brandName1'>
                  <p className='hello1 ' style={{ textTransform:'uppercase' }}>{item.heading}</p>
                  </div>
                </div>
              ))} */}

        </div>
        <div className='stpProducts'>
          <h2 className='essentialPdts'>ARMORALL PRODUCT RANGE</h2>
          {/* <p className='productp'>For over 60 years – and more miles than we can count – STP® has been one of the most trusted names in automotive care. Our products are essential tools in your car maintenance routine.</p> */}

          <div className="brandImg323">
            <div className='brandImg2 '>
              {/* <div className='yellowColor1'></div> */}


              <div className='MainBrandImg'>
                {StpCategory && StpCategory.length > 0 ? (
                  StpCategory?.map((item, index) => (
                    <a
                          href={`/product/${item?.id}`}
                          style={{ textDecoration: "none"  }}
                        >
                    <div key={index} className='brandImg3'>
                      <img src={item.image_url} className="productImgarArm" alt={item.title} />
                      {/* <p className="intExtTxt">{item.img_heading}</p> */}
                      <div className='brandName1 brandNameArm '>
                        <p className='helloImg2' style={{ textTransform: 'capitalize' }}>{item.title}</p>
                      </div>
                    </div>
                    </a>
                  ))) : (
                  <div style={{ width: '200%' }}>
                    <p className="noProdtFound2">No products found.</p>
                  </div>
                )}
              </div>
            </div>
            <div className='viewing-more2' >
              <button onClick={() => stpProductWrld()} className='viewBtn2'>View All Products</button>
            </div>
            <div className='discovery '>
              {/* <h3 className='lookinghr2 '>ARMOR ALL®</h3> */}
              <h2 className='discoverhry'>ORACLE RED BULL RACING CLEAN CAR RACE FEATURING SERGIO PEREZ</h2>

              {/* <iframe className='rectangleImg' src="https://www.youtube.com/embed/BpoF5pOgAws" title="Discover The Secret Of Engine Maintenance" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
              <iframe className='rectangleImg' src="https://www.youtube.com/embed/0BFGNZTInCc" title="Oracle Red Bull Racing- Armor All" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

          </div>
          <div className='yellowDisc' style={{textTransform:'capitalize'}}>
          DISCLAIMER: ALL PHOTOS, VIDEOS, PRODUCTS, LOGOS BELONG TO © 2023 ENERGIZER AUTO.</div>
          <Footer />
        </div>
      </div>
        </>)
      }
      
    </>
  )
}
