import React, { useState, useEffect } from "react";
import NavMenu from "./Navbar";
import Footer from "./Footer";
import "../assets/css/brandpage.css";
import sliderImg from "../assets/img/brandpage/brandslider.webp";
import stp from "../assets/img/brandpage/stp.png";
import GridImg from "../Json/bannerGrid";
import testBg from "../assets/img/brandpage/testimoBackground.webp";
import testPers from "../assets/img/brandpage/testmoPerson.png";
import insta from "../Json/insta";
import Feature from "./Feature";
import { useLocation } from "react-router-dom";
import { getBrandsWithId } from "../api/api";
import axios from "axios";

const BrandPage = () => {
  const [brandId, setBrandId] = useState();
  const [boxId, setboxId] = useState(0);
  const [brandData, setBrandData] = useState([]);
  const [brandCategory, setBrandCategory] = useState([]);

  const location = useLocation();

  useEffect(() => {
    // console.log(" brand id ==> ",location.state.brandid);
   
  }, [brandId]);

  const handleModalClose = () => {
    // getCartData()
    // Your code to run when the modal is closed goes here
    console.log('Modal is closed, running effect in CartPage...');
  };

  return (
    <div>
      <NavMenu  onCloseModal={handleModalClose}/>
      <div style={{ position: "relative" }}>
        <div className="BannerrsliderCon">
          <div className="container ">
            {/* sataTic */}

            <div className="row ">
              <div className="col-lg-6 col-sm-12 d-flex">
                <img src={stp} className="sliderstp" alt="StpImage" />
              </div>
              <div className="col-lg-6 col-sm-12">
                <p className="sliderFirstTitle">meet our</p>
                <p className="sliderText">Slaritas Tst Ptiam </p>
                <p className="sliderpara">
                  Claritas est etiam processus dynamicus, qui sequitur
                  mutationem consuetudium lectorum. Mirum est notare quam
                  littera gothica, quam nunc putamus parum claram, anteposuerit
                  litterarum formas humanitatis per seacula quarta decima et
                  quinta decima.
                  <br />
                  <br />
                  Eodem modo typi, qui nunc nobis videntur parum clari, fiant
                  sollemnes in futurum.
                </p>
              </div>
            </div>

            {/* Dyanamic Data */}
            {/* {
                    brandData.map((item)=>{
                        return(      
                   <div className='row'>
                    <div className='col-lg-6 col-sm-12 d-flex'>
                        <img src={item.image_url} className='sliderstp'/>
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <p className='sliderFirstTitle'>{item.brand_name}</p>
                         <p className='sliderText'>{item.heading} </p>
                         <p className='sliderpara'>
                         {item.short_description}<br/><br/>
                         {item.image_description_one}
                         </p>
                    </div>
                   </div>
                        )
                    })
                } */}
            <div
              className="brandImg"
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              {GridImg.map((item) => {
                return (
                  
                  <img
                    onClick={() => setboxId(item.id)}
                    src={item.img}
                    className="brandImg"
                    alt="brand"
                    // style={{width:'200px',height:'200px'}}
                  />
                  
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* {
                brandCategory.map((item)=>{
                    return(
                        <Feature id={item.category_id}/>
                    )
                })
            } */}

      <Feature title={"New Trending"} />

      <Feature title={"High Performance"} />

      <Feature title={"Red sale"} />

      <Feature title={"FEATURE PRODUCTS"} />


      <div
        style={{
          backgroundImage: `url(${testBg})`,
          height: "500px",
          backgroundPosition: "center center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <img src={testPers} className="testPers" />
            <p className="testText">
              This is Photoshops version of Lorem Ipsum. Proin gravida nibh vel
              velit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              <br /> In molestie augue magna. Pellentesque felis lorem, pulvinar
              sed eros n..
            </p>
            <p className="testPersName">Rebecka Filson</p>
          </div>
        </div>
      </div>
      <div style={{ margin: "100px auto" }}>
        <p className="instaTitle">OUR INSTAGRAM</p>
        <p className="instaText">
          How us how you are styling your Rare pieces on Instagram with the
          hashtag #Presiden.
        </p>

        <div className="container">
          <div className="row" style={{ display: "flex" }}>
            {insta.map((item) => {
              return (
                <img src={item.img} style={{ width: "25%", height: "auto" }} />
              );
            })}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BrandPage;

