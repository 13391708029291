import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import NavMenu from '../Navbar'
import Footer from '../Footer'
import './editAddress.css'
import { toUpdateAddress, updateSuccessfully } from '../../api/api'
import axios from 'axios'
import { useSelector } from 'react-redux'
import toast, { Toaster } from "react-hot-toast";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


function EditAddress() {
    const storedData = useSelector((state) => state.profile);

    const [firstName, setFirstName]=useState('')
    const [mobile,setMobile] = useState('')
    const [address,setAddress]=useState('')
    const [landmark,setLandmark]=useState('')
    const [pincode,setPincode]=useState('')
    const [city, setCity] = useState('')
    const [state,setState] = useState('')
    const [country,setCountry]= useState('')
    const [addressType,setAddressType]=useState('')
    const [defaultValue,setDefaultValue ] = useState(0)
    const [defaultValue1,setDefaultValue1 ] = useState(0)
    const {id}=useParams()

    const navigate = useNavigate()

    useEffect(()=>{
        firstAddress(id)
    },[])
    

    const firstAddress=(id)=>{
        const url=toUpdateAddress(id)
        axios.get(url)
        .then((resp)=>{
            console.log('==>updated address',resp.data.data[0].is_default);
            setFirstName(resp.data.data[0].name)
            setMobile(resp.data.data[0].mobile)
            setAddress(resp.data.data[0].address)
            setLandmark(resp.data.data[0].landmark)
            setPincode(resp.data.data[0].pincode)
            setCity(resp.data.data[0].city)
            setState(resp.data.data[0].territory)
            setAddressType(resp.data.data[0].type_of_address)
            setCountry(resp.data.data[0].country)
            setDefaultValue(resp.data.data[0].is_default)
        })
        .catch((err)=>{
            console.log('updated error',err);
        })
    }

    const updatedAddress=()=>{
        const url = updateSuccessfully()
        const body = {
            id:id,
            user_id:storedData.userId,
            name:firstName,
            mobile:mobile,
            city:city,
            territory:state,
            address:address,
            pincode:pincode,
            landmark:landmark,
            country:country,
            type_of_address:addressType,
            is_default:defaultValue1
        }
        axios.post(url,body,{
            headers:{
                "Content-Type": "multipart/form-data",    
            }})
            .then((resp)=>{
                console.log('updated successfully',resp.data.status);
                if(resp.data.status){
                    // toast.success('Updated Successfully')
                    // alert('updated Successfully')
                    navigate('/profile')
                    // setTimeout(() => {
                        
                    // }, 1000);
                }
                else{
                    toast.error('Something went wrong')
                }
            })
            .catch((error)=>{
                console.log('updated error',error);
            })
    }

    const stateNames = [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal'
      ];
      
      const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setDefaultValue1(isChecked ? 1 : 0);
      };
    

    
    
  return (
    <>
    <NavMenu/>
    <Toaster />
     <div className='editAddress'>
       <h1 className='addressEditLine'>Edit Address</h1> 
       <div className="checkorder1">
       {defaultValue == 1 ? (<input
                          type="checkbox"
                          className="checkbox"
                          id="checkbox1"
                          checked
                        />):(<input
                            type="checkbox"
                            className="checkbox"
                            id="checkbox1"
                            onChange={handleCheckboxChange}
                          />) }
                        <span class="checkmark"></span>
                        <p className="pointorder1">Set as default</p>
                      </div>
                                    <div className='payAddInputCon '>
                                        <p className='payDelvyLabel payAddInputCon1'>Name <div style={{ color: 'red' }}>*</div></p>
                                        <input value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='Name' className='PayDeliveryInput PayDeliveryInput1'  />
                                    </div>
                                    <div className='payAddInputCon '>
                                        <p className='payDelvyLabel payAddInputCon1'>Phone Number<div style={{ color: 'red' }}>*</div></p>
                                        <input type="name"  value={mobile} onChange={(e) => setMobile(e.target.value)}   placeholder='Phone Number' className='PayDeliveryInput PayDeliveryInput1'  />
                                    </div>
                                    <div className='payAddInputCon'>
                                        <p className='payDelvyLabel payAddInputCon1'>Address<div style={{ color: 'red' }}>*</div></p>
                                        <input type='name' value={address} onChange={(e) => setAddress(e.target.value)}  placeholder='Your address' className='PayDeliveryInput PayDeliveryInput1' />
                                    </div>
                                    <div className='payAddInputCon'>
                                        <p className='payDelvyLabel payAddInputCon1'>Landmark<div style={{ color: 'red' }}>*</div></p>
                                        <input type='name'  value={landmark} onChange={(e) => setLandmark(e.target.value)}   placeholder='Landmark' className='PayDeliveryInput PayDeliveryInput1' />
                                    </div>
                                    <div className='payAddInputCon '>
                                        <p className='payDelvyLabel payAddInputCon1'>Pincode<div style={{ color: 'red' }}>*</div></p>
                                        <input type='number' maxLength={6}  value={pincode} onChange={(e) => setPincode(e.target.value)}  placeholder='Pincode'  pattern="[7-9]{1}[0-9]{9}" className='PayDeliveryInput PayDeliveryInput1' />
                                    </div>
                                    <div className='payAddInputCon '>
                                        <p className='payDelvyLabel payAddInputCon1'>City<div style={{ color: 'red' }}>*</div></p>
                                        <input type='name' value={city} onChange={(e) => setCity(e.target.value)}  placeholder='City' className='PayDeliveryInput PayDeliveryInput1' />
                                    </div>
                                    <div className='payAddInputCon '>
                                        <p className='payDelvyLabel payAddInputCon1'>State<div style={{ color: 'red' }}>*</div></p>
                                        <input type='name' value={state} onChange={(e) => setState(e.target.value)} placeholder='State' className='PayDeliveryInput PayDeliveryInput1' />
                                    </div>
                                    {/* <div className='payAddInputCon'>
                                <p className='payDelvyLabel payAddInputCon1'>State<div style={{ color: 'red' }}>*</div></p>
                                  <FormControl className='PayDeliveryInput PayDeliveryInput1'>
                                  <InputLabel id="schoolBoard-label">{state}</InputLabel>

                                     <Select
                                       labelId='state-label'
                                       id='state'
                                       placeholder='State'
                                       defaultValue={state}
                                       onChange={(e) => {setState(e.target.value);}} // Set the default value if needed
                                     >
                                       {stateNames.map((states, index) => (
                                         <MenuItem key={index} value={states}>
                                           {states}
                                         </MenuItem>
                                       ))}
                                    </Select>
                                  </FormControl>
                                </div> */}
                                    <div className='payAddInputCon '>
                                        <p className='payDelvyLabel payAddInputCon1'>Country<div style={{ color: 'red' }}>*</div></p>
                                        <input type='name' value={country} onChange={(e) => setCountry(e.target.value)}   placeholder='Country' className='PayDeliveryInput PayDeliveryInput1' />
                                    </div>
                                    <div className='payAddInputCon '>
                                        <p className='payDelvyLabel payAddInputCon1'>Address Type<div style={{ color: 'red' }}>*</div></p>
                                        <input type='name'  value={addressType} onChange={(e) => setAddressType(e.target.value)}  placeholder='e.g "Home, Work, Office..."' className='PayDeliveryInput PayDeliveryInput1' />
                                    </div>
                                    <button onClick={updatedAddress} style={{ textTransform: 'capitalize' }} className='totalamtbtn' >Update Address</button>
         </div>
         <Footer/>
    </>
  )
}

export default EditAddress