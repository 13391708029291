import React, { useCallback, useEffect, useState, useRef } from "react";
import NavMenu from "./Navbar";
import Footer from "./Footer";
import "../assets/style.css";
import img from "../assets/img/Home/NewFeature1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getUserAddressbyId,
  getSummary,
  getcartbyId,
  getcartId,
  getUserAddress,
  getStoredOrder2,
  getTheOrder,
  getStoredOrder,
  getCartByItsId,
  generateTheToken,
  } from "../api/api";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BiWallet } from "react-icons/bi";
import { SiRazorpay } from "react-icons/si";
import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { TailSpin } from "react-loader-spinner";
import $ from "jquery";
import razarRay from "../assets/img/profile/7713209.png";
// import useRazorpay from "react-razorpay";
import useScript from "react-script-hook";
// import { SHA512 } from 'crypto-js';
import { log } from "util";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import worldLine from "../assets/img/Home/worldlineImg-removebg-preview.png";
import { Modal, ModalHeader } from 'react-bootstrap';

const min = 1000000000; // Minimum 10-digit number
const max = 9999999999; // Maximum 10-digit number
var transactionId = Math.floor(Math.random() * (max - min + 1)) + min;
var transionString = transactionId.toString();

const PaymentPage = () => {
  const location = useLocation();
  const { cuponDiscountInput } = location?.state;
  const storedData = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const [delivery, setDelivery] = useState(true);
  const [payment, setPayment] = useState(false);
  const [comfirm, setComfirm] = useState(false);
  const [address, setaddress] = useState([]);
  const [noAddressFound, setnoAddressFound] = useState(false);
  const [selectedAddress, setselectedAddress] = useState("");
  const [cartData, setCartData] = useState([]);
  const [cartFullData, setCartFullData] = useState([]);
  const [summaryInfo, setsummaryInfo] = useState([]);
  const [noproduct, setNoProduct] = useState(false);
  const [cartTotalPrice, setcartTotalPrice] = useState(0);
  const [getCartPId, setgetCartPId] = useState([]);
  const [getCartPQty, setgetCartPQty] = useState([]);
  const [showAddressForm, setshowAddressForm] = useState(false);
  const [showSelectAddressErrorMessaage, setshowSelectAddressErrorMessaage] = useState(false);
  const [addAddressLoding, setaddAddressLoding] = useState(false);
  const [paymentLoding, setpaymentLoding] = useState(false);
  const [confirmationPageVisited, setConfirmationPageVisited] = useState(false);
  const [disablePayment, setdisablePayment] = useState(true);
  const [disableDelivery, setdisableDelivery] = useState(true);
  const [disableOrderConfirmation, setdisableOrderConfirmation] = useState(true);
  const [status, setStatus] = useState(false);
  const [quantity, setquantity] = useState([]);
  // const [pointsInput, setpointsInput] = useState(cuponDiscountInput);
  const [paymentMode, setpaymentMode] = useState(0);
  const [order, setOrder] = useState([]);
  const [showEditBasket, setshowEditBasket] = useState(true);
  // const Razorpay = useRazorpay();
  const [orderData, setorderData] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [cartValues, setCartValues] = useState(false);
  const [isLoding, setisLoding] = useState(false);
  const [response, setResponse] = useState(null);
  const [enableNewWindowFlow, setEnableNewWindowFlow] = useState(false);
  const [tokenValue, setTokenValue] = useState("");
  const pnCheckoutRef = useRef(null);
  const { verificationValue, respStatus } = location.state;
  const [pageLoading, setPageLoading] = useState(true);

  // const { Instamojo } = useScript({ src:"https://js.instamojo.com/v1/checkout.js",  })
  const pointCoupon = localStorage.getItem("pointstate");
  let cartStatus = localStorage.getItem("cartStatus");

  useEffect(() => {
    //  const hello = localStorage.getItem('shippingPrice')
    console.log("==shiiping price ==>", cartStatus);
    if (storedData.userLoginSuccess) {
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
      console.log("==store id ==", storedData.userId);
      getUserAddress_func(storedData.userId);
      getCartData(storedData.userId);
      getOrder(storedData.userId);
      generateToken();
      console.log("==transaction id", transionString);
      console.log("==transaction id", storedData);
    } else {
      getCartData(null);
    }
    window.scrollTo(0, 0);
    pnCheckoutRef.current = window.pnCheckout;
    jQuerycode();
  }, []);

  useEffect(() => {
    if (respStatus) {
      getCartData(storedData.userId);
      storedTheOrder();
      console.log("==>verification value", verificationValue);
      console.log(respStatus);
    } else {
      console.log("==tokenDesired==", verificationValue);
      console.log("==tokenvalue==", verificationValue);
      console.log(respStatus);
    }
  }, []);

  const getCartData = async () => {
    // await setpointsInput(cuponDiscountInput);
    console.log("==points input==>", pointCoupon);
    const url = getcartbyId();
    var body = {
      discount: pointCoupon,
      user_id: storedData.userId,
    };
    console.log(" = = = url = = =", body);

    await axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log(" = = =cartdata = = =", resp.data);
        setCartData(resp.data.data);
        if (!resp.data.status) {
          setNoProduct(true);
        } else {
          setNoProduct(false);
          setCartFullData(resp.data);
          setCartData(resp.data.data);
          setcartTotalPrice(resp.data.cart_price);
          setgetCartPId(resp.data.products);
          console.log("---select The Products", resp.data.summary);
          // setgetCartPQty(resp.data.quantity);

          setsummaryInfo(resp.data.summary);

          setquantity(resp.data[3].quantity);

          // getSummaryValue()
        }
      })
      .catch((error) => {
        console.log(" - - - error - - -", error);
      });

    // console.log("in state cart data ==> ", cartFullData);
  };

  const deleteCard = (id) => {
    let data = new FormData();
    data.append("_method", "DELETE");
    const url = getCartByItsId(id);
    console.log("--->url of delete cart", url);
    axios
      .post(url, data)
      .then((resp) => {
        console.log(" = = = deleted card = = ", resp.data);
        if (storedData.userLoginSuccess) {
          getCartData();
          setCartValues(true);
        } else {
          getCartData(null);
        }
      })
      .catch((error) => {
        console.log("--error--", error);
      });
  };

  const storedTheOrder1 = () => {
    setisLoding(true);
    var pIdArray = [];
    cartData.map(async (item) => {
      // console.log("jhhiho ==>", item.product_id);
      await pIdArray.push(item.quantity);
    });

    const url = getStoredOrder2();
    var body = {
      discount: localStorage.getItem("pointstate"),
      status: "Order Confirmed",
      coupon: "0",
      product_id: productData1,
      user_id: storedData.userId,
      shipping_charge: shippingPrice,
      price: localStorage.getItem("DISCOUNT_ON_MRP"),
      final_price: localStorage.getItem("toalAMtAfterPoints"),
      quantity: quantityData1,
      reward_point: localStorage.getItem("POINTS_GET"),
      address_id: localStorage.getItem("addressId"),
      tax: localStorage.getItem("GST"),
      ...(localStorage.getItem('couponAMount') != 0 ? { gift_code_price: localStorage.getItem('couponAMount') } : {}),
      ...(localStorage.getItem('couponAMountNumber') != 0 ? { gift_code : localStorage.getItem('couponAMountNumber') } : {}),
    ...(localStorage.getItem('couponAMount2Name') != 0 ? { coupon_code: localStorage.getItem('couponAMount2Name') } : {}),
    ...(localStorage.getItem('couponAMount2') != 0 ? { coupon_discount: localStorage.getItem('couponAMount2') } : {}),
      payment_mode: "cod",
      is_discount: true,
      // quantity: quantity,
      response_id: '',
      response_payment_id: "",
      response_amount: localStorage.getItem("toalAMtAfterPoints"),
      response_currency: "Rs",
      response_billing_instrument:
        "",
      response_instrument_type: "CARD",
      response_status: "Credit",
      response_created_at: '',
      RESPCODE: '',
      RESPMSG:'',
      BANKTXNID: '',
      CHECKSUMHASH: '',
      merchant_id: "",
      GATEWAYNAME: "",
      BANKNAME: '',
      response: '',
    };

    console.log("==productid==", getCartPId);
    console.log("--body-value--", body);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("---userStoredData", resp.data.data);
                setisLoding(false);
        navigate(`/Confirmation/${resp.data.data}`, {
          state: {
            orderData: resp.data.data,
          },
        });
              console.log("---userStoredData2", orderData);
        localStorage.removeItem("toalAMtAfterPoints");
        localStorage.removeItem("DISCOUNT_ON_MRP");
        localStorage.removeItem("cartNumber");
        localStorage.removeItem("productQuantity");
        localStorage.removeItem("productQuantity1");
        localStorage.removeItem("POINTS_GET");
        localStorage.removeItem("TOTAL_ON_MRP");
        localStorage.removeItem("GST");
        localStorage.removeItem('couponAMount') 
        localStorage.removeItem('couponAMount2') 
        localStorage.removeItem('couponAMountNumber') 
        localStorage.removeItem('couponAMount2Name')
        localStorage.removeItem('addressId')
      })
      .catch((err) => {
        console.log("--error--", err);
        setisLoding(false);
      });
  };

  const productData = localStorage.getItem("productQuantity");
  const productData1 = JSON.parse(productData);

  const quantityData = localStorage.getItem("productQuantity1");
  const quantityData1 = JSON.parse(quantityData);

  const totalPoint = localStorage.getItem("DISCOUNT_ON_MRP");
  let shippingPrice = totalPoint < 500 ? 150 : 0;
  localStorage.setItem("shippingPrice", shippingPrice);

  useEffect(() => {
    console.log(shippingPrice);
  });

  const storedTheOrder = () => {
    setisLoding(true);
    var pIdArray = [];
    cartData.map(async (item) => {
      // console.log("jhhiho ==>", item.product_id);
      await pIdArray.push(item.quantity);
    });

    const url = getStoredOrder();
    var body = {
      discount: localStorage.getItem("pointstate"),
      status: "Order Confirmed",
      coupon: "0",
      product_id: productData1,
      user_id: storedData.userId,
      shipping_charge: shippingPrice,
      price: localStorage.getItem("DISCOUNT_ON_MRP"),
      final_price: localStorage.getItem("toalAMtAfterPoints"),
      quantity: quantityData1,
      reward_point: localStorage.getItem("POINTS_GET"),
      address_id: localStorage.getItem("addressId"),
      tax: localStorage.getItem("GST"),
      ...(localStorage.getItem('couponAMount') != 0 ? { gift_code_price: localStorage.getItem('couponAMount') } : {}),
      ...(localStorage.getItem('couponAMountNumber') != 0 ? { gift_code : localStorage.getItem('couponAMountNumber') } : {}),
    ...(localStorage.getItem('couponAMount2Name') != 0 ? { coupon_code: localStorage.getItem('couponAMount2Name') } : {}),
    ...(localStorage.getItem('couponAMount2') != 0 ? { coupon_discount: localStorage.getItem('couponAMount2') } : {}),
      payment_mode: "worldline",
      is_discount: true,
      // quantity: quantity,
      response_id: verificationValue.tpsl_txn_id,
      response_payment_id: "MOJO3704A05A79942508",
      response_amount: localStorage.getItem("toalAMtAfterPoints"),
      response_currency: "Rs",
      response_billing_instrument:
        "International Regular Credit Card (Visa/Mastercard)",
      response_instrument_type: "CARD",
      response_status: "Credit",
      response_created_at: verificationValue.tpsl_txn_time,
      RESPCODE: verificationValue.txn_status,
      RESPMSG: verificationValue.txn_msg,
      BANKTXNID: verificationValue.BankTransactionID,
      CHECKSUMHASH: verificationValue.hash,
      merchant_id: "T954243",
      GATEWAYNAME: "worldline",
      BANKNAME: verificationValue.alias_name,
      response: verificationValue,
    };

    // console.log('==productid==',summaryInfo);
    // console.log('==productidtotla==',summaryInfo.TOTAL_AMOUNT_AFTER_POINTS);
    console.log("--body-value--", body);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("---userStoredData", resp.data);
        setisLoding(false);
        // navigate('/Confirmation' , {
        //   state: {
        //    orderData:resp.data.data
        //   }
        // })
        if (!resp.data.status) {
          alert("payment is unsuccessful");
        } else {
          navigate(`/Confirmation/${resp.data.data}`, {
            state: {
              orderData: resp.data.data,
            },
          });
        }

        console.log("---userStoredData2", orderData);
        //  localStorage.clear()
        localStorage.removeItem("toalAMtAfterPoints");
        localStorage.removeItem("DISCOUNT_ON_MRP");
        localStorage.removeItem("cartNumber");
        localStorage.removeItem("productQuantity");
        localStorage.removeItem("productQuantity1");
        localStorage.removeItem("POINTS_GET");
        localStorage.removeItem("TOTAL_ON_MRP");
        localStorage.removeItem("GST");
        localStorage.removeItem('couponAMount') 
        localStorage.removeItem('couponAMount2') 
        localStorage.removeItem('couponAMountNumber') 
        localStorage.removeItem('couponAMount2Name')
        localStorage.removeItem('addressId')
      })
      .catch((err) => {
        console.log("--error--", err);
        setisLoding(false);
      });
  };

  const getSummaryValue = async () => {
    var pIdArray = await [];
    cartData.map(async (item) => {
      // console.log("jhhiho ==>", item.product_id);
      await pIdArray.push(item.quantity);
    });

    const url = getSummary();
    const body = {
      discount: "0",
      coupon: "0",
      products: getCartPId,
      user_id: storedData.userId,
      price: cartTotalPrice,
      quantity: pIdArray,
    };

    // console.log("----body of summarty---", url, body);
    await axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        if (resp.data.status) {
          // setsummaryInfo(resp.data.data);
        }
        // console.log("---get summary ===> ", resp.data);
      })
      .catch((error) => {
        console.log("- - - error - - -", error);
      });
  };

  const getUserAddress_func = (id) => {
    const url = getUserAddressbyId(id);

    axios
      .get(url)
      .then((resp) => {
        // console.log("Address info ==> ", url, resp.data.data);
        if (resp.data.data == "") {
          setnoAddressFound(true);
          setStatus(true);
        } else {
          setaddress(resp.data.data);
          setnoAddressFound(false);
          console.log(" -- -  -address", resp.data);
        }
      })
      .catch((error) => {
        console.log(" - - -error", error);
        setnoAddressFound(true);
      });
  };

  useEffect(() => {
    if (address.length > 0 && !selectedAddress) {
      const defaultAddressId = address[0].id;
      setselectedAddress(defaultAddressId);
      localStorage.setItem("addressId", defaultAddressId);
    }
  }, [address, selectedAddress]);

  const handleAddress = (id) => {
    setselectedAddress(id);
    // console.log("address id ", id, selectedAddress);
    localStorage.setItem("addressId", id);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data.phoneNumber.length === 10) {
      setStatus(true);
    } else {
      alert("Please enter a valid phone number.");
      setStatus(false);
    }

    if (data.pincode.length === 6) {
      setStatus(true);
    } else {
      alert("Please enter a valid pincode number.");
      setStatus(false);
    }
    if (status) {
      setaddAddressLoding(true);
      const url = getUserAddress();
      var body = {
        user_id: storedData.userId,
        name: data.name,
        mobile: data.phoneNumber,
        city: data.city,
        territory: data.state,
        address: data.addres,
        pincode: data.pincode,
        landmark: data.landmark,
        country: data.country,
        type_of_address: data.addressType,
      };

      // console.log(" url & body ==> ", url, "   ", body);

      axios
        .post(url, body, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          // console.log("resp in adding address ==> ", resp.data);
          if (resp.data.status) {
            toast.success("Address Added", { icon: "📍" });
            window.scrollTo(0, 0);
            setshowAddressForm(false);
            setTimeout(() => {
              setaddAddressLoding(false);
              getUserAddress_func(storedData.userId);
            }, 1000);
          } else {
            toast.success("Enter valid data", { icon: "⚠️" });
            window.scrollTo(0, 0);
            setTimeout(() => {
              setaddAddressLoding(false);
            }, 1000);
          }
        })
        .catch((err) => {
          // console.log("err in saving address ->< ", err);
          toast.success("!Oops Something Went Wrong", { icon: "⚠️" });
          window.scrollTo(0, 0);
          setTimeout(() => {
            setaddAddressLoding(false);
          }, 1000);
        });
    }
  };

  const goToCart = () => {
    navigate("/Cart");
  };

  const moveToPayments = () => {
    if (selectedAddress == "") {
      setshowSelectAddressErrorMessaage(true);
      toast.error("please select one address", { icon: "⚠️" });
      setDelivery(true);
      setComfirm(false);
      setPayment(false);
    } else {
      setshowSelectAddressErrorMessaage(false);
      setDelivery(false);
      setComfirm(false);
      setPayment(true);
      setdisablePayment(false);
    }
    window.scrollTo(0, 0);
    // console.log(" paymet message --> ", showSelectAddressErrorMessaage);
  };

  const getOrder = (id) => {
    const url = getTheOrder(id);

    axios
      .get(url)
      .then((resp) => {
        // console.log('..--..--..', resp.data.data);
        setOrder(resp.data.data);
        // console.log('sate info ==> ', order );
      })
      .catch((error) => {
        console.log("errerrerrerr", error);
      });
  };

  const moveToOrderconfirmation = () => {
    if (paymentMode == "") {
      setDelivery(false);
      setComfirm(false);
      setPayment(true);
      setshowEditBasket(false);
    } else {
      setDelivery(false);
      setComfirm(true);
      setPayment(false);
      setdisableOrderConfirmation(false);
      setshowEditBasket(false);
      window.scrollTo(0, 0);
    }
    setConfirmationPageVisited(true);
  };

  const goToConfirmation = () => {
    if (paymentMode === "cod") {
      storedTheOrder1();
    } else if (paymentMode === "worldline") {
      handlePayClick();
    } else if (paymentMode === "ccavnue") {
      handleCcAvenue();
    } else {
      alert("please the payment method");
    }
    // initiateInstamojoPayment()

    // <Scanner/>
    // deleteCard(storedData.userId)

    // handlePay();
    // navigate('/Confirmation')
    // handlePay1()
  };

  const stateNames = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const RenderNewAddressComponent = () => {
    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            {/* <h1 className='deliveryHeadings'>Delivery Address</h1> */}
            {/* <div>
                          <input type='mail' placeholder='Email Address' className='PayDeliveryInput' />
                          <span>*</span>
                      </div> */}
            <div style={{}}>
              <div className="payAddInputCon">
                <p className="payDelvyLabel">
                  Name <div style={{ color: "red" }}>*</div>
                </p>
                <input
                  type="name"
                  {...register("name", { required: true })}
                  placeholder="Name"
                  className="PayDeliveryInput"
                />
              </div>
              <div className="payAddInputCon">
                <p className="payDelvyLabel">
                  Phone Number<div style={{ color: "red" }}>*</div>
                </p>
                <input
                  type="name"
                  {...register("phoneNumber", {
                    required: true,
                  })}
                  placeholder="Phone Number"
                  className="PayDeliveryInput"
                  maxLength={10}
                />
              </div>
              <div className="payAddInputCon">
                <p className="payDelvyLabel">
                  Address<div style={{ color: "red" }}>*</div>
                </p>
                <input
                  type="name"
                  {...register("addres", { required: true })}
                  placeholder="Your address"
                  className="PayDeliveryInput"
                />
              </div>
              <div className="payAddInputCon">
                <p className="payDelvyLabel">
                  Landmark<div style={{ color: "red" }}>*</div>
                </p>
                <input
                  type="name"
                  {...register("landmark", { required: true })}
                  placeholder="Landmark"
                  className="PayDeliveryInput"
                />
              </div>
              <div className="payAddInputCon">
                <p className="payDelvyLabel">
                  Pincode<div style={{ color: "red" }}>*</div>
                </p>
                <input
                  type="name"
                  maxLength={6}
                  {...register("pincode", {
                    required: true,
                    maxLength: 6,
                  })}
                  placeholder="Pincode"
                  className="PayDeliveryInput"
                />
              </div>
              <div className="payAddInputCon">
                <p className="payDelvyLabel">
                  City<div style={{ color: "red" }}>*</div>
                </p>
                <input
                  type="name"
                  {...register("city", { required: true })}
                  placeholder="City"
                  className="PayDeliveryInput"
                />
              </div>
              <div className="payAddInputCon">
                <p className="payDelvyLabel">
                  State<div style={{ color: "red" }}>*</div>
                </p>
                <input
                  type="name"
                  {...register("state", { required: true })}
                  placeholder="State"
                  className="PayDeliveryInput"
                />
              </div>
              {/* <div className='payAddInputCon'>
                                <p className='payDelvyLabel'>State<div style={{ color: 'red' }}>*</div></p>
                                  <FormControl className='PayDeliveryInput PayDeliveryInput1'>
                                     <Select
                                       labelId='state-label'
                                       id='state'
                                       {...register('state', { required: true })}
                                       placeholder='State'
                                       defaultValue='' // Set the default value if needed
                                     >
                                       {stateNames.map((states, index) => (
                                         <MenuItem key={index} value={states}>
                                           {states}
                                         </MenuItem>
                                       ))}
                                    </Select>
                                  </FormControl>
                                </div> */}

              <div className="payAddInputCon">
                <p className="payDelvyLabel">
                  Country<div style={{ color: "red" }}>*</div>
                </p>
                <input
                  type="name"
                  {...register("country", { required: true })}
                  placeholder="Country"
                  className="PayDeliveryInput"
                />
              </div>
              <div className="payAddInputCon">
                <p className="payDelvyLabel">
                  Address Type<div style={{ color: "red" }}>*</div>
                </p>
                <input
                  type="name"
                  {...register("addressType", { required: true })}
                  placeholder='e.g "Home, Work, Office..."'
                  className="PayDeliveryInput"
                />
              </div>
            </div>

            <button
              type="submit"
              style={{ textTransform: "capitalize" }}
              className="totalamtbtn"
              disabled={addAddressLoding ? true : false}
            >
              {addAddressLoding ? (
                <TailSpin
                  height="30"
                  width="30"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                "Save Address"
              )}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const RenderSavedAddress = () => {
    return (
      <div style={{}}>
        {address.map((item) => {
          return (
            <div
              onClick={() => handleAddress(item.id)}
              style={{ paddingBottom: 15 }}
            >
              <div
                style={{
                  padding: 10,
                  border:
                    item.id == selectedAddress
                      ? "3px solid #ffd11b"
                      : "1px solid lightgray",
                  borderRadius: 10,
                  width: "100%",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems:"start",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <p className="addressDetails2">{item.name}</p> */}
                  <div>
                  {
                      item.is_default == 1 && (<>
                    <p className='addressHome2'>Default</p>
                      </>)
                    }
                    <p className='addressDetails2'>{item.name}</p>
                  </div>
                  <p className="addressHome">{item.type_of_address}</p>
                 
                </span>
                <p className="addressDetails">
                  {" "}
                  <strong>Mob no:</strong> {item.mobile}
                </p>
                <p className="addressDetails">
                  <strong>Address:</strong> {item.address}, {item.landmark},{" "}
                  {item.city}, {item.territory}, {item.country} ,{item.pincode}
                </p>
                <p className="addressDetails">
                  <strong>Landmark:</strong> {item.landmark}
                </p>
              </div>
              {/* {showSelectAddressErrorMessaage ? (
                <p style={{ color: "red", fontSize: 15, marginTop: "2px" }}>
                  Please Pick One Address
                </p>
              ) : null} */}
            </div>
          );
        })}
      </div>
    );
  };

  const generateToken = () => {
    const url = generateTheToken();
    const body = {
      value1: "L954243",
      value2: transionString,
      value3: localStorage.getItem("toalAMtAfterPoints"),
      value4: "",
      value5: storedData.userId,
      value6: storedData.userNumber,
      value7: storedData.userEmail,
      value8: "",
      value9: "",
      value10: "",
      value11: "",
      value12: "",
      value13: "",
      value14: "",
      value15: "",
      value16: "",
      avlue17: "4380966650VNJYXS",
    };
    console.log("token body", body);
    axios
      .post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        console.log("==>", resp.data);
        setTokenValue(resp.data.guessed_token);
      })
      .catch((error) => {
        console.log("==>error", error);
      });
  };

  function handleResponse(res) {
    console.log("---", res);
    if (
      typeof res !== "undefined" &&
      typeof res.paymentMethod !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction.statusCode !== "undefined" &&
      res.paymentMethod.paymentTransaction.statusCode === "0300"
    ) {
      // Payment was successful, you can proceed with further actions here
      // For example, you can trigger the returnUrl here
    } else if (
      typeof res !== "undefined" &&
      typeof res.paymentMethod !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction !== "undefined" &&
      typeof res.paymentMethod.paymentTransaction.statusCode !== "undefined" &&
      res.paymentMethod.paymentTransaction.statusCode === "0398"
    ) {
      // Payment was initiated
    } else {
      // Payment encountered an error
    }
  }

  function handlePayClick() {
    console.log("hello");
    const configJson = {
      tarCall: false,
      features: {
        showPGResponseMsg: true,
        enableExpressPay: true,
        enableNewWindowFlow: true,
      },

      // T954243|1694598321583|10||c964634|9876543210|test@test.com||||||||||4380966650VNJYXS
      consumerData: {
        deviceId: "WEBSH2", //possible values "WEBSH1" or "WEBSH2"
        token: tokenValue,
        returnUrl:
          "https://admin.creativecarcare.in/api/token_generator/index ", //merchant response page URL
        // "returnUrl": "localhost:3000/verification",    //merchant response page URL
        responseHandler: handleResponse,
        paymentMode: "all",
        merchantLogoUrl:
          "https://www.paynimo.com/CompanyDocs/company-logo-vertical.png", //provided merchant logo will be displayed
        merchantId: "L954243",
        currency: "INR",
        consumerId: storedData.userId,
        consumerMobileNo: storedData.userNumber,
        consumerEmailId: storedData.userEmail,
        txnId: transionString, //Unique merchant transaction ID
        items: [
          {
            itemId: "FIRST",
            amount: localStorage.getItem("toalAMtAfterPoints"),
            // "amount": summaryInfo.TOTAL_AMOUNT_AFTER_POINTS,
            comAmt: "0",
          },
        ],
        customStyle: {
          PRIMARY_COLOR_CODE: "#45beaa", //merchant primary color code
          SECONDARY_COLOR_CODE: "#FFFFFF", //provide merchant's suitable color code
          BUTTON_COLOR_CODE_1: "#2d8c8c", //merchant's button background color code
          BUTTON_COLOR_CODE_2: "#FFFFFF", //provide merchant's suitable color code for button text
        },
      },
    }; // <-- Add a comma here
    console.log("payent value", configJson);

    // console.log(" dsdssd => ", $);
    // $(document).ready(function() {
    // Initialize the plugin or call pnCheckout here
    window.$.pnCheckout(configJson);
    if (configJson.features.enableNewWindowFlow) {
      window.$.pnCheckoutShared.openNewWindow();
    }
    // });

    //   var a = "sas"

    // if (pnCheckout) {
    //     console.log("pnCheckoutRef",pnCheckout)
    //     pnCheckout.current(configJson);
    //     console.log('pncheck1');
    //     if (configJson.features.enableNewWindowFlow && window.pnCheckoutShared) {
    //       window.pnCheckoutShared.openNewWindow();
    //       console.log('pncheck2');
    //     }else{
    //       console.log('pncheck3');
    //     }
    //   }else{
    //     console.log('pncheck4',pnCheckout.current);
    //   }
  }

  function handleCcAvenue() {
    console.log("CCAvenue");
  }

  const jQuerycode = () => {
    $(".button").click(function () {
      $("p").css("color", "red");
    });
  };

  const handleModalClose = () => {
    console.log("Modal is closed, running effect in CartPage...");
  };

  return (
    <>
      <NavMenu
        onCloseModal={handleModalClose}
        cartBoolean={cartValues}
        setCartBoolean={setCartValues}
      />
      <Toaster />
      {pageLoading ? (
        <>
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
            }}
          >
            <TailSpin color="#ffd11b" />
          </div>
        </>
      ) : (
        <>
          {localStorage.getItem("cartStatus") == "true" ? (
            <>
              <div className="container paymentOverall">
                <div className="row">
                  <div className="pay col-lg-8 col-12">
                    {/* <div>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci vel voluptates sit voluptas illum eveniet consectetur corrupti suscipit nesciunt et, inventore velit quaerat ex deleniti ut facere, voluptate ab? Ducimus.</p>
            <button  className="button">Click here</button>
          </div> */}
                    {delivery && !confirmationPageVisited ? (
                      noAddressFound ? (
                        <div>
                          <p className="deliveryHeadings">No Address Added</p>
                          <h3 style={{ fontSize: 15, color: "#000" }}>
                            (Add new Delivery Address)
                          </h3>
                          <RenderNewAddressComponent />
                        </div>
                      ) : (
                        <>
                          <span className="mainDeliveryInfo">
                            <div style={{ marginBottom: "15px" }}>
                              <h3 className="deliveryHeadings">
                                My Saved Address
                              </h3>
                              <h3 style={{ fontSize: 15, color: "#000" }}>
                                {showAddressForm
                                  ? "(Add new Delivery Address)"
                                  : "(Select One Delivery Address)"}
                              </h3>
                            </div>
                            {showAddressForm ? (
                              <button
                                onClick={() => setshowAddressForm(false)}
                                className="addNew"
                              >
                                <i
                                  class="fa-regular fa-circle-xmark"
                                  style={{ fontSize: "37px" }}
                                ></i>
                              </button>
                            ) : (
                              <button
                                onClick={() => setshowAddressForm(true)}
                                className="addNew"
                              >
                                + NEW ADDRESS
                              </button>
                            )}
                          </span>
                          {showAddressForm ? (
                            <RenderNewAddressComponent />
                          ) : (
                            <>
                              <RenderSavedAddress />
                              <br />
                              <button
                                onClick={() => moveToPayments()}
                                type="submit"
                                style={{ textTransform: "uppercase" }}
                                className="paymentBtn"
                              >
                                {isLoding ? (
                                  <TailSpin
                                    height="30"
                                    width="30"
                                    color="#fff"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                  />
                                ) : (
                                  "Make Payment"
                                )}
                              </button>
                            </>
                          )}
                        </>
                      )
                    ) : payment && !confirmationPageVisited ? (
                      <div className="paymentDesktop">
                        <h1 className="deliveryHeadings">
                          Choose Payment Mode
                        </h1>
                        <div>
                          <div style={{ padding: "5px" }}>
                            <div className="paymentMethod">
                              <label style={{ paddingTop: "7px" }}>
                                <input
                                  onClick={(e) =>
                                    setpaymentMode(e.target.value)
                                  }
                                  type="radio"
                                  name="paymentMethod"
                                  value="worldline"
                                  style={{
                                    transform: "scale(1.8)",
                                    marginLeft: 20,
                                    paddingTop: 5,
                                  }}
                                />
                                {/* <SiRazorpay/> */}
                                <img
                                  src={worldLine}
                                  alt="worldlineICon"
                                  style={{
                                    marginLeft: "22px",
                                    fontSize: "23px",
                                    marginTop: "-6px",
                                    width: "14%",
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    marginLeft: "16px",
                                    marginTop: "18px",
                                  }}
                                >
                                  Worldline
                                </span>
                              </label>
                            </div>
                            {/* <div className="paymentMethod2">
                          <label style={{ marginTop: "7px" }}>
                            <input
                              onClick={(e) => setpaymentMode(e.target.value)}
                              type="radio"
                              name="paymentMethod"
                              value="ccavnue"
                              style={{ transform: "scale(1.8)", marginLeft: 13 }}
                            />
                            <BiWallet
                              style={{
                                marginLeft: "22px",
                                fontSize: "24px",
                                marginTop: "-5px",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginLeft: "16px",
                              }}
                            >
                              CCAvenue
                            </span>
                          </label>
                        </div> */}
                            <div className="paymentMethod2">
                          <label style={{ marginTop: "7px" }}>
                            <input
                              onClick={(e) => setpaymentMode(e.target.value)}
                              type="radio"
                              name="paymentMethod"
                              value="cod"
                              style={{ transform: "scale(1.8)", marginLeft: 13 }}
                            />
                            <BiWallet
                              style={{
                                marginLeft: "40px",
                                fontSize: "24px",
                                marginTop: "-5px",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                marginLeft: "25px",
                              }}
                            >
                              COD 
                            </span>
                          </label>
                        </div>
                            <div>
                              <button
                                onClick={() => goToConfirmation()}
                                style={{ textTransform: "uppercase" }}
                                className="paymentBtn"
                              >
                                {isLoding ? (
                                  <TailSpin
                                    height="30"
                                    width="30"
                                    color="#fff"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                  />
                                ) : (
                                  "Continue"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="">
                          <div className="addressConfirmation">
                            {address.map((item) => {
                              return (
                                <>
                                  {" "}
                                  <h3
                                    style={{
                                      marginTop: "15px",
                                      fontFamily: "Oswald",
                                      marginBottom: "10px",
                                      fontSize: "25px",
                                    }}
                                  >
                                    <strong>Delivery Address</strong>{" "}
                                  </h3>
                                  <div
                                    onClick={() => handleAddress(item.id)}
                                    style={{
                                      border: "1px solid lightgray",
                                      borderRadius: 10,
                                      width: "100%",
                                      padding: 20,
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <p className="addressDetails2">
                                        {item.name}
                                      </p>
                                      <p className="addressHome">
                                        {item.type_of_address}
                                      </p>
                                    </span>

                                    <p className="addressDetails">
                                      {item.address} {item.landmark} {item.city}{" "}
                                      {item.pincode} {item.territory}
                                    </p>
                                    <p className="addressDetails">
                                      {" "}
                                      <strong>Mob no:</strong> {item.mobile}
                                    </p>
                                  </div>
                                  {order?.map((item) => {
                                    return (
                                      <>
                                        <div className="orderDetails">
                                          <div className="orderDetails2">
                                            <div style={{ display: "block" }}>
                                              <p className="orderStatus">
                                                <strong>
                                                  {item.payment_details.status}{" "}
                                                </strong>{" "}
                                              </p>
                                              <p className="order-status">
                                                Item will be delivered on: 20th
                                                Mar 2023
                                              </p>
                                              <p className="orderID">
                                                Order ID:
                                                {item.payment_details.order_id}{" "}
                                              </p>
                                              <p className="orderID2">
                                                <strong>
                                                  {item.order_details[0].title}
                                                </strong>
                                              </p>
                                              <p className="orderID3">
                                                <strong>
                                                  ₹ {item.order_details[0].mrp}
                                                </strong>
                                              </p>
                                            </div>

                                            <div className="orderImg">
                                              <img
                                                className="image-order"
                                                alt={
                                                  item.order_details[0].title
                                                }
                                                src={
                                                  item.order_details[0]
                                                    .image_url
                                                }
                                              />
                                            </div>
                                            <div></div>
                                          </div>
                                          <button className="trackBtn">
                                            TRACK
                                          </button>
                                          <button className="cancelRBtn">
                                            CANCEL
                                          </button>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-12">
                    <div className="container">
                      <div className="cartChart">
                        <p className="orderSummery">Order Summary</p>
                        {showEditBasket && (
                          <p
                            className="EditBasket"
                            style={{ cursor: "pointer" }}
                            onClick={goToCart}
                          >
                            Edit Basket
                          </p>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        {cartData.map((item) => (
                          <React.Fragment key={item.cart_id}>
                            <hr />

                            <div className="cartAllContext">
                              <div className="cartimage22">
                                <img
                                  src={item.image_url}
                                  className="cartimage"
                                  alt={item.title}
                                />
                              </div>
                            </div>
                            <div className=" cartContext22">
                              <p className="CartbrandTitle2">
                                {" "}
                                Brand :{item.brand_name}
                              </p>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  fontSize: "13px",
                                }}
                              >
                                Quantity:{item.quantity}
                              </p>
                              <p className="CartProTitle22">{item.title}</p>
                              <p className="stock"></p>

                              <div className="prizes">
                                <p className="cartPrize ">
                                  ₹ {item.starting_price}
                                </p>
                                {/* <p className="cartstartPrize">₹{item.starting_price}</p> */}
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                        <hr />
                      </div>

                      <div className="couponcalc2">
                        <h3 className="priceSummary">
                          Price Summary
                          <span
                            style={{
                              fontSize: "12px",
                              textTransform: "capitalize",
                            }}
                          >
                            : ( {cartData.length} items)
                          </span>
                        </h3>
                        <div className="row paymentRow">
                          <div className="col-6  priceDistribution">
                            <ul>
                              <li
                                className="paymentList"
                                style={{ marginBottom: "8px" }}
                              >
                                Total MRP
                              </li>
                              <li
                                className="paymentList"
                                style={{ marginBottom: "8px" }}
                              >
                                Selling Price
                              </li>

                              <li
                                className="paymentList"
                                style={{ marginBottom: "8px" }}
                              >
                                Point discount
                              </li>
                              <li
                                className="paymentList"
                                style={{ marginBottom: "8px" }}
                              >
                              Gift Voucher
                              </li>
                              <li
                                className="paymentList"
                                style={{ marginBottom: "8px" }}
                              >
                                Coupon
                              </li>
                              <li
                                className="paymentList"
                                style={{ marginBottom: "8px" }}
                              >
                                Delivery Charges
                              </li>
                              {/* <hr/> */}
                              <div className="hrLine"></div>
                              <li
                                className="paymentList"
                                style={{
                                  marginBottom: "8px",
                                  fontWeight: "500",
                                  marginTop: "15px",
                                }}
                              >
                                TOTAL AMOUNT :
                              </li>
                            </ul>
                          </div>
                          <div className="col-6 numericalPrize">
                            <ul>
                              <li
                                className="paymentNumber"
                                style={{ marginBottom: "8px" }}
                              >
                                ₹ {localStorage.getItem("TOTAL_ON_MRP")}
                              </li>
                              <li
                                className="paymentNumber"
                                style={{ marginBottom: "8px" }}
                              >
                                ₹ {localStorage.getItem("DISCOUNT_ON_MRP")}
                              </li>

                              <li
                                className="paymentNumber"
                                style={{ marginBottom: "8px" }}
                              >
                                ₹ {pointCoupon}
                              </li>
                              <li
                                className="paymentNumber"
                                style={{ marginBottom: "8px" }}
                              >
                                ₹ {localStorage.getItem('couponAMount') }
                              </li>
                              <li
                                className="paymentNumber"
                                style={{ marginBottom: "8px" }}
                              >
                                ₹ {localStorage.getItem('couponAMount2') }
                              </li>
                              <li
                                className="paymentNumber"
                                style={{ marginBottom: "8px" }}
                              >
                                ₹{" "}
                                {localStorage.getItem("DISCOUNT_ON_MRP") < 500
                                  ? 150
                                  : 0.0}
                              </li>
                              {/* <hr/> */}
                              <li
                                className="paymentNumber"
                                style={{
                                  marginBottom: "8px",
                                  fontWeight: "500",
                                  marginTop: "15px",
                                }}
                              >
                                ₹ {localStorage.getItem("toalAMtAfterPoints")}
                              </li>
                            </ul>
                          </div>
                          {/* <hr style={{ width: "75%", marginLeft: "45px" }} /> */}
                          {/* <div className="totalPrizes2">
            <h3 className="totalAmountinPaymnt">TOTAL AMOUNT : </h3>
            <h3 className="totalAmountinPaymnt2">
              {" "}
              INR {summaryInfo.TOTAL_AMOUNT_AFTER_POINTS}
            </h3>
          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </>
          ) : (
            <>
              <h2 style={{ marginTop: "140px", textAlign: "center" }}>
                No product In Cart
              </h2>
            </>
          )}
        </>
      )}
     <Modal show={isLoding}  centered size="lg" className='modalClass modalClass2'>
       
       <Modal.Body style={{height:'100px', background:'rgba(241, 241, 241, 1)'}}>
        <p style={{textAlign:'center'}}>Your order is being processed</p>
        <div style={{display:'flex', justifyContent:'center'}}>
        <TailSpin
          height="30"
          width="30"
          color="red"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        </div>
       </Modal.Body>
     </Modal>
    </>
  );
};

export default PaymentPage;
