import React from 'react'
import NavMenu from './Components/Navbar'
import Discription from './Components/Discription'
import Footer from './Components/Footer'
import { BrowserRouter, Route, Routes} from 'react-router-dom'
import Cart from './Components/Cart/Cart'
import ProductPage from './Components/ProductPage/ProductPage'
import BrandPage from './Components/BrandPage'
import Home from './Components/Home'
import './App.css';
import PageRoute from './Routes/PageRoute'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Provider } from 'react-redux';
import store from '../src/redux/store.js';


const App = () => {
  return ( 
    <>
      <PageRoute></PageRoute>
      {/* <Footer/> */}
      </>
  )
}

export default App


